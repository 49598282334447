import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {BaseCard} from '@components/commons/cards/BaseCard.tsx'
import {Button} from '@components/ui/button/Button.tsx'

export const StyledPatientMoodSection = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        color: ${palette.neutral[600]};

        & > h4 {
            font-weight: 500;
        }

        & > p {
            color: ${palette.neutral[400]};
            ${typography.textSm};
        }
    `
)

export const StyledPatientDetailsCardBody = styled(Flexbox)`
    ${({theme: {typography, palette}}) => css`
        overflow: hidden;
        & h4 {
            color: ${palette.neutral[600]};
            font-weight: 500;
            ${typography.textSm};
        }
        & p {
            font-weight: 500;
            ${typography.textMd};
            color: ${palette.neutral[900]};
            overflow: hidden;
            text-overflow: ellipsis;
        }
    `}
`

export const StyledMoodIconSkeleton = styled(Skeleton)`
    border-radius: 50%;
`
export const StyledPatientDetailsCard = styled(BaseCard)`
    height: auto;
    max-width: 280px;
    overflow: hidden;
`

export const StyledEditButton = styled(Button)`
    padding: 0;
`

export const StyledDeletePatientModalContent = styled(Flexbox)`
    ${({theme}) => css`
        color: ${theme.palette.neutral[900]};
        max-width: 330px;
        ${theme.typography.textSm};

        .description {
            ${theme.typography.textXl};
            font-weight: 500;
        }

        .information {
            font-weight: 700;
        }

        .paragraph {
            font-weight: 400;
        }
    `}
`
