import {useGetUserTrend} from '@/features/dashboard/services/useGetUserTrend.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {DashboardAdminUrlParams} from '@/features/dashboard/types.ts'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {ErrorIcon} from 'react-hot-toast'
import {useTranslation} from 'react-i18next'
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts'
import {adaptDataForUserTrendChart} from '@/features/dashboard/utils.ts'
import {useTheme} from 'styled-components'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'

export const UserTrendChart = () => {
    const {t} = useTranslation()
    const {searchParams} = useParsedSearchParams([], DashboardAdminUrlParams)
    const {data, isError, isLoading} = useGetUserTrend(searchParams)
    const {palette} = useTheme()

    return (
        <>
            {isLoading ? (
                <>
                    <Skeleton height={200} />
                </>
            ) : isError ? (
                <ErrorBox icon={<ErrorIcon />} title={t('errors:default')} />
            ) : (
                data && (
                    <ResponsiveContainer width={'100%'} height={200}>
                        <LineChart data={adaptDataForUserTrendChart(data)}>
                            <CartesianGrid vertical={false} />
                            <XAxis strokeOpacity={0} dy={8} dataKey={'name'} />
                            <YAxis strokeOpacity={0} dx={-4} domain={[0, 1000]} />
                            <Line
                                dot={false}
                                type={'monotone'}
                                dataKey="registeredUsers"
                                strokeWidth={4}
                                stroke={palette.primary[600]}
                                name={t(`dashboard:trend:registeredUsers`)}
                            />
                            <Line
                                dot={false}
                                type={'monotone'}
                                dataKey="currentlyInTherapyUsers"
                                strokeWidth={4}
                                stroke={palette.primary[400]}
                                name={t(`dashboard:trend:currentlyInTherapyUsers`)}
                            />
                            <Legend verticalAlign={'top'} align={'right'} iconType={'circle'} />
                            <Tooltip />
                        </LineChart>
                    </ResponsiveContainer>
                )
            )}
        </>
    )
}
