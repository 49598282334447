import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'

export const StyledNotificationButton = styled(Button)`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 2}px;
        position: relative;
        width: 40px;
        height: 40px;
    `}
`

export const StyledNotificationBadge = styled(Flexbox)`
    ${({theme: {palette}}) => css`
        position: absolute;
        top: -5px;
        right: -5px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: ${palette.danger[200]};
        color: ${palette.danger[600]};
        font-weight: 500;
        font-size: 8px;
        border: 1px solid ${palette.neutral[200]};
    `}
`
export const StyledNotificationHeader = styled(Flexbox)`
    ${({theme: {typography, palette, spacing}}) => css`
        ${typography.textMd};
        font-weight: 600;
        color: ${palette.neutral[700]};
        border-bottom: 1px solid ${palette.neutral[200]};
        padding-bottom: ${spacing * 6}px;
    `}
`

export const StyledWrapperNotification = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 4.5}px;
    `}
`

export const StyledNotificationItem = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: ${spacing * 3}px;
        border-bottom: 1px solid ${palette.neutral[200]};
        width: 100%;
        padding: ${spacing * 4}px 0 ${spacing * 2}px 0;
        cursor: default;

        .patient-name {
            ${typography.textSm};
            font-weight: 600;
            color: ${palette.neutral[600]};
            text-decoration: underline;
        }

        .appointment-info {
            ${typography.textSm};
            color: ${palette.neutral[500]};
            width: 100%;
            white-space: nowrap;
        }
    `}
`

export const StyledUnreadDot = styled.div`
    ${({theme: {palette}}) => css`
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: ${palette.primary[600]};
    `}
`

export const StyledWrapperBodyNotification = styled.div`
    ${() => css`
        height: 300px;
    `}
`

export const StyledListNotification = styled.div`
    display: flex;
    gap: 0;
    flex-direction: column;
`
