import {Table} from '@components/commons/table/Table.tsx'
import {useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {FileQuestion02Icon, Globe03Icon, Phone02Icon} from '@components/ui/icon/Icon.tsx'
import {formatLocaleDate} from '@utilities/helpers.ts'
import {EmptySearch} from '@components/commons/empty-search/EmptySearch.tsx'
import {generatePath, useNavigate} from 'react-router-dom'
import {useSelfEvaluationTable} from '@/features/self-evaluation/services/useSelfEvaluationTable.ts'
import {
    StyledSelfAssessmentOutcome,
    StyledSelfAssessmentTableId,
    StyledSelfAssessmentTableLink
} from '@/features/self-evaluation/components/self-evaluation-table/style.ts'
import {routes} from '@utilities/constants/routes.tsx'
import React from 'react'

export const SelfEvaluationTable = () => {
    const {t} = useTranslation()
    const selfAssessmentTable = useSelfEvaluationTable()
    const navigate = useNavigate()
    const onClickPatient = ({
        event,
        patientId
    }: {
        event: React.MouseEvent<HTMLElement>
        patientId: number | undefined
    }) => {
        event.stopPropagation()
        return navigate(generatePath(routes.PATIENT_DETAILS.handle.path, {patientId: patientId}))
    }
    return (
        <Table
            data={selfAssessmentTable.data || []}
            isLoading={selfAssessmentTable.isLoading}
            sorter={selfAssessmentTable.sorter}
            searchValue={selfAssessmentTable.searchValue}
            onClickRow={item =>
                navigate(generatePath(routes.SELF_ASSESSMENT_DETAILS.handle.path, {selfEvaluationId: item.id}))
            }
            emptyStateComponent={
                <EmptySearch
                    title={t('self_assessment:empty_search:title')}
                    subtitle={t('self_assessment:empty_search:description', {search: selfAssessmentTable.searchValue})}
                    onClearSearch={() => navigate(routes.SELF_ASSESSMENT.path)}
                    icon={<FileQuestion02Icon />}
                />
            }
            columns={[
                {
                    label: t('self_assessment:table:label:patient'),
                    width: '25%',
                    sortName: 'patient',
                    alignment: 'left',
                    cellRender: data => (
                        <Flexbox direction="column">
                            {data.user ? (
                                <StyledSelfAssessmentTableLink
                                    variant={'tertiaryColor'}
                                    onClick={event => onClickPatient({event: event, patientId: data.user?.id})}
                                >
                                    {`${data.user.lastName} ${data.user.firstName}`}
                                </StyledSelfAssessmentTableLink>
                            ) : (
                                <p>{t('self_assessment:filters:patient:not_register')}</p>
                            )}
                            <StyledSelfAssessmentTableId>{`ID ${data.id}`}</StyledSelfAssessmentTableId>
                        </Flexbox>
                    )
                },
                {
                    label: t('self_assessment:table:label:outcome'),
                    width: '25%',
                    sortName: 'result',
                    alignment: 'left',
                    cellRender: data => (
                        <StyledSelfAssessmentOutcome
                            $hasPositive={data.totalScore > data.selfEvaluationForm.minValidScore}
                        >
                            {data.totalScore > data.selfEvaluationForm.minValidScore
                                ? t('self_assessment:table:positive')
                                : t('self_assessment:table:negative')}
                        </StyledSelfAssessmentOutcome>
                    )
                },
                {
                    label: t('self_assessment:table:label:provenance'),
                    width: '25%',
                    alignment: 'left',
                    sortName: 'intakeSource',
                    cellRender: data => (
                        <Flexbox gap={3}>
                            {data.intakeSource ? (
                                <>
                                    {data.intakeSource === 'web' ? <Globe03Icon /> : <Phone02Icon />}
                                    <p>{t(`self_assessment:table:${data.intakeSource}`)}</p>
                                </>
                            ) : (
                                <>--</>
                            )}
                        </Flexbox>
                    )
                },
                {
                    label: t('patients:table:last_update'),
                    width: '25%',
                    sortName: 'createdAt',
                    alignment: 'left',
                    cellRender: data => formatLocaleDate(data.createdAt, 'D MMM YYYY, HH:mm')
                }
            ]}
        />
    )
}
