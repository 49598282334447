import {FC, ReactNode} from 'react'
import {BaseCard, BaseCardSectionedProps} from '@components/commons/cards/BaseCard.tsx'
import {Skeleton} from '@/components/ui/skeleton/Skeleton'
import {
    StyledCardSkeletonContainer,
    StyledCardStatsBody,
    StyledCardStatsFooter,
    StyledCardStatsHeader,
    StyledCardStatsSkeletonGrid
} from '@/components/commons/card-stats/style'

interface CardStatsProps extends BaseCardSectionedProps {
    asSkeleton?: true
    className?: string
    headerSlot?: ReactNode
    bodySlot?: ReactNode
    footerSlot?: ReactNode
}
export const CardStats: FC<CardStatsProps> = ({asSkeleton, className, headerSlot, bodySlot, footerSlot, ...props}) => {
    if (asSkeleton) {
        return (
            <BaseCard paddingX={4} paddingY={4} cardClassName={className} bodyHasBorder={false}>
                <StyledCardSkeletonContainer justify="space-between" align="center" fullWidth>
                    <Skeleton width={80} height={24} />
                    <Skeleton width={24} height={24} />
                </StyledCardSkeletonContainer>
                <StyledCardSkeletonContainer gap={2} direction="column" fullWidth>
                    <StyledCardStatsSkeletonGrid>
                        <Skeleton width={50} height={40} />
                        <Skeleton width="100%" height={40} />
                    </StyledCardStatsSkeletonGrid>
                    <Skeleton width="60%" height={20} />
                </StyledCardSkeletonContainer>
                <Skeleton width="100%" height={20} />
            </BaseCard>
        )
    }

    return (
        <BaseCard
            variant={'base'}
            bodyHasBorder={false}
            paddingX={4}
            paddingY={4}
            cardClassName={className}
            headerSlot={<StyledCardStatsHeader>{headerSlot}</StyledCardStatsHeader>}
            footerSlot={<StyledCardStatsFooter>{footerSlot}</StyledCardStatsFooter>}
            {...props}
        >
            {bodySlot && <StyledCardStatsBody>{bodySlot}</StyledCardStatsBody>}
        </BaseCard>
    )
}
