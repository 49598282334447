import {
    StyledDiarySectionContainer,
    StyledMoodCardTitle
} from '@/features/patient-details/components/diary-section/style.ts'
import {BaseCard} from '@components/commons/cards/BaseCard.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {DateRangeSelect} from '@components/commons/date-range-select/DateRangeSelect.tsx'
import {CalendarIcon, InfoCircleIcon} from '@components/ui/icon/Icon.tsx'
import {useGetPatientDetailsEmotion} from '@/features/patient-details/services/useGetPatientDetailsEmotion.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {PatientDetailsEmotionUrlParams, PatientDetailsURLParams} from '@/features/patient-details/types.ts'
import {useOutletContext} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {Tooltip} from '@components/ui/tooltip/Tooltip.tsx'
import {useTheme} from 'styled-components'
import {StyledEmotionLegendContainer} from '@/features/dashboard/components/genders-trend-chart/style.ts'
import {round} from '@utilities/helpers.ts'
import {MoodIcon} from '@components/commons/mood-icon/MoodIcon.tsx'
import {Emotion} from '@/features/auth/types.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {ErrorIcon} from 'react-hot-toast'
import {PatientDiary} from '@/features/patient-details/components/patient-diary/PatientDiary.tsx'

export const DiarySection = () => {
    const {t} = useTranslation()
    const {searchParams, setSearchParams} = useParsedSearchParams([], PatientDetailsEmotionUrlParams)
    const {urlParams} = useOutletContext<{urlParams: PatientDetailsURLParams}>()
    const mood = useGetPatientDetailsEmotion({patientId: urlParams.patientId, ...searchParams})
    const {palette} = useTheme()
    return (
        <StyledDiarySectionContainer>
            <PatientDiary />
            <div>
                <BaseCard paddingX={6} paddingY={5} bodyHasBorder={false}>
                    <Flexbox direction={'column'} gap={6}>
                        <DateRangeSelect
                            value={searchParams.emotionRangeDateLabels}
                            icon={<CalendarIcon />}
                            onChange={value => setSearchParams({emotionRangeDateLabels: value})}
                        />
                        <Flexbox direction={'column'} gap={5} fullWidth>
                            <Flexbox justify={'space-between'} fullWidth>
                                <StyledMoodCardTitle>{t('patient_details:diary_tab:card:title')}</StyledMoodCardTitle>
                                <Tooltip
                                    trigger={<InfoCircleIcon size={20} color={palette.neutral[400]} />}
                                    side="top"
                                    variant="light"
                                    content={t('patient_details:diary_tab:card:tooltip')}
                                />
                            </Flexbox>
                            {mood.isLoading ? (
                                Array.from({length: 5}).map((_, index) => (
                                    <StyledEmotionLegendContainer key={index}>
                                        <Skeleton height={32} />
                                        <Skeleton height={32} />
                                    </StyledEmotionLegendContainer>
                                ))
                            ) : mood.isError ? (
                                <ErrorBox icon={<ErrorIcon />} title={t('errors:default')} />
                            ) : (
                                mood.data?.partitions.map((value, index) => (
                                    <StyledEmotionLegendContainer key={index}>
                                        <p>{value.percentage ? `${round(value.percentage)}%` : value.total}</p>
                                        <MoodIcon
                                            variant={value.label as Emotion['name']}
                                            label={t(`commons:mood:${value.label}`)}
                                        />
                                    </StyledEmotionLegendContainer>
                                ))
                            )}
                        </Flexbox>
                    </Flexbox>
                </BaseCard>
            </div>
        </StyledDiarySectionContainer>
    )
}
