import {FC, useMemo} from 'react'
import {useGetContacts} from '@/features/settings/services/services-contact/useGetContacts.ts'
import {SettingsContactsSkeleton} from '@/features/settings/components/settings-contacts/SettingsContactsSkeleton.tsx'
import {ContactForm} from '@/features/settings/components/settings-contacts/ContactForm.tsx'

export const SettingsContacts: FC = () => {
    const {data, isLoading} = useGetContacts()

    const formattedContacts = useMemo(() => {
        return (
            data?.data?.map(office => {
                const emailContact = office?.contacts && office?.contacts.find(contact => contact.type === 'email')
                const phoneContact = office?.contacts && office?.contacts.find(contact => contact.type === 'phone')

                return {
                    id: office.id,
                    contact_id: office.id,
                    name: office.name,
                    address: office.address || null,
                    email: emailContact ? emailContact.value : null,
                    phone_number: phoneContact ? phoneContact.value : null
                }
            }) || []
        )
    }, [data])

    return <>{isLoading ? <SettingsContactsSkeleton /> : <ContactForm defaultContacts={formattedContacts} />}</>
}
