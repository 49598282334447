import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance'
import {captureException} from '@sentry/react'
import {httpGetPatientDetailsEmotion} from '@/features/patient-details/services/patientDetails.http.ts'
import {PatientEmotion} from '@/features/patients/types.ts'
import {RangeDateLabels, ResponseType} from '@/types.ts'
import {z} from 'zod'
import {getDateFromLabel} from '@utilities/utils.ts'

const UseGetPatientDetailsEmotion = z.object({
    patientId: z.coerce.number().optional(),
    emotionRangeDateLabels: RangeDateLabels
})
type UseGetPatientDetailsEmotion = z.infer<typeof UseGetPatientDetailsEmotion>

export const useGetPatientDetailsEmotion = (
    promiseArgs: UseGetPatientDetailsEmotion,
    options?: Omit<UseQueryOptions<PatientEmotion>, 'queryFn' | 'queryKey'>
) => {
    const {emotionRangeDateLabels, ...rest} = promiseArgs
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.PATIENTS_EMOTIONS, promiseArgs],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetPatientDetailsEmotion({
                    ...getDateFromLabel(emotionRangeDateLabels),
                    ...rest,
                    responseType: ResponseType.Enum.backofficeRegular
                }),
                responseShape: PatientEmotion,
                onZodError: captureException
            }),
        enabled: !!promiseArgs.patientId
    })
}
