import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {httpGetShiftsIndex} from '@/features/appointments/services/shift.http.ts'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {HttpShiftsResponse} from '@/features/appointments/types.ts'
import {captureException} from '@sentry/react'

export const useGetShiftsIndex = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_SHIFTS],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetShiftsIndex(),
                responseShape: HttpShiftsResponse,
                onZodError: captureException
            })
    })
}
