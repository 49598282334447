import {z} from 'zod'

export const CHANGE_PASSWORD_FORM_MODEL = {
    password: {
        name: 'password',
        label: 'change_password:form:password',
        placeholder: 'change_password:form:password',
        defaultValue: '',
        helpText: ''
    },
    confirmPassword: {
        name: 'confirmPassword',
        label: 'change_password:form:confirm_password',
        placeholder: 'change_password:form:confirm_password',
        defaultValue: '',
        helpText: ''
    }
} as const

export const ChangePasswordValidationSchema = z
    .object({
        password: z.string().min(6, {message: 'errors:password'}),
        confirmPassword: z.string().min(6, {message: 'errors:password'})
    })
    .refine(
        values => {
            return values.password == values.confirmPassword
        },
        {
            message: 'errors:password_match',
            path: ['confirmPassword']
        }
    )

export type ChangePasswordValidationSchema = z.infer<typeof ChangePasswordValidationSchema>
