import styled, {css} from 'styled-components'

type StyledChipProps = {
    $color: 'neutral' | 'primary' | 'secondary' | 'success' | 'danger' | 'warning'
    $variant?: 'filled' | 'outlined'
    $fullWidth?: boolean
}
export const StyledChip = styled.div<StyledChipProps>`
    ${({theme, $color, $variant, $fullWidth}) => css`
        display: inline-flex;
        align-items: center;
        padding: 0 ${theme.spacing * 2}px;
        border-radius: 50px;
        color: ${theme.palette[$color][700]};
        font-weight: 500;
        min-height: 22px;
        max-width: ${$fullWidth ? '100%' : '94px'};

        ${$variant === 'outlined' &&
        css`
            ${theme.typography.textSm};
            border: 2px solid ${theme.palette[$color][700]};
            background-color: transparent;
        `}

        ${$variant === 'filled' &&
        css`
            ${theme.typography.textXs};
            border: 1px solid ${theme.palette[$color][200]};
            background-color: ${theme.palette[$color][50]};
        `}
        
        
        & p {
            //truncate text
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 75px;
        }

        & .delete-icon {
            cursor: pointer;
            width: 12px;
            height: 12px;
            fill: ${theme.palette[$color][400]};
        }
    `}
`
