import {FC} from 'react'
import {StyledContactCard} from '@/features/settings/components/settings-contacts/style.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const SettingsContactsSkeleton: FC = () => {
    const skeletonArray = Array.from({length: 4}, (_, i) => i)
    return (
        <Flexbox direction={'column'} gap={6}>
            {skeletonArray.map((_, index) => (
                <StyledContactCard key={index}>
                    <Skeleton width={'100%'} height={20} />
                    <Skeleton width={'100%'} height={20} />
                    <Skeleton width={'100%'} height={20} />
                    <Skeleton width={'100%'} height={20} />

                    <Skeleton width={200} height={20} className={'button'} />
                </StyledContactCard>
            ))}
        </Flexbox>
    )
}
