import styled, {css} from 'styled-components'
import * as AvatarPrimitive from '@radix-ui/react-avatar'

interface AvatarRootProps {
    $isActive?: boolean
}
export const AvatarRoot = styled(AvatarPrimitive.Root)<AvatarRootProps>`
    ${({theme: {palette, typography}, $isActive}) => css`
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        user-select: none;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        overflow: hidden;
        background: ${palette.neutral['100']};
        border: 1px solid ${$isActive ? palette.primary['500'] : palette.neutral['300']};

        ${typography.textXs};
        font-weight: 600;
    `}
`
export const AvatarImage = styled(AvatarPrimitive.Image)`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
`
export const AvatarFallback = styled(AvatarPrimitive.Fallback)(
    ({theme: {palette, typography}}) => css`
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${palette.neutral.white};
        color: ${palette.neutral['600']};
        ${typography.textMd};
        font-weight: 600;
    `
)
