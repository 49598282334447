import React from 'react'
import {Container} from '@components/ui/container/Container.tsx'
import {useTranslation} from 'react-i18next'
import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {CHANGE_PASSWORD_FORM_MODEL, ChangePasswordValidationSchema} from './ChangePasswordModel'
import {Button} from '@components/ui/button/Button.tsx'
import {routes} from '@utilities/constants/routes.tsx'
import {useNavigate, useParams} from 'react-router-dom'
import {CheckIcon, Key01Icon} from '@/components/ui/icon/Icon'
import {AuthContent, AuthForm, AuthSubTitle, AuthTitle, AuthWrapper} from '@/components/ui/auth-atoms/AuthAtoms'
import {useChangePassword} from '@/features/auth/services/useChangePassword'
import {ToastBody} from '@/components/ui/toast-body/ToastBody'
import toast from 'react-hot-toast'
import {StyledChangePasswordIconContainer, StyledCorrespondWrapper} from '@/pages/change-password/style'

interface ChangePasswordFormProps {
    isFirstLogin?: boolean
}

export const ChangePassword: React.FC<ChangePasswordFormProps> = ({isFirstLogin = false}) => {
    const {t} = useTranslation()
    const {token} = useParams()
    const {
        register,
        handleSubmit,
        formState: {errors, isSubmitting, touchedFields}
    } = useForm<ChangePasswordValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(ChangePasswordValidationSchema)
    })
    const navigate = useNavigate()
    const {mutate: changePasswordMutation, isPending: isPendingChangePassword} = useChangePassword({
        onSuccess: () => {
            toast.success(() => (
                <ToastBody
                    title={t('change_password:success:password_changed')}
                    subtitle={t('change_password:success:access')}
                />
            ))
            navigate(routes.LOGIN.path)
        },
        onError: () => {
            toast.error(<ToastBody title={t('errors:default')} subtitle={t('commons:try_again')} />)
        }
    })

    const onSubmit: SubmitHandler<ChangePasswordValidationSchema> = data => {
        changePasswordMutation({
            password: data.password,
            confirmPassword: data.confirmPassword,
            token: token || '',
            isFirstLogin
        })
    }

    return (
        <Container>
            <AuthWrapper direction="column" align="center" justify="center">
                <AuthContent direction="column" align="center" gap={6}>
                    <IconContainer size={'lg'}>
                        <Key01Icon />
                    </IconContainer>

                    <Flexbox direction={'column'} align="center" gap={3}>
                        <AuthTitle>{t('change_password:title')}</AuthTitle>
                        <AuthSubTitle>{t('change_password:subtitle')}</AuthSubTitle>
                    </Flexbox>

                    <AuthForm onSubmit={handleSubmit(onSubmit)}>
                        <Flexbox direction={'column'} gap={6} align={'stretch'}>
                            <InputText
                                label={t(CHANGE_PASSWORD_FORM_MODEL.password.label)}
                                type={'password'}
                                placeholder={t(CHANGE_PASSWORD_FORM_MODEL.password.label)}
                                errorMessage={t(errors.password?.message || '')}
                                touched={touchedFields.password}
                                helpText={t(CHANGE_PASSWORD_FORM_MODEL.password.helpText)}
                                visibilityToggle={true}
                                {...register(CHANGE_PASSWORD_FORM_MODEL.password.name)}
                            />
                            <InputText
                                label={t(CHANGE_PASSWORD_FORM_MODEL.confirmPassword.label)}
                                type={'password'}
                                placeholder={t(CHANGE_PASSWORD_FORM_MODEL.confirmPassword.placeholder)}
                                errorMessage={t(errors.confirmPassword?.message || '')}
                                touched={touchedFields.confirmPassword}
                                helpText={t(CHANGE_PASSWORD_FORM_MODEL.confirmPassword.helpText)}
                                visibilityToggle={true}
                                {...register(CHANGE_PASSWORD_FORM_MODEL.confirmPassword.name)}
                            />

                            {!errors.confirmPassword && !!touchedFields.confirmPassword && (
                                <StyledCorrespondWrapper align={'center'} gap={4}>
                                    <StyledChangePasswordIconContainer>
                                        <CheckIcon size={16} />
                                    </StyledChangePasswordIconContainer>
                                    <p>{t('change_password:form:correspond')}</p>
                                </StyledCorrespondWrapper>
                            )}

                            <Button
                                type={'submit'}
                                fullWidth
                                variant={'primary'}
                                disabled={isSubmitting || isPendingChangePassword}
                            >
                                {t('change_password:primary_cta')}
                            </Button>
                        </Flexbox>
                    </AuthForm>
                </AuthContent>
            </AuthWrapper>
        </Container>
    )
}

ChangePassword.displayName = 'ChangePassword'
