import {CardStats} from '@/components/commons/card-stats/CardStats'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {
    ArrowDownIcon,
    ArrowUpIcon,
    EqualIcon,
    Globe03Icon,
    InfoCircleIcon,
    Phone02Icon
} from '@/components/ui/icon/Icon'
import {useTheme} from 'styled-components'
import {useTranslation} from 'react-i18next'
import {Tooltip} from '@/components/ui/tooltip/Tooltip'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {useGetSelfEvaluationStats} from '@/features/self-evaluation/services/useGetSelfEvaluationStats.ts'
import {
    StyledProvenienceContainer,
    StyledSelfAssessmentCardsStats,
    StyledSelfAssessmentCompareLabel,
    StyledSelfAssessmentStatsCollectedAmount,
    StyledSelfAssessmentStatsPercentResult
} from '@/features/self-evaluation/components/self-evaluation-cards-stats/style.ts'
import {SelfEvaluationUrlSearchParams} from '@/features/self-evaluation/types.ts'
import {calculatePercentage} from '@utilities/helpers.ts'

export const SelfEvaluationCardsStats = () => {
    const {t} = useTranslation()
    const {searchParams} = useParsedSearchParams([], SelfEvaluationUrlSearchParams)
    const patientsStatsQuery = useGetSelfEvaluationStats({
        rangeDateLabels: searchParams.rangeDateLabels
    })

    const {palette} = useTheme()

    const renderSelfAssessmentSummary = (amount: number) => {
        switch (true) {
            case amount > 0: {
                return (
                    <StyledSelfAssessmentStatsPercentResult gap={0.5} align="center" $variant={'under'}>
                        <ArrowUpIcon size={20} />
                        {amount}%
                    </StyledSelfAssessmentStatsPercentResult>
                )
            }
            case amount < 0: {
                return (
                    <StyledSelfAssessmentStatsPercentResult gap={0.5} align="center" $variant={'over'}>
                        <ArrowDownIcon size={20} />
                        {amount}%
                    </StyledSelfAssessmentStatsPercentResult>
                )
            }
            default: {
                return (
                    <StyledSelfAssessmentStatsPercentResult gap={0.5} align="center" $variant={'under'}>
                        <EqualIcon size={20} />
                        {amount}%
                    </StyledSelfAssessmentStatsPercentResult>
                )
            }
        }
    }

    return (
        <StyledSelfAssessmentCardsStats>
            {patientsStatsQuery.isLoading
                ? Array.from({length: 3}).map((_, index) => <CardStats asSkeleton key={index} />)
                : patientsStatsQuery.data && (
                      <>
                          <CardStats
                              headerSlot={
                                  <Flexbox justify="space-between">
                                      <h3>{t('self_assessment:card_stats:collected:title')}</h3>
                                      <Tooltip
                                          trigger={<InfoCircleIcon size={20} color={palette.neutral[400]} />}
                                          side="top"
                                          variant="light"
                                          content={<p>{t('self_assessment:card_stats:collected:info')}</p>}
                                      />
                                  </Flexbox>
                              }
                              bodySlot={
                                  <Flexbox direction="column" gap={4}>
                                      <StyledSelfAssessmentStatsCollectedAmount>
                                          {patientsStatsQuery.data.onPeriod.count ?? 0}
                                      </StyledSelfAssessmentStatsCollectedAmount>
                                      <Flexbox justify="start" gap={2}>
                                          <StyledSelfAssessmentCompareLabel>
                                              {t(`commons:compare_labels:${searchParams.rangeDateLabels}`)}
                                          </StyledSelfAssessmentCompareLabel>
                                      </Flexbox>
                                  </Flexbox>
                              }
                          />
                          <CardStats
                              headerSlot={
                                  <Flexbox justify="space-between">
                                      <h3>{t('self_assessment:card_stats:successful:title')}</h3>
                                      <Tooltip
                                          trigger={<InfoCircleIcon size={20} color={palette.neutral[400]} />}
                                          side="top"
                                          variant="light"
                                          content={<p>{t('self_assessment:card_stats:successful:info')}</p>}
                                      />
                                  </Flexbox>
                              }
                              bodySlot={
                                  <Flexbox direction="column" gap={4}>
                                      <StyledSelfAssessmentStatsCollectedAmount>
                                          {patientsStatsQuery.data.onPeriod.positive ?? 0}%
                                      </StyledSelfAssessmentStatsCollectedAmount>
                                      <Flexbox justify="start" gap={2}>
                                          {renderSelfAssessmentSummary(
                                              calculatePercentage(
                                                  patientsStatsQuery.data.onPeriod.count,
                                                  patientsStatsQuery.data.onPeriod.positive
                                              )
                                          )}
                                          <StyledSelfAssessmentCompareLabel>
                                              {t(`commons:compare_labels:${searchParams.rangeDateLabels}`)}
                                          </StyledSelfAssessmentCompareLabel>
                                      </Flexbox>
                                  </Flexbox>
                              }
                          />
                          <CardStats
                              headerSlot={<h3>{t('self_assessment:card_stats:provenience:title')}</h3>}
                              bodySlot={
                                  <Flexbox direction="column" gap={1}>
                                      <StyledProvenienceContainer gap={4} align={'center'}>
                                          <h3>{patientsStatsQuery.data.onPeriod.fromApp}%</h3>
                                          <Flexbox gap={1} align={'center'}>
                                              <Phone02Icon />
                                              <p>{t(`self_assessment:card_stats:provenience:app`)}</p>
                                          </Flexbox>
                                      </StyledProvenienceContainer>
                                      <StyledProvenienceContainer gap={4} align={'center'}>
                                          <h3>{patientsStatsQuery.data.onPeriod.fromWeb}%</h3>
                                          <Flexbox gap={1} align={'center'}>
                                              <Globe03Icon />
                                              <p>{t(`self_assessment:card_stats:provenience:web`)}</p>
                                          </Flexbox>
                                      </StyledProvenienceContainer>
                                  </Flexbox>
                              }
                          />
                      </>
                  )}
        </StyledSelfAssessmentCardsStats>
    )
}
