import {useTranslation} from 'react-i18next'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {DashboardAppointmentsUrlParams} from '@/features/dashboard/types.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {ErrorIcon} from 'react-hot-toast'
import {useGetAppointmentsTrend} from '@/features/dashboard/services/useGetAppointmentsTrend.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts'
import {adaptDataForAppointmentTrendChart} from '@/features/dashboard/utils.ts'
import {useTheme} from 'styled-components'

export const AppointmentsTrend = () => {
    const {t} = useTranslation()
    const {searchParams} = useParsedSearchParams([], DashboardAppointmentsUrlParams)
    const {data, isError, isLoading} = useGetAppointmentsTrend(searchParams)
    const {palette} = useTheme()

    return (
        <Flexbox fullWidth gap={2} align={'center'}>
            {isLoading ? (
                <Skeleton height={220} width={640} />
            ) : isError ? (
                <ErrorBox icon={<ErrorIcon />} title={t('errors:default')} />
            ) : (
                data && (
                    <ResponsiveContainer height={219}>
                        <BarChart data={adaptDataForAppointmentTrendChart(data)}>
                            <CartesianGrid vertical={false} height={100} />
                            <XAxis strokeOpacity={0} dy={8} dataKey={'name'} />
                            <YAxis strokeOpacity={0} dx={-4} domain={[0, 100]} />
                            <Tooltip />
                            <Legend verticalAlign={'top'} align={'right'} iconType={'circle'} />
                            <Bar
                                dataKey="totalCompleted"
                                stackId="a"
                                fill={palette.success[600]}
                                name={t(`commons:appointment_state:completed`)}
                            />
                            <Bar
                                radius={[10, 10, 0, 0]}
                                dataKey="totalBooked"
                                stackId="a"
                                fill={palette.neutral[200]}
                                name={t(`commons:appointment_state:booked`)}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                )
            )}
        </Flexbox>
    )
}
