import {useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {StyledSettingsSubtitle, StyledWrapperRemindersTime} from '@pages/settings/style.ts'
import {NOTIFICATION_REMINDER, NOTIFICATION_TYPE_OPTIONS} from '@/features/profile/types.ts'
import {Switch} from '@components/ui/switch/Switch.tsx'
import {useState} from 'react'
import {APPOINTMENT_NOTIFICATION_OPTIONS} from '@utilities/constants/list.ts'
import {SingleSelect} from '@components/commons/single-select/SingleSelect.tsx'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {useEditNotificationsReminder} from '@/features/profile/services/useEditNotificationsReminder.ts'
import {useEditProfile} from '@/features/profile/services/useEditProfile.ts'

export const AppointmentNotificationsForm = () => {
    const {t} = useTranslation()
    const user = useAuthStore(state => state.user)
    const selectedUserNotifications = user?.notificationTypes && user?.notificationTypes.map(item => item.id)
    const defaultReminderTime = APPOINTMENT_NOTIFICATION_OPTIONS.find(
        item => item.value === NOTIFICATION_REMINDER.find(item => item.label === String(user?.remindOffset))?.value
    )?.value
    const [reminderTime, setReminderTime] = useState(defaultReminderTime)
    const [selectedNotifications, setSelectedNotifications] = useState(new Set(selectedUserNotifications))
    const {mutate: editNotificationsReminder} = useEditNotificationsReminder()
    const {mutate: editProfile} = useEditProfile()
    const handleOnChange = (id: number) => {
        const updatedSelection = new Set(selectedNotifications)
        if (updatedSelection.has(id)) {
            updatedSelection.delete(id)
        } else {
            updatedSelection.add(id)
        }
        setSelectedNotifications(updatedSelection)

        editNotificationsReminder({
            ids: [...updatedSelection]
        })
    }

    return (
        <Flexbox direction="column" gap={6}>
            <StyledSettingsSubtitle>{t('profile:subtitle_appointments_notifications')}</StyledSettingsSubtitle>
            {NOTIFICATION_TYPE_OPTIONS.map(item => (
                <Flexbox key={item.id} align="center" justify={'space-between'} fullWidth>
                    <Switch
                        label={t(`profile:notifications:type:${item.type}_appointments`)}
                        description={t(`profile:notifications:description:${item.type}_appointments`)}
                        defaultChecked={selectedNotifications.has(item.id)}
                        onChange={() => handleOnChange(item.id)}
                    />

                    {item.id === 4 && selectedNotifications.has(item.id) && (
                        <StyledWrapperRemindersTime>
                            <SingleSelect
                                minWidth={0}
                                options={APPOINTMENT_NOTIFICATION_OPTIONS}
                                value={reminderTime || APPOINTMENT_NOTIFICATION_OPTIONS[0].value}
                                onChange={value => {
                                    setReminderTime(value)
                                    editProfile({
                                        remindOffset: Number(
                                            NOTIFICATION_REMINDER.find(item => item.value === value)?.label
                                        )
                                    })
                                }}
                            />
                            <Flexbox gap={1} direction={'column'}>
                                <span className={'title'}>{t('profile:notifications:reminder:title')}</span>
                                <span className={'description'}>{t('profile:notifications:reminder:description')}</span>
                            </Flexbox>
                        </StyledWrapperRemindersTime>
                    )}
                </Flexbox>
            ))}
        </Flexbox>
    )
}
