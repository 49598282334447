import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'
import {
    HttpDashboardAppointmentsTrendOptions,
    httpGetAppointmentsTrend,
    HttpGetAppointmentsTrendResponse
} from '@/features/dashboard/services/dashboard.http.ts'
import {DashboardAppointmentsUrlParams} from '@/features/dashboard/types.ts'
import {getDateFromLabel} from '@utilities/utils.ts'
import {z} from 'zod'

const UseAppointmentsTrendOption = z.object({
    ...DashboardAppointmentsUrlParams.shape,
    ...HttpDashboardAppointmentsTrendOptions.omit({startDate: true, endDate: true}).shape
})
export type UseAppointmentsTrendOption = z.infer<typeof UseAppointmentsTrendOption>

export const useGetAppointmentsTrend = (
    promiseArgs: UseAppointmentsTrendOption,
    options?: Omit<UseQueryOptions<HttpGetAppointmentsTrendResponse>, 'queryFn' | 'queryKey'>
) => {
    const {rangeDateLabels, ...rest} = promiseArgs
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.APPOINTMENTS_TREND, promiseArgs],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetAppointmentsTrend({
                    ...rest,
                    ...getDateFromLabel(rangeDateLabels)
                }),
                responseShape: HttpGetAppointmentsTrendResponse,
                onZodError: captureException
            })
    })
}
