export default [
    {
        id: 1,
        name: '8:00 - 9:00',
        start: '8',
        end: '9'
    },
    {
        id: 2,
        name: '9:00 - 10:00',
        start: '9',
        end: '10'
    },
    {
        id: 3,
        name: '10:00 - 11:00',
        start: '10',
        end: '11'
    },
    {
        id: 4,
        name: '11:00 - 12:00',
        start: '11',
        end: '12'
    },
    {
        id: 5,
        name: '12:00 - 13:00',
        start: '12',
        end: '13'
    },
    {
        id: 6,
        name: '13:00 - 14:00',
        start: '13',
        end: '14'
    },
    {
        id: 7,
        name: '14:00 - 15:00',
        start: '14',
        end: '15'
    },
    {
        id: 8,
        name: '15:00 - 16:00',
        start: '15',
        end: '16'
    },
    {
        id: 9,
        name: '16:00 - 17:00',
        start: '16',
        end: '17'
    },
    {
        id: 10,
        name: '17:00 - 18:00',
        start: '17',
        end: '18'
    }
]
