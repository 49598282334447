import React from 'react'
import {Container} from '@components/ui/container/Container.tsx'
import {useTranslation} from 'react-i18next'
import Tabs from '@components/commons/tabs/Tabs.tsx'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {SETTINGS_TABS, SettingsSearchParams, SettingsTabs} from '@/features/settings/types.ts'
import {StyledContainerSettingsBody} from '@pages/settings/style.ts'
import {SettingsUsers} from '@/features/settings/components/settings-users/SettingsUsers.tsx'
import {SettingsContacts} from '@/features/settings/components/settings-contacts/SettingsContacts.tsx'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'

export const Settings: React.FC = () => {
    const {t} = useTranslation()
    const {searchParams, setSearchParams} = useParsedSearchParams([], SettingsSearchParams)

    const tabsBody = {
        [SETTINGS_TABS[0].id]: <SettingsUsers />,
        [SETTINGS_TABS[1].id]: <SettingsContacts />
    }

    return (
        <Container fullWidth>
            <PageHero title={t('settings:tabs:users')} />

            <Tabs
                tabsList={SETTINGS_TABS}
                activeTabId={searchParams.type ?? SettingsTabs.users}
                setActiveTabId={id => {
                    setSearchParams({type: id as SettingsSearchParams['type']})
                }}
            />

            <StyledContainerSettingsBody>
                {tabsBody[searchParams.type ?? SettingsTabs.users]}
            </StyledContainerSettingsBody>
        </Container>
    )
}

Settings.displayName = 'Settings'
