import {Dispatch, FC, SetStateAction} from 'react'
import {Table} from '@components/commons/table/Table.tsx'
import {ActionsMenu} from '@/features/settings/components/actions-menu/ActionsMenu.tsx'
import {actionsListAppointmentDetails} from '@utilities/constants/actionsMenus.tsx'
import {
    StyledAppointmentsTimeAndLocation,
    StyledPatientNameLink,
    StyledUpcomingAppointmentsTableWrapper
} from '@/features/dashboard/components/appointments/style.ts'
import {ClockIcon, MarkerPin01Icon, VideoRecorderIcon} from '@components/ui/icon/Icon.tsx'
import {useGetAppointments} from '@/features/appointments/services/useGetAppointments.ts'
import dayjs from '@/dayjs.ts'
import {useTranslation} from 'react-i18next'
import {AppointmentStatusChip} from '@components/commons/appointment-status-chip/AppointmentStatusChip.tsx'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {generatePath} from 'react-router-dom'
import {routes} from '@utilities/constants/routes.tsx'
import {ACTION_TYPES_APPOINTMENT_DETAILS, Appointment, AppointmentStatus} from '@/features/appointments/types.ts'
import {useEditAppointment} from '@/features/appointments/services/useEditAppointment.ts'
import {useDeleteAppointment} from '@/features/appointments/services/useDeleteAppointment.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {useGetContacts} from '@/features/settings/services/services-contact/useGetContacts.ts'
const now = dayjs()
interface DoctorUpcomingAppointmentsProps {
    appointments: Appointment[]
    setOpenAddOrEditAppointmentModal: Dispatch<SetStateAction<{open: boolean; id: number | null}>>
}
export const DoctorUpcomingAppointments: FC<DoctorUpcomingAppointmentsProps> = ({
    appointments,
    setOpenAddOrEditAppointmentModal
}) => {
    const {t} = useTranslation()
    const user = useAuthStore(state => state.user)
    const office = useGetContacts()
    const {isLoading, fetchNextPage, isFetchingNextPage} = useGetAppointments(
        {
            limit: 1000,
            therapistId: user?.id,
            fromDate: now.format(),
            toDate: now.endOf('day').format()
        },
        900000
    )
    const {mutate: editAppointment} = useEditAppointment()
    const {mutate: deleteAppointment} = useDeleteAppointment()

    const onActionClick = (type: string, selectedItem?: number) => {
        switch (type) {
            case ACTION_TYPES_APPOINTMENT_DETAILS.completed:
                editAppointment({
                    appointmentID: selectedItem!,
                    status: AppointmentStatus.enum.completed
                })
                break
            case ACTION_TYPES_APPOINTMENT_DETAILS.edit:
                setOpenAddOrEditAppointmentModal({open: true, id: selectedItem ?? null})
                break
            case ACTION_TYPES_APPOINTMENT_DETAILS.delete:
                deleteAppointment(selectedItem!)
                break
            case ACTION_TYPES_APPOINTMENT_DETAILS.missed:
                editAppointment({
                    appointmentID: selectedItem!,
                    status: AppointmentStatus.enum.missed
                })
                break
            default:
                break
        }
    }
    return (
        <StyledUpcomingAppointmentsTableWrapper>
            <Table
                data={appointments}
                isLoading={isLoading}
                isHeaderVisible={false}
                onChangePage={fetchNextPage}
                isChangingPage={isFetchingNextPage}
                columns={[
                    {
                        width: '25%',
                        cellRender: data => (
                            <div>
                                <StyledPatientNameLink
                                    size={'xl'}
                                    to={generatePath(routes.PATIENT_DETAILS.handle.path, {patientId: data.patientId})}
                                    variant={'tertiaryColor'}
                                    className={'patient-name'}
                                >
                                    {data.patient?.lastName} {data.patient?.firstName}
                                </StyledPatientNameLink>
                            </div>
                        )
                    },
                    {
                        width: '20%',
                        cellRender: data => (
                            <StyledAppointmentsTimeAndLocation>
                                <ClockIcon />
                                <p>
                                    {`${dayjs.utc(data.start).format('HH:mm')} - ${dayjs.utc(data.end).format('HH:mm')}
                            (${dayjs.utc(data.end).diff(dayjs.utc(data.start), 'hour')} ${t('patient_details:appointments_tab:hour')})`}
                                </p>
                            </StyledAppointmentsTimeAndLocation>
                        )
                    },
                    {
                        width: '25%',
                        cellRender: data => (
                            /*TODO: check if is on site or in a meeting and change icon*/
                            <StyledAppointmentsTimeAndLocation>
                                {data.isRemote ? <VideoRecorderIcon size={20} /> : <MarkerPin01Icon size={20} />}
                                {office.isLoading ? (
                                    <Skeleton height={24} width={200} />
                                ) : (
                                    <p>{office.data?.data.find(item => item.id === data.officeId)?.name}</p>
                                )}
                            </StyledAppointmentsTimeAndLocation>
                        )
                    },
                    {
                        width: '20%',
                        alignment: 'right',
                        cellRender: data => (
                            <AppointmentStatusChip
                                label={t(`commons:appointment_state:${data.status}`)}
                                variant={data.status}
                            />
                        )
                    },
                    {
                        width: '10%',
                        alignment: 'center',
                        cellRender: data => (
                            <ActionsMenu
                                actions={actionsListAppointmentDetails(data?.status as AppointmentStatus)}
                                onActionClick={onActionClick}
                                selectedItem={data?.id}
                            />
                        )
                    }
                ]}
            />
        </StyledUpcomingAppointmentsTableWrapper>
    )
}
