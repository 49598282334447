import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

type StyledAppointmentChipProps = {
    $isSingleDoctorView: boolean
}
export const StyledAppointmentChip = styled(Flexbox)<StyledAppointmentChipProps>`
    ${({theme: {spacing, palette, typography}, $isSingleDoctorView}) => css`
        background-color: ${palette.primary[200]};
        border-radius: ${spacing * 2}px;
        border: 1px solid ${palette.primary[300]};
        color: ${palette.neutral[900]};
        ${typography.textXs}
        width: 100%;
        padding: ${spacing}px ${spacing * 2}px;
        cursor: pointer;
        height: ${$isSingleDoctorView ? '100%' : '26px'};

        & .patient-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        & svg {
            width: 16px;
            height: 16px;
        }

        @media (max-width: 1542px) {
            max-width: 105px;
        }
    `}
`
