import styled, {css} from 'styled-components'
import {Container} from '@components/ui/container/Container.tsx'
import {ChevronRightIcon} from '@components/ui/icon/Icon.tsx'

export const StyledContainerAppointment = styled(Container)`
    ${({theme}) => css`
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: ${theme.spacing * 8}px;
    `}
`

export const StyledOfficeName = styled.h3`
    ${({theme}) => css`
        color: ${theme.palette.neutral[900]};
        ${theme.typography.displayMd}
        font-weight: 500;
    `}
`

export const StyledChevronIcon = styled(ChevronRightIcon)`
    ${() => css`
        rotate: 90deg;
    `}
`
