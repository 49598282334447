import {useInfiniteQuery} from '@tanstack/react-query'
import {infiniteQueryFetchNextPage, QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance'
import {captureException} from '@sentry/react'
import {httpGetCheckIn, HttpGetCheckInResponse} from '@/features/check-in/services/checkIn.http.ts'
import {z} from 'zod'
import {PaginationParams, RangeDateLabels} from '@/types.ts'
import {CheckInSearchParams} from '@/features/check-in/types.ts'
import {getDateFromLabel} from '@utilities/utils.ts'

const UseGetCheckInOptions = z.object({
    dateRangeLabels: RangeDateLabels,
    ...CheckInSearchParams.omit({startDate: true, endDate: true}).shape,
    ...PaginationParams.shape
})
type UseGetCheckInOptions = z.infer<typeof UseGetCheckInOptions>

export const useGetCheckIn = (promiseArgs: Omit<UseGetCheckInOptions, 'page'>) => {
    const {dateRangeLabels, ...rest} = promiseArgs
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.CHECK_IN, promiseArgs],
        queryFn: ({pageParam = 1}) =>
            parseAxiosPromise({
                axiosPromise: httpGetCheckIn({
                    ...rest,
                    ...getDateFromLabel(dateRangeLabels),
                    responseType: 'backofficeRegular',
                    page: pageParam
                }),
                responseShape: HttpGetCheckInResponse,
                onZodError: captureException
            }),
        initialPageParam: 1,
        getNextPageParam: ({meta}) => (meta.current_page < meta.last_page ? meta.current_page + 1 : null)
    })

    const remappedData = query.data?.pages[0]?.data?.length ? query.data.pages.flatMap(page => page.data) : []

    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData
    }
}
