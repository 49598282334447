import {FormProvider, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {
    ForeignPatientFormValidationSchema,
    PatientFormSchema,
    PatientFormValidationSchema,
    getPatientFormFormSchema,
    ZodObjectPatientFormSchema,
    ForeignPatientFormSchema
} from '@/features/patient-form/PatientFormModel'
import {FC, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
    StyledPatientFormFormBody,
    StyledPatientFormModal,
    StyledPatientFormModalHeader
} from '@/features/patient-form/components/patient-form-modal/style'
import {Button} from '@components/ui/button/Button.tsx'
import {ArrowRightIcon, XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {ModalBody, ModalFooter} from '@/components/ui/modal-atoms/ModalAtoms'
import {CountryKey, PatientFormStep} from '@/features/patient-form/types'
import {checkValidityStep, getAddPatientServerErrorMessage} from '@/features/patients/utils'
import {PatientFormPersonalDataStep} from '@/features/patient-form/components/patient-form-personal-data-step/PatientFormPersonalDataStep'
import {useAddPatient} from '@/features/patient-form/services/useAddPatient'
import toast from 'react-hot-toast'
import {formatDate} from '@/dayjs'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useEditPatient} from '@/features/patient-form/services/useEditPatient'
import {PatientFormEmailStep} from '@/features/patient-form/components/patient-form-email-step/PatientFormEmailStep'

export const DEFAULT_COUNTRY = {
    label: 'Italy',
    value: CountryKey.Italy.toString()
}

interface PatientFormModalProps {
    onClose: () => void
    patientId?: number
    defaultValues?: PatientFormValidationSchema | ForeignPatientFormValidationSchema
}

export const PatientFormModal: FC<PatientFormModalProps> = ({
    onClose,
    patientId,
    defaultValues = {
        birthCountryId: DEFAULT_COUNTRY
    }
}) => {
    const {t} = useTranslation()
    const [step, setStep] = useState<PatientFormStep>('personalData')
    const [isForeignBorn, setIsForeignBorn] = useState(defaultValues?.birthCountryId?.value != DEFAULT_COUNTRY.value)
    const [currentFormSchema, setCurrentFormSchema] = useState<ZodObjectPatientFormSchema>(PatientFormSchema)

    // Form config
    const methods = useForm<PatientFormValidationSchema | ForeignPatientFormValidationSchema>({
        context: isForeignBorn,
        mode: 'all',
        defaultValues,
        resolver: (values, context: boolean | undefined, options) => {
            const createResolver = zodResolver(getPatientFormFormSchema(!!context))
            return createResolver(values, context, options)
        }
    })

    const {
        getValues,
        watch,
        formState: {errors}
    } = methods

    const formValues = getValues()
    const watcher = watch()

    // Mutation hook
    const addPatientMutation = useAddPatient({
        onSuccess: () => {
            toast.success(t('commons:operation_completed'))
            onClose()
        },
        onError: error => {
            getAddPatientServerErrorMessage(error, (errorMessage, keyField) => {
                if (keyField) {
                    methods.setError(keyField, {message: t(errorMessage)})
                }

                toast.error(t(errorMessage))
            })

            setStep('personalData')
        }
    })

    const editPatientMutation = useEditPatient({
        patientId,
        options: {
            onSuccess: () => {
                toast.success(t('commons:operation_completed'))
                onClose()
            },
            onError: error => {
                getAddPatientServerErrorMessage(error, (errorMessage, keyField) => {
                    if (keyField) {
                        methods.setError(keyField, {message: t(errorMessage)})
                    }
                })
            }
        }
    })

    // Enable or disable the "Continue" button
    const isValidCurrentStep = useMemo(() => checkValidityStep(step, formValues, isForeignBorn, errors), [watcher])

    // Callbacks
    const onChangeStep = () => {
        if (step == 'personalData') {
            setStep('email')
        } else {
            setStep('personalData')
        }
    }

    const onSubmit = methods.handleSubmit(data => {
        if (!data.birthCountryId) {
            setStep('personalData')
            toast.error('patients:create_patient:form:errors:birthCountry')
            return methods.setError('birthCountryId', {message: 'patients:create_patient:form:errors:birthCountry'})
        }

        const patientPayload = {
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            birthCountryId: parseInt(data.birthCountryId.value),
            birthStateId: data.birthStateId ? parseInt(data.birthStateId.value) : undefined,
            birthCityId: data?.birthCityId ? parseInt(data.birthCityId.value) : undefined,
            birthDate: formatDate(data.birthDate.toISOString(), 'YYYY-MM-DD'),
            email: data.email,
            gender: data.gender,
            taxCode: data.taxCode
        }

        if (patientId) {
            editPatientMutation.mutate({payload: patientPayload})
        } else {
            addPatientMutation.mutate({payload: patientPayload})
        }
    })

    useEffect(() => {
        setCurrentFormSchema(() => (isForeignBorn ? ForeignPatientFormSchema : PatientFormSchema))
    }, [isForeignBorn])

    return (
        <StyledPatientFormModal width={'444px'} onOverlayClick={onClose}>
            <StyledPatientFormModalHeader>
                {patientId ? (
                    <h4>{t('patient_details:patient_tabs:info:edit_patient')}</h4>
                ) : (
                    <h4>{t('patients:create_patient:title')}</h4>
                )}
                <Button variant="tertiary" size="sm" shape="square" onClick={onClose}>
                    <XCloseIcon />
                </Button>
            </StyledPatientFormModalHeader>

            <ModalBody>
                <FormProvider {...methods}>
                    <StyledPatientFormFormBody
                        id="patient-form"
                        render="form"
                        direction={'column'}
                        gap={6}
                        fullWidth
                        onSubmit={onSubmit}
                    >
                        {step == 'personalData' && (
                            <PatientFormPersonalDataStep
                                formSchema={currentFormSchema}
                                isForeignBorn={isForeignBorn}
                                setIsForeignBorn={setIsForeignBorn}
                            />
                        )}
                        {step == 'email' && <PatientFormEmailStep formSchema={currentFormSchema} />}
                    </StyledPatientFormFormBody>
                </FormProvider>
            </ModalBody>

            <ModalFooter>
                {step === 'personalData' ? (
                    <>
                        <Button type={'button'} variant={'tertiary'} onClick={onClose}>
                            {t('commons:actions:cancel')}
                        </Button>
                        {!patientId ? (
                            <Button
                                type={'button'}
                                variant={'primary'}
                                disabled={!isValidCurrentStep}
                                onClick={e => {
                                    e.preventDefault()
                                    onChangeStep()
                                }}
                            >
                                {t('commons:actions:continue')}
                                <ArrowRightIcon />
                            </Button>
                        ) : (
                            <Button
                                type={'submit'}
                                form="patient-form"
                                disabled={!isValidCurrentStep || editPatientMutation.isPending}
                                variant={'primary'}
                            >
                                {t('commons:actions:save')}
                                {editPatientMutation.isPending && <Spinner size={14} />}
                            </Button>
                        )}
                    </>
                ) : (
                    <>
                        <Button type={'button'} variant={'tertiary'} onClick={onChangeStep}>
                            {t('commons:actions:back')}
                        </Button>
                        <Button
                            type={'submit'}
                            form="patient-form"
                            disabled={!isValidCurrentStep || addPatientMutation.isPending}
                            variant={'primary'}
                        >
                            {t('commons:actions:confirm')}
                            {addPatientMutation.isPending && <Spinner size={14} />}
                        </Button>
                    </>
                )}
            </ModalFooter>
        </StyledPatientFormModal>
    )
}
