import {InputText} from '@/components/commons/input-text/InputText'
import {useTranslation} from 'react-i18next'
import {FilterLinesIcon, SearchLgIcon} from '@/components/ui/icon/Icon'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Popover} from '@/components/ui/popover/Popover'
import {StyledPatientsTableFiltersTrigger} from '@/features/patients/components/patients-table-filters/style'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {useMemo, useState} from 'react'
import {ObjectValues} from '@/utilities/helpers'
import {useSelfEvaluationTable} from '@/features/self-evaluation/services/useSelfEvaluationTable.ts'
import {
    SELF_ASSESSMENT_FILTERS_OUTCOME_OPTIONS,
    SELF_ASSESSMENT_FILTERS_PATIENT_OPTIONS,
    SELF_ASSESSMENT_FILTERS_PROVENANCE_OPTIONS
} from '@/features/self-evaluation/utils.ts'
import {StyledSelfAssessmentFiltersContent} from '@/features/self-evaluation/components/self-evaluation-table-filters/style.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {SingleSelect} from '@components/commons/single-select/SingleSelect.tsx'
import {
    SELF_ASSESSMENT_FILTERS_MODEL,
    SelfAssessmentFiltersValidationSchema
} from '@/features/self-evaluation/components/self-evaluation-table-filters/SelfEvaluationFiltersModel.ts'
import {SelfEvaluationFilters} from '@/features/self-evaluation/types.ts'

export const SelfEvaluationTableFilters = () => {
    const {t} = useTranslation()
    const {searchValue, onSearch} = useSelfEvaluationTable()
    const {searchParams, setSearchParams} = useParsedSearchParams([], SelfEvaluationFilters)
    const [popoverVisibility, setPopoverVisibility] = useState(false)

    const {
        handleSubmit,
        control,
        reset,
        formState: {errors}
    } = useForm<SelfAssessmentFiltersValidationSchema>({
        mode: 'onSubmit',
        resolver: zodResolver(SelfAssessmentFiltersValidationSchema),
        defaultValues: {
            ...searchParams
        }
    })

    const onSubmit: SubmitHandler<SelfAssessmentFiltersValidationSchema> = filters => {
        setSearchParams({
            anonymous: filters.anonymous || undefined,
            result: filters.result || undefined,
            intakeSource: filters.intakeSource || undefined
        })

        setPopoverVisibility(false)
    }

    const onClear = () => {
        reset({anonymous: '', result: '', intakeSource: ''})
        setSearchParams({
            anonymous: undefined,
            result: undefined,
            intakeSource: undefined
        })
    }

    const appliedFiltersCount = useMemo(
        () => ObjectValues(searchParams).filter(param => !!param).length,
        [searchParams]
    )

    return (
        <Flexbox align="center" justify="space-between" fullWidth>
            <InputText
                type="text"
                width="auto"
                placeholder={t('self_assessment:filters:search:placeholder')}
                typeIcon={<SearchLgIcon size={20} />}
                onChange={e => onSearch(e.currentTarget.value)}
                defaultValue={searchValue}
            />

            <Popover
                rootProps={{
                    open: popoverVisibility,
                    onOpenChange: value => setPopoverVisibility(value)
                }}
                triggerProps={{asChild: true}}
                contentProps={{align: 'end'}}
                trigger={
                    <StyledPatientsTableFiltersTrigger variant="secondaryColor" $hasFilters={!!appliedFiltersCount}>
                        <FilterLinesIcon />
                        <span>{t('commons:filters')}</span>
                        {!!appliedFiltersCount && <b>({appliedFiltersCount})</b>}
                    </StyledPatientsTableFiltersTrigger>
                }
            >
                <StyledSelfAssessmentFiltersContent
                    onSubmit={handleSubmit(onSubmit)}
                    render={'form'}
                    direction={'column'}
                    gap={4}
                >
                    <Controller
                        control={control}
                        name={SELF_ASSESSMENT_FILTERS_MODEL.anonymous.name}
                        render={({field: {onChange, value}}) => (
                            <SingleSelect
                                options={SELF_ASSESSMENT_FILTERS_PATIENT_OPTIONS}
                                label={t(SELF_ASSESSMENT_FILTERS_MODEL.anonymous.label)}
                                placeholder={t(SELF_ASSESSMENT_FILTERS_MODEL.anonymous.placeholder)}
                                onChange={onChange}
                                value={value || ''}
                                errorMessage={t(errors.anonymous?.message || '')}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={SELF_ASSESSMENT_FILTERS_MODEL.result.name}
                        render={({field: {onChange, value}}) => (
                            <SingleSelect
                                options={SELF_ASSESSMENT_FILTERS_OUTCOME_OPTIONS}
                                label={t(SELF_ASSESSMENT_FILTERS_MODEL.result.label)}
                                placeholder={t(SELF_ASSESSMENT_FILTERS_MODEL.result.placeholder)}
                                onChange={onChange}
                                value={value || ''}
                                errorMessage={t(errors.result?.message || '')}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={SELF_ASSESSMENT_FILTERS_MODEL.intakeSource.name}
                        render={({field: {onChange, value}}) => (
                            <SingleSelect
                                options={SELF_ASSESSMENT_FILTERS_PROVENANCE_OPTIONS}
                                label={t(SELF_ASSESSMENT_FILTERS_MODEL.intakeSource.label)}
                                placeholder={t(SELF_ASSESSMENT_FILTERS_MODEL.intakeSource.placeholder)}
                                onChange={onChange}
                                value={value || ''}
                                errorMessage={t(errors.intakeSource?.message || '')}
                            />
                        )}
                    />
                    <Flexbox fullWidth justify="end" gap={3}>
                        <Button type="button" variant="secondary" onClick={onClear}>
                            {t('commons:cancel')}
                        </Button>
                        <Button type="submit">{t('commons:apply')}</Button>
                    </Flexbox>
                </StyledSelfAssessmentFiltersContent>
            </Popover>
        </Flexbox>
    )
}
