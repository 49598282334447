import styled, {css} from 'styled-components'

export const StyledPersonalInformation = styled.form`
    ${({theme}) => css`
        display: grid;
        column-gap: ${theme.spacing * 10}px;
        row-gap: ${theme.spacing * 6}px;
        grid-template-columns: 1fr 1fr;

        grid-template-areas:
            'title .'
            'surname name'
            'email phone'
            'job role'
            '. button';

        .title {
            ${theme.typography.textLg};
            font-weight: 600;
            color: ${theme.palette.neutral[900]};
        }

        .button {
            justify-self: flex-end;
        }
    `}
`

interface PersonalInfoItemProps {
    area: string
}

export const PersonalInfoItem = styled.div<PersonalInfoItemProps>`
    ${({area}) => css`
        grid-area: ${area};
    `}
`

export const StyledWrapperSurname = styled.div`
    ${({theme}) => css`
        display: grid;
        grid-template-columns: auto 1fr;
        gap: ${theme.spacing * 4}px;
        align-items: flex-end;
    `}
`

export const StyledPasswordManagementFormWrapper = styled.form`
    ${({theme}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: ${theme.spacing * 10}px;
        row-gap: ${theme.spacing * 6}px;
        align-items: baseline;
        padding: ${theme.spacing * 12}px 0;
    `}
`

export const StyledLastPasswordUpdate = styled.p`
    ${({theme}) => css`
        justify-self: flex-end;
        font-style: italic;
        font-weight: 400;
        color: ${theme.palette.neutral[600]};
    `}
`

export const StyledContainerButtonToChangePassword = styled.div`
    ${() => css`
        justify-self: flex-end;
        align-self: flex-end;
    `}
`
