import {useMemo, useState} from 'react'
import dayjs from '@/dayjs.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {ChevronLeftIcon, ChevronRightIcon} from '@components/ui/icon/Icon.tsx'
import {capitalize} from '@utilities/helpers.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {DiaryDateSelectorUrlParams} from '@/features/patient-details/types.ts'
import {StyledDayItem, StyledMonthItem} from '@/features/patient-details/components/diary-date-selector/style.ts'

export const DiaryDateSelector = () => {
    const {searchParams, setSearchParams} = useParsedSearchParams([], DiaryDateSelectorUrlParams)
    const [startDate, setStartDate] = useState(dayjs(searchParams.selectedDate) || dayjs())

    const currentYearInMonth = useMemo(() => {
        const currentYear = startDate.startOf('year')
        return Array.from({length: 12}, (_, i) => currentYear.add(i, 'month'))
    }, [startDate])

    const currentTwoWeek = useMemo(() => {
        const startOfWeek = startDate.subtract(6, 'day')
        return Array.from({length: 14}, (_, i) => startOfWeek.add(i, 'day'))
    }, [startDate])

    const onClickMonth = (month: dayjs.Dayjs) => {
        setSearchParams({selectedDate: dayjs(searchParams.selectedDate).set('month', month.month()).toISOString()})
        setStartDate(dayjs(searchParams.selectedDate).set('month', month.month()))
    }

    const onClickNextYear = () => {
        setSearchParams({selectedDate: dayjs(searchParams.selectedDate).add(1, 'year').toISOString()})
        setStartDate(dayjs(searchParams.selectedDate).add(1, 'year'))
    }

    const onClickPrevYear = () => {
        setSearchParams({selectedDate: dayjs(searchParams.selectedDate).subtract(1, 'year').toISOString()})
        setStartDate(dayjs(searchParams.selectedDate).subtract(1, 'year'))
    }

    return (
        <Flexbox direction={'column'} gap={3} fullWidth>
            <Flexbox fullWidth justify={'space-between'}>
                <Flexbox align={'center'}>
                    <Button variant={'tertiary'} shape={'square'} onClick={onClickPrevYear}>
                        <ChevronLeftIcon size={20} />
                    </Button>
                    <p>{startDate.format('YYYY')}</p>
                    <Button variant={'tertiary'} shape={'square'} onClick={onClickNextYear}>
                        <ChevronRightIcon size={20} />
                    </Button>
                </Flexbox>
                {currentYearInMonth.map((month, index) => (
                    <StyledMonthItem
                        shape={'square'}
                        onClick={() => onClickMonth(month)}
                        variant={
                            dayjs(month).isSame(dayjs(searchParams.selectedDate), 'month') ? 'primary' : 'tertiary'
                        }
                        key={index}
                    >
                        {capitalize(month.locale('it').format('MMM'))}
                    </StyledMonthItem>
                ))}
            </Flexbox>
            <Flexbox fullWidth>
                <Button
                    variant={'tertiary'}
                    shape={'square'}
                    onClick={() => setStartDate(startDate.subtract(1, 'day'))}
                >
                    <ChevronLeftIcon size={20} />
                </Button>
                <Flexbox fullWidth justify={'space-between'}>
                    {currentTwoWeek.map((day, index) => (
                        <StyledDayItem
                            shape={'square'}
                            onClick={() => setSearchParams({selectedDate: day.toISOString()})}
                            variant={
                                dayjs(day).isSame(dayjs(searchParams.selectedDate), 'date') ? 'primary' : 'tertiary'
                            }
                            key={index}
                        >
                            {day.format('D')}
                        </StyledDayItem>
                    ))}
                </Flexbox>
                <Button variant={'tertiary'} shape={'square'} onClick={() => setStartDate(startDate.add(1, 'day'))}>
                    <ChevronRightIcon size={20} />
                </Button>
            </Flexbox>
        </Flexbox>
    )
}
