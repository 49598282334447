import {BaseCard} from '@components/commons/cards/BaseCard.tsx'
import {StyledAppointmentCardBody} from '@/features/patient-details/components/appointment-item/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'

export const AppointmentItemSkeleton = () => (
    <BaseCard bodyHasBorder={false}>
        <StyledAppointmentCardBody direction={'column'} gap={4}>
            <Flexbox justify={'space-between'} fullWidth={true}>
                <Skeleton height={24} width={100} />
                <Skeleton height={20} width={20} />
            </Flexbox>
            <Flexbox gap={6}>
                <Flexbox gap={2} align={'center'}>
                    <Skeleton height={20} width={20} />
                    <Skeleton height={24} width={100} />
                </Flexbox>
                <Flexbox gap={2} align={'center'}>
                    <Skeleton height={20} width={20} />
                    <Skeleton height={24} width={150} />
                </Flexbox>
            </Flexbox>
            <Flexbox justify={'space-between'} fullWidth>
                <Flexbox gap={6} align={'center'}>
                    <Flexbox gap={2} align={'center'}>
                        <Skeleton height={20} width={20} />
                        <Skeleton height={24} width={100} />
                    </Flexbox>
                    <Flexbox gap={2} align={'center'}>
                        <Skeleton height={20} width={20} />
                        <Skeleton height={24} width={150} />
                    </Flexbox>
                </Flexbox>
                <Flexbox gap={2} align={'center'}>
                    <Skeleton height={20} width={20} />
                    <Skeleton height={24} width={150} />
                </Flexbox>
            </Flexbox>
        </StyledAppointmentCardBody>
    </BaseCard>
)
