import {RouteObject, useMatches} from 'react-router-dom'
import {StyledBreadCrumbs, StyledNavLinkCrumb} from '@components/commons/bread-crumbs/style.ts'
import {HomeLineIcon, SlashDividerIcon} from '@components/ui/icon/Icon.tsx'
import {Fragment} from 'react'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'

export type HandleRouterObj = {
    path: string
    paramName?: string
    name: string
}

type Crumb = {
    item: HandleRouterObj
    match: RouteObject
}
export const BreadCrumbs = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const matches: RouteObject[] = useMatches()
    const crumbs = matches
        // first get rid of any matches that don't have handle and crumb
        .filter(match => Boolean(match.handle))
        // now map them into an array of elements, passing the loader
        // data to each one
        .map((match): Crumb => ({item: match.handle, match: match}))

    return (
        <StyledBreadCrumbs>
            <HomeLineIcon size={20} />

            <SlashDividerIcon size={20} fill={theme.palette.neutral['300']} />
            {crumbs.map(({item, match}, index) => {
                const path = !item?.paramName
                    ? item.path
                    : // @ts-expect-error [TS2339] Necessary because params is not mapped in the RouteObject Type
                      item.path.replace(`:${item.paramName}`, match?.params[item.paramName])
                // @ts-expect-error [TS2339] Necessary because params is not mapped in the RouteObject Type
                const name = item?.name ? t(item.name) : match.params[item.paramName]

                return (
                    <Fragment key={item.name}>
                        <StyledNavLinkCrumb
                            key={index}
                            to={path}
                            variant="tertiary"
                            size={'sm'}
                            $isActive={index === crumbs.length - 1}
                        >
                            {name}
                        </StyledNavLinkCrumb>
                        {index < crumbs.length - 1 && (
                            <SlashDividerIcon size={20} fill={theme.palette.neutral['300']} />
                        )}
                    </Fragment>
                )
            })}
        </StyledBreadCrumbs>
    )
}

BreadCrumbs.displayName = 'BreadCrumbs'
