import {Modal} from '@components/ui/modal-legacy/Modal.tsx'
import {FC, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {
    StyledCreateUserTitle,
    StyledFormDescription,
    StyledModalBody
} from '@/features/settings/components/create-and-edit-user-modal/style.ts'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {ACTION_TYPES, Role} from '@/features/settings/types.ts'
import {Controller, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {ContactObj, SETTINGS_FORM_MODEL, SettingsValidationSchema} from '@/features/settings/SettingsFormModal.ts'
import {TITLE_TYPE_OPTIONS} from '@utilities/constants/list.ts'
import {useAddUser} from '@/features/settings/services/services-users/useAddUser.ts'
import {useEditUser} from '@/features/settings/services/services-users/useEditUser.ts'
import {User} from '@/features/auth/types.ts'
import {InputPhone} from '@components/commons/input-phone/InputPhone.tsx'
import {capitalize, ObjectKeys} from '@utilities/helpers.ts'
import {CheckIcon} from '@components/ui/icon/Icon.tsx'
import {SingleSelect} from '@components/commons/single-select/SingleSelect.tsx'
import {MultiSelect} from '@components/commons/multi-select/MultiSelect.tsx'

interface CreateAndEditUserModalProps {
    modalType: string
    onClose: () => void
    selectedUser: User | null
    roles: Role[]
    officeAllocations: ContactObj[]
}

export const CreateAndEditUserModal: FC<CreateAndEditUserModalProps> = ({
    modalType,
    onClose,
    selectedUser,
    roles,
    officeAllocations
}) => {
    const {t} = useTranslation()
    const isEdit = modalType === ACTION_TYPES.edit
    const {mutateAsync: createUser} = useAddUser()
    const {mutateAsync: updateUser} = useEditUser()

    const remapRoles = useMemo(() => {
        const rolesMap = roles.map(role => ({
            value: String(role.id),
            label: capitalize(role.name)
        }))
        return rolesMap.filter(role => role.value !== '4' && role.value !== '5')
    }, [roles])

    const {
        register,
        control,
        handleSubmit,
        formState: {isSubmitting, errors, touchedFields, isDirty}
    } = useForm<SettingsValidationSchema>({
        mode: 'onSubmit',
        resolver: zodResolver(SettingsValidationSchema),
        defaultValues: {
            title: selectedUser?.title ?? undefined,
            lastName: selectedUser?.lastName ?? undefined,
            firstName: selectedUser?.firstName ?? undefined,
            email: selectedUser?.email ?? undefined,
            phoneNumber: selectedUser?.phoneNumber ?? undefined,
            occupation: selectedUser?.occupation ?? undefined,
            roleId: String(selectedUser?.roleId) ?? undefined,
            officeIds:
                selectedUser?.offices?.map(office => {
                    return {
                        value: String(office.id),
                        label: String(office.name)
                    }
                }) ?? []
        }
    })

    const onSubmit = async (data: SettingsValidationSchema) => {
        if (isEdit) {
            await updateUser({
                ...data,
                id: selectedUser?.id ?? 0,
                officeIds: data.officeIds.map((office: {value: string}) => +office.value)
            })
        } else {
            await createUser({
                ...data,
                type: roles.find(role => role.id === +data.roleId)?.name,
                officeIds: data.officeIds.map((office: {value: string}) => +office.value)
            })
        }
        onClose()
    }

    return (
        <Modal
            isLoading={isSubmitting}
            width={440}
            onClose={onClose}
            title={t('settings:create_modal:title')}
            submitButton={
                <Button
                    variant={'primary'}
                    type={'submit'}
                    form={'create-user-form'}
                    disabled={
                        isEdit
                            ? !isDirty || ObjectKeys(errors).length > 0
                            : ObjectKeys(errors).length > 0 || ObjectKeys(touchedFields).length === 0
                    }
                >
                    {t('commons:actions:confirm')}
                </Button>
            }
        >
            <StyledModalBody direction={'column'} gap={6}>
                <Flexbox gap={2} direction={'column'}>
                    <StyledCreateUserTitle>{t('settings:create_modal:title_2')}</StyledCreateUserTitle>

                    {!isEdit && <p>{t('settings:create_modal:description')}</p>}
                </Flexbox>

                <Flexbox
                    onSubmit={handleSubmit(onSubmit)}
                    render={'form'}
                    direction={'column'}
                    gap={4}
                    fullWidth
                    id={'create-user-form'}
                >
                    <Controller
                        name={SETTINGS_FORM_MODEL.title.name}
                        control={control}
                        render={({field}) => {
                            return (
                                <SingleSelect
                                    options={TITLE_TYPE_OPTIONS}
                                    label={t(SETTINGS_FORM_MODEL.title.label)}
                                    placeholder={t(SETTINGS_FORM_MODEL.title.placeholder)}
                                    value={field.value ?? ''}
                                    onChange={field.onChange}
                                    errorMessage={t(errors.title?.message || '')}
                                    hasIconOption={<CheckIcon size={20} />}
                                />
                            )
                        }}
                    />

                    <InputText
                        type={'text'}
                        required
                        label={t(SETTINGS_FORM_MODEL.lastName.label)}
                        placeholder={t(SETTINGS_FORM_MODEL.lastName.placeholder)}
                        errorMessage={t(errors.lastName?.message || '')}
                        touched={touchedFields.lastName}
                        {...register(SETTINGS_FORM_MODEL.lastName.name)}
                    />

                    <InputText
                        type={'text'}
                        required
                        label={t(SETTINGS_FORM_MODEL.firstName.label)}
                        placeholder={t(SETTINGS_FORM_MODEL.firstName.placeholder)}
                        errorMessage={t(errors.firstName?.message || '')}
                        touched={touchedFields.firstName}
                        {...register(SETTINGS_FORM_MODEL.firstName.name)}
                    />

                    <InputText
                        type={'email'}
                        required
                        label={t(SETTINGS_FORM_MODEL.email.label)}
                        placeholder={t(SETTINGS_FORM_MODEL.email.placeholder)}
                        errorMessage={t(errors.email?.message || '')}
                        touched={touchedFields.email}
                        {...register(SETTINGS_FORM_MODEL.email.name)}
                    />

                    <Controller
                        name={SETTINGS_FORM_MODEL.phoneNumber.name}
                        control={control}
                        render={({field}) => {
                            return (
                                <InputPhone
                                    label={t(SETTINGS_FORM_MODEL.phoneNumber.label)}
                                    placeholder={t(SETTINGS_FORM_MODEL.phoneNumber.placeholder)}
                                    errorMessage={t(errors.phoneNumber?.message || '')}
                                    touched={touchedFields.phoneNumber || false}
                                    {...field}
                                />
                            )
                        }}
                    />

                    <InputText
                        type={'text'}
                        label={t(SETTINGS_FORM_MODEL.occupation.label)}
                        placeholder={t(SETTINGS_FORM_MODEL.occupation.placeholder)}
                        errorMessage={t(errors.occupation?.message || '')}
                        touched={touchedFields.occupation}
                        {...register(SETTINGS_FORM_MODEL.occupation.name)}
                    />

                    <Controller
                        name={SETTINGS_FORM_MODEL.roleId.name}
                        control={control}
                        render={({field}) => {
                            return (
                                <SingleSelect
                                    required
                                    options={remapRoles}
                                    value={field.value}
                                    onChange={field.onChange}
                                    label={t(SETTINGS_FORM_MODEL.roleId.label)}
                                    placeholder={t(SETTINGS_FORM_MODEL.roleId.placeholder)}
                                    errorMessage={t(errors.roleId?.message || '')}
                                />
                            )
                        }}
                    />

                    <Controller
                        name={SETTINGS_FORM_MODEL.officeIds.name}
                        control={control}
                        render={({field}) => {
                            return (
                                <MultiSelect
                                    required
                                    options={
                                        officeAllocations.map(office => ({
                                            value: String(office.id),
                                            label: office.name || ''
                                        })) || []
                                    }
                                    value={field.value}
                                    onChange={field.onChange}
                                    label={t(SETTINGS_FORM_MODEL.officeIds.label)}
                                    placeholder={t(SETTINGS_FORM_MODEL.officeIds.placeholder)}
                                    errorMessage={t(errors.officeIds?.message || '')}
                                />
                            )
                        }}
                    />

                    {!isEdit && (
                        <StyledFormDescription>
                            <p>{t('settings:form:create_description')}</p>
                        </StyledFormDescription>
                    )}
                </Flexbox>
            </StyledModalBody>
        </Modal>
    )
}
