import {z} from 'zod'

export const PatientFormSchema = z.object({
    firstName: z.string().min(1, {message: 'patients:create_patient:form:errors:firstName'}),
    lastName: z.string().min(1, {message: 'patients:create_patient:form:errors:lastName'}),
    gender: z.string().min(1, {message: 'patients:create_patient:form:errors:gender'}),
    phoneNumber: z.string().min(1, {message: 'patients:create_patient:form:errors:phoneNumber'}),
    birthDate: z.date({
        errorMap: issue => ({
            message: issue.code == 'invalid_date' ? 'patients:create_patient:form:errors:birthDate' : 'errors:default'
        })
    }),
    birthCityId: z
        .object({
            label: z.string().min(1, {message: 'patients:create_patient:form:errors:birthCity'}),
            value: z.string().min(1, {message: 'patients:create_patient:form:errors:birthCity'})
        })
        .nullable(),
    birthStateId: z
        .object({
            label: z.string().min(1, {message: 'patients:create_patient:form:errors:birthState'}),
            value: z.string().min(1, {message: 'patients:create_patient:form:errors:birthState'})
        })
        .nullable(),
    birthCountryId: z
        .object({
            label: z.string().min(1, {message: 'patients:create_patient:form:errors:birthCountry'}),
            value: z.string().min(1, {message: 'patients:create_patient:form:errors:birthCountry'})
        })
        .nullable(),
    taxCode: z.string().regex(/^[A-Za-z]{6}[0-9]{2}[A-Za-z][0-9]{2}[A-Za-z][0-9]{3}[A-Za-z]$/, {
        message: 'patients:create_patient:form:errors:taxCode'
    }),
    email: z.string().email({message: 'errors:email_valid'})
})
export type PatientFormValidationSchema = z.infer<typeof PatientFormSchema>

export const ForeignPatientFormSchema = z.object({
    firstName: z.string().min(1, {message: 'patients:create_patient:form:errors:firstName'}),
    lastName: z.string().min(1, {message: 'patients:create_patient:form:errors:lastName'}),
    gender: z.string().min(1, {message: 'patients:create_patient:form:errors:gender'}),
    birthDate: z.date(),
    birthCityId: z.undefined(),
    birthStateId: z.undefined(),
    phoneNumber: z.string().min(1, {message: 'patients:create_patient:form:errors:phoneNumber'}),
    birthCountryId: z
        .object({
            label: z.string().min(1, {message: 'patients:create_patient:form:errors:birthCountry'}),
            value: z.string().min(1, {message: 'patients:create_patient:form:errors:birthCountry'})
        })
        .nullable(),
    taxCode: z.string().regex(/^[A-Za-z]{6}[0-9]{2}[A-Za-z][0-9]{2}[A-Za-z][0-9]{3}[A-Za-z]$/, {
        message: 'patients:create_patient:form:errors:taxCode'
    }),
    email: z.string().email({message: 'errors:email_valid'})
})
export type ForeignPatientFormValidationSchema = z.infer<typeof ForeignPatientFormSchema>

export const getPatientFormFormSchema = (isForeignBorn: boolean) => {
    return z.object({
        firstName: z.string().min(1, {message: 'patients:create_patient:form:errors:firstName'}),
        lastName: z.string().min(1, {message: 'patients:create_patient:form:errors:lastName'}),
        gender: z.string().min(1, {message: 'patients:create_patient:form:errors:gender'}),
        birthDate: z.date(),
        phoneNumber: z.string().min(1, {message: 'patients:create_patient:form:errors:phoneNumber'}),
        taxCode: z.string().regex(/^[A-Za-z]{6}[0-9]{2}[A-Za-z][0-9]{2}[A-Za-z][0-9]{3}[A-Za-z]$/, {
            message: 'patients:create_patient:form:errors:taxCode'
        }),
        email: z.string().min(1, {message: 'errors:email_valid'}).email({message: 'errors:email_valid'}),
        birthCountryId: z.object(
            {
                label: z.string().min(1, {message: 'patients:create_patient:form:errors:birthCountry'}),
                value: z.string().min(1, {message: 'patients:create_patient:form:errors:birthCountry'})
            },
            {
                invalid_type_error: 'patients:create_patient:form:errors:birthCountry',
                required_error: 'patients:create_patient:form:errors:birthCountry'
            }
        ),
        birthCityId: isForeignBorn
            ? z.undefined().nullable()
            : z.object(
                  {
                      label: z.string().min(1, {message: 'patients:create_patient:form:errors:birthCity'}),
                      value: z.string().min(1, {message: 'patients:create_patient:form:errors:birthCity'})
                  },
                  {
                      invalid_type_error: 'patients:create_patient:form:errors:birthCity',
                      required_error: 'patients:create_patient:form:errors:birthCity'
                  }
              ),
        birthStateId: isForeignBorn
            ? z.undefined().nullable()
            : z.object(
                  {
                      label: z.string().min(1, {message: 'patients:create_patient:form:errors:birthState'}),
                      value: z.string().min(1, {message: 'patients:create_patient:form:errors:birthState'})
                  },
                  {
                      invalid_type_error: 'patients:create_patient:form:errors:birthState',
                      required_error: 'patients:create_patient:form:errors:birthState'
                  }
              )
    })
}

// Create a union type for the schemas
export type ZodObjectPatientFormSchema = typeof PatientFormSchema | typeof ForeignPatientFormSchema
