import {SelectItem} from '@/components/ui/select-atoms/SelectAtoms'
import {SelectTrigger} from '@/components/ui/select-atoms/SelectTrigger'
import styled, {css} from 'styled-components'

export const StyledSelectRangeTrigger = styled(SelectTrigger)<{$maxWidth?: number}>`
    ${({$maxWidth}) => css`
        font-weight: 600;
        min-height: 40px;
        width: fit-content;
        max-width: ${$maxWidth}px;
    `}
`

export const StyledSelectRangeItem = styled(SelectItem)(
    ({theme: {typography}}) => css`
        ${typography.textSm};
        font-weight: 500;
    `
)
