import {useTranslation} from 'react-i18next'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {SelfEvaluationUrlSearchParams} from '@/features/self-evaluation/types.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {CalendarIcon, CheckIcon} from '@components/ui/icon/Icon.tsx'
import {SelectPopover} from '@components/ui/select-atoms/SelectAtoms.tsx'
import {ObjectKeys} from '@utilities/helpers.ts'
import {SelectProvider, useSelectStore} from '@ariakit/react'
import {
    StyledSelectRangeItem,
    StyledSelectRangeTrigger
} from '@/features/self-evaluation/components/self-evaluation-options/style.ts'
import {rangeDateToLabel} from '@utilities/utils.ts'
import {RangeDateLabels} from '@/types.ts'

export const SelfEvaluationOptions = () => {
    const {t} = useTranslation()
    const {searchParams, setSearchParams} = useParsedSearchParams([], SelfEvaluationUrlSearchParams)

    const selectStore = useSelectStore({
        value: searchParams.rangeDateLabels,
        setValue: (value: RangeDateLabels) => setSearchParams({rangeDateLabels: value}),
        defaultValue: 'range_date'
    })

    return (
        <SelectProvider store={selectStore}>
            <StyledSelectRangeTrigger size="sm" variant="secondary" minWidth={220}>
                <Flexbox align="center" gap={2}>
                    <CalendarIcon />
                    {t(rangeDateToLabel[searchParams.rangeDateLabels])}
                </Flexbox>
            </StyledSelectRangeTrigger>

            <SelectPopover gutter={4} sameWidth>
                {ObjectKeys(rangeDateToLabel).map(option => (
                    <StyledSelectRangeItem key={option} value={option}>
                        {t(rangeDateToLabel[option])}
                        {option == searchParams.rangeDateLabels && <CheckIcon size={20} />}
                    </StyledSelectRangeItem>
                ))}
            </SelectPopover>
        </SelectProvider>
    )
}
