import {z} from 'zod'
import {OrderDirection, RangeDate, RangeDateLabels} from '@/types.ts'
import {UserSmall} from '@/features/auth/types.ts'

export const Provenance = z.enum(['app', 'web'])
export type Provenance = z.infer<typeof Provenance>

export const SelfEvaluationForm = z.object({
    id: z.number(),
    createdAt: z.string(),
    minValidScore: z.number(),
    selfEvaluationFormQuestions: z
        .object({
            id: z.number(),
            keyName: z.string(),
            createdAt: z.string()
        })
        .array()
})
export type SelfEvaluationForm = z.infer<typeof SelfEvaluationForm>

export const SelfEvaluation = z.object({
    id: z.number(),
    token: z.string().nullable(),
    selfEvaluationFormId: z.number(),
    intakeSource: Provenance.nullable(),
    createdAt: z.string(),
    selfEvaluationForm: SelfEvaluationForm,
    totalScore: z.number(),
    user: UserSmall.nullable()
})
export type SelfEvaluation = z.infer<typeof SelfEvaluation>

export const SelfEvaluationStats = z.object({
    onPeriod: z.object({
        count: z.number(),
        positive: z.number(),
        negative: z.number(),
        fromApp: z.number(),
        fromWeb: z.number()
    }),
    pastPeriod: z.object({
        count: z.number(),
        positive: z.number(),
        negative: z.number()
    })
})
export type SelfEvaluationStats = z.infer<typeof SelfEvaluationStats>

export const SelfEvaluationSearchParams = z.object({
    order_by: z.enum(['patient', 'result', 'intakeSource', 'createdAt']).optional(),
    order_direction: OrderDirection.optional(),
    ...RangeDate.shape,
    search: z.string().optional(),
    anonymous: z.string().optional(),
    result: z.string().optional(),
    intakeSource: z.string().optional()
})
export type SelfEvaluationSearchParams = z.infer<typeof SelfEvaluationSearchParams>

export const SelfEvaluationUrlSearchParams = z.object({
    ...SelfEvaluationSearchParams.omit({startDate: true, endDate: true}).shape,
    rangeDateLabels: RangeDateLabels
})
export type SelfEvaluationUrlSearchParams = z.infer<typeof SelfEvaluationUrlSearchParams>

export const SelfEvaluationFilters = SelfEvaluationSearchParams.omit({
    order_by: true,
    order_direction: true,
    range_date: true,
    search: true
})
export type SelfEvaluationFilters = z.infer<typeof SelfEvaluationFilters>
