import {Outlet} from 'react-router-dom'
import {useURLParsedParams} from '@/hooks/useURLParsedParams'
import {ErrorBox} from '@/components/commons/error-box/ErrorBox'
import {useTranslation} from 'react-i18next'
import {AlertCircleIcon} from '@components/ui/icon/Icon.tsx'
import {PatientDetailsURLParams} from '@/features/patient-details/types.ts'

export const PatientDetailsRouteGuard = () => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(PatientDetailsURLParams)

    return urlParams.success ? (
        <Outlet context={{urlParams: urlParams.data}} />
    ) : (
        <ErrorBox title={t('errors:invalid_url')} icon={<AlertCircleIcon />} />
    )
}
