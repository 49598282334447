import React, {useEffect} from 'react'
import {Container} from '@components/ui/container/Container.tsx'
import {StyledContainerItems} from '@pages/articles/style.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {PlusIcon, WordPressIcon} from '@components/ui/icon/Icon.tsx'
import {useTranslation} from 'react-i18next'
import {EmptyState} from '@components/commons/empty-state/EmptyState.tsx'
import {ArticleItem} from '@components/commons/article-item/ArticleItem.tsx'
import toast from 'react-hot-toast'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {useGetEvents} from '@/features/events/services/useGetEvents.ts'
import {SkeletonArticleItem, skeletonItems} from '@components/commons/article-item/SkeletonArticleItems.tsx'
import {envVars} from '@/envVars.ts'

export const Events: React.FC = () => {
    const {t} = useTranslation()
    const {data: events, isError, isLoading} = useGetEvents()

    useEffect(() => {
        isError && toast.error(t('errors:default'))
    }, [isError])

    return (
        <>
            <Container fullWidth>
                <PageHero
                    title={t('routeNames:events')}
                    ctaComponent={
                        events &&
                        events?.length > 0 && (
                            <Button onClick={() => window.open(envVars.VITE_CREATE_ARTICLES_ENDPOINT, '_blank')}>
                                <PlusIcon />
                                <p>{t('events:cta')}</p>
                            </Button>
                        )
                    }
                />
                {isLoading && (
                    <StyledContainerItems direction={'column'} gap={4}>
                        {skeletonItems?.map(item => <SkeletonArticleItem key={item} />)}
                    </StyledContainerItems>
                )}
                {!events?.length && !isLoading ? (
                    <EmptyState
                        title={t('events:title')}
                        description={t('events:subtitle')}
                        onClickCta={() => window.open(envVars.VITE_CREATE_ARTICLES_ENDPOINT, '_blank')}
                        ctaLabel={
                            <>
                                <PlusIcon />
                                <p>{t('events:cta')}</p>
                            </>
                        }
                        icon={<WordPressIcon />}
                    />
                ) : (
                    <StyledContainerItems direction={'column'} gap={4}>
                        {events?.map(article => <ArticleItem key={article.id} article={article} />)}
                    </StyledContainerItems>
                )}
            </Container>
        </>
    )
}

Events.displayName = 'Events'
