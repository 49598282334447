import React from 'react'
import {Container} from '@components/ui/container/Container.tsx'
import {useOutletContext} from 'react-router-dom'
import {SelfEvaluationDetailsURLParams} from '@/features/self-evaluation-details/types.ts'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {useGetSelfEvaluationDetails} from '@/features/self-evaluation-details/service/useGetSelfEvaluationDetails.ts'
import {useTranslation} from 'react-i18next'
import {AlertCircleIcon} from '@components/ui/icon/Icon.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {SelfEvaluationTableHeader} from '@/features/self-evaluation-details/components/self-evaluation-details-table-header/SelfEvaluationTableHeader.tsx'
import {SelfEvaluationDetailsTable} from '@/features/self-evaluation-details/components/self-evaluation-details-table/SelfEvaluationDetailsTable.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const SelfEvaluationDetails: React.FC = () => {
    const {urlParams} = useOutletContext<{urlParams: SelfEvaluationDetailsURLParams}>()
    const {t} = useTranslation()
    const {isError} = useGetSelfEvaluationDetails({selfEvaluationId: urlParams.selfEvaluationId})
    return (
        <Container fullWidth>
            <PageHero title={`#${urlParams.selfEvaluationId}`} />

            {isError ? (
                <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
            ) : (
                <Flexbox direction={'column'} gap={8}>
                    <SelfEvaluationTableHeader />
                    <SelfEvaluationDetailsTable />
                </Flexbox>
            )}
        </Container>
    )
}

SelfEvaluationDetails.displayName = 'SelfAssessmentDetails'
