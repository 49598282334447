import styled, {css} from 'styled-components'

type StyledThumbnailContainerProps = {
    size: number
    $isRounded: boolean
}
export const StyledThumbnailContainer = styled.div<StyledThumbnailContainerProps>`
    ${({theme, size, $isRounded}) => css`
        width: ${size}px;
        height: ${size}px;
        border-radius: ${$isRounded ? theme.spacing * 2 : 0}px;
        overflow: hidden;
    `}
`

export const StyledThumbnailImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`
