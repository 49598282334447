import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledContainerEventsAndArticles = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 6}px;
        max-height: 300px;
        overflow-y: scroll;
    `}
`

export const StyledSectionCardsTitle = styled.span`
    ${({theme: {typography, palette}}) => css`
        ${typography.textLg};
        font-weight: 600;
        color: ${palette.neutral[900]};
    `}
`

export const StyledBaseCardsTitle = styled.span`
    ${({theme: {typography, palette}}) => css`
        ${typography.textMd};
        font-weight: 600;
        color: ${palette.neutral[900]};
    `}
`

export const StyledCardWrapper = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 6}px ${spacing * 8}px;
    `}
`
export const StyledSelfEvaluationCardWrapper = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 5}px ${spacing * 6}px;
    `}
`

export const StyledContainerAdminCard = styled.div`
    ${({theme}) => css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
        gap: ${theme.spacing * 6}px;
        width: 100%;
    `}
`
