export const TITLE_TYPE = {
    female_doctor: 'female_doctor',
    male_doctor: 'male_doctor',
    mr: 'mr',
    mrs: 'mrs'
}

export const APPOINTMENT_NOTIFICATION = {
    fifteen_minutes: 'fifteen_minutes',
    thirty_minutes: 'thirty_minutes',
    one_hour: 'one_hour',
    two_hours: 'two_hours'
}

export const AGE_SELECT = {
    until_17: '14_17',
    from_18_to_34: '18_34',
    from_35_to_50: '35_50',
    over_50: 'over_50'
}

export const RANGE_DATE = {
    last_year: 'last_year',
    last_6_months: 'last_6_months',
    last_3_months: 'last_3_months',
    last_month: 'last_month',
    last_week: 'last_week'
}

export const GENRE_SELECT = {
    male: 'male',
    female: 'female'
}
