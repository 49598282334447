import {DefaultTheme} from 'styled-components/dist/types'
import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const makeFilledOutPercentStyle = ({palette}: DefaultTheme) =>
    ({
        over: css`
            color: ${palette.success[600]};
        `,
        under: css`
            color: ${palette.danger[600]};
        `,
        equal: css`
            color: ${palette.neutral[400]};
        `
    }) as const satisfies Record<NonNullable<'over' | 'under' | 'equal'>, ReturnType<typeof css>>

export const StyledMoneyGambledFilledOutPercentResult = styled(Flexbox)<{$variant: 'over' | 'under' | 'equal'}>(
    ({theme, $variant}) => css`
        ${theme.typography.textSm};
        ${makeFilledOutPercentStyle(theme)[$variant]};
    `
)

export const StyledMoneyGambledStatsGrid = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: ${spacing * 6}px;
        width: 100%;
    `
)

export const StyledMoneyGambledStatTitle = styled.h3(
    ({theme: {typography}}) => css`
        ${typography.displaySm};
        font-weight: 500;
    `
)

export const StyledDailyAverageListValue = styled.h3(
    ({theme: {palette, typography}}) => css`
        ${typography.textXl};
        font-weight: 600;
        color: ${palette.neutral[900]};
    `
)

export const StyledDailyAverageListItem = styled.p(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        font-weight: 600;
        color: ${palette.neutral[600]};
    `
)
