import axios from '@/axiosInstance'
import {Location} from '@/features/patients/types'
import {PaginationParams, PaginationResponse} from '@/types'
import {z} from 'zod'

/**
 * Get Countries
 */
export const HttpGetCountriesParams = z.object({
    ...PaginationParams.shape,
    search: z.string().optional()
})
export type HttpGetCountriesParams = z.infer<typeof HttpGetCountriesParams>

export const HttpGetCountriesResponse = z.object({
    meta: PaginationResponse,
    data: Location.array()
})
export type HttpGetCountriesResponse = z.infer<typeof HttpGetCountriesResponse>

export const httpGetCountries = (params: HttpGetCountriesParams) => {
    return axios.get('/countries', {params})
}

/**
 * Get States
 */
export const HttpGetStatesResponse = z.object({
    meta: PaginationResponse,
    data: Location.array()
})
export type HttpGetStatesResponse = z.infer<typeof HttpGetStatesResponse>

export const HttpGetStatesParams = HttpGetCountriesParams.merge(z.object({countryId: z.coerce.number()}))
export type HttpGetStatesParams = z.infer<typeof HttpGetStatesParams>

export const httpGetStates = (params: HttpGetStatesParams) => {
    return axios.get(`/countries/${params.countryId}/states`, {params})
}

/**
 * Get Cities
 */
export const HttpGetLocationsResponse = z.object({
    meta: PaginationResponse,
    data: Location.array()
})
export type HttpGetLocationsResponse = z.infer<typeof HttpGetLocationsResponse>

export const HttpGetCitiesParams = HttpGetStatesParams.merge(z.object({stateId: z.number()}))
export type HttpGetCitiesParams = z.infer<typeof HttpGetCitiesParams>

export const httpGetCities = (params: HttpGetCitiesParams) => {
    return axios.get(`/countries/${params.countryId}/states/${params.stateId}/cities`, {params})
}

/**
 * Create Patients
 */
export const HttpAddPatientPayload = z.object({
    firstName: z.string(),
    lastName: z.string(),
    gender: z.string(),
    birthDate: z.string(),
    phoneNumber: z.string(),
    birthCountryId: z.number(),
    birthStateId: z.number().optional(),
    birthCityId: z.number().optional(),
    taxCode: z.string(),
    email: z.string().email()
})
export type HttpAddPatientPayload = z.infer<typeof HttpAddPatientPayload>

export const HttpAddPatientParams = z.object({
    payload: HttpAddPatientPayload
})
export type HttpAddPatientParams = z.infer<typeof HttpAddPatientParams>

export const httpAddPatient = (params: HttpAddPatientParams) => axios.post(`/users`, params.payload)

/**
 * Update Patients
 */
export const HttpEditPatientPayload = z.object({
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    gender: z.string().optional(),
    birthDate: z.string().optional(),
    birthCountryId: z.number().optional(),
    birthStateId: z.number().optional(),
    phoneNumber: z.string().optional(),
    birthCityId: z.number().optional(),
    onlineAppointmentEnabled: z.boolean().optional(),
    taxCode: z.string().optional(),
    isCurrentlyInTherapy: z.boolean().optional()
})
export type HttpEditPatientPayload = z.infer<typeof HttpEditPatientPayload>

export const HttpEditPatientParams = z.object({
    urlParams: z.object({patientId: z.number()}),
    payload: HttpEditPatientPayload
})
export type HttpEditPatientParams = z.infer<typeof HttpEditPatientParams>

export const httpEditPatient = ({urlParams, payload}: HttpEditPatientParams) =>
    axios.patch(`/users/${urlParams.patientId}`, payload)

/**
 * Get Tax code
 */
export const HttpGetPatientTaxCode = z.object({
    firstName: z.string(),
    lastName: z.string(),
    gender: z.string(),
    birthDate: z.string(),
    countryId: z.number().positive().int(),
    cityId: z.number().positive().int().optional()
})
export type HttpGetPatientTaxCode = z.infer<typeof HttpGetPatientTaxCode>

export const httpGetPatientTaxCode = (params: HttpGetPatientTaxCode) =>
    axios.get(`/users/generate-tax-code`, {
        params
    })
