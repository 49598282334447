import React from 'react'
import {Container} from '@components/ui/container/Container.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AlertCircleIcon, FileQuestion02Icon} from '@components/ui/icon/Icon.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {EmptyState} from '@components/commons/empty-state/EmptyState.tsx'
import {PageContent} from '@components/ui/page-content/PageContent.tsx'
import {useSelfEvaluationTable} from '@/features/self-evaluation/services/useSelfEvaluationTable.ts'
import {useTranslation} from 'react-i18next'
import {SelfEvaluationCardsStats} from '@/features/self-evaluation/components/self-evaluation-cards-stats/SelfEvaluationCardsStats.tsx'
import {SelfEvaluationTable} from '@/features/self-evaluation/components/self-evaluation-table/SelfEvaluationTable.tsx'
import {SelfEvaluationTableFilters} from '@/features/self-evaluation/components/self-evaluation-table-filters/SelfEvaluationTableFilters.tsx'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {SelfEvaluationOptions} from '@/features/self-evaluation/components/self-evaluation-options/SelfEvaluationOptions.tsx'

export const SelfEvaluation: React.FC = () => {
    const {t} = useTranslation()
    const selfAssessmentTable = useSelfEvaluationTable()
    return (
        <Container fullWidth>
            <PageContent>
                <PageHero title={t('self_assessment:title')} ctaComponent={<SelfEvaluationOptions />} />
                {selfAssessmentTable.isError ? (
                    <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
                ) : selfAssessmentTable.isLoading ||
                  (selfAssessmentTable.data && selfAssessmentTable.data.length > 0) ||
                  selfAssessmentTable.filterValue ? (
                    <Flexbox gap={6} direction="column">
                        <SelfEvaluationCardsStats />
                        <SelfEvaluationTableFilters />
                        <SelfEvaluationTable />
                    </Flexbox>
                ) : (
                    <EmptyState
                        icon={<FileQuestion02Icon />}
                        title={t('self_assessment:empty_state:title')}
                        description={t('self_assessment:empty_state:description')}
                    />
                )}
            </PageContent>
        </Container>
    )
}

SelfEvaluation.displayName = 'SelfAssessment'
