import {ZodObjectPatientFormSchema} from '@/features/patient-form/PatientFormModel'
import {Controller, useFormContext} from 'react-hook-form'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {SingleSelect} from '@components/commons/single-select/SingleSelect.tsx'
import {GENDER_SELECT_OPTIONS} from '@/features/patients/utils.ts'
import {useTranslation} from 'react-i18next'
import {DatePicker} from '@components/commons/date-picker/DatePicker.tsx'
import dayjs, {formatDate} from '@/dayjs.ts'
import {Switch} from '@components/ui/switch/Switch.tsx'
import {z} from 'zod'
import {Dispatch, SetStateAction, useMemo} from 'react'
import {CitySelector} from '@/features/patient-form/components/city-selector/CitySelector'
import {CountrySelector} from '@/features/patient-form/components/country-selector/CountrySelector'
import {
    StyledCalculatePatientFiscalCodeButton,
    StyledStateSelector
} from '@/features/patient-form/components/patient-form-modal/style'
import {useAsync} from '@/hooks/useAsync'
import {httpGetPatientTaxCode} from '@/features/patient-form/services/patientForm.http'
import toast from 'react-hot-toast'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {CountryKey} from '@/features/patient-form/types'
import {InputPhone} from '@/components/commons/input-phone/InputPhone'
import {DEFAULT_COUNTRY} from '../patient-form-modal/PatientFormModal'

export const PatientFormPersonalDataStep = ({
    formSchema,
    isForeignBorn,
    setIsForeignBorn
}: {
    formSchema: ZodObjectPatientFormSchema
    isForeignBorn: boolean
    setIsForeignBorn: Dispatch<SetStateAction<boolean>>
}) => {
    const {t} = useTranslation()
    const {
        control,
        watch,
        setValue,
        register,
        clearErrors,
        formState: {errors, touchedFields}
    } = useFormContext<z.infer<typeof formSchema>>()
    const calculatePatientTaxCode = useAsync()

    const [firstName, lastName, birthDate, birthCountryId, birthStateId, birthCityId, gender] = watch([
        'firstName',
        'lastName',
        'birthDate',
        'birthCountryId.value',
        'birthStateId.value',
        'birthCityId.value',
        'gender'
    ])

    const isCalculateTaxCodeDisabled = useMemo(
        () =>
            !firstName ||
            !lastName ||
            !gender ||
            !birthDate ||
            !birthCountryId ||
            (birthCountryId == CountryKey.Italy.toString() && !birthCityId),
        [firstName, lastName, birthDate, birthCountryId, birthStateId, birthCityId]
    )

    const onCalculatePatientTaxCode = async () => {
        try {
            if (isCalculateTaxCodeDisabled) {
                throw new Error(t('patients:create_patient:form:calculate_fiscal_code_error'))
            }

            const {data} = await calculatePatientTaxCode.run(
                httpGetPatientTaxCode({
                    firstName: firstName,
                    lastName: lastName,
                    gender: gender,
                    birthDate: formatDate(birthDate.toISOString(), 'YYYY-MM-DD'),
                    countryId: parseInt(birthCountryId),
                    cityId: birthCityId ? parseInt(birthCityId) : undefined
                })
            )

            if (data.cf) {
                setValue('taxCode', data.cf)
                clearErrors('taxCode')
            } else {
                throw new Error(t('patients:create_patient:form:calculate_fiscal_code_error'))
            }
        } catch (error) {
            toast.error(t('patients:create_patient:form:calculate_fiscal_code_error'))
        }
    }

    return (
        <>
            <Flexbox direction={'column'} gap={2}>
                <h1>{t('patients:create_patient:subtitle')}</h1>
                <p>{t('patients:create_patient:description')}</p>
            </Flexbox>
            <InputText
                required
                label={t('patients:create_patient:form:labels:lastName')}
                type={'text'}
                placeholder={t('patients:create_patient:form:placeholder:lastName')}
                errorMessage={t(errors.lastName?.message || '')}
                touched={touchedFields.lastName}
                {...register('lastName')}
                onChange={e => {
                    setValue('taxCode', '')
                    setValue('lastName', e.currentTarget.value)
                }}
            />
            <InputText
                required
                label={t('patients:create_patient:form:labels:firstName')}
                type={'text'}
                placeholder={t('patients:create_patient:form:placeholder:firstName')}
                errorMessage={t(errors.firstName?.message || '')}
                touched={touchedFields.firstName}
                {...register('firstName')}
                onChange={e => {
                    setValue('taxCode', '')
                    setValue('firstName', e.currentTarget.value)
                }}
            />
            <Controller
                control={control}
                name={'gender'}
                render={({field: {onChange, value}}) => (
                    <SingleSelect
                        required
                        options={GENDER_SELECT_OPTIONS}
                        label={t('patients:create_patient:form:labels:gender')}
                        placeholder={t('patients:create_patient:form:placeholder:gender')}
                        onChange={e => {
                            setValue('taxCode', '')
                            onChange(e)
                        }}
                        value={value || ''}
                        errorMessage={t(errors.gender?.message || '')}
                    />
                )}
            />
            <Controller
                control={control}
                name={'birthDate'}
                render={({field: {onChange, value, onBlur}, fieldState: {error}}) => (
                    <DatePicker
                        toggle
                        formatter={date => dayjs(date).format('DD/MM/YYYY')}
                        numMonths={1}
                        maxDate={dayjs().subtract(10, 'year').toDate()}
                        mode={'single'}
                        selectedDates={value ? [value] : []}
                        onDatesChange={dates => {
                            setValue('taxCode', '')
                            onChange(dates[0])
                        }}
                        onBlur={onBlur}
                        triggerProps={{
                            required: true,
                            label: t('patients:create_patient:form:labels:birth_date'),
                            errorMessage: t(error?.message || ''),
                            placeholder: t('patients:create_patient:form:placeholder:birth_date')
                        }}
                    />
                )}
            />
            <Switch
                label={t('patients:create_patient:form:labels:has_born_abroad')}
                onChange={() => {
                    if (isForeignBorn) {
                        setIsForeignBorn(false)
                        setValue('birthCountryId', DEFAULT_COUNTRY)
                    } else {
                        setIsForeignBorn(true)
                        setValue('birthCountryId', null)
                        setValue('birthStateId', null)
                        setValue('birthCityId', null)
                    }

                    clearErrors('birthStateId')
                    clearErrors('birthCityId')
                }}
                defaultChecked={isForeignBorn}
            />
            {isForeignBorn ? (
                <Controller
                    control={control}
                    name={'birthCountryId'}
                    render={({field: {value, onChange}, fieldState: {error}}) => (
                        <CountrySelector
                            value={value}
                            errorMessage={error?.message}
                            onChange={e => {
                                setValue('taxCode', '')
                                onChange(e)
                            }}
                        />
                    )}
                />
            ) : (
                <>
                    <Controller
                        control={control}
                        name={'birthStateId'}
                        render={({field: {value, onChange}, fieldState: {error}}) => (
                            <StyledStateSelector
                                countryId={birthCountryId ?? ''}
                                value={value}
                                onChange={e => {
                                    setValue('birthCityId', null)
                                    setValue('taxCode', '')
                                    onChange(e)
                                }}
                                errorMessage={error?.message}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name={'birthCityId'}
                        render={({field: {value, onChange}, fieldState: {error}}) => (
                            <CitySelector
                                countryId={birthCountryId ?? ''}
                                stateId={birthStateId ?? ''}
                                value={value}
                                onChange={e => {
                                    setValue('taxCode', '')
                                    onChange(e)
                                }}
                                errorMessage={error?.message}
                            />
                        )}
                    />
                </>
            )}
            <InputText
                required
                label={t('patients:create_patient:form:labels:fiscal_code')}
                type={'text'}
                placeholder={t('patients:create_patient:form:placeholder:fiscal_code')}
                errorMessage={t(errors.taxCode?.message || '')}
                touched={touchedFields.taxCode}
                {...register('taxCode')}
            />

            <StyledCalculatePatientFiscalCodeButton
                variant="tertiaryColor"
                disabled={calculatePatientTaxCode.isLoading || isCalculateTaxCodeDisabled}
                onClick={onCalculatePatientTaxCode}
            >
                {calculatePatientTaxCode.isLoading && <Spinner size={14} />}
                {t('patients:create_patient:form:calculate_fiscal_code')}
            </StyledCalculatePatientFiscalCodeButton>

            <Controller
                control={control}
                name={'phoneNumber'}
                render={({field}) => (
                    <InputPhone
                        label={`${t('patient_details:caregiver_form_labels:phoneNumber')}*`}
                        placeholder={t('patient_details:caregiver_form_labels:phoneNumber')}
                        errorMessage={t(errors.phoneNumber?.message || '')}
                        touched={touchedFields.phoneNumber || false}
                        {...field}
                    />
                )}
            />
        </>
    )
}
