import {useMutation, useQueryClient} from '@tanstack/react-query'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {HttpCreateShiftPayload, Shift} from '@/features/appointments/types.ts'
import {captureException} from '@sentry/react'
import toast from 'react-hot-toast'
import {QUERY_KEYS, queryProps} from '@/queryClient.ts'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types.ts'
import {useTranslation} from 'react-i18next'
import {httpCreateShift} from '@/features/appointments/services/shift.http.ts'
import {errorHandler} from '@utilities/helpers.ts'

export const useCreateShift = (options?: queryProps) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: HttpCreateShiftPayload) =>
            parseAxiosPromise({
                axiosPromise: httpCreateShift(data),
                responseShape: Shift,
                onZodError: captureException
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GET_SHIFTS]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GET_SHIFT_DETAILS]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GET_AVAILABLE_SLOTS]})
            options?.onSuccess?.()
            toast.success(t('commons:operation_completed'))
        },
        onError: (error: AxiosError<ErrorResponseData>) => {
            options?.onError?.(error)
            errorHandler(error)
        }
    })
}
