import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance'
import {captureException} from '@sentry/react'
import {
    httpGetCheckInDetails,
    HttpGetCheckInDetailsOptions
} from '@/features/check-in-details/services/checkInDetails.http.ts'
import {CheckInDetails} from '@/features/check-in-details/types.ts'

export const useGetCheckInDetails = (
    promiseArgs: HttpGetCheckInDetailsOptions,
    options?: Omit<UseQueryOptions<CheckInDetails>, 'queryFn' | 'queryKey'>
) =>
    useQuery({
        ...options,
        queryKey: [QUERY_KEYS.CHECK_IN_DETAILS, promiseArgs],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetCheckInDetails(promiseArgs),
                responseShape: CheckInDetails,
                onZodError: captureException
            })
    })
