import {BaseCard} from '@components/commons/cards/BaseCard.tsx'
import {DiaryDateSelector} from '@/features/patient-details/components/diary-date-selector/DiaryDateSelector.tsx'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {DiaryDateSelectorUrlParams} from '@/features/patient-details/types.ts'
import dayjs from '@/dayjs.ts'
import {capitalize} from '@utilities/helpers.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    StyledCheckInAnswer,
    StyledCheckInQuestion,
    StyledDiaryWrapper,
    StyledSectionTitle,
    StyledSelectedDay
} from '@/features/patient-details/components/patient-diary/style.ts'
import {useTranslation} from 'react-i18next'
import {DailyTimeLine} from '@/features/patient-details/components/daily-time-line/DailyTimeLine.tsx'

export const PatientDiary = () => {
    const {t} = useTranslation()

    const {searchParams} = useParsedSearchParams([], DiaryDateSelectorUrlParams)

    return (
        <BaseCard paddingX={2} paddingY={2} bodyHasBorder={false}>
            <Flexbox direction={'column'} gap={6}>
                <DiaryDateSelector />
                <StyledSelectedDay>
                    {capitalize(dayjs(searchParams.selectedDate).locale('it').format('dddd'))}
                </StyledSelectedDay>
                <StyledDiaryWrapper fullWidth gap={9} justify={'space-between'}>
                    <Flexbox fullWidth direction={'column'} gap={6}>
                        <StyledSectionTitle>{t('patient_details:diary_tab:check_in')}</StyledSectionTitle>
                        <Flexbox direction={'column'} gap={2}>
                            <StyledCheckInQuestion>Check-in</StyledCheckInQuestion>
                            <StyledCheckInAnswer>Non disponibile</StyledCheckInAnswer>
                        </Flexbox>
                    </Flexbox>
                    <Flexbox fullWidth direction={'column'} gap={6}>
                        <StyledSectionTitle>{t('patient_details:diary_tab:daily_activity')}</StyledSectionTitle>
                        <DailyTimeLine />
                    </Flexbox>
                </StyledDiaryWrapper>
            </Flexbox>
        </BaseCard>
    )
}
