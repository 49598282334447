import styled, {css} from 'styled-components'
import {Button} from '@components/ui/button/Button.tsx'

export const StyledActionsMenuButton = styled(Button)`
    ${({theme: {palette}}) => css`
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &:hover {
            background-color: ${palette.neutral[50]};
        }
    `}
`
