import styled, {css} from 'styled-components'
import {ButtonNavLink} from '@components/ui/button-nav-link/ButtonNavLink.tsx'

export const StyledBreadCrumbs = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
        color: ${palette.neutral['500']};
        height: 36px;

        & a {
            cursor: pointer;
        }

        & span {
            color: ${palette.neutral['300']};
            margin: 0 ${spacing * 2}px;
        }
    `}
`

type StyledNavLinkCrumbProps = {
    $isActive: boolean
}

export const StyledNavLinkCrumb = styled(ButtonNavLink)<StyledNavLinkCrumbProps>`
    ${({theme, $isActive}) => css`
        padding: ${theme.spacing}px ${theme.spacing * 2}px;
        border-radius: ${theme.spacing * 1.5}px;
        background-color: ${$isActive ? theme.palette.neutral.white : 'transparent'};
    `}
`
