import {z} from 'zod'

export const FORGOT_PASSWORD_FORM_MODEL = {
    email: {
        name: 'email',
        label: 'forgot_password:form:email',
        placeholder: 'forgot_password:form:email',
        defaultValue: '',
        helpText: ''
    }
} as const

export const ForgotPasswordValidationSchema = z.object({
    email: z.string().email({
        message: 'errors:email_valid'
    })
})

export type ForgotPasswordValidationSchema = z.infer<typeof ForgotPasswordValidationSchema>
