import {FC} from 'react'
import {ArrowDownIcon, ArrowUpIcon, EqualIcon} from '@components/ui/icon/Icon.tsx'
import {round} from '@utilities/helpers.ts'
import {StyledPercentageSummary, StyledPercentageSummaryLabels} from '@components/commons/percentage-summary/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

interface PercentageSummaryProps {
    amount: number
    percentage: number | null
    label?: string
}
export const PercentageSummary: FC<PercentageSummaryProps> = ({amount, label, percentage}) => {
    if (percentage) {
        return (
            <Flexbox gap={2} align={'center'}>
                <StyledPercentageSummary
                    gap={0.5}
                    align="center"
                    $variant={percentage > 0 ? 'over' : percentage < 0 ? 'under' : 'equal'}
                >
                    {percentage > 0 ? (
                        <ArrowUpIcon size={20} />
                    ) : percentage < 0 ? (
                        <ArrowDownIcon size={20} />
                    ) : (
                        <EqualIcon size={20} />
                    )}
                    {`${round(percentage)}%`}
                </StyledPercentageSummary>
                <StyledPercentageSummaryLabels>{label}</StyledPercentageSummaryLabels>
            </Flexbox>
        )
    } else {
        return (
            <Flexbox gap={2} align={'center'}>
                <StyledPercentageSummary
                    gap={0.5}
                    align="center"
                    $variant={amount > 0 ? 'over' : amount < 0 ? 'under' : 'equal'}
                >
                    {amount > 0 ? (
                        <ArrowUpIcon size={20} />
                    ) : amount < 0 ? (
                        <ArrowDownIcon size={20} />
                    ) : (
                        <EqualIcon size={20} />
                    )}
                    {round(amount)}
                </StyledPercentageSummary>
                <StyledPercentageSummaryLabels>{label}</StyledPercentageSummaryLabels>
            </Flexbox>
        )
    }
}
