import axios, {AxiosPromise} from 'axios'
import {envVars} from '@/envVars.ts'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN} from '@/features/auth/constants.ts'
import {z, ZodError, ZodSchema} from 'zod'

const axiosConfig = {
    baseURL: envVars.VITE_API_ENDPOINT,
    headers: {
        common: {
            Authorization: localStorage.getItem('accessToken')
                ? `Bearer ${localStorage.getItem('accessToken')}`
                : false,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        },
        post: {
            'Access-Control-Allow-Origin': '*'
        }
    }
}

// create axios custom instance with custom config
const axiosInstance = axios.create(axiosConfig)

const attemptRefresh = async (refreshToken: string) => {
    const url = '/auth/refresh'
    try {
        const {
            data: {oauth2_authorization}
        } = await axiosInstance.post(url, {refresh_token: refreshToken})
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, oauth2_authorization.access_token)
        localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, oauth2_authorization.refresh_token)
        useAuthStore.setState(() => ({
            accessToken: oauth2_authorization.access_token,
            refreshToken: oauth2_authorization.refresh_token
        }))

        return {oauth2_authorization}
    } catch (error) {
        if (axios.isAxiosError(error)) {
            localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN)
            localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN)
            useAuthStore.getState().reset()
        } else {
            console.error(error)
        }
    }
}

axiosInstance.interceptors.request.use(request => {
    const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)
    if (token) request.headers['Authorization'] = `Bearer ${token}`
    return request
})

const endpointToIgnore = ['/login']

axiosInstance.interceptors.response.use(
    response => {
        // pass response without change
        return response
    },
    async error => {
        // get error info
        const statusCode = error?.response?.status
        const originalRequest = error.config
        switch (statusCode) {
            case 401: {
                const refreshToken = useAuthStore.getState().refreshToken
                localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN)
                if (refreshToken && !originalRequest._retry && !endpointToIgnore.includes(error.config.url)) {
                    originalRequest._retry = true // prevent infinite retry loop
                    await attemptRefresh(refreshToken)
                    return axiosInstance.request(originalRequest)
                } else {
                    localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN)
                    useAuthStore.getState().reset()
                    return Promise.reject(error)
                }
            }
            default:
                return Promise.reject(error)
        }
    }
)

export default axiosInstance

/**
 * This function parses the api response using the provided shape and returns the response data or the ZodError if the parse fails
 * */
export const parseAxiosPromise = <T extends ZodSchema>({
    axiosPromise,
    responseShape,
    onZodError
}: {
    axiosPromise: AxiosPromise
    responseShape: T
    onZodError?: (error: ZodError) => void
}): Promise<z.infer<T>> =>
    axiosPromise.then(response => {
        const safeParsedResponseData = responseShape.safeParse(response.data)
        if (!safeParsedResponseData.success) {
            console.error(safeParsedResponseData.error.message)
            onZodError?.(safeParsedResponseData.error)
            throw new Error(safeParsedResponseData.error.message)
        }

        return safeParsedResponseData.data
    })
