import {RangeDate, RangeDateLabels} from '@/types'
import {z} from 'zod'

export const PatientDetailsURLParams = z.object({
    patientId: z.coerce.number()
})
export type PatientDetailsURLParams = z.infer<typeof PatientDetailsURLParams>

export const PatientDetailsSearchParams = z.object({
    type: z.enum(['patient', 'diary', 'appointments']).default('patient')
})
export type PatientDetailsSearchParams = z.infer<typeof PatientDetailsSearchParams>

export const PatientTabs = {
    patient: 'patient',
    diary: 'diary',
    appointments: 'appointments'
} as const

export const PatientAppointmentsSearchParams = z.object({
    ...RangeDate.shape
})
export type PatientAppointmentsSearchParams = z.infer<typeof PatientAppointmentsSearchParams>

export const PatientAppointmentsURLSearchParamsParams = z.object({
    ...PatientAppointmentsSearchParams.omit({startDate: true, endDate: true}).shape,
    rangeDateLabels: RangeDateLabels
})
export type PatientAppointmentsURLSearchParamsParams = z.infer<typeof PatientAppointmentsURLSearchParamsParams>

export const PatientDetailsBetsSearchParams = z.object({
    ...RangeDate.shape
})
export type PatientDetailsBetsSearchParams = z.infer<typeof PatientDetailsBetsSearchParams>

export const PatientDetailsBetsUrlParams = z.object({
    betsRangeDateLabels: RangeDateLabels
})
export type PatientDetailsBetsUrlParams = z.infer<typeof PatientDetailsBetsUrlParams>

export const PatientDetailsEmotionUrlParams = z.object({
    emotionRangeDateLabels: RangeDateLabels
})
export type PatientDetailsEmotionUrlParams = z.infer<typeof PatientDetailsEmotionUrlParams>

export const DiaryDateSelectorUrlParams = z.object({
    selectedDate: z.string().optional()
})
export type DiaryDateSelectorUrlParams = z.infer<typeof DiaryDateSelectorUrlParams>

export const TimeLine = z.object({
    type: z.enum(['emotion', 'bet', 'appointment']),
    action: z.enum(['created', 'updated']),
    datetime: z.string(),
    data: z.object({
        id: z.number(),
        userId: z.number(),
        emotionId: z.number().nullish(),
        date: z.string().nullish(),
        time: z.string().nullish(),
        note: z.string().nullish(),
        amount: z.number().nullish(),
        datetime: z.string().nullish(),
        userFormId: z.number().nullable()
    })
})
export type TimeLine = z.infer<typeof TimeLine>
