import PhoneInput from 'react-phone-input-2'
import styled, {css} from 'styled-components'

interface StyledInputProps {
    hasError?: boolean
}
export const StyledInput = styled(PhoneInput)<StyledInputProps>`
    ${({theme, hasError}) => css`
        .react-tel-input,
        .form-control {
            height: 38px;
            width: 100% !important;

            ${theme.typography.textMd}
            border-radius: ${theme.spacing * 3}px;
            border: none;
            color: ${theme.palette.neutral[900]};

            &::placeholder {
                color: ${theme.palette.neutral[400]};
            }
        }

        .flag-dropdown,
        .selected-flag {
            background-color: transparent;
            border-radius: ${theme.spacing * 3}px 0 0 ${theme.spacing * 3}px !important;
            border: none;
            ${hasError &&
            css`
                border-color: ${theme.palette.danger[500]};
            `}
        }

        .country,
        .dial-code {
            ${theme.typography.textMd}
            color: ${theme.palette.neutral[950]} !important;
            border-radius: ${theme.spacing - 2}px;
        }

        .country-list {
            border-radius: ${theme.spacing * 3}px;
            border: 1px solid ${theme.palette.neutral[300]};
            box-shadow: ${theme.shadows['lg']};
        }

        .search,
        .search-box {
            padding: 0;
            margin: 0;
            cursor: text;
        }

        .search {
            height: 32px;
            border-bottom: 1px solid ${theme.palette.neutral[200]};
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0 4px;

            .search-emoji {
                content: url('https://img.icons8.com/search');
                height: 12px;
                width: 12px;
                margin: 0 ${theme.spacing}px;
            }
        }

        .search-box {
            border-radius: 0;
            border: none !important;
            box-shadow: none;
            color: ${theme.palette.neutral[950]};

            ${theme.typography.textMd};

            &::placeholder {
                color: ${theme.palette.neutral[200]};
            }

            &::-webkit-search-cancel-button {
                -webkit-appearance: none;
            }
        }

        .no-entries-message {
            ${theme.typography.textMd}
            color: ${theme.palette.neutral[950]};
        }
    `}
`
