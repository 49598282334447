import {httpLogout, httpUserMe} from '@/features/auth/services/auth.http.ts'
import toast from 'react-hot-toast'
import i18next from 'i18next'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {captureException} from '@sentry/react'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {User} from '@/features/auth/types.ts'

export const logout = async () => {
    try {
        await httpLogout()
        useAuthStore.getState().reset()
    } catch (e) {
        toast.error(i18next.t('errors:default'))
    }
}

export const userMe = async () => {
    try {
        const user = await parseAxiosPromise({
            axiosPromise: httpUserMe(),
            onZodError: captureException,
            responseShape: User
        })
        useAuthStore.setState(() => ({user}))
    } catch (e) {
        toast.error(i18next.t('errors:default'))
        throw new Error()
    }
}
