import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance'
import {captureException} from '@sentry/react'
import {httpGetCheckInStats} from '@/features/check-in/services/checkIn.http.ts'
import {CheckInStatsResponse, CheckInUrlSearchParams} from '@/features/check-in/types.ts'
import {getDateFromLabel} from '@utilities/utils.ts'

export const useGetCheckInStats = (
    promiseArgs: CheckInUrlSearchParams,
    options?: Omit<UseQueryOptions<CheckInStatsResponse>, 'queryFn' | 'queryKey'>
) => {
    const {dateRangeLabels, ...rest} = promiseArgs
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.CHECK_IN_STATS, promiseArgs],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetCheckInStats({
                    ...rest,
                    ...getDateFromLabel(dateRangeLabels)
                }),
                responseShape: CheckInStatsResponse,
                onZodError: captureException
            })
    })
}
