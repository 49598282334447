import axios from '@/axiosInstance'
import {z} from 'zod'
import {PaginationResponse, Partitions, RangeDate} from '@/types.ts'
import {NotificationItem} from '@/features/dashboard/types.ts'

export const HttpDashboardUserOptions = z.object({
    ...RangeDate.shape,
    gender: z.enum(['male', 'female']).optional(),
    age: z.enum(['low', 'medium', 'high', 'over']).optional()
})
export type HttpDashboardUserOptions = z.infer<typeof HttpDashboardUserOptions>

export const HttpDashboardUserOptionsExtended = HttpDashboardUserOptions.merge(
    z.object({isCurrentlyInTherapy: z.enum(['true', 'false']).default('false')})
)
export type HttpDashboardUserOptionsExtended = z.infer<typeof HttpDashboardUserOptionsExtended>

export const HttpDashboardAppointmentsGroupOptions = HttpDashboardUserOptions.merge(
    z.object({
        therapistId: z.coerce.number().optional(),
        groupBy: z.enum(['location', 'status'])
    })
)
export type HttpDashboardAppointmentsGroupOptions = z.infer<typeof HttpDashboardAppointmentsGroupOptions>

export const HttpDashboardAppointmentsTrendOptions = HttpDashboardUserOptions.merge(
    z.object({
        therapistId: z.coerce.number().optional()
    })
)
export type HttpDashboardAppointmentsTrendOptions = z.infer<typeof HttpDashboardAppointmentsTrendOptions>

export const HttpGetSelfEvaluationsGroupResponse = z.object({
    analytics: z.object({
        total: z.number(),
        partitions: Partitions.array()
    }),
    increments: z.object({
        total: z.number(),
        percentage: z.number().nullable(),
        partitions: Partitions.array()
    })
})
export type HttpGetSelfEvaluationsGroupResponse = z.infer<typeof HttpGetSelfEvaluationsGroupResponse>

export const httpGetSelfEvaluationsGroup = (params: HttpDashboardUserOptions) => {
    return axios.get('/analytics/self-evaluations-group', {params})
}

export const HttpGetVerifiedUserGroupResponse = z.object({
    analytics: z.object({
        total: z.number(),
        partitions: Partitions.array()
    }),
    increments: z.object({
        total: z.number(),
        percentage: z.number().nullable(),
        partitions: Partitions.array()
    })
})
export type HttpGetVerifiedUserGroupResponse = z.infer<typeof HttpGetVerifiedUserGroupResponse>

export const httpGetVerifiedUserGroup = (params: HttpDashboardUserOptions) => {
    return axios.get('/analytics/verified-users-group', {params})
}

export const HttpGetUserTrendResponse = z
    .object({
        label: z.string(),
        registeredUsers: z.number(),
        currentlyInTherapyUsers: z.number()
    })
    .array()
export type HttpGetUserTrendResponse = z.infer<typeof HttpGetUserTrendResponse>

export const httpGetUserTrend = (params: HttpDashboardUserOptions) => {
    return axios.get('/analytics/users-trend', {params})
}

export const HttpGetGendersTrendResponse = z.object({
    total: z.number(),
    partitions: Partitions.array()
})
export type HttpGetGendersTrendResponse = z.infer<typeof HttpGetGendersTrendResponse>

export const httpGetGendersTrend = (params: HttpDashboardUserOptionsExtended) => {
    return axios.get('/analytics/genders-group', {params})
}

export const HttpGetAgesGroupResponse = z.object({
    analytics: z.object({
        total: z.number(),
        partitions: Partitions.array()
    }),
    increments: z.object({
        total: z.number(),
        percentage: z.number().nullable(),
        partitions: Partitions.array()
    })
})
export type HttpGetAgesGroupResponse = z.infer<typeof HttpGetAgesGroupResponse>

export const httpGetAgesGroup = (params: HttpDashboardUserOptionsExtended) => {
    return axios.get('/analytics/ages-group', {params})
}

export const HttpGetEmotionsGroupResponse = z.object({
    total: z.number(),
    partitions: Partitions.array()
})
export type HttpGetEmotionsGroupResponse = z.infer<typeof HttpGetEmotionsGroupResponse>

export const httpGetEmotionsGroup = (params: HttpDashboardUserOptionsExtended) => {
    return axios.get('/analytics/emotions-group', {params})
}

export const HttpGetBetsGroupResponse = z.object({
    total: z.number(),
    partitions: z
        .object({
            label: z.string(),
            total: z.number(),
            percentage: z.number()
        })
        .array()
})
export type HttpGetBetsGroupResponse = z.infer<typeof HttpGetBetsGroupResponse>

export const httpGetBetsGroup = (params: HttpDashboardUserOptionsExtended) => {
    return axios.get('/analytics/bets-group', {params})
}

export const HttpGetAppointmentsGroupResponse = z.object({
    analytics: z.object({
        total: z.number(),
        partitions: Partitions.array()
    }),
    increments: z.object({
        total: z.number(),
        percentage: z.number().nullable(),
        partitions: Partitions.array()
    })
})
export type HttpGetAppointmentsGroupResponse = z.infer<typeof HttpGetAppointmentsGroupResponse>

export const httpGetAppointmentsGroup = (params: HttpDashboardAppointmentsGroupOptions) => {
    return axios.get('/analytics/appointments', {params})
}

export const HttpGetAppointmentsTrendResponse = z
    .object({
        label: z.string(),
        totalBooked: z.number(),
        totalCompleted: z.number()
    })
    .array()
export type HttpGetAppointmentsTrendResponse = z.infer<typeof HttpGetAppointmentsTrendResponse>

export const httpGetAppointmentsTrend = (params: HttpDashboardAppointmentsTrendOptions) => {
    return axios.get('/analytics/appointments-trend', {params})
}

export const HttpGetFollowUpResponse = z.object({
    total: z.number(),
    partitions: Partitions.array()
})
export type HttpGetFollowUpResponse = z.infer<typeof HttpGetFollowUpResponse>

export const httpGetFollowUp = (params: HttpDashboardUserOptions) => {
    return axios.get('/analytics/follow-up-check-in', {params})
}

export const HttpGetNotificationsResponse = z.object({
    data: z.array(NotificationItem),
    meta: PaginationResponse
})
export const httpGetNotifications = (params: {page: number}) => {
    return axios.get('/notifications', {params})
}
