import {z} from 'zod'
import {CheckIn, CheckInSearchParams} from '@/features/check-in/types.ts'
import {PaginationParams, PaginationResponse, ResponseType} from '@/types.ts'
import axios from '@/axiosInstance'

/**
 * Get checkIn
 */

export const HttpGetCheckInOptions = z.object({
    ...CheckInSearchParams.shape,
    ...PaginationParams.shape,
    responseType: ResponseType
})
export type HttpGetCheckInOptions = z.infer<typeof HttpGetCheckInOptions>

export const HttpGetCheckInResponse = z.object({
    data: z.array(CheckIn),
    meta: PaginationResponse
})
export type HttpGetCheckInResponse = z.infer<typeof HttpGetCheckInResponse>

export const httpGetCheckIn = (params: HttpGetCheckInOptions) => {
    return axios.get('check-in', {params})
}

/**
 * Get checkInStats
 */
export const HttpGetCheckInStatsOptions = CheckInSearchParams.pick({isCurrentlyInTherapy: true, range_date: true})
export type HttpGetCheckInStatsOptions = z.infer<typeof HttpGetCheckInStatsOptions>
export const httpGetCheckInStats = (params: HttpGetCheckInStatsOptions) => {
    return axios.get('/analytics/check-in', {params})
}
