import {PageHero} from '@/components/commons/page-hero/PageHero'
import {PageContent} from '@/components/ui/page-content/PageContent'
import styled, {css} from 'styled-components'

// To do: remove all the margin-bottoms in PageHero and then delete this styled
export const StyledPatientDetailsPageHero = styled(PageHero)`
    margin-bottom: 0;
`

export const StyledPatientDetailsPageContent = styled(PageContent)(
    ({theme: {spacing}}) => css`
        grid-template-rows: auto auto minmax(0, 1fr);
        gap: ${spacing * 8}px;
    `
)
