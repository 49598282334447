import {useTranslation} from 'react-i18next'
import {useGetBetsGroup} from '@/features/dashboard/services/useGetBetsGroup.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {DashboardUrlParamsExtended} from '@/features/dashboard/types.ts'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {ErrorIcon} from 'react-hot-toast'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ProgressBar} from '@components/ui/progress-bar/ProgressBar.tsx'
import {StyledBetsProgressLabel} from '@/features/dashboard/components/bets-group/style.ts'

export const BetsGroup = () => {
    const {t} = useTranslation()
    const {searchParams} = useParsedSearchParams([], DashboardUrlParamsExtended)
    const {data, isLoading, isError} = useGetBetsGroup(searchParams)

    return (
        <>
            {isLoading ? (
                <Flexbox fullWidth direction={'column'} gap={6}>
                    {Array.from(Array(5)).map((_, index) => (
                        <Flexbox fullWidth gap={2} key={index}>
                            <Skeleton height={24} />
                        </Flexbox>
                    ))}
                </Flexbox>
            ) : isError ? (
                <ErrorBox icon={<ErrorIcon />} title={t('errors:default')} />
            ) : (
                data && (
                    <Flexbox fullWidth direction={'column'} gap={6}>
                        {data.partitions.map((value, index) => (
                            <Flexbox fullWidth gap={2} key={index}>
                                <StyledBetsProgressLabel>{t(`commons:bets:${value.label}`)}</StyledBetsProgressLabel>
                                <ProgressBar percentageProgress={value.percentage} variant={'primary'} />
                            </Flexbox>
                        ))}
                    </Flexbox>
                )
            )}
        </>
    )
}
