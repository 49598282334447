import axios from '@/axiosInstance'
import {ContactObj, SettingsPayload} from '@/features/settings/SettingsFormModal.ts'
import {HttpGetUsersOptions, HttpShowUsersOptions} from '@/features/settings/types.ts'
import {ResponseType} from '@/types.ts'

export const httpGetContacts = () => {
    return axios.get('/offices', {
        params: {
            responseType: ResponseType.enum.regular,
            limit: 10000
        }
    })
}

export const httpGetContactDetails = (id: number | null) => {
    return axios.get(`/offices/${id}`, {
        params: {
            responseType: ResponseType.enum.regular
        }
    })
}

export const httpDeleteContact = (id: number | null | undefined) => {
    return axios.delete(`/offices/${id}`)
}

export const httpAddContact = (params: ContactObj) => {
    return axios.post('/offices', params)
}

export const httpEditContact = (params: ContactObj) => {
    return axios.patch(`/offices/${params.id}`, params)
}

/*
 * Users
 * */

export const httpGetRoles = () => {
    return axios.get('/roles')
}

export const httpGetUsers = ({params}: {params: HttpGetUsersOptions}) => {
    return axios.get('backoffice/users', {params})
}

export const httpShowUsers = ({params}: {params: HttpShowUsersOptions}) => {
    return axios.get(`backoffice/users/${params.userId}`)
}

export const httpDeleteUser = (params?: number) => {
    return axios.delete(`/backoffice/users/${params}`)
}

export const httpAddUser = (params: SettingsPayload) => {
    return axios.post('/backoffice/users', {
        ...params,
        roleId: Number(params.roleId)
    })
}

export const httpEditUser = (params: SettingsPayload) => {
    return axios.patch(`/backoffice/users/${params.id}`, params)
}

export const httpUserPasswordRecovery = (id: number) => {
    return axios.post(`/backoffice/users/${id}/password-recovery`)
}
