import {useInfiniteQuery, UseInfiniteQueryOptions} from '@tanstack/react-query'
import {HttpGetPatientsResponse, httpGetPatients} from './patient.http'
import {QUERY_KEYS, infiniteQueryFetchNextPage} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance'
import {captureException} from '@sentry/react'
import {PaginationParams, RangeDateLabels, ResponseType} from '@/types.ts'
import {PatientsSearchParams} from '@/features/patients/types.ts'
import {z} from 'zod'
import {getDateFromLabel} from '@utilities/utils.ts'

const UseGetPatientOptions = z.object({
    dateRangeLabels: RangeDateLabels.optional(),
    ...PatientsSearchParams.omit({startDate: true, endDate: true}).shape,
    ...PaginationParams.shape
})
type UseGetPatientOptions = z.infer<typeof UseGetPatientOptions>

export const useGetPatients = (
    promiseArgs?: Omit<UseGetPatientOptions, 'page'>,
    options?: Pick<UseInfiniteQueryOptions<HttpGetPatientsResponse>, 'enabled'>
) => {
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.PATIENTS, promiseArgs],
        queryFn: ({pageParam = 1}) =>
            parseAxiosPromise({
                axiosPromise: httpGetPatients({
                    ...promiseArgs,
                    ...getDateFromLabel(promiseArgs?.dateRangeLabels),
                    responseType: ResponseType.enum.regular,
                    page: pageParam
                }),
                responseShape: HttpGetPatientsResponse,
                onZodError: captureException
            }),
        ...options,
        initialPageParam: 1,
        getNextPageParam: ({meta}) => (meta.current_page < meta.last_page ? meta.current_page + 1 : null)
    })

    const remappedData = query.data?.pages[0]?.data?.length ? query.data.pages.flatMap(page => page.data) : []

    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData
    }
}
