import axios from '@/axiosInstance'
import {z} from 'zod'
import {User} from '@/features/auth/types'
import {ResponseType} from '@/types.ts'

/**
 * Simple Login function
 */
export const HttpLoginPayload = z.object({
    email: z.string(),
    password: z.string()
})
export type HttpLoginPayload = z.infer<typeof HttpLoginPayload>

export const HttpLoginResponse = z.object({
    user: User,
    accessToken: z.string(),
    refreshToken: z.string()
})
export type HttpLoginResponse = z.infer<typeof HttpLoginResponse>

export const httpLogin = (data: HttpLoginPayload) => {
    return axios.post('/auth/login', data)
}
/**
 * Simple Logout function
 */
export const httpLogout = () => {
    return axios.get('/auth/logout')
}

/**
 * This function Retrieves user data
 */
export const httpUserMe = () => {
    return axios.get(`/users/profile`, {
        params: {
            responseType: ResponseType.enum.backofficeRegular
        }
    })
}

/**
 *  Forgot password function
 */
export const HttpForgotPasswordPayload = z.object({
    email: z.string()
})
export type HttpForgotPasswordPayload = z.infer<typeof HttpForgotPasswordPayload>
export const httpForgotPassword = (data: HttpForgotPasswordPayload) => {
    return axios.post('/password-reset', data)
}

/**
 * Change password function
 */
export const HttpChangePasswordPayload = z.object({
    token: z.string(),
    password: z.string(),
    confirmPassword: z.string(),
    isFirstLogin: z.boolean()
})
export type HttpChangePasswordPayload = z.infer<typeof HttpChangePasswordPayload>

export const httpChangePassword = (data: HttpChangePasswordPayload) => {
    return axios.post('/password-reset/change', data)
}
