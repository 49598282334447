import {FlexboxProps} from '@/components/ui/flexbox/FlexBox'
import {SupportedHTMLElements} from 'styled-components'
import {
    StyledAuthContent,
    StyledAuthForm,
    StyledAuthSubtitle,
    StyledAuthTitle,
    StyledAuthWrapper
} from '@/components/ui/auth-atoms/style'
import {DOMAttributes} from 'react'

export const AuthTitle = (props: DOMAttributes<HTMLElement>) => <StyledAuthTitle {...props} />
AuthTitle.displayName = 'AuthTitle'

export const AuthSubTitle = (props: DOMAttributes<HTMLElement>) => <StyledAuthSubtitle {...props} />
AuthSubTitle.displayName = 'AuthSubTitle'

export const AuthWrapper = (props: FlexboxProps<SupportedHTMLElements>) => <StyledAuthWrapper {...props} />
AuthWrapper.displayName = 'AuthWrapper'

export const AuthContent = (props: FlexboxProps<SupportedHTMLElements>) => <StyledAuthContent {...props} />
AuthContent.displayName = 'AuthContent'

export const AuthForm = (props: DOMAttributes<HTMLFormElement>) => <StyledAuthForm {...props} />
AuthForm.displayName = 'AuthForm'
