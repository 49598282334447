import {useQuery} from '@tanstack/react-query'
import {httpGetArticles} from '@/features/articles/services/articles.http.ts'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'
import {HttpGetArticlesResponse} from '@/features/articles/types.ts'

export const useGetArticles = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_ARTICLES],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetArticles(),
                responseShape: HttpGetArticlesResponse,
                onZodError: captureException
            })
    })
}
