import {FC} from 'react'

import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {
    StyledMoodIconWrapper,
    StyledMoodSectionGrid
} from '@/features/check-in-details/components/mood-section/style.ts'

export const MoodSectionSkeleton: FC = () => {
    return (
        <>
            {Array.from(Array(2)).map(() => (
                <StyledMoodSectionGrid>
                    <StyledMoodIconWrapper>
                        <Skeleton height={24} width={100} />
                    </StyledMoodIconWrapper>
                    <Flexbox direction={'column'} gap={2}>
                        <Flexbox gap={2}>
                            <Skeleton height={24} width={200} />
                        </Flexbox>
                        <Flexbox gap={2}>
                            <Skeleton height={24} width={200} />
                        </Flexbox>
                    </Flexbox>
                </StyledMoodSectionGrid>
            ))}
        </>
    )
}
