import React, {FC, useState} from 'react'
import {Bell03Icon, CalendarIcon, XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {useTheme} from 'styled-components'
import {Popover} from '@components/ui/popover/Popover.tsx'
import {useTranslation} from 'react-i18next'
import {
    StyledListNotification,
    StyledNotificationBadge,
    StyledNotificationButton,
    StyledNotificationHeader,
    StyledWrapperBodyNotification
} from '@/features/dashboard/components/notifications/style.ts'
import {useGetNotifications} from '@/features/dashboard/services/useGetNotifications.ts'
import {Virtuoso} from 'react-virtuoso'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {AppointmentItemSkeleton} from '@/features/patient-details/components/appointment-item/AppointmentItemSkeleton.tsx'
import {EmptyState} from '@components/commons/empty-state/EmptyState.tsx'
import {ErrorMessage} from '@components/commons/table/error-message/ErrorMessage.tsx'
import {NotificationItem} from '@/features/dashboard/components/notifications/NotificationItem.tsx'

export const Notifications: FC = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const [openNotification, setOpenNotification] = useState(false)
    const {remappedData: notifications, isError, isLoading, fetchNextPage} = useGetNotifications()
    const count = notifications?.length

    return (
        <>
            <Popover
                rootProps={{
                    open: openNotification
                }}
                contentProps={{
                    style: {
                        width: 400,
                        padding: theme.spacing * 6
                    }
                }}
                triggerProps={{asChild: true}}
                trigger={
                    <StyledNotificationButton
                        variant={'secondary'}
                        size={'sm'}
                        onClick={() => setOpenNotification(!openNotification)}
                    >
                        <Bell03Icon fill={theme.palette.neutral[500]} />
                        {count > 0 && (
                            <StyledNotificationBadge justify={'center'} align={'center'}>
                                {count}
                            </StyledNotificationBadge>
                        )}
                    </StyledNotificationButton>
                }
            >
                <StyledNotificationHeader justify={'space-between'} align={'center'} fullWidth>
                    <p>{t('dashboard:notifications:notifications_appointments')}</p>
                    <XCloseIcon cursor="pointer" onClick={() => setOpenNotification(false)} />
                </StyledNotificationHeader>

                <StyledWrapperBodyNotification>
                    <Virtuoso
                        style={{
                            width: '100%',
                            gap: 0
                        }}
                        data={notifications}
                        overscan={100}
                        endReached={isError ? undefined : fetchNextPage}
                        itemContent={(_itemIndex, item) => <NotificationItem key={_itemIndex} notification={item} />}
                        components={{
                            EmptyPlaceholder: () => {
                                if (isLoading) {
                                    return (
                                        <Flexbox direction={'column'}>
                                            <AppointmentItemSkeleton />
                                            <AppointmentItemSkeleton />
                                            <AppointmentItemSkeleton />
                                        </Flexbox>
                                    )
                                }
                                if (isError) {
                                    return null
                                }

                                return (
                                    <EmptyState
                                        title={t('patient_details:appointments_tab:empty_state:title')}
                                        description={t('patient_details:appointments_tab:empty_state:description')}
                                        icon={<CalendarIcon />}
                                        iconCta={<CalendarIcon />}
                                        ctaLabel={t('patient_details:appointments_tab:add_appointment')}
                                    />
                                )
                            },
                            Footer: () => {
                                if (isLoading) {
                                    return null
                                }
                                if (isError) {
                                    return <ErrorMessage />
                                }

                                return null
                            },
                            List: React.forwardRef((props, ref) => {
                                return <StyledListNotification ref={ref} {...props} />
                            })
                        }}
                    />
                </StyledWrapperBodyNotification>
            </Popover>
        </>
    )
}
