import {z} from 'zod'
import {PaginationResponse} from '@/types.ts'

export const SETTINGS_FORM_MODEL = {
    title: {
        name: 'title',
        label: 'settings:form:title',
        placeholder: 'settings:form:title_placeholder',
        defaultValue: '',
        helpText: ''
    },
    lastName: {
        name: 'lastName',
        label: 'settings:form:surname',
        placeholder: 'settings:form:surname'
    },
    firstName: {
        name: 'firstName',
        label: 'settings:form:name',
        placeholder: 'settings:form:name'
    },
    email: {
        name: 'email',
        label: 'settings:form:email',
        placeholder: 'settings:form:email_placeholder',
        defaultValue: '',
        helpText: ''
    },
    phoneNumber: {
        name: 'phoneNumber',
        label: 'settings:form:telephone',
        placeholder: 'settings:form:telephone_placeholder',
        defaultValue: '',
        helpText: ''
    },
    occupation: {
        name: 'occupation',
        label: 'settings:form:job',
        placeholder: 'settings:form:job_placeholder',
        defaultValue: '',
        helpText: ''
    },
    roleId: {
        name: 'roleId',
        label: 'settings:form:role',
        placeholder: 'settings:form:role_placeholder',
        defaultValue: '',
        helpText: ''
    },
    officeIds: {
        name: 'officeIds',
        label: 'settings:form:office_allocation',
        placeholder: 'settings:form:office_allocation_placeholder',
        defaultValue: '',
        helpText: ''
    }
} as const

export const SettingsValidationSchema = z.object({
    title: z.string().nullish(),
    lastName: z.string().min(1, {message: 'errors:last_name_required'}),
    firstName: z.string().min(1, {message: 'errors:first_name_required'}),
    email: z.string().email({message: 'errors:email_valid'}),
    phoneNumber: z.string().nullish(),
    occupation: z.string().nullish(),
    type: z.string().nullish(),
    roleId: z.string() || z.number(),
    isCurrentlyInTherapy: z.boolean().optional(),
    officeIds: z.array(
        z.object({
            value: z.string(),
            label: z.string()
        })
    )
})

export type SettingsValidationSchema = z.infer<typeof SettingsValidationSchema>

export const SettingsPayload = z.object({
    title: z.string().nullish(),
    lastName: z.string().min(1, {message: 'errors:last_name_required'}),
    firstName: z.string().min(1, {message: 'errors:first_name_required'}),
    email: z.string().email({message: 'errors:email_valid'}),
    phoneNumber: z.string().nullish(),
    occupation: z.string().nullish(),
    type: z.string().nullish(),
    roleId: z.string() || z.number(),
    officeIds: z.array(z.number()),
    id: z.number().nullish()
})

export type SettingsPayload = z.infer<typeof SettingsPayload>

export const SETTINGS_CONTACTS_FORM_MODEL = {
    name: {
        name: 'name',
        label: 'contacts:form:site',
        placeholder: 'contacts:form:site_placeholder',
        defaultValue: undefined,
        helpText: ''
    },
    address: {
        name: 'address',
        label: 'contacts:form:address',
        placeholder: 'contacts:form:address_placeholder',
        defaultValue: undefined,
        helpText: ''
    },
    phone_number: {
        name: 'phone_number',
        label: 'contacts:form:phone_number',
        placeholder: 'contacts:form:phone_number_placeholder',
        defaultValue: undefined,
        helpText: ''
    },
    email: {
        name: 'email',
        label: 'contacts:form:email',
        placeholder: 'contacts:form:email_placeholder',
        defaultValue: undefined,
        helpText: ''
    }
}

export const ContactFormSchema = z.object({
    contacts: z
        .array(
            z.object({
                id: z.number().nullish(),
                contact_id: z.number().nullish(),
                name: z.string().nullish(),
                address: z.string().nullish(),
                phone_number: z.string().nullish(),
                email: z
                    .string()
                    .refine(
                        value => {
                            if (value === null || value === '') {
                                return true
                            }
                            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                            return value && emailRegex.test(value)
                        },
                        {
                            message: 'Invalid email'
                        }
                    )
                    .nullish()
            })
        )
        .nullish()
})

export type ContactValidationSchema = z.infer<typeof ContactFormSchema>

export const ContactObj = z.object({
    id: z.number().nullish(),
    contact_id: z.number().nullish(),
    name: z.string().nullish(),
    address: z.string().nullish(),
    phone_number: z.string().nullish(),
    email: z.string().nullish()
})

export type ContactObj = z.infer<typeof ContactObj>

export const ContactSchema = z.object({
    id: z.number().nullish(),
    officeId: z.number().nullish(),
    type: z.string().nullish(),
    value: z.string().nullish(),
    createdAt: z.string().nullish(),
    updatedAt: z.string().nullish(),
    deletedAt: z.string().nullish()
})

export const OfficeSchema = z.object({
    id: z.number(),
    name: z.string(),
    address: z.string().nullish(),
    createdAt: z.string(),
    updatedAt: z.string(),
    deletedAt: z.string().nullish(),
    contacts: z.array(ContactSchema).nullish()
})

export type OfficeSchema = z.infer<typeof OfficeSchema>

export const HttpGetContactsResponseSchema = z.object({
    meta: PaginationResponse,
    data: z.array(OfficeSchema)
})
