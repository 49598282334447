import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {StyledSelectRangeTrigger} from '@/features/patients/components/patients-options/style'
import {ObjectKeys} from '@/utilities/helpers'
import {SelectProvider, useSelectStore} from '@ariakit/react'
import {FC, ReactNode} from 'react'
import {StyledSelectRangeItem} from './style'
import {rangeDateToLabel} from '@/utilities/utils'
import {useTranslation} from 'react-i18next'
import {CheckIcon} from '@/components/ui/icon/Icon'
import {RangeDateLabels} from '@/types'
import {SelectPopover} from '@/components/ui/select-atoms/SelectAtoms'

interface DateRangeSelectProps {
    icon?: ReactNode
    onChange: (value: RangeDateLabels) => void
    value: RangeDateLabels
}

export const DateRangeSelect: FC<DateRangeSelectProps> = ({icon, value, onChange}) => {
    const {t} = useTranslation()

    const selectStore = useSelectStore({
        value,
        setValue: (value: RangeDateLabels) => onChange(value)
    })

    return (
        <SelectProvider store={selectStore}>
            <StyledSelectRangeTrigger size="sm" variant="secondary" minWidth={210}>
                <Flexbox align="center" gap={2}>
                    {icon}
                    {t(rangeDateToLabel[value])}
                </Flexbox>
            </StyledSelectRangeTrigger>

            <SelectPopover gutter={4} sameWidth>
                {ObjectKeys(rangeDateToLabel).map(option => (
                    <StyledSelectRangeItem key={option} value={option}>
                        {t(rangeDateToLabel[option])}
                        {value == option && <CheckIcon size={20} />}
                    </StyledSelectRangeItem>
                ))}
            </SelectPopover>
        </SelectProvider>
    )
}
