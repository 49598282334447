import {useTranslation} from 'react-i18next'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS, queryProps} from '@/queryClient.ts'
import {httpDeleteAppointmentNote} from '@/features/appointments/services/appointments.http.ts'
import toast from 'react-hot-toast'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types.ts'
import {errorHandler} from '@utilities/helpers.ts'

export const useDeleteAppointmentNote = (options?: queryProps) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_APPOINTMENT],
        mutationFn: (payload: {appointmentID: number; noteID: number}) => httpDeleteAppointmentNote(payload),
        onSuccess: () => {
            options?.onSuccess?.()
            toast.success(t('commons:operation_completed'))
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.APPOINTMENTS]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.APPOINTMENT_DETAILS]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PATIENT_DETAILS_APPOINTMENTS]})
        },
        onError: (error: AxiosError<ErrorResponseData>) => {
            options?.onError?.(error)
            errorHandler(error)
        }
    })
}
