import * as RPopover from '@radix-ui/react-popover'
import styled, {css} from 'styled-components'

export const StyledPopoverTrigger = styled(RPopover.Trigger)`
    cursor: pointer;
`

export const StyledPopoverContent = styled(RPopover.Content)`
    ${({theme: {palette, shadows, spacing}}) => css`
        padding: ${spacing * 1.5}px;
        background-color: ${palette.neutral.white};
        box-shadow: ${shadows.lg};
        border: 1px solid ${palette.neutral[200]};
        border-radius: ${spacing * 2}px;
        z-index: 20;
    `}
`
