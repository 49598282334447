import {z} from 'zod'
import {Emotion} from '@/features/auth/types.ts'

export const CheckInDetailsURLParams = z.object({
    checkInId: z.coerce.number()
})
export type CheckInDetailsURLParams = z.infer<typeof CheckInDetailsURLParams>

export const RecordedMoods = z.object({
    id: z.number(),
    mood: z.enum(['sad', 'frown', 'neutral', 'happy', 'smile']),
    thoughts: z.boolean(),
    created_at: z.string()
})
export type RecordedMoods = z.infer<typeof RecordedMoods>

export const CheckInDetails = z.object({
    id: z.number(),
    patient_id: z.number(),
    patient_firstname: z.string(),
    patient_lastname: z.string(),
    patient_age: z.number(),
    survey: z
        .object({
            id: z.number(),
            created_at: z.string(),
            note: z.string().nullable(),
            question: z
                .object({
                    question: z.string(),
                    answer: z.string()
                })
                .array()
        })
        .nullable(),
    recorded_moods: z
        .object({
            id: z.number(),
            mood: Emotion,
            thoughts: z.boolean(),
            created_at: z.string()
        })
        .array()
        .nullable(),
    recorded_money_gambled: z
        .object({
            id: z.number(),
            money_gambled: z.number(),
            created_at: z.string()
        })
        .array()
        .nullable()
})
export type CheckInDetails = z.infer<typeof CheckInDetails>
