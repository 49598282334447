import styled, {css} from 'styled-components'
import {Button} from '@components/ui/button/Button.tsx'

export const StyledDayItem = styled(Button)(
    ({theme}) => css`
        border-radius: 20px;
        font-weight: 400;
        ${theme.typography.textSm};
    `
)
export const StyledMonthItem = styled(Button)(
    ({theme}) => css`
        border-radius: 20px;
        font-weight: 500;
        ${theme.typography.textSm};
    `
)
