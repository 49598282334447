import React, {useState} from 'react'
import {Container} from '@components/ui/container/Container.tsx'
import {AlertCircleIcon, PlusIcon, Users01Icon} from '@/components/ui/icon/Icon'
import {EmptyState} from '@/components/commons/empty-state/EmptyState'
import {useTranslation} from 'react-i18next'
import {PatientsCardsStats} from '@/features/patients/components/patients-cards-stats/PatientsCardsStats'
import {ErrorBox} from '@/components/commons/error-box/ErrorBox'
import {PatientsOptions} from '@/features/patients/components/patients-options/PatientsOptions'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {usePatientsTable} from '@/features/patients/services/usePatientsTable'
import {PageContent} from '@/components/ui/page-content/PageContent'
import {PatientsTable} from '@/features/patients/components/patients-table/PatientsTable'
import {PatientsTableFilters} from '@/features/patients/components/patients-table-filters/PatientsTableFilters'
import {PageHero} from '@/components/commons/page-hero/PageHero'
import {User} from '@/features/auth/types.ts'
import {DeleteModal} from '@components/commons/delete-modal/DeleteModal.tsx'
import {useRemoveUser} from '@/features/settings/services/services-users/useRemoveUser.ts'
import {StyledDeleteParagraph} from '@/features/settings/components/delete-user-modal/style.ts'
import {useRecoveryPassword} from '@/features/settings/services/services-users/useRecoveryPassword.ts'
import {PatientFormModal} from '@/features/patient-form/components/patient-form-modal/PatientFormModal'

export const Patients: React.FC = () => {
    const {t} = useTranslation()
    const patientsTable = usePatientsTable()
    const {mutateAsync: deleteUser, isPending: isPendingDelete} = useRemoveUser()
    const {mutateAsync: resetPassword, isPending: isPendingReset} = useRecoveryPassword()
    const [createModalOpen, setCreateModalOpen] = useState<'create' | null>(null)
    const [deleteModalOpen, setDeleteModalOpen] = useState<User | null>(null)
    const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState<User | null>(null)

    return (
        <Container fullWidth>
            <PageContent>
                <PageHero
                    title={t('patients:title')}
                    ctaComponent={<PatientsOptions setCreateModalOpen={setCreateModalOpen} />}
                />
                {patientsTable.isError ? (
                    <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
                ) : patientsTable.isLoading || patientsTable.remappedData.length > 0 || patientsTable.filterValue ? (
                    <Flexbox gap={6} direction="column">
                        <PatientsCardsStats />
                        <PatientsTableFilters />
                        <PatientsTable
                            setDeleteModalOpen={setDeleteModalOpen}
                            setResetPasswordModalOpen={setResetPasswordModalOpen}
                        />
                    </Flexbox>
                ) : (
                    <EmptyState
                        icon={<Users01Icon />}
                        title={t('patients:empty_state:title')}
                        description={t('patients:empty_state:description')}
                        ctaLabel={t('patients:empty_state:cta_label')}
                        iconCta={<PlusIcon />}
                        onClickCta={() => setCreateModalOpen('create')}
                    />
                )}
            </PageContent>
            {createModalOpen === 'create' && <PatientFormModal onClose={() => setCreateModalOpen(null)} />}
            {deleteModalOpen && (
                <DeleteModal
                    modalTitle={t('patients:delete_modal:title')}
                    isLoading={isPendingDelete}
                    onDelete={async () => {
                        await deleteUser(deleteModalOpen.id)
                        setDeleteModalOpen(null)
                    }}
                    onClose={() => setDeleteModalOpen(null)}
                >
                    <StyledDeleteParagraph direction={'column'} gap={2.5}>
                        <p className={'description'}>{t('patients:delete_modal:description')}</p>

                        <p className={'information'}>
                            #{deleteModalOpen.id} {deleteModalOpen.firstName} {deleteModalOpen.lastName}
                        </p>

                        <p className={'paragraph'}>{t('patients:delete_modal:paragraph')}</p>
                    </StyledDeleteParagraph>
                </DeleteModal>
            )}
            {resetPasswordModalOpen && (
                <DeleteModal
                    modalTitle={t('patients:reset_password_modal:title')}
                    isLoading={isPendingReset}
                    onDelete={async () => {
                        await resetPassword(resetPasswordModalOpen.id)
                        setResetPasswordModalOpen(null)
                    }}
                    onClose={() => setResetPasswordModalOpen(null)}
                >
                    <StyledDeleteParagraph direction={'column'} gap={2.5}>
                        <p className={'description'}>{t('patients:reset_password_modal:description')}</p>

                        <p className={'information'}>
                            #{resetPasswordModalOpen.id} {resetPasswordModalOpen.firstName}{' '}
                            {resetPasswordModalOpen.lastName}
                        </p>

                        <p className={'paragraph'}>{t('patients:reset_password_modal:paragraph')}</p>
                    </StyledDeleteParagraph>
                </DeleteModal>
            )}
        </Container>
    )
}

Patients.displayName = 'Patients'
