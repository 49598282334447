import {CSSProperties, FC, ReactNode} from 'react'
import {
    StyledModal,
    StyledModalBody,
    StyledModalFooter,
    StyledModalHeader,
    StyledOverlay,
    StyledSpinnerContainer
} from '@components/ui/modal-legacy/style.ts'
import {XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {createPortal} from 'react-dom'
import {useTranslation} from 'react-i18next'
import {Button} from '@components/ui/button/Button.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {StyledSpinnerOverlay} from '@components/ui/spinner/style.ts'

interface ModalProps {
    children: ReactNode
    submitButton?: ReactNode
    className?: string
    closeIconOffset?: 'sm' | 'md' | 'lg'
    onClose?: () => void
    onOverlayClick?: () => void
    showCloseButton?: boolean
    width?: CSSProperties['width']
    title?: string
    isLoading?: boolean
}

/**
 * @deprecated use the new Modal.tsx with ModalAtoms instead
 * */
export const Modal: FC<ModalProps> = ({
    children,
    submitButton,
    className,
    closeIconOffset = 'md',
    onClose,
    onOverlayClick,
    width,
    title,
    isLoading
}) => {
    const {i18n, t} = useTranslation()

    return createPortal(
        <>
            <StyledOverlay onClick={onOverlayClick} />
            <StyledModal width={width} className={className}>
                {/*Header*/}
                <StyledModalHeader
                    justify="space-between"
                    align="center"
                    dir={i18n.dir()}
                    $closeIconOffset={closeIconOffset}
                >
                    {title && <p>{title}</p>}
                    {onClose && <XCloseIcon onClick={onClose} size={20} />}
                </StyledModalHeader>

                {/*Body*/}
                {isLoading && (
                    <StyledSpinnerOverlay>
                        <StyledSpinnerContainer>
                            <Spinner color={'black'} size={40} />
                        </StyledSpinnerContainer>
                    </StyledSpinnerOverlay>
                )}
                <StyledModalBody>{children}</StyledModalBody>

                {/*Footer*/}
                {submitButton && (
                    <StyledModalFooter justify={'space-between'} align={'center'}>
                        <Button variant={'tertiary'} onClick={onClose} type={'button'}>
                            {t('commons:actions:cancel')}
                        </Button>

                        {submitButton}
                    </StyledModalFooter>
                )}
            </StyledModal>
        </>,
        document.body
    )
}

Modal.displayName = 'Modal'
