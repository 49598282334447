import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledHeadingSection = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};
        color: ${palette.neutral[900]};

        & h3 {
            font-weight: 600;
        }
    `
)
