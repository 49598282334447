import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance'
import {captureException} from '@sentry/react'
import {SelfEvaluation} from '@/features/self-evaluation/types.ts'
import {
    httpGetSelfEvaluationDetails,
    HttpGetSelfEvaluationDetailsOptions
} from '@/features/self-evaluation-details/service/selfEvaluationDetails.http.ts'
import {ResponseType} from '@/types.ts'

export const useGetSelfEvaluationDetails = (
    promiseArgs: HttpGetSelfEvaluationDetailsOptions,
    options?: Omit<UseQueryOptions<SelfEvaluation>, 'queryFn' | 'queryKey'>
) =>
    useQuery({
        ...options,
        queryKey: [QUERY_KEYS.SELF_EVALUATION_DETAILS, promiseArgs],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetSelfEvaluationDetails({
                    responseType: ResponseType.Enum.regular,
                    ...promiseArgs
                }),
                responseShape: SelfEvaluation,
                onZodError: captureException
            })
    })
