import {z} from 'zod'
import {OrderDirection, Partitions, RangeDate, RangeDateLabels} from '@/types'

export const PatientsSearchParams = z.object({
    orderBy: z.enum(['name', 'age', 'appointment', 'type', 'createAt', 'updatedAt']).optional(),
    orderDirection: OrderDirection.optional(),
    currentlyInTherapy: z.enum(['true', 'false']).default('false').optional(),
    ...RangeDate.shape,
    name: z.string().optional(),
    age: z.string().optional(),
    gender: z.string().optional(),
    place: z.string().optional(),
    appointmentModality: z.string().optional()
})
export type PatientsSearchParams = z.infer<typeof PatientsSearchParams>

export const PatientsFilters = PatientsSearchParams.omit({
    orderBy: true,
    orderDirection: true,
    currentlyInTherapy: true,
    startDate: true,
    endDate: true,
    search: true
})
export type PatientsFilters = z.infer<typeof PatientsFilters>

export const PatientUrlSearchParams = z.object({
    dateRangeLabels: RangeDateLabels,
    ...PatientsSearchParams.omit({startDate: true, endDate: true}).shape
})
export type PatientUrlSearchParams = z.infer<typeof PatientUrlSearchParams>

export const HttpGetPatientsStatsUrlSearchParams = PatientUrlSearchParams.pick({
    currentlyInTherapy: true,
    dateRangeLabels: true
})
export type HttpGetPatientsStatsUrlSearchParams = z.infer<typeof HttpGetPatientsStatsUrlSearchParams>

export const Location = z.object({
    id: z.number(),
    name: z.string()
})
export type Location = z.infer<typeof Location>

export const PatientBets = z.object({
    averageDailyBet: z.number(),
    percentageBets: z.object({
        partitions: Partitions.array()
    }),
    data: z
        .object({
            date: z.string(),
            amount: z.number()
        })
        .array()
})
export type PatientBets = z.infer<typeof PatientBets>

export const PatientEmotion = z.object({
    partitions: Partitions.array()
})
export type PatientEmotion = z.infer<typeof PatientEmotion>
