import {CardStats} from '@/components/commons/card-stats/CardStats'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ArrowDownIcon, ArrowUpIcon, CurrencyEuroIcon, EqualIcon} from '@/components/ui/icon/Icon'
import {useTranslation} from 'react-i18next'
import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'
import {useGetPatientDetailsBets} from '@/features/patient-details/services/useGetPatientDetailsBets.ts'
import {
    StyledDailyAverageListItem,
    StyledDailyAverageListValue,
    StyledMoneyGambledFilledOutPercentResult,
    StyledMoneyGambledStatsGrid,
    StyledMoneyGambledStatTitle
} from '@/features/patient-details/components/patient-money-gambled-card/style.ts'
import {formatCurrency, round} from '@utilities/helpers.ts'
import {useOutletContext} from 'react-router-dom'
import {PatientDetailsBetsUrlParams, PatientDetailsURLParams} from '@/features/patient-details/types.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {useGetPatientDetails} from '@/features/patient-details/services/useGetPatientDetails.ts'
export const PatientMoneyGambledCard = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PatientDetailsURLParams}>()
    const {searchParams} = useParsedSearchParams([], PatientDetailsBetsUrlParams)
    const patientDetails = useGetPatientDetails(urlParams)
    const patientMoneyGambled = useGetPatientDetailsBets({
        patientId: urlParams.patientId,
        betsRangeDateLabels: searchParams.betsRangeDateLabels
    })
    const renderCompletedSummary = (amount: number) => {
        switch (true) {
            case amount > 0: {
                return (
                    <StyledMoneyGambledFilledOutPercentResult gap={0.5} align="center" $variant={'under'}>
                        <ArrowUpIcon size={20} />
                        {`${round(amount)}%`}
                    </StyledMoneyGambledFilledOutPercentResult>
                )
            }
            case amount < 0: {
                return (
                    <StyledMoneyGambledFilledOutPercentResult gap={0.5} align="center" $variant={'over'}>
                        <ArrowDownIcon size={20} />
                        {`${round(amount)}%`}
                    </StyledMoneyGambledFilledOutPercentResult>
                )
            }
            default: {
                return (
                    <StyledMoneyGambledFilledOutPercentResult gap={0.5} align="center" $variant={'over'}>
                        <EqualIcon size={20} />
                        {`${round(amount)}%`}
                    </StyledMoneyGambledFilledOutPercentResult>
                )
            }
        }
    }

    return (
        <StyledMoneyGambledStatsGrid>
            {!patientMoneyGambled.isLoading && patientMoneyGambled.data ? (
                <>
                    <CardStats
                        headerSlot={
                            <Flexbox gap={3} align={'center'}>
                                <IconContainer size={'lg'}>
                                    <CurrencyEuroIcon />
                                </IconContainer>
                                <h3>{t('patient_details:patient_tabs:money_gambled:card:general_amount')}</h3>
                            </Flexbox>
                        }
                        bodySlot={
                            <Flexbox direction="column" gap={4}>
                                <StyledMoneyGambledStatTitle>
                                    {patientDetails.data?.totalBets && formatCurrency(patientDetails.data?.totalBets)}
                                </StyledMoneyGambledStatTitle>
                            </Flexbox>
                        }
                    />

                    <CardStats
                        headerSlot={
                            <Flexbox gap={3} align={'center'}>
                                <IconContainer size={'lg'}>
                                    <CurrencyEuroIcon />
                                </IconContainer>
                                <h3>{t('patient_details:patient_tabs:money_gambled:card:average_day')}</h3>
                            </Flexbox>
                        }
                        bodySlot={
                            <Flexbox direction={'column'} gap={2}>
                                <StyledMoneyGambledStatTitle>
                                    {formatCurrency(patientMoneyGambled.data.averageDailyBet)}
                                </StyledMoneyGambledStatTitle>
                                <Flexbox gap={2}>
                                    {renderCompletedSummary(patientMoneyGambled.data?.averageDailyBet || 0)}
                                </Flexbox>
                            </Flexbox>
                        }
                    />

                    <CardStats
                        headerSlot={
                            <Flexbox gap={3} align={'center'}>
                                <IconContainer size={'lg'}>
                                    <CurrencyEuroIcon />
                                </IconContainer>
                                <h3>{t('patient_details:patient_tabs:money_gambled:card:average_day')}</h3>
                            </Flexbox>
                        }
                        bodySlot={
                            <Flexbox gap={2} direction="column">
                                {patientMoneyGambled.data?.percentageBets.partitions.map((result, index) => (
                                    <Flexbox gap={8} align="center" key={index}>
                                        {result.percentage ? (
                                            <StyledDailyAverageListValue>{`${round(result.percentage)}%`}</StyledDailyAverageListValue>
                                        ) : (
                                            <StyledDailyAverageListValue>{`${round(result.total)}`}</StyledDailyAverageListValue>
                                        )}
                                        <StyledDailyAverageListItem>
                                            {t(`patient_details:patient_tabs:money_gambled:card:${result.label}`)}
                                        </StyledDailyAverageListItem>
                                    </Flexbox>
                                ))}
                            </Flexbox>
                        }
                    />
                </>
            ) : (
                Array.from({length: 3}).map((_, index) => <CardStats asSkeleton key={index} />)
            )}
        </StyledMoneyGambledStatsGrid>
    )
}
