import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledLegendItemContainer = styled(Flexbox)`
    ${({theme: {palette, typography}}) => css`
        color: ${palette.neutral[600]};
        width: 100px;
        ${typography.textSm};
        & p {
            font-weight: 600;
        }
    `}
`

export const StyledItemDot = styled.div<{$color: string}>`
    ${({$color}) => css`
        margin-top: 6px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${$color || 'FFF'};
    `}}
`
