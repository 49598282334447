import {useInfiniteQuery} from '@tanstack/react-query'
import {QUERY_KEYS, infiniteQueryFetchNextPage} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance'
import {captureException} from '@sentry/react'
import {
    HttpGetPatientAppointmentsOptions,
    HttpGetPatientAppointmentsResponse,
    httpGetUserAppointments
} from '@/features/patient-details/services/patientDetails.http'
import {z} from 'zod'
import {getDateFromLabel} from '@utilities/utils.ts'
import {ResponseType} from '@/types.ts'

const UseGetUserAppointments = z.object({
    rangeDateLabels: z.enum(['today', 'last_week', 'last_month', 'last_3_months', 'last_6_months', 'last_year']),
    ...HttpGetPatientAppointmentsOptions.omit({startDate: true, endDate: true}).shape
})
type UseGetPatientAppointments = z.infer<typeof UseGetUserAppointments>

export const useGetUserAppointments = (promiseArgs: Omit<UseGetPatientAppointments, 'page' | 'responseType'>) => {
    const {rangeDateLabels, ...rest} = promiseArgs
    const query = useInfiniteQuery({
        enabled: !!promiseArgs.patientId,
        queryKey: [QUERY_KEYS.PATIENT_DETAILS_APPOINTMENTS, promiseArgs],
        queryFn: ({pageParam = 1}) =>
            parseAxiosPromise({
                axiosPromise: httpGetUserAppointments({
                    ...rest,
                    ...getDateFromLabel(rangeDateLabels),
                    page: pageParam,
                    responseType: ResponseType.Enum.regular,
                    limit: 20
                }),
                responseShape: HttpGetPatientAppointmentsResponse,
                onZodError: captureException
            }),
        initialPageParam: 1,
        getNextPageParam: ({meta}) => (meta.current_page < meta.last_page ? meta.current_page + 1 : null)
    })

    const remappedData = query.data?.pages[0]?.data?.length ? query.data.pages.flatMap(page => page.data) : []

    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData
    }
}
