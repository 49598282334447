import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledSurveyTableWrapper = styled.div`
    height: 400px;
    width: 100%;
`

export const StyledSelfAssessmentTableFooter = styled(Flexbox)`
    ${({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 4}px;
        & h4 {
            ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral[600]};
        }
    `}
`
