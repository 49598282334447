import {FC} from 'react'
import {Container} from '@components/ui/container/Container.tsx'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import Tabs from '@/components/commons/tabs/Tabs'
import {StyledPatientDetailsPageContent, StyledPatientDetailsPageHero} from '@/pages/patient-details/style'
import {useGetPatientDetails} from '@/features/patient-details/services/useGetPatientDetails.ts'
import {PatientSection} from '@/features/patient-details/components/patient-section/PatientSection.tsx'
import {DiarySection} from '@/features/patient-details/components/diary-section/DiarySection.tsx'
import {PatientAppointmentsTab} from '@/features/patient-details/components/patient-appointments-tab/PatientAppointmentsTab'
import {PatientDetailsSearchParams, PatientDetailsURLParams, PatientTabs} from '@/features/patient-details/types.ts'
import {useOutletContext} from 'react-router-dom'
import {PATIENT_DETAILS_TABS} from '@/features/patient-details/utils.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'

export const PatientDetails: FC = () => {
    const {searchParams, setSearchParams} = useParsedSearchParams([], PatientDetailsSearchParams)
    const {urlParams} = useOutletContext<{urlParams: PatientDetailsURLParams}>()

    const patientDetails = useGetPatientDetails(urlParams)

    const renderTabContent = () => {
        switch (searchParams.type) {
            case 'diary':
                return <DiarySection />
            case 'appointments':
                return <PatientAppointmentsTab />
            default:
                return <PatientSection />
        }
    }

    return (
        <Container fullWidth>
            <StyledPatientDetailsPageContent>
                {patientDetails.isLoading ? (
                    <Skeleton width={300} height={38} />
                ) : (
                    <StyledPatientDetailsPageHero
                        title={`${patientDetails.data?.firstName} ${patientDetails.data?.lastName} | ${patientDetails.data?.id}`}
                    />
                )}

                <Tabs
                    tabsList={PATIENT_DETAILS_TABS}
                    activeTabId={searchParams.type ?? PatientTabs.patient}
                    setActiveTabId={id => setSearchParams({type: id as PatientDetailsSearchParams['type']})}
                />
                {renderTabContent()}
            </StyledPatientDetailsPageContent>
        </Container>
    )
}

PatientDetails.displayName = 'PatientDetails'
