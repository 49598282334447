import {BaseCard} from '@components/commons/cards/BaseCard.tsx'
import {useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {
    StyledBaseCardsTitle,
    StyledCardWrapper,
    StyledContainerAdminCard,
    StyledSectionCardsTitle
} from '@/features/dashboard/views/style.ts'
import {CalendarIcon} from '@components/ui/icon/Icon.tsx'
import {SelfEvaluationsChart} from '@/features/dashboard/components/self-evaluations-chart/SelfEvaluationsChart.tsx'
import {DateRangeSelect} from '@components/commons/date-range-select/DateRangeSelect.tsx'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {DashboardAppointmentsUrlParams, DashboardUrlParamsExtended} from '@/features/dashboard/types.ts'
import {VerifiedUserGroup} from '@/features/dashboard/components/verify-user-group/VerifiedUserGroup.tsx'
import {UserTrendChart} from '@/features/dashboard/components/user-trend-chart/UserTrendChart.tsx'
import {AgesGroupChart} from '@/features/dashboard/components/ages-group-chart/AgesGroupChart.tsx'
import {EmotionsGroupChart} from '@/features/dashboard/components/genders-trend-chart/EmotionsGroupChart.tsx'
import {GendersTrendChart} from '@/features/dashboard/components/emotions-group-chart/GendersTrendChart.tsx'
import {BetsGroup} from '@/features/dashboard/components/bets-group/BetsGroup.tsx'
import {Switch} from '@components/ui/switch/Switch.tsx'
import {AppointmentsGroup} from '@/features/dashboard/components/appointments-group/AppointmentsGroup.tsx'
import {AppointmentsTrend} from '@/features/dashboard/components/appointments-trend/AppointmentsTrend.tsx'
import {FollowUpGroup} from '@/features/dashboard/components/follow-up-group/FollowUpGroup.tsx'
import {DoctorSelect} from '@/features/dashboard/components/doctor-select/DoctorSelect.tsx'
import {SingleValue} from 'react-select'
import {SelectValue} from '@/types.ts'

export const DashboardAdmin = () => {
    const {t} = useTranslation()
    const {searchParams, setSearchParams} = useParsedSearchParams([], DashboardUrlParamsExtended)
    const {searchParams: dashboardSearchParams, setSearchParams: setDashboardSetSearchParams} = useParsedSearchParams(
        [],
        DashboardAppointmentsUrlParams
    )
    const onChange = (newValue: SingleValue<SelectValue>) => {
        setDashboardSetSearchParams({therapistId: newValue ? parseInt(newValue.value) : undefined})
    }
    return (
        <>
            <Flexbox fullWidth gap={6} direction="column">
                <DateRangeSelect
                    value={searchParams.rangeDateLabels}
                    icon={<CalendarIcon />}
                    onChange={value => setSearchParams({rangeDateLabels: value})}
                />
                <Flexbox gap={8} direction="column" fullWidth>
                    <BaseCard variant={'sectioned'} headerSlot={<p>{t('settings:tabs:users')}</p>}>
                        <StyledCardWrapper gap={6} direction="column">
                            <StyledContainerAdminCard>
                                <BaseCard variant={'base'} paddingX={6} paddingY={6} bodyHasBorder={false}>
                                    <Flexbox direction={'column'} gap={4}>
                                        <StyledBaseCardsTitle>{t('routeNames:self_assessment')}</StyledBaseCardsTitle>
                                        <SelfEvaluationsChart />
                                    </Flexbox>
                                </BaseCard>
                                <BaseCard variant={'base'} paddingX={6} paddingY={6} bodyHasBorder={false}>
                                    <Flexbox direction={'column'} gap={5}>
                                        <StyledBaseCardsTitle>{t('dashboard:total:title')}</StyledBaseCardsTitle>
                                        <VerifiedUserGroup />
                                    </Flexbox>
                                </BaseCard>
                            </StyledContainerAdminCard>

                            <BaseCard variant={'base'} paddingX={6} paddingY={6} bodyHasBorder={false}>
                                <Flexbox direction={'column'} gap={6}>
                                    <StyledBaseCardsTitle>{t('dashboard:trend:title')}</StyledBaseCardsTitle>
                                    <UserTrendChart />
                                </Flexbox>
                            </BaseCard>
                            <Switch
                                label={t('patients:top_filters:active_path')}
                                onChange={() =>
                                    setSearchParams({
                                        isCurrentlyInTherapy:
                                            searchParams.isCurrentlyInTherapy == 'true' ? 'false' : 'true'
                                    })
                                }
                                defaultChecked={searchParams.isCurrentlyInTherapy == 'true'}
                            />

                            <StyledContainerAdminCard>
                                <BaseCard variant={'base'} paddingX={6} paddingY={6} bodyHasBorder={false}>
                                    <Flexbox direction={'column'} gap={6}>
                                        <StyledBaseCardsTitle>{t('dashboard:gender')}</StyledBaseCardsTitle>
                                        <GendersTrendChart />
                                    </Flexbox>
                                </BaseCard>

                                <BaseCard variant={'base'} paddingX={6} paddingY={6} bodyHasBorder={false}>
                                    <Flexbox direction={'column'} gap={6}>
                                        <StyledBaseCardsTitle>{t('dashboard:age')}</StyledBaseCardsTitle>
                                        <AgesGroupChart />
                                    </Flexbox>
                                </BaseCard>

                                <BaseCard variant={'base'} paddingX={6} paddingY={6} bodyHasBorder={false}>
                                    <Flexbox direction={'column'} gap={5}>
                                        <StyledBaseCardsTitle>{t('dashboard:mood')}</StyledBaseCardsTitle>
                                        <EmotionsGroupChart />
                                    </Flexbox>
                                </BaseCard>

                                <BaseCard variant={'base'} paddingX={6} paddingY={6} bodyHasBorder={false}>
                                    <Flexbox direction={'column'} gap={5} fullWidth>
                                        <StyledBaseCardsTitle>{t('dashboard:money_spent')}</StyledBaseCardsTitle>
                                        <BetsGroup />
                                    </Flexbox>
                                </BaseCard>
                            </StyledContainerAdminCard>

                            {searchParams.isCurrentlyInTherapy == 'true' && (
                                <BaseCard variant={'base'} paddingX={6} paddingY={6} bodyHasBorder={false}>
                                    <Flexbox direction={'column'} gap={5} fullWidth>
                                        <StyledBaseCardsTitle>
                                            {t('dashboard:patients_in_progress')}
                                        </StyledBaseCardsTitle>
                                        <FollowUpGroup />
                                    </Flexbox>
                                </BaseCard>
                            )}
                        </StyledCardWrapper>
                    </BaseCard>

                    <BaseCard
                        variant={'sectioned'}
                        headerSlot={
                            <>
                                <StyledSectionCardsTitle>{t('routeNames:appointments')}</StyledSectionCardsTitle>
                                <div>
                                    <DoctorSelect
                                        userId={dashboardSearchParams.therapistId}
                                        onChange={newValue => onChange(newValue as SingleValue<SelectValue>)}
                                    />
                                </div>
                            </>
                        }
                        footerSlot={<Button variant={'secondary'}>{t('dashboard:see_appointments')}</Button>}
                    >
                        <StyledCardWrapper fullWidth direction={'column'} gap={6}>
                            <AppointmentsGroup />
                            <BaseCard
                                paddingX={6}
                                paddingY={6}
                                variant={'sectioned'}
                                bodyHasBorder={false}
                                headerSlot={
                                    <StyledSectionCardsTitle>
                                        {t('dashboard:appointments:title')}
                                    </StyledSectionCardsTitle>
                                }
                            >
                                <AppointmentsTrend />
                            </BaseCard>
                        </StyledCardWrapper>
                    </BaseCard>
                </Flexbox>
            </Flexbox>
        </>
    )
}
