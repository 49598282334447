import {routes} from '@utilities/constants/routes.tsx'
import {
    BookOpen01Icon,
    CalendarIcon,
    ActivityIcon,
    Announcement03Icon,
    Home03Icon,
    FileQuestion02Icon,
    Settings01Icon,
    Users01Icon
} from '@components/ui/icon/Icon.tsx'

export const sidebarItems = [
    {
        name: 'routeNames:home',
        path: routes.DASHBOARD.path,
        icon: <Home03Icon />
    },
    {
        name: 'routeNames:appointments',
        path: routes.APPOINTMENTS.path,
        icon: <CalendarIcon />
    },
    {
        name: 'routeNames:patients',
        path: routes.PATIENTS.path,
        icon: <Users01Icon />
    },
    {
        name: 'routeNames:check_in',
        path: routes.CHECK_IN.path,
        icon: <ActivityIcon />
    },
    {
        name: 'routeNames:self_assessment',
        path: routes.SELF_ASSESSMENT.path,
        icon: <FileQuestion02Icon />
    },
    {
        name: 'routeNames:articles',
        path: routes.ARTICLES.path,
        icon: <BookOpen01Icon />
    },
    {
        name: 'routeNames:events',
        path: routes.EVENTS.path,
        icon: <Announcement03Icon />
    }
]

export const operatorSidebarItems = [
    {
        name: 'routeNames:home',
        path: routes.DASHBOARD.path,
        icon: <Home03Icon />
    },
    {
        name: 'routeNames:appointments',
        path: routes.APPOINTMENTS.path,
        icon: <CalendarIcon />
    },
    {
        name: 'routeNames:articles',
        path: routes.ARTICLES.path,
        icon: <BookOpen01Icon />
    },
    {
        name: 'routeNames:events',
        path: routes.EVENTS.path,
        icon: <Announcement03Icon />
    }
]

export const settingsItem = {
    name: 'routeNames:settings',
    path: routes.SETTINGS.path,
    icon: <Settings01Icon />
}
