import {z} from 'zod'
import {RangeDateLabels} from '@/types.ts'
import {Appointment} from '@/features/appointments/types.ts'

export const ACTION_TYPES_APPOINTMENTS_DASHBOARD = {
    done: 'done',
    not_done: 'not_done',
    edit: 'edit',
    delete: 'delete'
} as const

export const USER_ROLES = {
    admin: 'admin',
    therapist: 'therapist',
    operator: 'operator'
} as const

export const DashboardAdminUrlParams = z.object({
    rangeDateLabels: RangeDateLabels,
    genders: z.enum(['male', 'female']).optional(),
    age: z.enum(['low', 'medium', 'high', 'over']).optional()
})
export type DashboardAdminUrlParams = z.infer<typeof DashboardAdminUrlParams>

export const DashboardUrlParamsExtended = DashboardAdminUrlParams.merge(
    z.object({isCurrentlyInTherapy: z.enum(['true', 'false']).default('false')})
)
export type DashboardUrlParamsExtended = z.infer<typeof DashboardUrlParamsExtended>

export const DashboardAppointmentsUrlParams = DashboardAdminUrlParams.merge(
    z.object({therapistId: z.coerce.number().optional()})
)
export type DashboardAppointmentsUrlParams = z.infer<typeof DashboardAppointmentsUrlParams>

export const ChartData = z.object({
    name: z.string(),
    percentage: z.number().nullable(),
    total: z.number(),
    fill: z.string(),
    incrementsPercentage: z.number().nullable()
})
export type ChartData = z.infer<typeof ChartData>

export const NotificationItem = z.object({
    id: z.number(),
    keyName: z.string(),
    type: z.string(),
    userId: z.number(),
    status: z.enum(['unread', 'read']).optional(),
    createdAt: z.string(),
    attributes: z.object({
        appointment: z.object({
            id: z.number()
        })
    })
})

export type NotificationItem = z.infer<typeof NotificationItem>

export const NotificationFromSocket = z.object({
    notificationKeyName: z.string(),
    attributes: z.object({
        appointment: Appointment,
        userId: z.number(),
        notification: z.object({
            notificationType: z.object({
                keyName: z.string(),
                type: z.string()
            })
        }),
        office: z.object({
            id: z.number(),
            name: z.string()
        })
    })
})

export type NotificationFromSocket = z.infer<typeof NotificationFromSocket>
