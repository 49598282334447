import React from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {StyledContainerDashboardPage, StyledDashboardTitle} from '@pages/dashboard/style.ts'
import {Notifications} from '@/features/dashboard/components/notifications/Notifications.tsx'
import {Container} from '@components/ui/container/Container.tsx'
import {useTranslation} from 'react-i18next'
import {DashboardSecretary} from '@/features/dashboard/views/DashboardSecretary.tsx'
import {DashboardAdmin} from '@/features/dashboard/views/DashboardAdmin.tsx'
import {DashboardDoctor} from '@/features/dashboard/views/DashboardDoctor.tsx'
import {useAuthStore} from '@/features/auth/store/store.ts'

export const Dashboard: React.FC = () => {
    const {t} = useTranslation()

    const user = useAuthStore(state => state.user)

    return (
        <Container fullWidth>
            <StyledContainerDashboardPage gap={8} direction={'column'} fullWidth>
                <Flexbox justify={'space-between'} fullWidth align={'baseline'}>
                    <StyledDashboardTitle>{t('dashboard:good_morning', {NAME: user?.firstName})}</StyledDashboardTitle>
                    <Notifications />
                </Flexbox>
                {user?.role?.name === 'admin' && <DashboardAdmin />}
                {user?.role?.name == 'therapist' && <DashboardDoctor />}
                {user?.role?.name == 'operator' && <DashboardSecretary />}
            </StyledContainerDashboardPage>
        </Container>
    )
}

Dashboard.displayName = 'Dashboard'
