import {Dispatch, SetStateAction} from 'react'
import {Table} from '@components/commons/table/Table.tsx'
import {ActionsMenu} from '@/features/settings/components/actions-menu/ActionsMenu.tsx'
import {actionsListAppointmentDetails} from '@utilities/constants/actionsMenus.tsx'
import {
    StyledAppointmentsTimeAndLocation,
    StyledOperatorUpcomingAppointmentsTableWrapper,
    StyledPatientName,
    StyledUpcomingAppointmentsTableDataCell
} from '@/features/dashboard/components/appointments/style.ts'
import {ClockIcon, MarkerPin01Icon, VideoRecorderIcon} from '@components/ui/icon/Icon.tsx'
import {useGetAppointments} from '@/features/appointments/services/useGetAppointments.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {
    ACTION_TYPES_APPOINTMENT_DETAILS,
    AppointmentStatus,
    AppointmentsUrlParams
} from '@/features/appointments/types.ts'
import dayjs from '@/dayjs.ts'
import {useTranslation} from 'react-i18next'
import {AppointmentStatusChip} from '@components/commons/appointment-status-chip/AppointmentStatusChip.tsx'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {useEditAppointment} from '@/features/appointments/services/useEditAppointment.ts'
import {useDeleteAppointment} from '@/features/appointments/services/useDeleteAppointment.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {useGetContacts} from '@/features/settings/services/services-contact/useGetContacts.ts'

dayjs.extend(utc)
dayjs.extend(timezone)
const now = dayjs()
export const OperatorUpcomingAppointments = ({
    setOpenAddOrEditAppointmentModal
}: {
    setOpenAddOrEditAppointmentModal: Dispatch<SetStateAction<{open: boolean; id: number | null}>>
}) => {
    const {t} = useTranslation()
    const {searchParams} = useParsedSearchParams([], AppointmentsUrlParams)
    const office = useGetContacts()
    const {
        remappedData: appointments,
        isLoading,
        isFetchingNextPage,
        fetchNextPage,
        isError
    } = useGetAppointments({
        limit: 1000,
        fromDate: now.format(),
        toDate: dayjs().endOf('day').format(),
        ...searchParams
    })
    const {mutate: editAppointment} = useEditAppointment()
    const {mutate: deleteAppointment} = useDeleteAppointment()

    if (appointments.length > 0) {
        appointments.sort((a, b) => {
            return dayjs.utc(a.end).diff(now) - dayjs.utc(b.end).diff(now)
        })
    }

    const onActionClick = (type: string, selectedItem?: number) => {
        switch (type) {
            case ACTION_TYPES_APPOINTMENT_DETAILS.completed:
                editAppointment({
                    appointmentID: selectedItem!,
                    status: AppointmentStatus.enum.completed
                })
                break
            case ACTION_TYPES_APPOINTMENT_DETAILS.edit:
                setOpenAddOrEditAppointmentModal({open: true, id: selectedItem!})
                break
            case ACTION_TYPES_APPOINTMENT_DETAILS.delete:
                deleteAppointment(selectedItem!)
                break
            case ACTION_TYPES_APPOINTMENT_DETAILS.missed:
                editAppointment({
                    appointmentID: selectedItem!,
                    status: AppointmentStatus.enum.missed
                })
                break
            default:
                break
        }
    }

    return (
        <StyledOperatorUpcomingAppointmentsTableWrapper>
            <Table
                data={appointments}
                isLoading={isLoading}
                isError={isError}
                isHeaderVisible={false}
                onChangePage={fetchNextPage}
                isChangingPage={isFetchingNextPage}
                columns={[
                    {
                        width: '25%',
                        cellRender: data => (
                            <StyledUpcomingAppointmentsTableDataCell direction={'column'} gap={2}>
                                <StyledPatientName>
                                    {data?.patient?.lastName} {data?.patient?.firstName}
                                </StyledPatientName>
                                <span>
                                    {t(`select_options:title_options:${data?.therapist?.title}`)}{' '}
                                    {data?.therapist?.firstName} {data?.therapist?.lastName}
                                </span>
                            </StyledUpcomingAppointmentsTableDataCell>
                        )
                    },
                    {
                        width: '20%',
                        cellRender: data => (
                            <StyledAppointmentsTimeAndLocation>
                                <ClockIcon />
                                <p>
                                    {`${dayjs.utc(data.start).format('HH:mm')} - ${dayjs.utc(data.end).format('HH:mm')} 
                            (${dayjs(data.end).diff(dayjs(data.start), 'hour')} ${t('patient_details:appointments_tab:hour')})`}
                                </p>
                            </StyledAppointmentsTimeAndLocation>
                        )
                    },
                    {
                        width: '25%',
                        cellRender: data => (
                            <StyledAppointmentsTimeAndLocation>
                                {data.isRemote ? <VideoRecorderIcon size={20} /> : <MarkerPin01Icon size={20} />}
                                {office.isLoading ? (
                                    <Skeleton height={24} width={200} />
                                ) : (
                                    <p>{office.data?.data.find(item => item.id === data.officeId)?.name}</p>
                                )}
                            </StyledAppointmentsTimeAndLocation>
                        )
                    },
                    {
                        width: '20%',
                        alignment: 'right',
                        cellRender: data => (
                            <AppointmentStatusChip
                                label={t(`commons:appointment_state:${data.status}`)}
                                variant={data.status}
                            />
                        )
                    },
                    {
                        width: '10%',
                        alignment: 'center',
                        cellRender: data => (
                            <ActionsMenu
                                actions={actionsListAppointmentDetails(data?.status as AppointmentStatus)}
                                onActionClick={onActionClick}
                                selectedItem={data?.id}
                            />
                        )
                    }
                ]}
            />
        </StyledOperatorUpcomingAppointmentsTableWrapper>
    )
}
