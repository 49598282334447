import {ModalBody, ModalHeader, ModalTitle} from '@/components/ui/modal-atoms/ModalAtoms'
import {Modal} from '@/components/ui/modal/Modal'
import styled, {css} from 'styled-components'

export const StyledCaregiverFormModal = styled(Modal)`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr) auto;
    overflow: visible;
`

export const StyledCaregiverFormModalHeader = styled(ModalHeader)(
    ({theme: {typography, palette, spacing}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: ${spacing * 2.5}px ${spacing * 6}px;

        & > h4 {
            ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral['black']};
        }
    `
)

export const StyledCaregiverFormBody = styled(ModalBody)`
    overflow: visible;
`

export const StyledCaregiverFormModalTitle = styled(ModalTitle)(
    ({theme: {palette}}) => css`
        color: ${palette.neutral['900']};
    `
)
