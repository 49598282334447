import {PatientDetailsCard} from '@/features/patient-details/components/patient-details-card/PatientDetailsCard.tsx'
import {
    StyledContainerPatientSection,
    StyledInfoTitle
} from '@/features/patient-details/components/patient-section/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'
import {PatientMoneyGambledCard} from '@/features/patient-details/components/patient-money-gambled-card/PatientMoneyGambledCard.tsx'
import {PatientMoneyGambledGraphicChart} from '@/features/patient-details/components/patient-money-gambled-graphic-chart/PatientMoneyGambledGraphicChart.tsx'
import {PatientMoneyGambledGraphicChartFilters} from '@/features/patient-details/components/patient-money-gambled-graphic-chart-filters/PatientMoneyGambledGraphicChartFilters.tsx'
import {useGetPatientDetails} from '@/features/patient-details/services/useGetPatientDetails.ts'
import {useOutletContext} from 'react-router-dom'
import {
    PatientAppointmentsURLSearchParamsParams,
    PatientDetailsSearchParams,
    PatientDetailsURLParams
} from '@/features/patient-details/types.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {CalendarPlus01Icon} from '@components/ui/icon/Icon.tsx'
import {AppointmentItemSkeleton} from '@/features/patient-details/components/appointment-item/AppointmentItemSkeleton.tsx'
import {BaseCard} from '@components/commons/cards/BaseCard.tsx'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {AddAppointmentsModal} from '@/features/appointments/components/modals/AddAppointmentsModal'
import {useGetContacts} from '@/features/settings/services/services-contact/useGetContacts'
import {useGetAppointmentsUser} from '@/features/appointments/services/useGetAppointmentsUser'
import {useState} from 'react'
import {useGetAppointmentDetails} from '@/features/appointments/services/useGetAppointmentDetails.ts'
import {AppointmentItem} from '@/features/patient-details/components/appointment-item/AppointmentItem.tsx'
import {useGetPatients} from '@/features/patients/services/useGetPatients.ts'
import {useGetUsers} from '@/features/settings/services/services-users/useGetUsers.ts'
import {ResponseType} from '@/types.ts'
import {useGetUserAppointments} from '@/features/patient-details/services/useGetUserAppointments.tsx'

export const PatientSection = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PatientDetailsURLParams}>()
    const patientDetails = useGetPatientDetails(urlParams)
    const {searchParams} = useParsedSearchParams([], PatientAppointmentsURLSearchParamsParams)
    const {setSearchParams} = useParsedSearchParams([], PatientDetailsSearchParams)
    const {data: offices} = useGetContacts()
    const {data: therapists} = useGetAppointmentsUser({
        roles: ['therapist'],
        limit: 10000
    })
    const {data: appointment, isLoading: appointmentLoading} = useGetAppointmentDetails(
        patientDetails.data?.appointment?.id || null
    )
    const {remappedData: patients} = useGetPatients({limit: 10000})
    const {remappedData: therapist} = useGetUsers({
        responseType: ResponseType.enum.backofficeRegular,
        limit: 100,
        roles: ['therapist']
    })
    const patientAppointmentsQuery = useGetUserAppointments({
        patientId: urlParams.patientId,
        ...searchParams
    })

    const [openAddOrEditAppointmentModal, setOpenAddOrEditAppointmentModal] = useState<{
        open: boolean
        id: number | null
    }>({
        open: false,
        id: null
    })

    return (
        <StyledContainerPatientSection>
            <Flexbox direction={'column'} gap={4}>
                <StyledInfoTitle>{t('patient_details:patient_tabs:info:title')}</StyledInfoTitle>
                <PatientDetailsCard />
            </Flexbox>
            <div>
                <Flexbox direction={'column'} gap={5}>
                    <Flexbox fullWidth justify={'space-between'} align={'center'}>
                        <h2>{t('patient_details:patient_tabs:appointment:title')}</h2>
                        <Flexbox>
                            {patientDetails.data?.appointment && (
                                <Button onClick={() => setOpenAddOrEditAppointmentModal({open: true, id: null})}>
                                    <CalendarPlus01Icon size={20} />
                                    {t('patient_details:appointments_tab:add_appointment')}
                                </Button>
                            )}
                            <Button onClick={() => setSearchParams({type: 'appointments'})} variant={'tertiaryColor'}>
                                {t('patient_details:appointments_tab:view_all')}
                            </Button>
                        </Flexbox>
                    </Flexbox>
                    {appointmentLoading ? (
                        <AppointmentItemSkeleton />
                    ) : appointment ? (
                        <AppointmentItem
                            setOpenAddOrEditAppointmentModal={setOpenAddOrEditAppointmentModal}
                            appointment={appointment}
                        />
                    ) : (
                        <BaseCard paddingX={6} paddingY={6} bodyHasBorder={false}>
                            <Flexbox direction={'column'} gap={6}>
                                <h2>{t('patient_details:appointments_tab:new_appointment:title')}</h2>
                                <p>{t('patient_details:appointments_tab:new_appointment:description')}</p>
                                <Button onClick={() => setOpenAddOrEditAppointmentModal({open: true, id: null})}>
                                    <CalendarPlus01Icon size={20} />
                                    {t('patient_details:appointments_tab:add_appointment')}
                                </Button>
                            </Flexbox>
                        </BaseCard>
                    )}
                    <h2>{t('patient_details:patient_tabs:money_gambled:title')}</h2>
                    <PatientMoneyGambledGraphicChartFilters />
                    <PatientMoneyGambledGraphicChart />
                    <PatientMoneyGambledCard />
                </Flexbox>
            </div>

            {openAddOrEditAppointmentModal.open && (
                <AddAppointmentsModal
                    offices={offices?.data ? offices.data : []}
                    patients={
                        openAddOrEditAppointmentModal?.id ? patients : patientDetails.data ? [patientDetails.data] : []
                    }
                    therapists={openAddOrEditAppointmentModal?.id ? therapist : therapists?.data?.data}
                    onClose={() => setOpenAddOrEditAppointmentModal({open: false, id: null})}
                    prefilledData={!openAddOrEditAppointmentModal?.id ? {patientId: urlParams.patientId} : null}
                    officeId={null}
                    appointments={patientAppointmentsQuery.remappedData}
                    appointment={appointment}
                />
            )}
        </StyledContainerPatientSection>
    )
}
