import {DefaultTheme} from 'styled-components/dist/types'
import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const makeStatsPercentStyle = ({palette}: DefaultTheme) =>
    ({
        over: css`
            color: ${palette.success[600]};
        `,
        under: css`
            color: ${palette.danger[600]};
        `,
        equal: css`
            color: ${palette.neutral[400]};
        `
    }) as const satisfies Record<NonNullable<'over' | 'under' | 'equal'>, ReturnType<typeof css>>

export const StyledPercentageSummary = styled(Flexbox)<{$variant: 'over' | 'under' | 'equal'}>(
    ({theme, $variant}) => css`
        ${theme.typography.textSm};
        ${makeStatsPercentStyle(theme)[$variant]};
    `
)

export const StyledPercentageSummaryLabels = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.neutral['600']};
    `
)
