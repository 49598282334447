import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledDeleteParagraph = styled(Flexbox)`
    ${({theme}) => css`
        color: ${theme.palette.neutral[900]};
        max-width: 330px;
        ${theme.typography.textSm};

        .description {
            ${theme.typography.textXl};
            font-weight: 500;
        }

        .information {
            font-weight: 700;
        }

        .paragraph {
            font-weight: 400;
        }
    `}
`
