import {SelectProps} from '@/components/commons/select/Select'
import {useTranslation} from 'react-i18next'
import {useGetCities} from '@/features/patient-form/services/useGetCities'
import {LocationSelector} from '@/features/patient-form/components/location-selector/LocationSelector'

interface CitySelectorProps {
    countryId: string
    stateId: string
    value: SelectProps['value']
    onChange: SelectProps['onChange']
    errorMessage?: string
    className?: string
}

export const CitySelector = ({countryId, stateId, value, onChange, errorMessage, className}: CitySelectorProps) => {
    const {t} = useTranslation()
    const {remappedData: cities, ...citiesQuery} = useGetCities(
        {
            countryId: parseInt(countryId),
            stateId: parseInt(stateId)
        },
        {enabled: countryId.length > 0 && stateId.length > 0}
    )

    return (
        <LocationSelector
            className={className}
            options={cities}
            onSearch={citiesQuery.onSearch}
            fetchNextPage={citiesQuery.fetchNextPage}
            isLoading={citiesQuery.isLoading || citiesQuery.isFetchingNextPage}
            label={`${t('patients:create_patient:form:labels:birth_city')}*`}
            placeholder={t('patients:create_patient:form:placeholder:birth_city')}
            value={value ?? null}
            onChange={onChange}
            errorMessage={errorMessage && t(errorMessage)}
            noOptionsMessage={t('patients:create_patient:form:no_cities_options')}
        />
    )
}
