import {SelectItem} from '@/components/ui/select-atoms/SelectAtoms'
import {SelectTrigger} from '@/components/ui/select-atoms/SelectTrigger'
import styled, {css} from 'styled-components'

export const StyledSelectRangeTrigger = styled(SelectTrigger)`
    font-weight: 600;
    min-height: 40px;
    width: auto;
`

export const StyledSelectRangeItem = styled(SelectItem)(
    ({theme: {typography}}) => css`
        ${typography.textSm};
        font-weight: 500;
    `
)
