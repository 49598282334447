import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import styled, {css} from 'styled-components'

export const StyledModalBody = styled(Flexbox)`
    ${({theme: {spacing, palette}}) => css`
        padding: ${spacing * 6}px ${spacing * 6}px ${spacing * 4}px ${spacing * 6}px;

        & p {
            color: ${palette.neutral[900]};
        }
    `}
`

export const StyledCreateUserTitle = styled.p`
    ${({theme: {typography}}) => css`
        ${typography.textXl};
        font-weight: 500;
    `}
`

export const StyledFormDescription = styled.div`
    ${({theme: {palette, spacing, typography}}) => css`
        background-color: ${palette.neutral[50]};
        padding: ${spacing * 3}px;
        margin: ${spacing * 2}px 0;
        border-radius: ${spacing * 3}px;
        ${typography.textSm};
        font-weight: 400;
    `}
`
