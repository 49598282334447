import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledContactCard = styled.div`
    ${({theme}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        column-gap: ${theme.spacing * 14}px;
        row-gap: ${theme.spacing * 5}px;
        padding: ${theme.spacing * 6}px;
        border-radius: ${theme.spacing * 3}px;
        border: 1px solid ${theme.palette.neutral[200]};
        width: 100%;

        & > .button {
            grid-area: 3 / 2 / 4 / 3;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    `}
`

export const StyledWrapperCards = styled(Flexbox)`
    ${({theme}) => css`
        max-height: calc(100vh - 350px);
        overflow-y: auto;
        margin-bottom: ${theme.spacing * 5}px;

        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            display: none;
        }
    `}
`
