import {StyledChip} from '@components/commons/chip/style.ts'
import {FC, ReactNode} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {XCloseIcon} from '@components/ui/icon/Icon.tsx'

interface ChipProps {
    label: string | ReactNode
    icon?: ReactNode
    color?: 'neutral' | 'primary' | 'secondary' | 'success' | 'danger' | 'warning'
    variant?: 'filled' | 'outlined'
    onClick?: () => void
    className?: string
    onDelete?: () => void
    fullWidth?: boolean
}

export const Chip: FC<ChipProps> = ({
    label,
    icon,
    color = 'neutral',
    variant,
    onClick,
    className,
    onDelete,
    fullWidth = false,
    ...rest
}) => {
    return (
        <StyledChip
            onClick={onClick}
            $color={color}
            $variant={variant}
            className={className}
            {...rest}
            $fullWidth={fullWidth}
        >
            <Flexbox gap={1} align={'center'}>
                {icon}
                {label}
                {onDelete && <XCloseIcon className={'delete-icon'} onClick={onDelete} />}
            </Flexbox>
        </StyledChip>
    )
}
