import {FC} from 'react'
import {StyledArticleContent, StyledArticleItemWrapper} from '@components/commons/article-item/style.ts'
import {Thumbnail} from '@components/commons/thumbnail/Thumbnail.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Chip} from '@components/commons/chip/Chip.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'
import {ArrowNarrowUpRightIcon} from '@components/ui/icon/Icon.tsx'
import dayjs from '@/dayjs.ts'
import {StyledArticleTitle} from '@components/commons/empty-state/style.ts'
import {Article} from '@/features/articles/types.ts'
import {truncateText} from '@utilities/helpers.ts'
import {envVars} from '@/envVars.ts'

export interface ArticleItemProps {
    article: Article
    size?: 'sm' | 'md'
}

const ARTICLE_TYPES = {
    10: 'articles',
    11: 'events'
}

export const ArticleItem: FC<ArticleItemProps> = ({article, size = 'md'}) => {
    const {t} = useTranslation()
    const articleImage = article._embedded['wp:featuredmedia']?.[0]?.source_url
    const articleType = ARTICLE_TYPES[article.tags[0] as keyof typeof ARTICLE_TYPES]
    const articleContent = article.excerpt.rendered

    return (
        <>
            <StyledArticleItemWrapper $size={size}>
                <Thumbnail src={articleImage} alt={article.title.rendered} size={size === 'sm' ? 72 : 95} />

                <Flexbox gap={size === 'md' ? 2 : 0} direction={'column'} justify={'flex-end'}>
                    <Flexbox gap={8} align={'center'} fullWidth>
                        <Chip color={'neutral'} label={t(`${articleType}:type`)} variant={'filled'} />
                        <StyledArticleContent>
                            {dayjs(article.date).locale('it').format('DD MMMM YYYY')}
                        </StyledArticleContent>

                        {size === 'sm' && (
                            <Button
                                variant={'tertiaryColor'}
                                size={'sm'}
                                style={{
                                    marginLeft: 'auto'
                                }}
                            >
                                {t('articles:edit_cta')}
                                <ArrowNarrowUpRightIcon />
                            </Button>
                        )}
                    </Flexbox>

                    <StyledArticleTitle
                        onClick={() => {
                            window.open(article.link, '_blank')
                        }}
                    >
                        {article.title.rendered}
                    </StyledArticleTitle>

                    <StyledArticleContent
                        dangerouslySetInnerHTML={{
                            __html: truncateText(articleContent ? articleContent : '-', size === 'md' ? 250 : 60)
                        }}
                    ></StyledArticleContent>
                </Flexbox>

                {size === 'md' && (
                    <Flexbox align={'flex-start'}>
                        <Button
                            variant={'secondaryColor'}
                            onClick={() =>
                                window.open(
                                    envVars.VITE_EDIT_ARTICLES_ENDPOINT.replace('{id}', article.id.toString()),
                                    '_blank'
                                )
                            }
                        >
                            {t('articles:edit_cta')}
                            <ArrowNarrowUpRightIcon />
                        </Button>
                    </Flexbox>
                )}
            </StyledArticleItemWrapper>
        </>
    )
}
