import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance'
import {captureException} from '@sentry/react'
import {Shift} from '@/features/appointments/types.ts'
import {httpGetShiftDetails} from '@/features/appointments/services/shift.http.ts'

export const useGetShiftDetails = (shiftID: number | null, newShift: number | null) => {
    return useQuery({
        enabled: !!shiftID,
        queryKey: [QUERY_KEYS.GET_SHIFT_DETAILS, shiftID, newShift],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetShiftDetails(shiftID),
                responseShape: Shift,
                onZodError: captureException
            })
    })
}
