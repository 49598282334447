import {CalendarIcon, PlusIcon} from '@/components/ui/icon/Icon'
import {PatientUrlSearchParams} from '@/features/patients/types'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useTranslation} from 'react-i18next'
import {Switch} from '@/components/ui/switch/Switch'
import {Button} from '@/components/ui/button/Button'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {DateRangeSelect} from '@/components/commons/date-range-select/DateRangeSelect'
import {Dispatch, SetStateAction} from 'react'

export const PatientsOptions = ({
    setCreateModalOpen
}: {
    setCreateModalOpen: Dispatch<SetStateAction<'create' | null>>
}) => {
    const {t} = useTranslation()
    const {searchParams, setSearchParams} = useParsedSearchParams([], PatientUrlSearchParams)

    return (
        <Flexbox fullWidth align="center" gap={6}>
            <Switch
                label={t('patients:top_filters:active_path')}
                onChange={() =>
                    setSearchParams({currentlyInTherapy: searchParams.currentlyInTherapy == 'true' ? 'false' : 'true'})
                }
                defaultChecked={searchParams.currentlyInTherapy == 'true'}
            />

            <DateRangeSelect
                value={searchParams.dateRangeLabels}
                icon={<CalendarIcon />}
                onChange={value => setSearchParams({dateRangeLabels: value})}
            />

            <Button size="md" variant="primary" onClick={() => setCreateModalOpen('create')}>
                <PlusIcon />
                {t('patients:empty_state:cta_label')}
            </Button>
        </Flexbox>
    )
}
