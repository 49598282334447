import {useTranslation} from 'react-i18next'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {DashboardAdminUrlParams} from '@/features/dashboard/types.ts'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {ErrorIcon} from 'react-hot-toast'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ProgressBar} from '@components/ui/progress-bar/ProgressBar.tsx'
import {StyledBetsProgressLabel} from '@/features/dashboard/components/bets-group/style.ts'
import {useGetFollowUp} from '@/features/dashboard/services/useGetFollowUp.ts'

export const FollowUpGroup = () => {
    const {t} = useTranslation()
    const {searchParams} = useParsedSearchParams([], DashboardAdminUrlParams)
    const {data, isLoading, isError} = useGetFollowUp(searchParams)

    return (
        <>
            {isLoading ? (
                <Flexbox fullWidth direction={'column'} gap={6}>
                    {Array.from(Array(2)).map(() => (
                        <Flexbox fullWidth gap={2}>
                            <Skeleton height={24} />
                        </Flexbox>
                    ))}
                </Flexbox>
            ) : isError ? (
                <ErrorBox icon={<ErrorIcon />} title={t('errors:default')} />
            ) : (
                data && (
                    <Flexbox fullWidth direction={'column'} gap={6}>
                        {data.partitions.map(value => (
                            <Flexbox fullWidth gap={2}>
                                <StyledBetsProgressLabel>{t(`dashboard:${value.label}`)}</StyledBetsProgressLabel>
                                {value.percentage && (
                                    <ProgressBar
                                        percentageProgress={value.percentage}
                                        variant={value.label === 'improved' ? 'success' : 'danger'}
                                    />
                                )}
                            </Flexbox>
                        ))}
                    </Flexbox>
                )
            )}
        </>
    )
}
