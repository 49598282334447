import {z} from 'zod'
import {AxiosPromise, AxiosRequestHeaders} from 'axios'
import {checkInDetails} from '@/mock/mock'
import {RangeDate, ResponseType} from '@/types.ts'

/**
 * Get checkInDetails
 */

export const HttpGetCheckInDetailsOptions = z.object({
    checkInId: z.coerce.number(),
    range_date: RangeDate.optional(),
    responseType: ResponseType.optional()
})
export type HttpGetCheckInDetailsOptions = z.infer<typeof HttpGetCheckInDetailsOptions>

export const httpGetCheckInDetails = (params: HttpGetCheckInDetailsOptions) => {
    console.log('Get checkin', params)
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({
                headers: {},
                config: {
                    headers: {} as AxiosRequestHeaders
                },
                data: checkInDetails,
                status: 200,
                statusText: 'ok'
            })
        }, 1000)
    }) as AxiosPromise
}
