import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance'
import {captureException} from '@sentry/react'
import {UserBackOfficeSmall} from '@/features/auth/types.ts'
import {httpShowUsers} from '@/features/settings/services/settings.http.ts'
import {HttpShowUsersOptions} from '@/features/settings/types.ts'

export const useGetUserDetails = (
    promiseArgs: HttpShowUsersOptions,
    options?: Omit<UseQueryOptions<UserBackOfficeSmall>, 'queryFn' | 'queryKey'>
) =>
    useQuery({
        ...options,
        queryKey: [QUERY_KEYS.USER, promiseArgs],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpShowUsers({params: promiseArgs}),
                responseShape: UserBackOfficeSmall,
                onZodError: captureException
            }),
        enabled: !!promiseArgs.userId
    })
