import {useGetSelfEvaluationsGroup} from '@/features/dashboard/services/useGetSelfEvaluationsGroup.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {DashboardAdminUrlParams} from '@/features/dashboard/types.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {ErrorIcon} from 'react-hot-toast'
import {useTranslation} from 'react-i18next'
import {RadialBar, RadialBarChart} from 'recharts'
import {adaptDataForChart} from '@/features/dashboard/utils.ts'
import {EmptyChart} from '@/features/dashboard/components/empty-chart/EmptyChart.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTheme} from 'styled-components'
import {StyledSelfEvaluationsGrid} from '@/features/dashboard/components/self-evaluations-chart/style.ts'
import {StyledItemDot} from '@/features/dashboard/components/chart-legend/style.ts'
import {Chip} from '@components/commons/chip/Chip.tsx'
import {ArrowDownIcon, ArrowUpIcon} from '@components/ui/icon/Icon.tsx'

export const SelfEvaluationsChart = () => {
    const {t} = useTranslation()
    const {searchParams} = useParsedSearchParams([], DashboardAdminUrlParams)
    const {isLoading, isError, data} = useGetSelfEvaluationsGroup(searchParams)
    const {palette} = useTheme()
    const colors = [palette.primary[700], palette.danger[600], palette.success[600]]

    return (
        <div>
            {isLoading ? (
                <Skeleton height={130} width={130} />
            ) : isError ? (
                <ErrorBox icon={<ErrorIcon />} title={t('errors:default')} />
            ) : (
                data && (
                    <Flexbox gap={4}>
                        {data.analytics.total !== 0 ? (
                            <RadialBarChart
                                height={130}
                                width={130}
                                innerRadius="120%"
                                outerRadius="50%"
                                barSize={8}
                                data={adaptDataForChart(
                                    [
                                        {
                                            total: data.analytics.total,
                                            percentage: null,
                                            label: 'completed'
                                        },
                                        ...data.analytics.partitions
                                    ],
                                    colors
                                )}
                            >
                                <RadialBar cornerRadius={30 / 2} background dataKey="total" />
                            </RadialBarChart>
                        ) : (
                            <EmptyChart height={130} width={130} title={t('commons:no_metrics')} />
                        )}
                        <Flexbox direction={'column'} gap={1}>
                            {adaptDataForChart(
                                [
                                    {
                                        total: data.analytics.total,
                                        percentage: null,
                                        label: 'completed'
                                    },
                                    ...data.analytics.partitions
                                ],
                                colors
                            ).map((value, index) => (
                                <Flexbox gap={6} justify={'space-between'} fullWidth key={index}>
                                    <Flexbox gap={2}>
                                        <StyledItemDot $color={value.fill} />
                                        <StyledSelfEvaluationsGrid>
                                            <span>{value.name}</span>
                                            {value.percentage ? (
                                                <Flexbox gap={1}>
                                                    <p>{`${value.percentage}%`}</p>
                                                    <span>{`(${value.total})`}</span>
                                                </Flexbox>
                                            ) : (
                                                <p>{value.total}</p>
                                            )}
                                        </StyledSelfEvaluationsGrid>
                                    </Flexbox>
                                    {value.incrementsPercentage && (
                                        <Chip
                                            label={
                                                <Flexbox gap={0.5} align={'center'}>
                                                    {value.incrementsPercentage > 0 ? (
                                                        <ArrowUpIcon size={12} />
                                                    ) : (
                                                        <ArrowDownIcon size={12} />
                                                    )}
                                                    {`${value.incrementsPercentage}%`}
                                                </Flexbox>
                                            }
                                            variant={'filled'}
                                            color={value.incrementsPercentage > 0 ? 'danger' : 'success'}
                                        />
                                    )}
                                </Flexbox>
                            ))}
                        </Flexbox>
                    </Flexbox>
                )
            )}
        </div>
    )
}
