import {BaseCard} from '@components/commons/cards/BaseCard.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {PlusIcon} from '@components/ui/icon/Icon.tsx'
import {useTranslation} from 'react-i18next'
import {StyledContainerPatientCards} from '@/features/dashboard/components/patients/style.ts'
import {StyledHeaderAppointments} from '@/features/dashboard/components/appointments/style.ts'
import {useNavigate} from 'react-router-dom'
import {routes} from '@utilities/constants/routes.tsx'
import {GendersTrendChart} from '@/features/dashboard/components/emotions-group-chart/GendersTrendChart.tsx'
import {AgesGroupChart} from '@/features/dashboard/components/ages-group-chart/AgesGroupChart.tsx'
import {useState} from 'react'
import {PatientFormModal} from '@/features/patient-form/components/patient-form-modal/PatientFormModal'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useGetVerifiedUsersGroup} from '@/features/dashboard/services/useGetVerifiedUsersGroup.ts'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {ErrorIcon} from 'react-hot-toast'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {DashboardUrlParamsExtended} from '@/features/dashboard/types.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {StyledPrimaryData} from '@/features/dashboard/components/verify-user-group/style.ts'
import {PercentageSummary} from '@components/commons/percentage-summary/PercentageSummary.tsx'
import MiniChart from '@assets/images/chart-mini.svg'

export const PatientAnalytical = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [hasModalOpen, setHasModalOpen] = useState(false)

    const {searchParams} = useParsedSearchParams([], DashboardUrlParamsExtended)
    const verifiedUserQuery = useGetVerifiedUsersGroup(searchParams)

    return (
        <>
            <BaseCard
                variant={'sectioned'}
                headerSlot={
                    <>
                        <StyledHeaderAppointments>
                            <p>{t('dashboard:patients')}</p>
                        </StyledHeaderAppointments>

                        <Button onClick={() => setHasModalOpen(true)}>
                            <PlusIcon />
                            <span>{t('dashboard:add_patient')}</span>
                        </Button>
                    </>
                }
                footerSlot={
                    <Button variant={'tertiaryColor'} onClick={() => navigate(routes.PATIENTS.path)}>
                        {t('dashboard:see_all')}
                    </Button>
                }
            >
                <StyledContainerPatientCards gap={6} align={'stretch'}>
                    <BaseCard
                        gap={5}
                        paddingX={6}
                        paddingY={6}
                        headerSlot={<p>{t('dashboard:active')}</p>}
                        bodyHasBorder={false}
                    >
                        <Flexbox direction={'column'} gap={2} fullWidth>
                            {verifiedUserQuery.isLoading ? (
                                <>
                                    <Skeleton height={38} width={100} />
                                    <Flexbox fullWidth justify={'space-between'} align={'center'}>
                                        <Skeleton height={20} width={200} />
                                        <img src={MiniChart} alt={'icon'} />
                                    </Flexbox>
                                </>
                            ) : verifiedUserQuery.isError ? (
                                <ErrorBox icon={<ErrorIcon />} title={t('errors:default')} />
                            ) : (
                                verifiedUserQuery.data && (
                                    <>
                                        <StyledPrimaryData>{verifiedUserQuery.data.analytics.total}</StyledPrimaryData>
                                        <Flexbox fullWidth justify={'space-between'} align={'center'}>
                                            <PercentageSummary
                                                amount={verifiedUserQuery.data.increments.total}
                                                percentage={verifiedUserQuery.data.increments.percentage}
                                                label={t(`commons:compare_labels:${searchParams.rangeDateLabels}`)}
                                            />
                                            <img src={MiniChart} alt={'icon'} />
                                        </Flexbox>
                                    </>
                                )
                            )}
                        </Flexbox>
                    </BaseCard>

                    <BaseCard
                        gap={5}
                        paddingX={6}
                        paddingY={6}
                        headerSlot={<p>{t('dashboard:gender')}</p>}
                        bodyHasBorder={false}
                    >
                        <GendersTrendChart />
                    </BaseCard>

                    <BaseCard
                        gap={5}
                        paddingX={6}
                        paddingY={6}
                        headerSlot={<p>{t('dashboard:age')}</p>}
                        bodyHasBorder={false}
                    >
                        <AgesGroupChart />
                    </BaseCard>
                </StyledContainerPatientCards>
            </BaseCard>
            {hasModalOpen && <PatientFormModal onClose={() => setHasModalOpen(false)} />}
        </>
    )
}
