import {Select} from '@/components/commons/select/Select'
import styled, {css} from 'styled-components'

export const StyledLocationSelector = styled(Select)(
    ({theme: {palette}}) => css`
        & .select__control {
            min-height: unset;
            height: 40px;
            border-radius: 12px;
            transition: all 200ms ease-in-out;
            &:hover {
                box-shadow: 0 0 0 4px ${palette.primary[100]};
                border: 1px solid ${palette.primary[300]}!important;
            }
            &:focus {
                box-shadow: 0 0 0 4px ${palette.primary[100]};
                border-color: ${palette.primary[300]};
            }
        }
    `
)
