import React from 'react'
import {Container} from '@components/ui/container/Container.tsx'
import {AuthContent, AuthForm, AuthSubTitle, AuthTitle, AuthWrapper} from '@/components/ui/auth-atoms/AuthAtoms'
import {StyledForgotPasswordLink} from '@pages/login/style.ts'
import {useTranslation} from 'react-i18next'
import Logo from '@assets/images/logo.svg'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useAuthStore} from '@/features/auth/store/store'
import {SubmitHandler, useForm} from 'react-hook-form'
import {LOGIN_FORM_MODEL, LoginValidationSchema} from './LoginFormModel'
import {useLogin} from '@/features/auth/services/useLogin'
import {zodResolver} from '@hookform/resolvers/zod'
import {InputText} from '@/components/commons/input-text/InputText'
import {Button} from '@/components/ui/button/Button'
import {routes} from '@/utilities/constants/routes'
import toast from 'react-hot-toast'
import {ToastBody} from '@/components/ui/toast-body/ToastBody'

export const Login: React.FC = () => {
    const {t} = useTranslation()

    const setAuthData = useAuthStore(state => state.setAuthData)

    const {
        register,
        handleSubmit,
        formState: {errors, touchedFields}
    } = useForm<LoginValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(LoginValidationSchema)
    })

    //login mutation
    const {mutate: login, isPending} = useLogin({
        onError: () => {
            toast.error(<ToastBody title={t('login:error:email_password')} subtitle={t('commons:try_again')} />)
        },
        onSuccess: data => setAuthData(data)
    })

    const onSubmit: SubmitHandler<LoginValidationSchema> = data => {
        login(data)
    }

    return (
        <Container>
            <AuthWrapper direction="column" align="center" justify="center">
                <AuthContent direction="column" align="center" gap={6}>
                    <img src={Logo} alt={'logo'} width={48} />
                    <Flexbox direction={'column'} gap={2} align={'center'}>
                        <AuthTitle>{t('login:title')}</AuthTitle>
                        <AuthSubTitle>{t('login:subtitle')}</AuthSubTitle>
                    </Flexbox>
                    <AuthForm onSubmit={handleSubmit(onSubmit)}>
                        <Flexbox direction={'column'} gap={6} align={'stretch'}>
                            <Flexbox direction={'column'} gap={5}>
                                <InputText
                                    label={t(LOGIN_FORM_MODEL.email.label)}
                                    type={'email'}
                                    placeholder={t(LOGIN_FORM_MODEL.email.placeholder)}
                                    errorMessage={t(errors.email?.message || '')}
                                    touched={touchedFields.email}
                                    disabled={isPending}
                                    {...register(t(LOGIN_FORM_MODEL.email.name))}
                                />
                                <InputText
                                    label={t(LOGIN_FORM_MODEL.password.label)}
                                    type={'password'}
                                    placeholder={t(LOGIN_FORM_MODEL.password.placeholder)}
                                    errorMessage={t(errors.password?.message || '')}
                                    touched={touchedFields.password}
                                    helpText={t(LOGIN_FORM_MODEL.password.helpText)}
                                    visibilityToggle={true}
                                    disabled={isPending}
                                    {...register(t(LOGIN_FORM_MODEL.password.name))}
                                />
                            </Flexbox>
                            <Flexbox justify={'end'}>
                                <StyledForgotPasswordLink
                                    tabIndex={-1}
                                    variant="tertiary"
                                    to={routes.FORGOT_PASSWORD.path}
                                    disabled={isPending}
                                >
                                    {t('login:secondary_cta')}
                                </StyledForgotPasswordLink>
                            </Flexbox>
                            <Button fullWidth variant={'primary'} size={'lg'} type={'submit'} disabled={isPending}>
                                {t('login:primary_cta')}
                            </Button>
                        </Flexbox>
                    </AuthForm>
                </AuthContent>
            </AuthWrapper>
        </Container>
    )
}

Login.displayName = 'Login'
