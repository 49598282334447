import {z} from 'zod'

export type Percentage = `${number}%`

export type ErrorResponseData = {
    message: string
}

export const OrderDirection = z.enum(['asc', 'desc'])
export type OrderDirection = z.infer<typeof OrderDirection>

export const ResponseType = z.enum(['backofficeSmall', 'backofficeRegular', 'regular', 'small'])
export type ResponseType = z.infer<typeof ResponseType>

export const ResponseTypeParam = z.object({responseType: ResponseType})
export type ResponseTypeParam = z.infer<typeof ResponseTypeParam>

export const RangeDateLabels = z
    .enum(['last_week', 'last_month', 'last_3_months', 'last_6_months', 'last_year'])
    .default('last_week')
export type RangeDateLabels = z.infer<typeof RangeDateLabels>

export const RangeDate = z.object({
    startDate: z.string().optional(),
    endDate: z.string().optional()
})
export type RangeDate = z.infer<typeof RangeDate>

export const PaginationParams = z.object({
    page: z.number().default(1),
    limit: z.number().optional(),
    responseType: ResponseType.optional()
})
export type PaginationParams = z.infer<typeof PaginationParams>

export const PaginationResponse = z.object({
    total: z.number(),
    last_page: z.number(),
    per_page: z.number(),
    current_page: z.number(),
    next_page_url: z.string().nullish()
})
export type PaginationResponse = z.infer<typeof PaginationResponse>

export const SelectValue = z
    .object({
        label: z.string(),
        value: z.string() || z.number()
    })
    .nullable()
export type SelectValue = z.infer<typeof SelectValue>

export const Partitions = z.object({
    label: z.string(),
    total: z.number(),
    percentage: z.number().nullable()
})

export type Partitions = z.infer<typeof Partitions>
