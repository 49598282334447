import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'
//translations
import it from './it/it.json'

import 'dayjs/locale/it'
import 'dayjs/locale/en'

export const APP_LANGUAGES = [{label: 'Italiano (IT)', code: 'it', flagCode: 'it', json: it}] as const

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: Object.fromEntries(APP_LANGUAGES.map(language => [language.code, language.json])),
        ns: [],
        defaultNS: '',
        keySeparator: false,
        fallbackLng: APP_LANGUAGES[0].code,
        debug: false,
        react: {
            useSuspense: false
        },
        supportedLngs: APP_LANGUAGES.map(language => language.code),
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['localStorage', 'navigator'],
            lookupLocalStorage: 'i18nextLng'
        }
    })

export const changeAppLanguage = async (nextLanguage: (typeof APP_LANGUAGES)[number]['code']) => {
    await i18n.changeLanguage(nextLanguage)
}

export default i18n
