import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

type StyledItemContainerProps = {
    $isSingleDoctorView: boolean
}
export const StyledItemContainer = styled.div<StyledItemContainerProps>`
    ${({$isSingleDoctorView}) => css`
        display: grid;
        grid-template-columns: 232px auto 1fr;
        grid-template-rows: 1fr;
        width: 100%;
        height: ${$isSingleDoctorView && '100vh'};
    `}
`

export const StyledDoctorInfo = styled(Flexbox)`
    ${({theme: {typography, palette, spacing}}) => css`
        padding: ${spacing * 7.5}px ${spacing * 3}px;
        gap: ${spacing * 2}px;
        background-color: ${palette.primary[200]};
        border-radius: ${spacing * 3}px 0 0 ${spacing * 3}px;
        min-height: 382px;

        & p {
            ${typography.textMd}
            font-weight: 600;
            color: ${palette.neutral[900]};
        }

        & span {
            ${typography.textXs}
            color: ${palette.neutral[700]};
        }
    `}
`

export const StyledContainerOfAvailableHours = styled(Flexbox)`
    ${({theme: {palette, spacing}}) => css`
        border: 1px solid ${palette.neutral[200]};
        padding: ${spacing * 2}px 0;
    `}
`

export const StyledAvailableHours = styled(Flexbox)`
    ${({theme: {typography, palette, spacing}}) => css`
        padding: 0 ${spacing * 2.5}px;
        color: ${palette.neutral[500]};
        ${typography.textXs}
    `}
`

export const StyledSlotsContainer = styled.div`
    ${() => css`
        height: 100%;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
    `}
`

export const StyledSlotWrapper = styled.div`
    ${() => css`
        text-align: center;
        flex-grow: 1;
    `}
`

type StyledSlotProps = {
    $isFilled: boolean
    $isSingleDoctorView: boolean
}

export const StyledSlot = styled(Flexbox)<StyledSlotProps>`
    ${({theme: {palette, spacing}, $isFilled, $isSingleDoctorView}) => css`
        height: ${$isSingleDoctorView ? '68px' : '35px'};
        border: 1px solid ${palette.neutral[200]};
        background: ${$isFilled ? palette.neutral.white : palette.neutral[100]};
        padding: ${$isSingleDoctorView ? spacing * 2.5 : spacing}px;
    `}
`

export const StyledEmptyState = styled.div`
    ${({theme: {spacing}}) => css`
        .empty-state {
            height: 100%;
            border-radius: 0 ${spacing * 3}px ${spacing * 3}px 0;
            border-left: none;
        }
    `}
`

export const StyledScrollableContainer = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        overflow-y: auto;
        max-height: calc(
            100vh - 350px
        ); /* Adjust based on the height of the fixed header and any other fixed elements */
        padding-bottom: ${spacing * 5}px;

        &::-webkit-scrollbar {
            width: 0;
            background: transparent;
        }
    `}
`

export const StyledAvailabilitySlotTrigger = styled.span`
    ${({theme: {typography, palette}}) => css`
        ${typography.textSm}
        font-weight: 500;
        color: ${palette.neutral[600]};
        cursor: pointer;
    `}
`

export const StyledDeleteShiftTitle = styled.span`
    ${({theme: {typography, palette}}) => css`
        ${typography.textXl} !important;
        font-weight: 500;
        color: ${palette.neutral[900]};
    `}
`

export const StyledDeleteShiftDescription = styled.span`
    ${({theme: {typography, palette}}) => css`
        ${typography.textSm}
        color: ${palette.neutral[900]};
    `}
`
