import {z} from 'zod'

export const PERSONAL_INFO_FORM_MODEL = {
    title: {
        name: 'title',
        label: 'settings:form:title',
        placeholder: 'settings:form:title_placeholder',
        defaultValue: '',
        helpText: ''
    },
    lastName: {
        name: 'lastName',
        label: 'settings:form:surname',
        placeholder: 'settings:form:surname'
    },
    firstName: {
        name: 'firstName',
        label: 'settings:form:name',
        placeholder: 'settings:form:name'
    },
    email: {
        name: 'email',
        label: 'settings:form:email',
        placeholder: 'settings:form:email_placeholder',
        defaultValue: '',
        helpText: ''
    },
    phoneNumber: {
        name: 'phoneNumber',
        label: 'settings:form:telephone',
        placeholder: 'settings:form:telephone_placeholder',
        defaultValue: '',
        helpText: ''
    },
    occupation: {
        name: 'occupation',
        label: 'settings:form:job',
        placeholder: 'settings:form:job_placeholder',
        defaultValue: '',
        helpText: ''
    },
    role: {
        name: 'role',
        label: 'settings:form:role',
        placeholder: 'settings:form:role_placeholder',
        defaultValue: '',
        helpText: ''
    }
} as const

export const PersonalInfoValidationSchema = z.object({
    title: z.string().nullish(),
    lastName: z.string().nullish(),
    firstName: z.string().nullish(),
    email: z.string().email({message: 'errors:email_valid'}).nullish(),
    phoneNumber: z.string().nullish(),
    occupation: z.string().nullish(),
    role: z.string().nullish()
})

export type PersonalInfoValidationSchema = z.infer<typeof PersonalInfoValidationSchema>

export const PASSWORD_MANAGEMENT_FORM_MODEL = {
    currentPassword: {
        name: 'currentPassword',
        label: 'profile:form:old_password',
        placeholder: 'profile:form:old_password_placeholder',
        defaultValue: '',
        helpText: ''
    },
    password: {
        name: 'password',
        label: 'profile:form:new_password',
        placeholder: 'profile:form:new_password_placeholder'
    },
    passwordConfirmation: {
        name: 'passwordConfirmation',
        label: 'profile:form:confirm_password',
        placeholder: 'profile:form:confirm_password_placeholder'
    }
} as const

export const PasswordManagementValidationSchema = z
    .object({
        currentPassword: z.string().min(6, {message: 'errors:password'}),
        password: z.string().min(6, {message: 'errors:password'}),
        passwordConfirmation: z.string().min(6, {message: 'errors:password'})
    })
    .superRefine((data, ctx) => {
        if (data.password === data.currentPassword) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'errors:same_as_old_password',
                path: ['new_password']
            })
        } else if (data.passwordConfirmation && data.password !== data.passwordConfirmation) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'errors:password_match',
                path: ['new_password']
            })
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'errors:password_match',
                path: ['confirm_password']
            })
        } else {
            return data
        }
    })

export type PasswordManagementValidationSchema = z.infer<typeof PasswordManagementValidationSchema>
