import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'
import {DefaultTheme} from 'styled-components/dist/types'

export const StyledCheckInStatsGrid = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: ${spacing * 6}px;
        width: 100%;
    `
)

export const StyledFilledOutPrimaryPercentage = styled.div(
    ({theme: {typography}}) => css`
        ${typography.displaySm}
    `
)

export const makeFilledOutPercentStyle = ({palette}: DefaultTheme) =>
    ({
        over: css`
            color: ${palette.success[600]};
        `,
        under: css`
            color: ${palette.danger[600]};
        `,
        equal: css`
            color: ${palette.neutral[400]};
        `
    }) as const satisfies Record<NonNullable<'over' | 'under' | 'equal'>, ReturnType<typeof css>>

export const StyledCheckInFilledOutPercentResult = styled(Flexbox)<{$variant: 'over' | 'under' | 'equal'}>(
    ({theme, $variant}) => css`
        ${theme.typography.textSm};
        ${makeFilledOutPercentStyle(theme)[$variant]};
    `
)

export const StyledCheckInCompareLabel = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        color: ${palette.neutral['600']};
    `
)

export const StyledMoodGrid = styled.div(
    ({theme: {spacing}}) => css`
        gap: ${spacing * 4}px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    `
)
export const CheckInStatsMoneyListItemValue = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        ${typography.textXl};
        font-weight: 600;
        color: ${palette.neutral[900]};
    `
)

export const CheckInStatsMoneyListItemLabel = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        font-weight: 600;
        color: ${palette.neutral[600]};
    `
)
export const StyledMoodPercentage = styled.h4(
    ({theme: {palette, typography}}) => css`
        ${typography.textXl};
        font-weight: 600;
        color: ${palette.neutral[900]};
    `
)

export const StyledBetsGrid = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 45px auto;
        gap: ${spacing * 8}px;
        align-items: center;
    `
)
