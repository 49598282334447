import {FC, ReactNode} from 'react'
import {StyledSwitchRoot, StyledSwitchThumb, StyledSwitchWrapper} from '@/components/ui/switch/style'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Label} from '@components/ui/label/Label.tsx'

interface SwitchProps {
    className?: string
    label?: string
    icon?: ReactNode
    labelPosition?: 'left' | 'right'
    disabled?: boolean
    defaultChecked?: boolean
    checked?: boolean
    onChange?: (checked: boolean) => void
    description?: string
}

export const Switch: FC<SwitchProps> = ({
    label,
    icon,
    className,
    labelPosition = 'right',
    disabled,
    defaultChecked = false,
    checked,
    onChange,
    description
}) => {
    return (
        <StyledSwitchWrapper
            className={className}
            align="center"
            gap={2}
            direction={labelPosition === 'right' ? 'row' : 'row-reverse'}
        >
            <StyledSwitchRoot
                id={label}
                disabled={disabled}
                defaultChecked={defaultChecked}
                checked={checked}
                onCheckedChange={value => onChange && onChange(value)}
            >
                <StyledSwitchThumb />
            </StyledSwitchRoot>

            <Flexbox direction="column" gap={1}>
                <Flexbox align="center" gap={2}>
                    {icon}
                    {label && <Label htmlFor={label}>{label}</Label>}
                </Flexbox>
                {description && <InputHelpText helpText={description} />}
            </Flexbox>
        </StyledSwitchWrapper>
    )
}

Switch.displayName = 'Switch'
