import {MUTATION_KEYS, QUERY_KEYS, queryClient} from '@/queryClient'
import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {httpAddPatient, HttpAddPatientParams} from '@/features/patient-form/services/patientForm.http'

export const useAddPatient = (
    options?: Omit<
        UseMutationOptions<unknown, AxiosError<{code: string}>, HttpAddPatientParams>,
        'mutation' | 'mutationKey'
    >
) =>
    useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.ADD_PATIENT],
        mutationFn: httpAddPatient,
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PATIENTS]})
            options?.onSuccess?.(data, variables, context)
        },
        onError: (data, variables, context) => {
            options?.onError?.(data, variables, context)
        }
    })
