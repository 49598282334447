import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {useCallback, useEffect, useRef, useState} from 'react'
import {SELECTION_CHANGE_COMMAND, FORMAT_TEXT_COMMAND, $getSelection, $isRangeSelection} from 'lexical'
import {$getNearestNodeOfType, mergeRegister} from '@lexical/utils'
import {
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    REMOVE_LIST_COMMAND,
    $isListNode,
    ListNode
} from '@lexical/list'
import {$isHeadingNode} from '@lexical/rich-text'
import {Bold01Icon, ListIcon, OrderedList} from '@components/ui/icon/Icon.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {StyledHeaderRichTextAreaButton} from '@components/ui/rich-text-area/style.ts'

const LowPriority = 1

export const ToolbarPlugin = () => {
    const [editor] = useLexicalComposerContext()
    const toolbarRef = useRef(null)
    const [blockType, setBlockType] = useState('paragraph')
    const [isBold, setIsBold] = useState(false)

    const updateToolbar = useCallback(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode()
            const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow()
            const elementKey = element.getKey()
            const elementDOM = editor.getElementByKey(elementKey)
            if (elementDOM !== null) {
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode)
                    const type = parentList ? parentList.getTag() : element.getTag()
                    setBlockType(type)
                } else {
                    const type = $isHeadingNode(element) ? element.getTag() : element.getType()
                    setBlockType(type)
                }
            }
            // Update text format
            setIsBold(selection.hasFormat('bold'))
        }
    }, [editor])

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({editorState}) => {
                editorState.read(() => {
                    updateToolbar()
                })
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateToolbar()
                    return false
                },
                LowPriority
            )
        )
    }, [editor, updateToolbar])

    const formatBulletList = () => {
        if (blockType !== 'ul') {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
        }
    }

    const formatNumberedList = () => {
        if (blockType !== 'ol') {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
        }
    }

    return (
        <div ref={toolbarRef}>
            <Flexbox align={'center'} gap={1}>
                <StyledHeaderRichTextAreaButton
                    $isActive={isBold}
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
                    }}
                    aria-label="Format Bold"
                >
                    <Bold01Icon />
                </StyledHeaderRichTextAreaButton>
                <StyledHeaderRichTextAreaButton $isActive={blockType === 'ul'} onClick={formatBulletList}>
                    <ListIcon />
                </StyledHeaderRichTextAreaButton>
                <StyledHeaderRichTextAreaButton $isActive={blockType === 'ol'} onClick={formatNumberedList}>
                    <OrderedList />
                </StyledHeaderRichTextAreaButton>
            </Flexbox>
        </div>
    )
}
