import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    StyledEmptyStateWrapper,
    StyledTimeLineCircle,
    StyledTimeLineDivider,
    StyledTimeLineItems,
    StyledTimeLineItemTitle
} from '@/features/patient-details/components/daily-time-line/style.ts'
import {useGetTimeLine} from '@/features/patient-details/services/useGetTimeLine.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {DiaryDateSelectorUrlParams, PatientDetailsURLParams, TimeLine} from '@/features/patient-details/types.ts'
import dayjs from '@/dayjs.ts'
import {useOutletContext} from 'react-router-dom'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {ErrorIcon} from 'react-hot-toast'
import {useTranslation} from 'react-i18next'
import {dailyActivityLabelsDictionary} from '@/features/patient-details/utils.ts'
import {formatCurrency} from '@utilities/helpers.ts'
import {MoodIcon} from '@components/commons/mood-icon/MoodIcon.tsx'
import {useGetEmotion} from '@/features/patient-details/services/useGetEmotion.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'

export const DailyTimeLine = () => {
    const {t} = useTranslation()
    const {searchParams} = useParsedSearchParams([], DiaryDateSelectorUrlParams)
    const {urlParams} = useOutletContext<{urlParams: PatientDetailsURLParams}>()
    const emotionQuery = useGetEmotion()

    const timelineQuery = useGetTimeLine({
        patientId: urlParams.patientId,
        fromDate: dayjs(searchParams.selectedDate).format('YYYY-MM-DD') || dayjs().format('YYYY-MM-DD'),
        toDate: dayjs(searchParams.selectedDate).format('YYYY-MM-DD') || dayjs().format('YYYY-MM-DD')
    })

    const renderItem = (timeline: TimeLine) => {
        if (timeline.type === 'emotion') {
            const emotion = emotionQuery.data?.data.find(value => value.id === timeline.data.emotionId)
            if (timeline.data.userFormId) {
                return (
                    <Flexbox direction={'column'} gap={1}>
                        <StyledTimeLineItemTitle>
                            {t('patient_details:diary_tab:timeline:add_check_in')}
                        </StyledTimeLineItemTitle>
                        {emotion && (
                            <MoodIcon variant={emotion.name} label={t(`commons:mood:${emotion.name}`)} hasBackground />
                        )}
                    </Flexbox>
                )
            }
            return (
                <Flexbox direction={'column'} gap={1}>
                    <StyledTimeLineItemTitle>
                        {t(dailyActivityLabelsDictionary[timeline.action][timeline.type])}
                    </StyledTimeLineItemTitle>
                    {emotion && (
                        <MoodIcon variant={emotion.name} label={t(`commons:mood:${emotion.name}`)} hasBackground />
                    )}
                </Flexbox>
            )
        }

        return (
            <StyledTimeLineItemTitle>
                {t(dailyActivityLabelsDictionary[timeline.action][timeline.type], {
                    amount: timeline.data.amount && `€ ${formatCurrency(timeline.data.amount, 'eur')}`
                })}
            </StyledTimeLineItemTitle>
        )
    }
    return (
        <>
            {timelineQuery.isLoading ? (
                <Flexbox direction={'column'} gap={1}>
                    {Array.from({length: 4}).map((_, i) => (
                        <StyledTimeLineItems key={i}>
                            <Skeleton height={24} />
                            <Flexbox direction={'column'} gap={1} align={'center'}>
                                <StyledTimeLineCircle />
                                <StyledTimeLineDivider className={'time-line-divider'} direction={'vertical'} />
                            </Flexbox>
                            <Skeleton height={24} width={234} />
                        </StyledTimeLineItems>
                    ))}
                </Flexbox>
            ) : timelineQuery.isError ? (
                <ErrorBox icon={<ErrorIcon />} title={t('errors:default')} />
            ) : timelineQuery.data && timelineQuery.data[0].timeline.length !== 0 ? (
                <Flexbox direction={'column'} gap={1}>
                    {timelineQuery.data[0].timeline.map((data, i) => (
                        <StyledTimeLineItems key={i}>
                            <p>{dayjs(data.datetime).format('HH:MM')}</p>
                            <Flexbox direction={'column'} gap={1} align={'center'}>
                                <StyledTimeLineCircle />
                                <StyledTimeLineDivider className={'time-line-divider'} direction={'vertical'} />
                            </Flexbox>
                            {renderItem(data)}
                        </StyledTimeLineItems>
                    ))}
                </Flexbox>
            ) : (
                <StyledEmptyStateWrapper direction={'column'} gap={2} align={'center'}>
                    <h3>{t('patient_details:diary_tab:no_info')}</h3>
                    <p>{t('patient_details:diary_tab:no_info_activity')}</p>
                </StyledEmptyStateWrapper>
            )}
        </>
    )
}
