import styled, {css} from 'styled-components'
import {spacing} from '@/theme/theme.ts'
import {HTMLAttributes} from 'react'

export const DESKTOP_TABLE_COLUMNS_PADDING = `${spacing * 4}px`
export const DESKTOP_TABLE_SCROLLBAR_WIDTH = '4px'

interface StyledDesktopTableProps extends HTMLAttributes<HTMLDivElement> {
    $isHeaderVisible?: boolean
}

// Use the interface for the props type in styled component
export const StyledDesktopTable = styled.div<StyledDesktopTableProps>(
    ({theme: {palette}, $isHeaderVisible}) => css`
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: auto 1fr;
        border: ${$isHeaderVisible ? `1px solid ${palette.neutral[200]}` : 'none'};
        border-radius: 12px;
        background: ${palette.neutral.white};
    `
)
