import {ComponentProps, CSSProperties, HTMLAttributes, ReactNode} from 'react'
import {StyledFlexbox} from '@components/ui/flexbox/style.ts'
import {SupportedHTMLElements} from 'styled-components/dist/types'

export type FlexboxProps<T extends SupportedHTMLElements> = {
    render?: T
    className?: string
    children?: ReactNode
    justify?: CSSProperties['justifyContent']
    align?: CSSProperties['alignItems']
    direction?: CSSProperties['flexDirection']
    gap?: number
    fullWidth?: boolean
} & HTMLAttributes<T>

export const Flexbox = <T extends SupportedHTMLElements>({
    children,
    className,
    justify,
    align,
    direction,
    gap,
    render,
    fullWidth,
    ...rest
}: FlexboxProps<T>) => {
    return (
        <StyledFlexbox
            {...(rest as Omit<ComponentProps<T>, keyof FlexboxProps<T> | 'as' | 'ref'>)}
            as={render ?? 'div'}
            className={className}
            $justify={justify}
            $align={align}
            $direction={direction}
            $gap={gap}
            $fullWidth={fullWidth}
        >
            {children}
        </StyledFlexbox>
    )
}
Flexbox.displayName = 'Flexbox'
