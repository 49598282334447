import styled, {css} from 'styled-components'

export const StyledModalHeader = styled.div(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 2.5}px ${spacing * 2}px;
        border-bottom: 1px solid ${palette.neutral['300']};
    `
)

export const StyledModalBody = styled.div(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 6}px;
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 6}px;
    `
)

export const StyledModalFooter = styled.div(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;
        border-top: 1px solid ${palette.neutral['300']};
        display: flex;
        justify-content: space-between;
    `
)

export const StyledModalTitle = styled.h3(
    ({theme: {typography}}) => css`
        ${typography.textXl};
        font-weight: 500;
        & b {
            font-weight: 700;
        }
    `
)

export const StyledModalParagraph = styled.p(
    ({theme: {typography}}) => css`
        ${typography.textSm};
    `
)
