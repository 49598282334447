import styled, {css} from 'styled-components'
import {ContentEditable} from '@lexical/react/LexicalContentEditable'

type StyledTextareaButtonProps = {
    $isActive?: boolean
}

export const StyledRichTextArea = styled.div<{$isEditEnabled?: boolean}>(
    ({theme, $isEditEnabled}) => css`
        overflow: auto;
        max-height: 200px;
        position: relative;
        ${$isEditEnabled
            ? css`
                  background-color: ${theme.palette.neutral.white};
              `
            : css`
                  background-color: transparent;
                  max-height: 200px;
                  overflow: auto;
              `}
    `
)

export const StyledContentEditable = styled(ContentEditable)`
    ${({theme: {typography, palette}}) => css`
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        user-modify: read-write;
        color: ${palette.neutral['900']};
        ${typography.textMd}
        user-modify: read-write;
        & > * {
            all: revert;
            user-modify: read-write;
            margin: 0;
        }
        & strong {
            all: revert;
        }
        &:focus {
            outline: none;
        }

        & p {
            &:hover {
                border: none !important;
                box-shadow: none !important;
            }
        }
    `}
`

export const StyledPlaceholder = styled.div`
    ${({theme: {palette}}) => css`
        overflow: hidden;
        position: absolute;
        text-overflow: ellipsis;
        top: 77px;
        left: 15px;
        font-size: 15px;
        user-select: none;
        display: inline-block;
        color: ${palette.neutral[400]};
        pointer-events: none;
    `}
`

export const StyledRichTextareaWrapper = styled.div<{$isEditEnabled?: boolean}>`
    ${({theme: {spacing, typography}, $isEditEnabled}) => css`
        padding: ${spacing * 3}px ${spacing * 3.5}px;
        ${typography.textMd}
        pointer-events: ${$isEditEnabled ? 'all' : 'none'}
    `}
`
export const StyledHeaderRichTextArea = styled.div`
    ${({theme: {spacing, palette}}) => css`
        padding: ${spacing * 4}px;
        position: sticky;
        top: -1px;
        background: ${palette.neutral.white};
    `}
`

export const StyledHeaderRichTextAreaButton = styled.button<StyledTextareaButtonProps>`
    ${({$isActive, theme: {palette, spacing}}) => css`
        ${$isActive
            ? css`
                  background: ${palette.neutral['400']};
              `
            : css`
                  background: ${palette.neutral['50']};
              `};
        width: 32px;
        height: 32px;
        cursor: pointer;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: ${spacing * 2}px;
    `}
`
export const StyledRichTextContainer = styled.div`
    ${({theme}) => css`
        width: 100%;
        height: 100%;
        max-height: 320px;
        background: ${theme.palette.neutral[50]};
        padding: ${theme.spacing * 6}px;
        border: 1px solid ${theme.palette.neutral[200]};
        border-bottom-left-radius: ${theme.spacing * 3}px;
        border-bottom-right-radius: ${theme.spacing * 3}px;
        border-top: none;

        display: grid;
        grid-template-columns: 1fr 90px;
        gap: ${theme.spacing * 2}px;
    `}
`
