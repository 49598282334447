import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Link} from 'react-router-dom'
import styled, {css} from 'styled-components'

export const StyledPatientTableLink = styled(Link)(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        color: ${palette.primary[600]};
        text-decoration: underline;
        cursor: pointer;
    `
)

export const StyledPatientTableId = styled.p(
    ({theme}) => css`
        color: ${theme.palette.neutral[500]};
        font-weight: 400;
    `
)

export const StyledPatientCaregiver = styled(Flexbox)<{$hasCaregiver: boolean}>(
    ({theme: {palette}, $hasCaregiver}) => css`
        color: ${$hasCaregiver ? palette.success[600] : palette.danger[600]};
    `
)

export const StyledPatientsTabelNoData = styled.span(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        color: ${palette.neutral[600]};
    `
)
