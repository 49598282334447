import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledPatientsCardsStats = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: ${spacing * 6}px;
        width: 100%;
    `
)

export const StyledPatientsStatsNewAmount = styled.div(
    ({theme: {typography}}) => css`
        ${typography.displaySm}
    `
)

export const PatientsStatsAgesListItemValue = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        ${typography.textXl};
        font-weight: 600;
        color: ${palette.neutral[900]};
    `
)

export const PatientsStatsAgesListItemLabel = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        font-weight: 600;
        color: ${palette.neutral[600]};
    `
)

export const PatientsStatsWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        gap: ${spacing * 8}px;
        grid-template-columns: 80px auto;
        align-items: center;
    `
)
