import {Popover} from '@components/ui/popover/Popover.tsx'
import {DotsVerticalIcon} from '@components/ui/icon/Icon.tsx'
import {FC, ReactNode, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledActionsMenuButton} from '@/features/settings/components/actions-menu/style.ts'
import {ButtonStyleProps} from '@components/ui/button/Button.tsx'

interface ActionsMenuProps {
    actions: {
        type: string
        label: string
        icon?: ReactNode
        color?: ButtonStyleProps['variant']
        disabled?: boolean
    }[]
    onActionClick: (type: string, selectedItem?: number) => void
    selectedItem?: number
}

export const ActionsMenu: FC<ActionsMenuProps> = ({actions, onActionClick, selectedItem}) => {
    const {t} = useTranslation()
    const [open, setOpen] = useState(false)

    return (
        <Popover rootProps={{open: open}} trigger={<DotsVerticalIcon onClick={() => setOpen(!open)} />}>
            {actions.map((action, index) => (
                <StyledActionsMenuButton
                    variant={action?.color ? action.color : 'tertiary'}
                    key={index}
                    onClick={() => {
                        onActionClick(action.type, selectedItem)
                        setOpen(false)
                    }}
                    disabled={action.disabled}
                >
                    {action.icon && action.icon}
                    <p>{t(action.label)}</p>
                </StyledActionsMenuButton>
            ))}
        </Popover>
    )
}
