import {DeleteModal} from '@components/commons/delete-modal/DeleteModal.tsx'
import {useTranslation} from 'react-i18next'
import {FC} from 'react'
import {StyledDeleteParagraph} from '@/features/settings/components/delete-user-modal/style.ts'
import {User} from '@/features/auth/types.ts'

interface DeleteUserModalProps {
    onClose: () => void
    selectedUser: User | null
    onDelete: () => void
    isLoading?: boolean
}
export const DeleteUserModal: FC<DeleteUserModalProps> = ({onClose, selectedUser, onDelete, isLoading}) => {
    const {t} = useTranslation()
    return (
        <DeleteModal
            modalTitle={'settings:delete_modal:title'}
            onClose={onClose}
            onDelete={onDelete}
            isLoading={isLoading ?? false}
            deleteLabel={t('settings:actions:delete')}
        >
            <StyledDeleteParagraph direction={'column'} gap={2.5}>
                <p className={'description'}>{t('settings:delete_modal:description')}</p>

                <p className={'information'}>
                    #{selectedUser?.id} {selectedUser?.firstName} {selectedUser?.lastName}
                </p>

                <p className={'paragraph'}>{t('settings:delete_modal:paragraph')}</p>
            </StyledDeleteParagraph>
        </DeleteModal>
    )
}
