import {MUTATION_KEYS} from '@/queryClient.ts'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {httpForgotPassword} from '@/features/auth/services/auth.http.ts'
import {ForgotPasswordValidationSchema} from '@/pages/forgot-password/ForgotPasswordModel'
import {ErrorResponseData} from '@/types'

type useLoginProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useForgotPassword = (options?: useLoginProps) =>
    useMutation({
        mutationKey: [MUTATION_KEYS.FORGOT_PASSWORD],
        mutationFn: (params: ForgotPasswordValidationSchema) => httpForgotPassword(params),
        onError: (error: AxiosError<ErrorResponseData>) => options?.onError?.(error),
        onSuccess: () => options?.onSuccess?.()
    })
