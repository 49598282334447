import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'
import {httpGetGendersTrend, HttpGetGendersTrendResponse} from '@/features/dashboard/services/dashboard.http.ts'
import {DashboardUrlParamsExtended} from '@/features/dashboard/types.ts'
import {getDateFromLabel} from '@utilities/utils.ts'

export const useGetGendersTrend = (
    promiseArgs: DashboardUrlParamsExtended,
    options?: Omit<UseQueryOptions<HttpGetGendersTrendResponse>, 'queryFn' | 'queryKey'>
) => {
    const {rangeDateLabels, ...rest} = promiseArgs
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.GENDERS_TREND, promiseArgs],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetGendersTrend({
                    ...rest,
                    ...getDateFromLabel(rangeDateLabels)
                }),
                responseShape: HttpGetGendersTrendResponse,
                onZodError: captureException
            })
    })
}
