import {z} from 'zod'
import {ResponseType} from '@/types.ts'

const Article = z.object({
    id: z.number(),
    title: z.object({
        rendered: z.string()
    }),
    date: z.string(),
    excerpt: z.object({
        rendered: z.string()
    }),
    link: z.string(),
    _embedded: z.object({
        'wp:featuredmedia': z.array(
            z.object({
                source_url: z.string()
            })
        )
    }),
    tags: z.array(z.number())
})
export type Article = z.infer<typeof Article>

export const HttpGetArticlesOptions = z.object({
    responseType: ResponseType.optional()
})

export type HttpGetArticlesOptions = z.infer<typeof HttpGetArticlesOptions>
export const HttpGetArticlesResponse = z.array(Article)
