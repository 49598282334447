import styled, {css, DefaultTheme} from 'styled-components'
import {ButtonStyleProps} from '@components/ui/button/Button.tsx'

export type StyledButtonProps = {
    $fullWidth?: boolean
    $shape?: 'rectangle' | 'square'
    $size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    $variant?: ButtonStyleProps['variant']
    $shouldWrap?: boolean
}

export const makeButtonBaseStyle = (
    theme: DefaultTheme,
    {$fullWidth, $shape, disabled, $shouldWrap}: StyledButtonProps & {disabled: boolean}
) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-weight: 600;
    word-break: break-word;
    flex-shrink: ${$shouldWrap ? 1 : 0};
    ${theme.transition};
    ${$shape == 'rectangle' &&
    $fullWidth &&
    css`
        width: 100%;
    `}
    ${$shape == 'square' &&
    css`
        flex-shrink: 0;
    `}
  ${disabled
        ? css`
              cursor: not-allowed;
          `
        : css`
              cursor: pointer;
              &:focus-visible {
                  box-shadow: ${`${theme.shadows.xs}, 0px 0px 0px 4px ${theme.palette.neutral['100']}`};
              }
          `}
`

export const makeButtonSizeStyle = (theme: DefaultTheme) =>
    ({
        xs: {
            rectangle: css`
                gap: ${theme.spacing * 2}px;
                padding: ${theme.spacing * 1.25}px ${theme.spacing * 2.5}px;
                ${theme.typography.textXs};
                & svg {
                    width: 14px;
                    height: 14px;
                }
            `,
            square: css`
                width: 28px;
                height: 28px;
                & svg {
                    width: 14px;
                    height: 14px;
                }
            `
        },
        sm: {
            rectangle: css`
                gap: ${theme.spacing * 2}px;
                padding: ${theme.spacing * 2}px ${theme.spacing * 3.5}px;
                ${theme.typography.textSm};
                & svg {
                    width: 20px;
                    height: 20px;
                }
            `,
            square: css`
                width: 36px;
                height: 36px;
                & svg {
                    width: 20px;
                    height: 20px;
                }
            `
        },
        md: {
            rectangle: css`
                gap: ${theme.spacing * 2}px;
                padding: ${theme.spacing * 2.5}px ${theme.spacing * 4}px;
                ${theme.typography.textSm};
                font-weight: 600;
                & svg {
                    width: 20px;
                    height: 20px;
                }
            `,
            square: css`
                width: 40px;
                height: 40px;
                & svg {
                    width: 20px;
                    height: 20px;
                }
            `
        },
        lg: {
            rectangle: css`
                gap: ${theme.spacing * 2}px;
                padding: ${theme.spacing * 2.5}px ${theme.spacing * 4.5}px;
                ${theme.typography.textMd};
                & svg {
                    width: 20px;
                    height: 20px;
                }
            `,
            square: css`
                width: 44px;
                height: 44px;
                & svg {
                    width: 20px;
                    height: 20px;
                }
            `
        },
        xl: {
            rectangle: css`
                gap: ${theme.spacing * 2}px;
                padding: ${theme.spacing * 4}px ${theme.spacing * 7}px;
                ${theme.typography.textLg};
                & svg {
                    width: 20px;
                    height: 20px;
                }
            `,
            square: css`
                width: 60px;
                height: 60px;
                & svg {
                    width: 20px;
                    height: 20px;
                }
            `
        }
    }) as const satisfies Record<
        NonNullable<StyledButtonProps['$size']>,
        Record<NonNullable<StyledButtonProps['$shape']>, ReturnType<typeof css>>
    >

export const makeButtonVariantStyle = (theme: DefaultTheme, disabled: boolean) =>
    ({
        primary: css`
            color: ${theme.palette.neutral.white};
            box-shadow: ${theme.shadows.xs};
            ${disabled
                ? css`
                      background: ${theme.palette.primary['400']};
                  `
                : css`
                      background: ${theme.palette.primary['600']};
                      &:hover {
                          color: ${theme.palette.neutral.white};
                          background: ${theme.palette.primary['700']};
                      }
                      &:active,
                      &.active {
                          background: ${theme.palette.primary['800']};
                      }
                  `}
        `,
        primaryDanger: css`
            color: ${theme.palette.neutral.white};
            box-shadow: ${theme.shadows.xs};
            ${disabled
                ? css`
                      background: ${theme.palette.danger['400']};
                  `
                : css`
                      background: ${theme.palette.danger['600']};
                      &:hover {
                          color: ${theme.palette.neutral.white};
                          background: ${theme.palette.danger['700']};
                      }
                      &:active,
                      &.active {
                          background: ${theme.palette.danger['800']};
                      }
                  `}
        `,
        secondary: css`
            background: ${theme.palette.neutral.white};
            outline: 1px solid ${theme.palette.neutral['300']};
            outline-offset: -1px;
            box-shadow: ${theme.shadows.xs};
            ${disabled
                ? css`
                      color: ${theme.palette.neutral['400']};
                  `
                : css`
                      color: ${theme.palette.neutral['700']};
                      &:hover {
                          color: ${theme.palette.neutral['700']};
                          background: ${theme.palette.neutral['50']};
                      }
                      &:active,
                      &.active {
                          background: ${theme.palette.neutral['100']};
                      }
                  `}
        `,
        secondaryDanger: css`
            background: ${theme.palette.neutral.white};
            outline: 1px solid ${theme.palette.danger['300']};
            outline-offset: -1px;
            box-shadow: ${theme.shadows.xs};
            ${disabled
                ? css`
                      color: ${theme.palette.neutral['400']};
                  `
                : css`
                      color: ${theme.palette.danger['700']};
                      &:hover {
                          color: ${theme.palette.danger['800']};
                          background: ${theme.palette.danger['300']};
                      }
                      &:active,
                      &.active {
                          background: ${theme.palette.danger['100']};
                      }
                  `}
        `,
        secondaryColor: css`
            background: ${theme.palette.neutral.white};
            outline: 1px solid ${theme.palette.primary['300']};
            outline-offset: -1px;
            box-shadow: ${theme.shadows.xs};
            ${disabled
                ? css`
                      color: ${theme.palette.neutral['400']};
                  `
                : css`
                      color: ${theme.palette.primary['600']};
                      &:hover {
                          color: ${theme.palette.primary['800']};
                          background: ${theme.palette.primary['300']};
                      }
                      &:active,
                      &.active {
                          background: ${theme.palette.primary['100']};
                      }
                  `}
        `,
        tertiary: css`
            color: ${theme.palette.neutral['600']};
            ${disabled
                ? css`
                      color: ${theme.palette.neutral['400']};
                  `
                : css`
                      &:hover {
                          color: ${theme.palette.neutral['700']};
                      }
                  `}
        `,
        tertiaryDanger: css`
            color: ${theme.palette.danger['700']};
            ${disabled
                ? css`
                      color: ${theme.palette.neutral['400']};
                  `
                : css`
                      &:hover {
                          color: ${theme.palette.danger['800']};
                      }
                  `}
        `,

        tertiaryColor: css`
            color: ${theme.palette.primary['600']};
            ${disabled
                ? css`
                      color: ${theme.palette.neutral['400']};
                  `
                : css`
                      &:hover {
                          color: ${theme.palette.primary['700']};
                      }
                  `}
        `
    }) as const satisfies Record<NonNullable<StyledButtonProps['$variant']>, ReturnType<typeof css>>

export const StyledButton = styled.button<StyledButtonProps & {disabled?: boolean}>(
    ({theme, ...props}) => css`
        white-space: nowrap;
        ${makeButtonSizeStyle(theme)[props.$size ?? 'md'][props.$shape ?? 'rectangle']};
        ${makeButtonVariantStyle(theme, !!props.disabled)[props.$variant ?? 'primary']};
        ${makeButtonBaseStyle(theme, {
            $fullWidth: props.$fullWidth,
            $shape: props.$shape,
            $size: props.$size,
            disabled: !!props.disabled
        })};
    `
)
