import {useLocation} from 'react-router-dom'
import {FC, ReactNode} from 'react'
import {StyledIconWrapper, StyledItemName, StyledItemWrapper} from '@components/commons/sidebar-item/style.ts'
import {useTranslation} from 'react-i18next'

interface SidebarItemProps {
    item: {
        name: string
        path: string
        icon: ReactNode
    }
    isOnlyIcon?: boolean
}

export const SidebarItem: FC<SidebarItemProps> = ({item, isOnlyIcon = false}) => {
    const {t} = useTranslation()
    const location = useLocation()
    return (
        <StyledItemWrapper to={item.path} $isActive={location.pathname == item.path} variant={'tertiary'}>
            <StyledIconWrapper $isOnlyIcon={isOnlyIcon}>{item.icon}</StyledIconWrapper>
            <StyledItemName $isVisible={!isOnlyIcon}>{t(item.name)}</StyledItemName>
        </StyledItemWrapper>
    )
}
