import styled, {css} from 'styled-components'
import {Button} from '@components/ui/button/Button.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledContainerSlotsAvailabilityBody = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 6}px;

        & .title {
            font-weight: 600;
            ${typography.textMd}
            color: ${palette.neutral[900]};
        }

        & .description {
            ${typography.textSm}
            font-weight: 500;
            color: ${palette.neutral[700]};
        }
    `}
`
export const StyledPatientName = styled.p(
    () => css`
        text-decoration: underline;
    `
)

export const StyledInputTime = styled.input`
    ${({theme}) => css`
        border: 1px solid ${theme.palette.neutral[300]};
        border-radius: ${theme.spacing * 2}px;
        padding: ${theme.spacing}px ${theme.spacing * 4}px;
        color: ${theme.palette.neutral[900]};
        font-weight: 500;
        ${theme.typography.textSm}
        &::-webkit-calendar-picker-indicator {
            display: none;
        }
    `}
`

export const StyledWrapperAvailability = styled.div`
    ${({theme}) => css`
        max-height: 350px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing * 6}px;

        &::-webkit-scrollbar {
            display: none;
        }
    `}
`

export const StyledContainerAvailability = styled.div`
    ${({theme}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: ${theme.spacing * 4}px;
        align-items: flex-start;
    `}
`

export const StyledWrapperAvailableSlots = styled.div`
    ${({theme}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr 24px 24px;
        column-gap: ${theme.spacing * 2}px;
        align-items: center;
    `}
`

export const StyledDeleteButton = styled(Button)`
    ${() => css`
        margin-left: auto;
    `}
`

export const StyledContainerAppointmentForm = styled(Flexbox)`
    ${({theme}) => css`
        padding: ${theme.spacing * 6}px;
        gap: ${theme.spacing * 6}px;

        & .styled-switch {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            gap: ${theme.spacing * 2}px;

            & label {
                font-weight: 400;
                ${theme.typography.textSm}
            }
        }
    `}
`

type StyledContainerAppointmentDetailsProps = {
    $openRichTextArea: boolean
}
export const StyledContainerAppointmentDetails = styled(Flexbox)<StyledContainerAppointmentDetailsProps>`
    ${({theme, $openRichTextArea}) => css`
        padding: ${theme.spacing * 6}px;
        border: 1px solid ${theme.palette.neutral[200]};
        border-top-left-radius: ${theme.spacing * 3}px;
        border-top-right-radius: ${theme.spacing * 3}px;

        ${!$openRichTextArea &&
        css`
            border-bottom-left-radius: ${theme.spacing * 3}px;
            border-bottom-right-radius: ${theme.spacing * 3}px;
        `}
    `}
`

export const StyledSwitchIsRemoteHelpText = styled.div`
    ${({theme}) => css`
        font-weight: 400;
        ${theme.typography.textXs}
        color: ${theme.palette.neutral[700]};
        background: ${theme.palette.neutral[50]};
        border: 1px solid ${theme.palette.neutral[300]};
        border-radius: ${theme.spacing * 2}px;
        padding: ${theme.spacing}px ${theme.spacing * 2}px;
    `}
`
