import {z} from 'zod'
import {APPOINTMENT_NOTIFICATION} from '@utilities/constants/enum.ts'

export const EditPassword = z.object({
    currentPassword: z.string(),
    password: z.string(),
    passwordConfirmation: z.string()
})

export type EditPassword = z.infer<typeof EditPassword>

export const NOTIFICATION_TYPE = {
    new: 'new',
    deleted: 'deleted',
    updated: 'updated',
    reminder: 'reminder'
}

export const NOTIFICATION_REMINDER_MINUTES = {
    15: '15',
    30: '30',
    60: '60',
    120: '120'
}

export const NOTIFICATION_REMINDER = [
    {
        value: APPOINTMENT_NOTIFICATION.fifteen_minutes,
        label: NOTIFICATION_REMINDER_MINUTES[15]
    },
    {
        value: APPOINTMENT_NOTIFICATION.thirty_minutes,
        label: NOTIFICATION_REMINDER_MINUTES[30]
    },
    {value: APPOINTMENT_NOTIFICATION.one_hour, label: NOTIFICATION_REMINDER_MINUTES[60]},
    {value: APPOINTMENT_NOTIFICATION.two_hours, label: NOTIFICATION_REMINDER_MINUTES[120]}
]

export const NOTIFICATION_TYPE_OPTIONS = [
    {
        id: 1,
        type: NOTIFICATION_TYPE.new
    },
    {
        id: 2,
        type: NOTIFICATION_TYPE.deleted
    },
    {
        id: 3,
        type: NOTIFICATION_TYPE.updated
    },
    {
        id: 4,
        type: NOTIFICATION_TYPE.reminder
    }
]
