import styled, {css, DefaultTheme} from 'styled-components'
import * as Progress from '@radix-ui/react-progress'

export const StyledProgressRoot = styled(Progress.Root)`
    ${({theme: {palette}}) => css`
        position: relative;
        overflow: hidden;
        border-radius: 16px;
        height: 8px;
        background-color: ${palette.neutral[200]};
        width: 100%;
    `}
`

export const makeProgressVariantStyle = (theme: DefaultTheme) =>
    ({
        primary: css`
            background-color: ${theme.palette.primary[600]};
        `,
        success: css`
            background-color: ${theme.palette.success[600]};
        `,
        warning: css`
            background-color: ${theme.palette.warning[500]};
        `,
        danger: css`
            background-color: ${theme.palette.danger[600]};
        `
    }) as const satisfies Record<NonNullable<StyledProgressIndicatorProps['$variant']>, ReturnType<typeof css>>

export type StyledProgressIndicatorProps = {
    $variant: 'primary' | 'success' | 'warning' | 'danger'
}
export const StyledProgressIndicator = styled(Progress.Indicator)<StyledProgressIndicatorProps>`
    ${({theme, $variant}) => css`
        width: 100%;
        height: 100%;
        border-radius: 16px;
        position: absolute;
        transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
        ${makeProgressVariantStyle(theme)[$variant]};
    `}
`
export const StyledProgressLabel = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};
        font-weight: 600;
        color: ${palette.neutral[700]};
    `
)
