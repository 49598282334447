import {StyledDivider} from '@components/ui/divider/style.ts'
import {CSSProperties} from 'react'
import {NeutralPalette, Palette} from '@/theme/style'

interface ContainerProps {
    direction: 'vertical' | 'horizontal'
    width?: CSSProperties['width']
    height?: CSSProperties['height']
    startSpacing?: number
    endSpacing?: number
    background?: Palette[keyof Palette] | NeutralPalette[keyof NeutralPalette]
    className?: string
}

export const Divider = ({
    direction,
    width,
    height,
    startSpacing,
    endSpacing,
    background,
    className
}: ContainerProps) => (
    <StyledDivider
        direction={direction}
        width={width}
        height={height}
        background={background}
        startSpacing={startSpacing}
        endSpacing={endSpacing}
        className={className}
    />
)

Divider.displayName = 'Divider'
