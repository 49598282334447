import {FC} from 'react'
import {StyledProgressIndicator, StyledProgressLabel, StyledProgressRoot} from '@components/ui/progress-bar/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {round} from '@utilities/helpers.ts'

interface ProgressBarProps {
    percentageProgress: number
    variant: 'primary' | 'success' | 'warning' | 'danger'
}

export const ProgressBar: FC<ProgressBarProps> = ({variant, percentageProgress}) => {
    return (
        <Flexbox fullWidth gap={2} align={'center'}>
            <StyledProgressRoot value={percentageProgress}>
                <StyledProgressIndicator $variant={variant} style={{width: `${percentageProgress}%`}} />
            </StyledProgressRoot>
            <StyledProgressLabel>{`${round(percentageProgress)}%`}</StyledProgressLabel>
        </Flexbox>
    )
}
