import {HttpCreateAppointmentsPayload} from '@/features/appointments/components/modals/AddAppointmentsSchema.tsx'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {httpCreateAppointment} from '@/features/appointments/services/appointments.http.ts'
import {Appointment} from '@/features/appointments/types.ts'
import {captureException} from '@sentry/react'
import toast from 'react-hot-toast'
import {QUERY_KEYS, queryProps} from '@/queryClient.ts'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types.ts'
import {useTranslation} from 'react-i18next'
import {errorHandler} from '@utilities/helpers.ts'

export const useCreateAppointment = (options?: queryProps) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: HttpCreateAppointmentsPayload) =>
            parseAxiosPromise({
                axiosPromise: httpCreateAppointment(data),
                responseShape: Appointment,
                onZodError: captureException
            }),
        onSuccess: () => {
            options?.onSuccess?.()
            toast.success(t('commons:operation_completed'))
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.APPOINTMENTS]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PATIENTS]})
        },
        onError: (error: AxiosError<ErrorResponseData>) => {
            options?.onError?.(error)
            errorHandler(error)
        }
    })
}
