import {Outlet} from 'react-router-dom'
import {useURLParsedParams} from '@/hooks/useURLParsedParams'
import {ErrorBox} from '@/components/commons/error-box/ErrorBox'
import {useTranslation} from 'react-i18next'
import {AlertCircleIcon} from '@components/ui/icon/Icon.tsx'
import {SelfEvaluationDetailsURLParams} from '@/features/self-evaluation-details/types.ts'

export const SelfEvaluationRouteGuard = () => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(SelfEvaluationDetailsURLParams)

    return urlParams.success ? (
        <Outlet context={{urlParams: urlParams.data}} />
    ) : (
        <ErrorBox title={t('errors:invalid_url')} icon={<AlertCircleIcon />} />
    )
}
