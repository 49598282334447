import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {
    httpGetPatientsStats,
    HttpGetPatientsStatsOptions,
    HttpGetPatientsStatsResponse
} from '@/features/patients/services/patient.http'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'
import {z} from 'zod'
import {RangeDateLabels} from '@/types.ts'
import {getDateFromLabel} from '@utilities/utils.ts'

const UseGetPatientStatsOptions = z.object({
    dateRangeLabels: RangeDateLabels,
    ...HttpGetPatientsStatsOptions.omit({startDate: true, endDate: true}).shape
})
type UseGetPatientStatsOptions = z.infer<typeof UseGetPatientStatsOptions>

export const useGetPatientsStats = (
    promiseArgs: UseGetPatientStatsOptions,
    options?: Omit<UseQueryOptions<HttpGetPatientsStatsResponse>, 'queryFn' | 'queryKey'>
) => {
    const {dateRangeLabels, ...rest} = promiseArgs
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.PATIENTS_STATS, promiseArgs],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetPatientsStats({
                    ...rest,
                    ...getDateFromLabel(dateRangeLabels)
                }),
                responseShape: HttpGetPatientsStatsResponse,
                onZodError: captureException
            })
    })
}
