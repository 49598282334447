import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {DashboardUrlParamsExtended} from '@/features/dashboard/types.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {ErrorIcon} from 'react-hot-toast'
import {useTranslation} from 'react-i18next'
import {Cell, Pie, PieChart, Tooltip} from 'recharts'
import {adaptDataForChart} from '@/features/dashboard/utils.ts'
import {EmptyChart} from '@/features/dashboard/components/empty-chart/EmptyChart.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTheme} from 'styled-components'
import {useGetAgesGroup} from '@/features/dashboard/services/useGetAgesGroup.ts'
import {ChartLegend} from '@/features/dashboard/components/chart-legend/ChartLegend.tsx'

export const AgesGroupChart = () => {
    const {t} = useTranslation()
    const {searchParams} = useParsedSearchParams([], DashboardUrlParamsExtended)
    const {isLoading, isError, data} = useGetAgesGroup(searchParams)
    const {palette} = useTheme()
    const colors = [palette.primary[300], palette.primary[400], palette.primary[500], palette.primary[600]]

    return (
        <div>
            {isLoading ? (
                <Skeleton height={140} width={140} />
            ) : isError ? (
                <ErrorBox icon={<ErrorIcon />} title={t('errors:default')} />
            ) : (
                data && (
                    <Flexbox gap={4}>
                        {data.analytics.total !== 0 ? (
                            <PieChart width={160} height={160}>
                                <Pie
                                    outerRadius={'100%'}
                                    data={adaptDataForChart(data.analytics.partitions, colors)}
                                    dataKey="total"
                                >
                                    {adaptDataForChart(data.analytics.partitions, colors).map((value, index) => (
                                        <Cell key={`cell-${index}`} fill={value.fill} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        ) : (
                            <EmptyChart height={160} width={160} title={t('commons:no_metrics')} />
                        )}
                        <ChartLegend showTotal data={adaptDataForChart(data.analytics.partitions, colors)} />
                    </Flexbox>
                )
            )}
        </div>
    )
}
