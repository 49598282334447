import {SelectValue} from '@/types.ts'
import i18next from 'i18next'
import {FieldErrors, FieldValues} from 'react-hook-form'
import {AxiosError} from 'axios'
import {PatientFormStep} from '@/features/patient-form/types'
import {PatientFormValidationSchema} from '@/features/patient-form/PatientFormModel'

export const AGE_SELECT_OPTIONS = ['low', 'medium', 'high', 'over']
export const GENRE_SELECT_OPTIONS = ['male', 'female']
export const PLACE_SELECT_OPTIONS = ['frosinone', 'sora', 'ceccano']
export const MODE_SELECT_OPTIONS = ['remote', 'onsite']

export const GENDER_SELECT_OPTIONS: SelectValue[] = [
    {
        label: i18next.t('commons:male'),
        value: 'male'
    },
    {
        label: i18next.t('commons:female'),
        value: 'female'
    }
]

export const checkValidityStep = (
    step: PatientFormStep,
    formData: FieldValues,
    isForeign: boolean,
    errors: FieldErrors
) => {
    if (Object.keys(errors).length) return false

    const hasRequiredPersonalData = () => {
        const commonFieldsFilled =
            formData.firstName && formData.lastName && formData.gender && formData.birthDate && formData.taxCode
        const locationFieldFilled = isForeign ? formData.birthCountryId : formData.birthCityId && formData.birthStateId
        return !!commonFieldsFilled && !!locationFieldFilled
    }

    switch (step) {
        case 'personalData':
            return hasRequiredPersonalData()
        case 'email':
            return !!formData.email
        default:
            return false
    }
}

export const getAddPatientServerErrorMessage = (
    error: AxiosError<{code?: string}>,
    callback: (errorMessage: string, keyField?: keyof PatientFormValidationSchema) => void
) => {
    switch (error.response?.data.code) {
        // extend this on demand
        case 'invalidTaxCode':
            return callback('patients:create_patient:form:errors:taxCode', 'taxCode')
        default:
            return callback('errors:default')
    }
}
