import styled, {css} from 'styled-components'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledTimeLineItems = styled.div(
    ({theme: {spacing, typography}}) => css`
        display: grid;
        column-gap: ${spacing * 2}px;
        grid-template-columns: 32px 18px auto;
        & p {
            ${typography.textXs};
        }
        &:last-child {
            .time-line-divider {
                display: none;
            }
        }
    `
)

export const StyledTimeLineCircle = styled.div(
    ({theme: {palette}}) => css`
        flex-shrink: 0;
        width: 18px;
        height: 18px;
        border-radius: 9px;
        background: ${palette.primary[600]};
    `
)

export const StyledTimeLineDivider = styled(Divider)(
    ({theme: {palette}}) => css`
        width: 2px;
        background-color: ${palette.primary[300]};
        min-height: 42px;
        border-radius: 1px;
    `
)

export const StyledTimeLineItemTitle = styled.h3(
    ({theme: {palette, typography}}) => css`
        ${typography.textMd};
        font-weight: 500;
        color: ${palette.neutral[900]};
    `
)

export const StyledEmptyStateWrapper = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        text-align: center;
        & h3 {
            ${typography.textMd};
            font-weight: 600;
            color: ${palette.neutral[900]};
        }
        & p {
            ${typography.textSm};
            color: ${palette.neutral[600]};
        }
    `
)
