import {useGetPatientDetails} from '@/features/patient-details/services/useGetPatientDetails.ts'
import {useNavigate, useOutletContext} from 'react-router-dom'
import {PatientDetailsURLParams} from '@/features/patient-details/types.ts'
import {useTranslation} from 'react-i18next'
import Tabs from '@components/commons/tabs/Tabs.tsx'
import {adaptPatientToDefaultValues, PATIENT_DETAILS_INFO_TABS} from '@/features/patient-details/utils.ts'
import {useState} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Switch} from '@components/ui/switch/Switch.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Edit05Icon} from '@components/ui/icon/Icon.tsx'
import {
    StyledDeletePatientModalContent,
    StyledEditButton,
    StyledPatientDetailsCard,
    StyledPatientDetailsCardBody,
    StyledPatientMoodSection
} from '@/features/patient-details/components/patient-details-card/style.ts'
import {MoodIcon} from '@components/commons/mood-icon/MoodIcon.tsx'
import {PatientDetailsCardSkeleton} from '@/features/patient-details/components/patient-details-card/PatientDetailsCardSkeleton.tsx'
import dayjs from '@/dayjs.ts'
import {useRemoveUser} from '@/features/settings/services/services-users/useRemoveUser'
import {DeleteModal} from '@/components/commons/delete-modal/DeleteModal'
import toast from 'react-hot-toast'
import {routes} from '@/utilities/constants/routes'
import {PatientFormModal} from '@/features/patient-form/components/patient-form-modal/PatientFormModal'
import {CaregiverFormModal} from '../caregiver-form-modal/CaregiverFormModal'
import {useEditPatient} from '@/features/patient-form/services/useEditPatient'
import {errorHandler} from '@utilities/helpers.ts'

export const PatientDetailsCard = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PatientDetailsURLParams}>()
    const patientDetailsQuery = useGetPatientDetails(urlParams)
    const [activeTabId, setActiveTabId] = useState<'personalData' | 'caregiver'>('personalData')

    const [isEditPatientModalOpen, setIsEditPatientModalOpen] = useState(false)
    const [isRemovePatientModalOpen, setIsRemovePatientModalOpen] = useState(false)
    const [isRemoveCaregiverModalOpen, setIsRemoveCaregiverModalOpen] = useState(false)
    const [isEditCaregiverModalOpen, setIsEditCaregiverModalOpen] = useState(false)

    const navigate = useNavigate()

    const removePatientMutation = useRemoveUser({
        onSuccess: () => {
            setIsRemovePatientModalOpen(false)
            navigate(routes.PATIENTS.path)
        },
        onError: error => {
            toast.error(t('errors:default'))
            console.error(error)
        }
    })

    const editPatientMutation = useEditPatient({
        patientId: patientDetailsQuery.data?.id,
        options: {
            onSuccess: () => {
                setIsRemovePatientModalOpen(false)
            },
            onError: error => {
                errorHandler(error)
                console.error(error)
            }
        }
    })

    return (
        <>
            <StyledPatientDetailsCard paddingX={6} paddingY={6} bodyHasBorder={false}>
                <Flexbox direction={'column'} gap={6}>
                    {patientDetailsQuery.isLoading ? (
                        <PatientDetailsCardSkeleton />
                    ) : (
                        patientDetailsQuery.data && (
                            <>
                                <StyledPatientMoodSection direction={'column'} gap={3}>
                                    <h4>{t('patient_details:patient_tabs:info:mood')}</h4>
                                    {patientDetailsQuery.data.emotion ? (
                                        <MoodIcon
                                            hasBackground
                                            variant={patientDetailsQuery.data.emotion.name}
                                            label={t(`commons:mood:${patientDetailsQuery.data.emotion.name}`)}
                                        />
                                    ) : (
                                        <p>{t('commons:unavailable')}</p>
                                    )}
                                </StyledPatientMoodSection>
                                <StyledPatientDetailsCardBody direction={'column'} gap={6}>
                                    <Tabs
                                        tabsList={PATIENT_DETAILS_INFO_TABS}
                                        activeTabId={activeTabId}
                                        setActiveTabId={id => {
                                            setActiveTabId(id as 'personalData' | 'caregiver')
                                        }}
                                    />
                                    {activeTabId === 'personalData' ? (
                                        <>
                                            <Flexbox direction={'column'} gap={2}>
                                                <h4>{t('patient_details:patient_tabs:info:therapy_path')}</h4>
                                                <Switch
                                                    defaultChecked={!!patientDetailsQuery.data.isCurrentlyInTherapy}
                                                    onChange={value =>
                                                        editPatientMutation.mutate({
                                                            payload: {
                                                                isCurrentlyInTherapy: value
                                                            }
                                                        })
                                                    }
                                                />
                                            </Flexbox>

                                            <StyledEditButton
                                                variant={'tertiaryColor'}
                                                onClick={() => setIsEditPatientModalOpen(true)}
                                            >
                                                <Edit05Icon />
                                                {t('patient_details:patient_tabs:info:edit_patient')}
                                            </StyledEditButton>

                                            <Flexbox direction={'column'} gap={2}>
                                                <h4>{t('patient_details:patient_tabs:info:lastName')}</h4>
                                                <p>{patientDetailsQuery.data?.lastName}</p>
                                            </Flexbox>

                                            <Flexbox direction={'column'} gap={2}>
                                                <h4>{t('patient_details:patient_tabs:info:firstName')}</h4>
                                                <p>{patientDetailsQuery.data?.firstName}</p>
                                            </Flexbox>

                                            <Flexbox direction={'column'} gap={2}>
                                                <h4>{t('patient_details:patient_tabs:info:gender')}</h4>
                                                <p>{patientDetailsQuery.data?.gender === 'male' ? 'M' : 'F'}</p>
                                            </Flexbox>

                                            <Flexbox direction={'column'} gap={2}>
                                                <h4>{t('patient_details:patient_tabs:info:birth_date')}</h4>
                                                <p>{dayjs(patientDetailsQuery.data?.birthDate).format('DD/MM/YYYY')}</p>
                                            </Flexbox>

                                            {patientDetailsQuery.data?.birthCountry ? (
                                                <Flexbox direction={'column'} gap={2}>
                                                    <h4>{t('patient_details:patient_tabs:info:birth_country')}</h4>
                                                    <p>{patientDetailsQuery.data?.birthCountry?.name}</p>
                                                </Flexbox>
                                            ) : (
                                                <>
                                                    <Flexbox direction={'column'} gap={2}>
                                                        <h4>{t('patient_details:patient_tabs:info:birth_state')}</h4>
                                                        <p>{patientDetailsQuery.data?.birthState?.name}</p>
                                                    </Flexbox>

                                                    <Flexbox direction={'column'} gap={2}>
                                                        <h4>{t('patient_details:patient_tabs:info:birth_city')}</h4>
                                                        <p>{patientDetailsQuery.data?.birthCity?.name}</p>
                                                    </Flexbox>
                                                </>
                                            )}

                                            <Flexbox direction={'column'} gap={2}>
                                                <h4>{t('patient_details:patient_tabs:info:fiscal_code')}</h4>
                                                <p>{patientDetailsQuery.data?.taxCode}</p>
                                            </Flexbox>

                                            <Flexbox direction={'column'} gap={2}>
                                                <h4>{t('patient_details:patient_tabs:info:phone')}</h4>
                                                <p>
                                                    {patientDetailsQuery.data?.phoneNumber
                                                        ? `+${patientDetailsQuery.data.phoneNumber}`
                                                        : t('commons:unavailable')}
                                                </p>
                                            </Flexbox>

                                            <Flexbox direction={'column'} gap={2}>
                                                <h4>{t('patient_details:patient_tabs:info:email')}</h4>
                                                <p>{patientDetailsQuery.data?.email}</p>
                                            </Flexbox>
                                        </>
                                    ) : (
                                        <>
                                            <div>
                                                <StyledEditButton
                                                    variant={'tertiaryColor'}
                                                    onClick={() => setIsEditCaregiverModalOpen(true)}
                                                >
                                                    <Edit05Icon />
                                                    {t('patient_details:patient_tabs:info:edit_caregiver')}
                                                </StyledEditButton>
                                            </div>

                                            <Flexbox direction={'column'} gap={2}>
                                                <h4>{t('patient_details:patient_tabs:info:lastName')}</h4>
                                                <p>
                                                    {patientDetailsQuery.data?.caregiver?.lastName ??
                                                        t('commons:unavailable')}
                                                </p>
                                            </Flexbox>

                                            <Flexbox direction={'column'} gap={2}>
                                                <h4>{t('patient_details:patient_tabs:info:firstName')}</h4>
                                                <p>
                                                    {patientDetailsQuery.data?.caregiver?.firstName ??
                                                        t('commons:unavailable')}
                                                </p>
                                            </Flexbox>

                                            <Flexbox direction={'column'} gap={2}>
                                                <h4>{t('patient_details:patient_tabs:info:kinship')}</h4>
                                                <p>
                                                    {patientDetailsQuery.data?.caregiver?.caregiverRelationshipDegree ??
                                                        t('commons:unavailable')}
                                                </p>
                                            </Flexbox>

                                            <Flexbox direction={'column'} gap={2}>
                                                <h4>{t('patient_details:patient_tabs:info:email')}</h4>
                                                <p>
                                                    {patientDetailsQuery.data?.caregiver?.email ??
                                                        t('commons:unavailable')}
                                                </p>
                                            </Flexbox>
                                            <Flexbox direction={'column'} gap={2}>
                                                <h4>{t('patient_details:patient_tabs:info:phone')}</h4>
                                                <p>
                                                    {patientDetailsQuery.data?.caregiver?.phoneNumber
                                                        ? `+${patientDetailsQuery.data.caregiver.phoneNumber}`
                                                        : t('commons:unavailable')}
                                                </p>
                                            </Flexbox>
                                        </>
                                    )}
                                </StyledPatientDetailsCardBody>
                                {activeTabId === 'personalData' ? (
                                    <>
                                        <Button
                                            variant={'secondaryDanger'}
                                            onClick={() => setIsRemovePatientModalOpen(true)}
                                        >
                                            {t('patient_details:patient_tabs:info:delete_patient')}
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            variant={'secondaryDanger'}
                                            disabled={!patientDetailsQuery.data.caregiver?.id}
                                            onClick={() => setIsRemoveCaregiverModalOpen(true)}
                                        >
                                            {t('patient_details:patient_tabs:info:delete_caregiver')}
                                        </Button>
                                    </>
                                )}
                            </>
                        )
                    )}
                </Flexbox>
            </StyledPatientDetailsCard>

            {isEditPatientModalOpen && (
                <PatientFormModal
                    onClose={() => setIsEditPatientModalOpen(false)}
                    patientId={patientDetailsQuery.data?.id}
                    defaultValues={patientDetailsQuery.data && adaptPatientToDefaultValues(patientDetailsQuery.data)}
                />
            )}

            {isEditCaregiverModalOpen && patientDetailsQuery.data?.caregiver && (
                <CaregiverFormModal
                    onClose={() => setIsEditCaregiverModalOpen(false)}
                    patientId={patientDetailsQuery.data.id}
                    caregiver={patientDetailsQuery.data.caregiver}
                />
            )}

            {patientDetailsQuery.data && isRemovePatientModalOpen && (
                <DeleteModal
                    modalTitle={t('patients:delete_modal:title')}
                    isLoading={removePatientMutation.isPending}
                    onDelete={() => removePatientMutation.mutate(patientDetailsQuery.data.id)}
                    onClose={() => setIsRemovePatientModalOpen(false)}
                >
                    <StyledDeletePatientModalContent direction={'column'} gap={2.5}>
                        <p className={'description'}>{t('patients:delete_modal:description')}</p>

                        <p className={'information'}>
                            #{patientDetailsQuery.data.id} {patientDetailsQuery.data.firstName}{' '}
                            {patientDetailsQuery.data.lastName}
                        </p>

                        <p className={'paragraph'}>{t('patients:delete_modal:paragraph')}</p>
                    </StyledDeletePatientModalContent>
                </DeleteModal>
            )}

            {patientDetailsQuery.data?.caregiver?.id && isRemoveCaregiverModalOpen && (
                <DeleteModal
                    modalTitle={t('patients:delete_modal:title')}
                    isLoading={removePatientMutation.isPending}
                    onDelete={() =>
                        patientDetailsQuery.data.caregiver?.id &&
                        removePatientMutation.mutate(patientDetailsQuery.data.caregiver.id)
                    }
                    onClose={() => setIsRemoveCaregiverModalOpen(false)}
                >
                    <StyledDeletePatientModalContent direction={'column'} gap={2.5}>
                        <p className={'description'}>{t('patients:delete_modal:description')}</p>

                        <p className={'information'}>
                            #{patientDetailsQuery.data.caregiver.id} {patientDetailsQuery.data.caregiver.firstName}{' '}
                            {patientDetailsQuery.data.caregiver.lastName}
                        </p>

                        <p className={'paragraph'}>{t('patients:delete_modal:paragraph')}</p>
                    </StyledDeletePatientModalContent>
                </DeleteModal>
            )}
        </>
    )
}
