import {RangeDate, RangeDateLabels} from '@/types.ts'
import dayjs from '@/dayjs.ts'

export const rangeDateToLabel = {
    last_year: 'patients:top_filters:last_year',
    last_6_months: 'patients:top_filters:last_6_months',
    last_3_months: 'patients:top_filters:last_3_months',
    last_month: 'patients:top_filters:last_month',
    last_week: 'patients:top_filters:last_week'
} as const satisfies Record<RangeDateLabels, string>

export const getDateFromLabel = (dateLabel?: string): RangeDate | null => {
    if (!dateLabel) return null

    const periods: {[key: string]: RangeDate} = {
        today: {
            startDate: dayjs().startOf('day').toISOString(),
            endDate: dayjs().endOf('day').toISOString()
        },
        last_week: {
            startDate: dayjs().startOf('week').toISOString(),
            endDate: dayjs().toISOString()
        },
        last_month: {
            startDate: dayjs().startOf('month').toISOString(),
            endDate: dayjs().toISOString()
        },
        last_3_months: {
            startDate: dayjs().subtract(3, 'months').startOf('month').toISOString(),
            endDate: dayjs().toISOString()
        },
        last_6_months: {
            startDate: dayjs().subtract(6, 'months').startOf('month').toISOString(),
            endDate: dayjs().toISOString()
        },
        last_year: {
            startDate: dayjs().startOf('year').toISOString(),
            endDate: dayjs().toISOString()
        }
    }
    return periods[dateLabel]
}
