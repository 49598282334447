import React, {CSSProperties, Dispatch, FC, SetStateAction} from 'react'
import {Virtuoso} from 'react-virtuoso'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ErrorMessage} from '@/components/commons/table/error-message/ErrorMessage'
import {
    StyledAppointmentsListWrapper,
    StyledList
} from '@/features/patient-details/components/patient-appointments-list/style'
import {AppointmentItem} from '@/features/patient-details/components/appointment-item/AppointmentItem.tsx'
import {AppointmentItemSkeleton} from '@/features/patient-details/components/appointment-item/AppointmentItemSkeleton.tsx'
import {EmptyState} from '@components/commons/empty-state/EmptyState.tsx'
import {CalendarIcon} from '@components/ui/icon/Icon.tsx'
import {useTranslation} from 'react-i18next'
import {Appointment} from '@/features/appointments/types.ts'

interface AppointmentsListProps {
    appointments: Appointment[]
    isError?: boolean
    isChangingPage?: boolean
    isLoading: boolean
    onChangePage?: (lastItemIndex: number) => void
    setOpenAddOrEditAppointmentModal: Dispatch<SetStateAction<{open: boolean; id: number | null}>>
}

export const AppointmentsList: FC<AppointmentsListProps> = ({
    appointments,
    isError,
    isChangingPage,
    isLoading,
    onChangePage,
    setOpenAddOrEditAppointmentModal
}) => {
    const style: CSSProperties = {
        scrollbarWidth: 'none'
    }
    const {t} = useTranslation()
    const onClickAddAppointment = () => {
        setOpenAddOrEditAppointmentModal({open: true, id: null})
    }
    return (
        <StyledAppointmentsListWrapper>
            <Virtuoso
                data={appointments}
                style={style}
                overscan={100}
                endReached={isError ? undefined : onChangePage}
                itemContent={(_itemIndex, item) => (
                    <AppointmentItem
                        setOpenAddOrEditAppointmentModal={setOpenAddOrEditAppointmentModal}
                        appointment={item}
                    />
                )}
                components={{
                    EmptyPlaceholder: () => {
                        if (isLoading) {
                            return (
                                <Flexbox direction={'column'} gap={6}>
                                    <AppointmentItemSkeleton />
                                    <AppointmentItemSkeleton />
                                    <AppointmentItemSkeleton />
                                </Flexbox>
                            )
                        }
                        if (isError) {
                            return null
                        }

                        return (
                            <EmptyState
                                title={t('patient_details:appointments_tab:empty_state:title')}
                                description={t('patient_details:appointments_tab:empty_state:description')}
                                icon={<CalendarIcon />}
                                iconCta={<CalendarIcon />}
                                onClickCta={onClickAddAppointment}
                                ctaLabel={t('patient_details:appointments_tab:add_appointment')}
                            />
                        )
                    },
                    Footer: () => {
                        if (isLoading) {
                            return null
                        }
                        if (isError) {
                            return <ErrorMessage />
                        }
                        if (isChangingPage) {
                            return (
                                <Flexbox direction={'column'} gap={6}>
                                    <AppointmentItemSkeleton />
                                    <AppointmentItemSkeleton />
                                    <AppointmentItemSkeleton />
                                </Flexbox>
                            )
                        }

                        return null
                    },
                    List: React.forwardRef((props, ref) => {
                        return <StyledList {...props} ref={ref} />
                    })
                }}
            />
        </StyledAppointmentsListWrapper>
    )
}
