import {Table} from '@components/commons/table/Table.tsx'
import {useTranslation} from 'react-i18next'
import {FC} from 'react'
import {useGetCheckInDetails} from '@/features/check-in-details/services/useGetCheckInDetails.ts'
import {
    StyledSurveyTableFooterWrapper,
    StyledSurveyTableWrapper
} from '@/features/check-in-details/components/survey-table/style.ts'

interface SurveyTableProps {
    checkInId: number
}
export const SurveyTable: FC<SurveyTableProps> = ({checkInId}) => {
    const {t} = useTranslation()
    const checkInDetailsQuery = useGetCheckInDetails({checkInId: checkInId})
    return (
        <StyledSurveyTableWrapper>
            <Table
                data={checkInDetailsQuery.data?.survey?.question || []}
                isLoading={checkInDetailsQuery.isLoading}
                footerComponent={
                    <StyledSurveyTableFooterWrapper direction={'column'} gap={3}>
                        <h4>{t('check_in_details:table:additional_notes')}</h4>
                        <p>{checkInDetailsQuery.data?.survey?.note ?? t('check_in_details:table:none')}</p>
                    </StyledSurveyTableFooterWrapper>
                }
                columns={[
                    {
                        label: t('check_in_details:table:label:question'),
                        width: '50%',
                        alignment: 'left',
                        cellRender: data => (
                            <>
                                <p>{data.question}</p>
                            </>
                        )
                    },
                    {
                        label: t('check_in_details:table:label:answer'),
                        width: '50%',
                        cellRender: data => (
                            <>
                                <p>{data.answer}</p>
                            </>
                        )
                    }
                ]}
            />
        </StyledSurveyTableWrapper>
    )
}
