import styled, {css} from 'styled-components'

export const StyledMoneyGambledSectionGrid = styled.div(
    ({theme: {typography, spacing, palette}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: ${spacing * 2}px;
        align-items: center;
        & p {
            font-weight: 400;
            ${typography.textSm}
        }
        & span {
            font-weight: 400;
            ${typography.textXs}
        }
        & h4 {
            ${typography.textSm}
            font-weight: 500;
            color: ${palette.neutral[600]};
        }
    `
)

export const StyledMoneyIconWrapper = styled.div(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 4}px;
        color: ${palette.danger[600]};
    `
)
