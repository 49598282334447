import {HeadingSection} from '@/components/commons/heading-section/HeadingSection'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {CalendarIcon, CalendarPlus01Icon, VideoRecorderIcon} from '@/components/ui/icon/Icon'
import {Switch} from '@/components/ui/switch/Switch'
import {useTranslation} from 'react-i18next'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {Button} from '@/components/ui/button/Button'
import {DateRangeSelect} from '@/components/commons/date-range-select/DateRangeSelect'
import {useGetUserAppointments} from '@/features/patient-details/services/useGetUserAppointments.tsx'
import {useOutletContext} from 'react-router-dom'
import {PatientAppointmentsURLSearchParamsParams, PatientDetailsURLParams} from '@/features/patient-details/types'
import {AppointmentsList} from '@/features/patient-details/components/patient-appointments-list/AppointmentsList'
import {useEditPatient} from '@/features/patient-form/services/useEditPatient'
import toast from 'react-hot-toast'
import {Skeleton} from '@/components/ui/skeleton/Skeleton'
import {useState} from 'react'
import {AddAppointmentsModal} from '@/features/appointments/components/modals/AddAppointmentsModal.tsx'
import {useGetContacts} from '@/features/settings/services/services-contact/useGetContacts.ts'
import {useGetPatients} from '@/features/patients/services/useGetPatients.ts'
import {useGetUsers} from '@/features/settings/services/services-users/useGetUsers.ts'
import {ResponseType} from '@/types.ts'
import {useGetAppointmentDetails} from '@/features/appointments/services/useGetAppointmentDetails.ts'
import {errorHandler} from '@utilities/helpers.ts'

export const PatientAppointmentsTab = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PatientDetailsURLParams}>()
    const {searchParams, setSearchParams} = useParsedSearchParams([], PatientAppointmentsURLSearchParamsParams)
    const [openAddOrEditAppointmentModal, setOpenAddOrEditAppointmentModal] = useState<{
        open: boolean
        id: number | null
    }>({
        open: false,
        id: null
    })
    const {data: offices} = useGetContacts()
    const {remappedData: patients} = useGetPatients({limit: 10000})
    const {remappedData: therapist} = useGetUsers({
        ...searchParams,
        responseType: ResponseType.enum.backofficeRegular,
        limit: 100,
        roles: ['therapist']
    })

    const patientAppointmentsQuery = useGetUserAppointments({
        patientId: urlParams.patientId,
        ...searchParams
    })

    /**
     * When the patient has no completed appointments ("Effettuato"), the update
     * will not be available.
     *
     * Todo: need to check the existence of a completed appointment, after BE updates.
     * The plan is:
     * query the path /users/:userId/appointments without startDate and endDate, a statuses to "completed" (?)
     * and a limit set to 1. If it responds a value, the button will be available
     */
    const editPatientMutation = useEditPatient({
        patientId: urlParams.patientId,
        options: {
            onSuccess: () => {
                toast.success(t('commons:operation_completed'))
            },
            onError: error => {
                errorHandler(error)
            }
        }
    })

    const {data: appointment, isLoading} = useGetAppointmentDetails(openAddOrEditAppointmentModal.id)

    return (
        <Flexbox direction="column" fullWidth gap={6}>
            {patientAppointmentsQuery.isLoading ? (
                <Flexbox justify="space-between" fullWidth>
                    <Skeleton height={40} width={200} />
                    <Flexbox gap={6}>
                        <Skeleton height={40} width={200} />
                        <Skeleton height={40} width={200} />
                    </Flexbox>
                </Flexbox>
            ) : (
                <HeadingSection
                    title={''}
                    endSlot={
                        <Flexbox justify={'space-between'} align="center" fullWidth>
                            <Switch
                                label={t('patient_details:appointments_tab:remote_toggle')}
                                icon={<VideoRecorderIcon />}
                                defaultChecked={false}
                                onChange={value =>
                                    editPatientMutation.mutate({payload: {onlineAppointmentEnabled: value}})
                                }
                            />
                            <Flexbox gap={6} align="center">
                                <DateRangeSelect
                                    value={searchParams.rangeDateLabels}
                                    icon={<CalendarIcon />}
                                    onChange={value => setSearchParams({rangeDateLabels: value})}
                                />

                                <Button onClick={() => setOpenAddOrEditAppointmentModal({open: true, id: null})}>
                                    <CalendarPlus01Icon size={20} />
                                    <span>{t('patient_details:appointments_tab:add_appointment')}</span>
                                </Button>
                            </Flexbox>
                        </Flexbox>
                    }
                />
            )}

            <AppointmentsList
                appointments={patientAppointmentsQuery.remappedData}
                isError={patientAppointmentsQuery.isError}
                isLoading={patientAppointmentsQuery.isLoading}
                isChangingPage={patientAppointmentsQuery.isFetchingNextPage}
                onChangePage={patientAppointmentsQuery.fetchNextPage}
                setOpenAddOrEditAppointmentModal={setOpenAddOrEditAppointmentModal}
            />
            {openAddOrEditAppointmentModal.open && !isLoading && (
                <AddAppointmentsModal
                    offices={offices?.data ? offices.data : []}
                    patients={patients}
                    therapists={therapist}
                    onClose={() => setOpenAddOrEditAppointmentModal({open: false, id: null})}
                    officeId={null}
                    prefilledData={{patientId: urlParams.patientId}}
                    appointment={appointment}
                    appointments={patientAppointmentsQuery.remappedData}
                />
            )}
        </Flexbox>
    )
}
