import {ReactNode} from 'react'
import {StyledPageHeaderContainer, StyledPageHeaderText} from '@components/commons/page-hero/style.ts'

interface PageHeaderProps {
    title?: string
    subtitle?: string | null
    description?: string | null
    className?: string
    ctaComponent?: ReactNode
}

export const PageHero = ({title, subtitle, description, className, ctaComponent}: PageHeaderProps) => {
    return (
        <StyledPageHeaderContainer className={className}>
            <StyledPageHeaderText>
                {title && <h1>{title}</h1>}
                {subtitle && <span>{subtitle}</span>}
                {description && <p>{description}</p>}
            </StyledPageHeaderText>
            {ctaComponent && <div>{ctaComponent}</div>}
        </StyledPageHeaderContainer>
    )
}

PageHero.displayName = 'PageHero'
