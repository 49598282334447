import {TableColumn, TableSorter} from '@components/commons/table/Table.tsx'
import {
    StyledBody,
    StyledButton,
    StyledHead,
    StyledSorterModal
} from '@/components/commons/table/mobile-sorter-modal/style'
import {CheckIcon} from '@components/ui/icon/Icon.tsx'
import {useTranslation} from 'react-i18next'

interface MobileSorterModalProps<TData extends unknown[], TSortName extends string | undefined> {
    columns: TableColumn<TData, TSortName>[]
    onClose: () => void
    sorter: TableSorter<TSortName>
}

export const MobileSorterModal = <TData extends unknown[], TSortName extends string | undefined>({
    columns,
    onClose,
    sorter
}: MobileSorterModalProps<TData, TSortName>) => {
    const {t} = useTranslation()

    return (
        <StyledSorterModal onClose={onClose} width={320} closeIconOffset="sm">
            <StyledHead>
                <h3>{t('commons:sort_the_table')}</h3>
            </StyledHead>
            <StyledBody>
                {columns
                    .filter(column => column.sortName)
                    .map(column => (
                        <StyledButton
                            size="sm"
                            variant="tertiary"
                            key={column.sortName}
                            onClick={() => {
                                sorter.onSort({orderBy: column.sortName, orderDirection: 'desc'})
                                onClose()
                            }}
                            fullWidth
                        >
                            {column.label}
                            {sorter.orderBy == column.sortName && <CheckIcon />}
                        </StyledButton>
                    ))}
            </StyledBody>
        </StyledSorterModal>
    )
}

MobileSorterModal.displayName = 'MobileSorterModal'
