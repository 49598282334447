import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance'
import {captureException} from '@sentry/react'
import {httpGetPatientDetails} from '@/features/patient-details/services/patientDetails.http'
import {User} from '@/features/auth/types.ts'
import {ResponseType} from '@/types.ts'
import {PatientDetailsURLParams} from '@/features/patient-details/types.ts'

export const useGetPatientDetails = (
    promiseArgs: PatientDetailsURLParams,
    options?: Omit<UseQueryOptions<User>, 'queryFn' | 'queryKey'>
) =>
    useQuery({
        ...options,
        queryKey: [QUERY_KEYS.PATIENTS_DETAILS, promiseArgs.patientId],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetPatientDetails({
                    ...promiseArgs,
                    responseType: ResponseType.enum.regular
                }),
                responseShape: User,
                onZodError: captureException
            })
    })
