import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS, queryProps} from '@/queryClient.ts'
import toast from 'react-hot-toast'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types.ts'
import {httpAddAvailableSlots} from '@/features/appointments/services/appointments.http.ts'
import {useTranslation} from 'react-i18next'
import {Slot} from '@/features/appointments/types.ts'
import {errorHandler} from '@utilities/helpers.ts'

export const useAddAvailableSlots = (options?: queryProps) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.ADD_AVAILABLE_SLOTS],
        mutationFn: (payload: Slot) => httpAddAvailableSlots(payload),
        onSuccess: () => {
            options?.onSuccess?.()
            toast.success(t('commons:operation_completed'))
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.APPOINTMENTS]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.GET_AVAILABLE_SLOTS]})
        },
        onError: (error: AxiosError<ErrorResponseData>) => {
            options?.onError?.(error)
            errorHandler(error)
        }
    })
}
