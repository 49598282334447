import i18next from 'i18next'
import {DashboardUrlParamsExtended} from '@/features/dashboard/types.ts'

export const AGE_SELECT_OPTIONS = [
    {value: 'low', label: i18next.t('patients:filters:age:low')},
    {value: 'medium', label: i18next.t('patients:filters:age:medium')},
    {value: 'high', label: i18next.t('patients:filters:age:high')},
    {value: 'over', label: i18next.t('patients:filters:age:over')}
] as const satisfies Record<string, string | DashboardUrlParamsExtended['age']>[]

export const GENDERS_SELECT_OPTIONS = [
    {value: 'male', label: i18next.t('commons:male')},
    {value: 'female', label: i18next.t('commons:female')}
] as const satisfies Record<string, string | DashboardUrlParamsExtended['genders']>[]
