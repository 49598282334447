import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledGraphicChartFilterWrapper = styled(Flexbox)`
    ${({theme: {palette}}) => css`
        border: 1px solid ${palette.neutral['200']};
        border-radius: 12px;
        overflow: hidden;
    `}
`
interface StyledGraphicChartFilterItemProps {
    $isActive: boolean
}
export const StyledGraphicChartFilterItem = styled.div<StyledGraphicChartFilterItemProps>`
    ${({theme: {spacing, palette, typography, transition}, $isActive}) => css`
        cursor: pointer;
        padding: ${spacing * 3}px ${spacing * 4}px;
        background-color: ${$isActive ? palette.primary[100] : palette.neutral['white']};
        border-right: 1px solid ${palette.neutral['200']};
        ${transition};
        & p {
            ${typography.textSm};
            font-weight: 600;
            color: ${palette.neutral['700']};
        }
        &:last-child {
            border-right: none;
        }
    `}
`
