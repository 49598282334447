import {Button} from '@components/ui/button/Button.tsx'
import {Modal} from '@components/ui/modal-legacy/Modal.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Chip} from '@components/commons/chip/Chip.tsx'
import {
    CalendarDateIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    ClockIcon,
    FilePlus02Icon,
    MarkerPin01Icon,
    VideoRecorderIcon
} from '@components/ui/icon/Icon.tsx'
import {
    StyledAppointmentsTimeAndLocation,
    StyledPatientNameLink
} from '@/features/dashboard/components/appointments/style.ts'
import {useTranslation} from 'react-i18next'
import {useGetAppointmentDetails} from '@/features/appointments/services/useGetAppointmentDetails.ts'
import dayjs from '@/dayjs.ts'
import {useGetContacts} from '@/features/settings/services/services-contact/useGetContacts.ts'
import {useMemo, useState} from 'react'
import {
    StyledContainerAppointmentDetails,
    StyledPatientName as StyledPatientNameParagraph
} from '@/features/appointments/components/modals/style.ts'
import {Editor} from '@/features/appointments/components/editor/Editor.tsx'
import {ActionsMenu} from '@/features/settings/components/actions-menu/ActionsMenu.tsx'
import {actionsListAppointmentDetails} from '@utilities/constants/actionsMenus.tsx'
import {ACTION_TYPES_APPOINTMENT_DETAILS, AppointmentStatus} from '@/features/appointments/types.ts'
import {useEditAppointment} from '@/features/appointments/services/useEditAppointment.ts'
import {useDeleteAppointment} from '@/features/appointments/services/useDeleteAppointment.ts'
import {generatePath} from 'react-router-dom'
import {routes} from '@utilities/constants/routes.tsx'

type ViewAppointmentDetailsProps = {
    appointmentID: number
    onClose: () => void
    handleOpenEditModal: (selectedItem: number) => void
}
export const ViewAppointmentDetails = ({appointmentID, onClose, handleOpenEditModal}: ViewAppointmentDetailsProps) => {
    const {t} = useTranslation()
    const [openRichTextArea, setOpenRichTextArea] = useState<boolean>(false)
    const {data: appointment, isLoading} = useGetAppointmentDetails(appointmentID)
    const {data: offices} = useGetContacts()
    const {mutate: editAppointment} = useEditAppointment()
    const {mutate: deleteAppointment} = useDeleteAppointment()

    const office = offices?.data?.find(office => office.id === appointment?.officeId)
    const startTime = dayjs.utc(appointment?.start).format('HH:mm')
    const endTime = dayjs.utc(appointment?.end).format('HH:mm')
    const time = `${startTime} - ${endTime}`
    const duration = dayjs.utc(appointment?.end).diff(dayjs.utc(appointment?.start), 'hours')

    const onActionClick = (type: string, selectedItem?: number) => {
        switch (type) {
            case ACTION_TYPES_APPOINTMENT_DETAILS.completed:
                editAppointment({
                    appointmentID: selectedItem!,
                    status: AppointmentStatus.enum.completed
                })
                break
            case ACTION_TYPES_APPOINTMENT_DETAILS.edit:
                handleOpenEditModal(selectedItem!)
                break
            case ACTION_TYPES_APPOINTMENT_DETAILS.delete:
                deleteAppointment(selectedItem!)
                onClose()
                break
            case ACTION_TYPES_APPOINTMENT_DETAILS.missed:
                editAppointment({
                    appointmentID: selectedItem!,
                    status: AppointmentStatus.enum.missed
                })
                break
            default:
                break
        }
    }

    const notes = useMemo(() => {
        return appointment?.appointmentCustomizations?.[0].note
    }, [appointment])

    return (
        <>
            <Modal isLoading={isLoading} onClose={onClose} title={t('appointments:appointment_details')} width={'788'}>
                <div style={{padding: 24}}>
                    <StyledContainerAppointmentDetails $openRichTextArea={openRichTextArea}>
                        <Flexbox direction={'column'} gap={6} fullWidth>
                            <Flexbox justify={'space-between'} align={'center'} fullWidth>
                                <Chip
                                    label={t(`commons:appointment_state:${appointment?.status}`)}
                                    color={
                                        appointment?.status === AppointmentStatus.enum.completed
                                            ? 'success'
                                            : appointment?.status === AppointmentStatus.enum.missed
                                              ? 'danger'
                                              : 'primary'
                                    }
                                    variant={'outlined'}
                                />

                                <ActionsMenu
                                    actions={actionsListAppointmentDetails(appointment?.status as AppointmentStatus)}
                                    onActionClick={onActionClick}
                                    selectedItem={appointment?.id}
                                />
                            </Flexbox>

                            <Flexbox direction={'column'} gap={2}>
                                <StyledPatientNameLink
                                    size={'xl'}
                                    to={generatePath(routes.PATIENT_DETAILS.handle.path, {
                                        patientId: appointment?.patientId ?? 0
                                    })}
                                    variant={'tertiaryColor'}
                                    className={'patient-name'}
                                >
                                    <StyledPatientNameParagraph>
                                        {appointment?.patient?.firstName} {appointment?.patient?.lastName}
                                    </StyledPatientNameParagraph>
                                </StyledPatientNameLink>

                                <p>ID {appointment?.id}</p>
                            </Flexbox>

                            <Flexbox gap={6}>
                                <StyledAppointmentsTimeAndLocation>
                                    <CalendarDateIcon />
                                    <p>{dayjs(appointment?.start).format('DD MMMM YYYY')}</p>
                                </StyledAppointmentsTimeAndLocation>

                                <StyledAppointmentsTimeAndLocation>
                                    <ClockIcon />
                                    <p>
                                        {time} ({duration} {t(`appointments:hour`)})
                                    </p>
                                </StyledAppointmentsTimeAndLocation>
                            </Flexbox>

                            <Flexbox justify={'space-between'} align={'center'} fullWidth>
                                <Flexbox align={'center'} gap={6}>
                                    <StyledAppointmentsTimeAndLocation>
                                        {appointment?.isRemote ? <VideoRecorderIcon /> : <MarkerPin01Icon />}
                                        <p>{office?.name.replace('ASL', '')}</p>
                                    </StyledAppointmentsTimeAndLocation>

                                    {appointment?.isRemote && (
                                        <Button
                                            variant={'secondary'}
                                            size={'sm'}
                                            onClick={() => window.open(appointment?.linkVideoCall ?? '', '_blank')}
                                        >
                                            <VideoRecorderIcon />
                                            {t('dashboard:meet_video')}
                                        </Button>
                                    )}
                                </Flexbox>

                                <Button
                                    variant={!notes ? 'secondaryColor' : 'tertiaryColor'}
                                    size={'sm'}
                                    onClick={() => setOpenRichTextArea(!openRichTextArea)}
                                >
                                    {!notes ? (
                                        <FilePlus02Icon />
                                    ) : openRichTextArea ? (
                                        <ChevronUpIcon />
                                    ) : (
                                        <ChevronDownIcon />
                                    )}
                                    {t(!notes ? 'appointments:add_notes' : 'appointments:view_notes')}
                                </Button>
                            </Flexbox>
                        </Flexbox>
                    </StyledContainerAppointmentDetails>
                    {openRichTextArea && appointment && <Editor appointment={appointment} />}
                </div>
            </Modal>
        </>
    )
}
