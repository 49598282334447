import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {CheckInSearchParams, CheckInUrlSearchParams} from '../types'
import {useInputSearch} from '@/hooks/useInputSearch'
import {useEffect} from 'react'
import {useGetCheckIn} from '@/features/check-in/services/useGetCheckIn.ts'

export const useCheckInTable = () => {
    const {searchParams, setSearchParams} = useParsedSearchParams([], CheckInUrlSearchParams)
    const {searchValue, searchError, onSearch, onResetSearch} = useInputSearch(searchParams.search)

    const patientsQuery = useGetCheckIn({
        ...searchParams,
        limit: 20
    })

    useEffect(() => {
        setSearchParams({search: searchValue})
    }, [searchValue])

    const sort = (sorter: {
        orderBy: CheckInSearchParams['orderBy']
        orderDirection: CheckInSearchParams['orderDirection']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({orderBy: sorter.orderBy, orderDirection: sorter.orderDirection})
        } else {
            setSearchParams({orderBy: undefined, orderDirection: undefined})
        }
    }

    return {
        ...patientsQuery,
        searchValue,
        searchError,
        onSearch,
        onResetSearch,
        sorter: {orderBy: searchParams.orderBy, orderDirection: searchParams.orderDirection, onSort: sort}
    }
}
