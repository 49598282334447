import {useTranslation} from 'react-i18next'
import {FC, useEffect, useMemo, useState} from 'react'
import {useForm, useFieldArray, Controller} from 'react-hook-form'
import Tabs from '@components/commons/tabs/Tabs.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {CalendarIcon, PlusIcon, Trash03Icon} from '@components/ui/icon/Icon.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import dayjs from '@/dayjs.ts'
import {Switch} from '@components/ui/switch/Switch.tsx'
import {capitalize} from '@utilities/helpers.ts'
import {
    StyledContainerAvailability,
    StyledDeleteButton,
    StyledInputTime,
    StyledWrapperAvailability,
    StyledWrapperAvailableSlots
} from '@/features/appointments/components/modals/style.ts'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {NEW_SHIFT_FORM_MODAL} from '@/features/appointments/components/modals/AddNewShiftFormModal.ts'
import {SingleSelect} from '@components/commons/single-select/SingleSelect.tsx'
import {BaseCard} from '@components/commons/cards/BaseCard.tsx'
import {DatePicker} from '@components/commons/date-picker/DatePicker.tsx'
import {useCreateShift} from '@/features/appointments/services/useCreateShift.ts'
import {DaysSchedule, Shift as ShiftType} from '@/features/appointments/types.ts'
import {useGetShiftDetails} from '@/features/appointments/services/useGetShiftDetails.ts'
import {useEditShift} from '@/features/appointments/services/useEditShift.ts'
import {ContactObj} from '@/features/settings/SettingsFormModal.ts'
import {EmptyState} from '@components/commons/empty-state/EmptyState.tsx'

type AddNewShiftModalProps = {
    onClose: () => void
    onDeletedShifts: (shiftID: number) => void
    doctorID: number
    shifts: ShiftType[]
    offices: ContactObj[]
}

export type ShiftIntoFields = {
    day: string
    hours: {start: string; end: string}[]
}

type FormValues = {
    shifts: ShiftIntoFields[]
}

export const AddNewShiftModal: FC<AddNewShiftModalProps> = ({onClose, onDeletedShifts, doctorID, shifts, offices}) => {
    const {t} = useTranslation()
    const {mutateAsync: createShift} = useCreateShift({onSuccess: onClose})
    const {mutate: editShift} = useEditShift({onSuccess: onClose})
    const [tabList, setTabList] = useState<number[]>(shifts?.map(shift => shift?.id) ?? [])
    const [newShift, setNewShift] = useState<number | null>(null)
    const [selectedTab, setSelectedTab] = useState<string>(
        newShift ? newShift.toString() : String(tabList[tabList.length - 1])
    )

    const isTabContained = useMemo(() => shifts?.some(shift => shift.id === +selectedTab), [shifts, selectedTab])
    const [officeSelected, setOfficeSelected] = useState<string>('')
    const [selectedDates, setSelectedDates] = useState<Date[]>([])
    const weekDays = Array.from({length: 7}, (_, i) => dayjs().startOf('week').add(i, 'day').format('dddd'))

    const {data: shiftDetails} = useGetShiftDetails(isTabContained ? +selectedTab : null, newShift)
    const isEdit = Boolean(shiftDetails)

    const {control, handleSubmit, watch, setValue} = useForm<FormValues>({
        defaultValues: {
            shifts: []
        }
    })

    useEffect(() => {
        if (isEdit && shiftDetails) {
            const formattedShifts = Object.entries(shiftDetails?.daysSchedule ?? {})?.map(([day, hours]) => {
                return {
                    day: capitalize(day),
                    hours: hours
                        ? hours.map(hour => ({
                              start: `${hour.fromTime.hour.toString().padStart(2, '0')}:${hour.fromTime.minute.toString().padStart(2, '0')}`,
                              end: `${hour.toTime.hour.toString().padStart(2, '0')}:${hour.toTime.minute.toString().padStart(2, '0')}`
                          }))
                        : []
                }
            })

            setValue('shifts', formattedShifts)
            setSelectedDates([new Date(shiftDetails.fromDate), new Date(shiftDetails.toDate)])
            setOfficeSelected(String(shiftDetails.officeId))
        } else {
            const defaultShifts = weekDays.map(day => ({
                day,
                hours: []
            }))

            setValue('shifts', defaultShifts)
            setSelectedDates([])
            setOfficeSelected('')
        }
    }, [shiftDetails, isEdit, tabList, selectedTab])

    const {fields, update} = useFieldArray({
        control,
        name: 'shifts'
    })

    const handleDatesChange = (dates: Date[]) => {
        setSelectedDates(dates)
    }

    const selectedDays = watch('shifts')
        .filter(shift => shift.hours.length > 0)
        .map(shift => shift.day)

    const parseTime = (timeStr: string) => {
        const [hour, minute] = timeStr.split(':').map(Number)
        return {hour, minute}
    }

    const onSubmit = async (data: FormValues) => {
        const startDate = new Date(selectedDates[0])
        const endDate = new Date(selectedDates[1])
        const startIsoString = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString()
        const endIsoString = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString()

        const daysSchedule = weekDays.reduce((schedule, day) => {
            const shift = data.shifts.find(shift => shift.day === day)
            if (shift && shift.hours.length > 0) {
                schedule[day.toLowerCase() as keyof DaysSchedule] = shift.hours.map(hour => ({
                    fromTime: parseTime(hour.start),
                    toTime: parseTime(hour.end)
                }))
            } else {
                schedule[day.toLowerCase() as keyof DaysSchedule] = null
            }
            return schedule
        }, {} as DaysSchedule)

        const payload = {
            userId: doctorID,
            officeId: Number(officeSelected),
            fromDate: startIsoString.split('T')[0],
            toDate: endIsoString.split('T')[0],
            daysSchedule: daysSchedule as DaysSchedule
        }

        if (isEdit && shiftDetails) {
            editShift({id: shiftDetails?.id, ...payload})
        } else {
            const data = await createShift(payload)
            setNewShift(data.id)
        }
    }

    const addHour = (index: number) => {
        const newHours = [...fields[index].hours, {start: '', end: ''}]
        update(index, {...fields[index], hours: newHours})
    }

    const removeHour = (dayIndex: number, hourIndex: number) => {
        const newHours = fields[dayIndex].hours.filter((_, idx) => idx !== hourIndex)
        update(dayIndex, {...fields[dayIndex], hours: newHours})
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Flexbox fullWidth direction="column" gap={6}>
                    <Flexbox fullWidth justify="space-between" align="center">
                        <Tabs
                            maxWidth={400}
                            tabsList={
                                tabList.map(tab => ({
                                    id: String(tab),
                                    name: t('appointments:tab', {WORK_SHIFT_NAME: tab})
                                })) ?? []
                            }
                            activeTabId={selectedTab}
                            setActiveTabId={id => setSelectedTab(id)}
                        />
                        <Button
                            type={'button'}
                            variant="tertiaryColor"
                            onClick={() => {
                                const lastTab = tabList[tabList.length - 1]
                                if (lastTab == undefined) {
                                    setTabList([1])
                                    setSelectedTab('1')
                                } else {
                                    setTabList([...tabList, lastTab + 1])
                                    setSelectedTab(`${lastTab + 1}`)
                                }
                            }}
                        >
                            <PlusIcon />
                            {t('appointments:add_work_shift')}
                        </Button>
                    </Flexbox>

                    {fields?.length > 0 ? (
                        <BaseCard paddingX={4} paddingY={4} bodyHasBorder={false}>
                            <Flexbox direction="column" gap={6} fullWidth>
                                <Flexbox fullWidth justify="space-between">
                                    <SingleSelect
                                        maxWidth={220}
                                        label={t('appointments:asl_site')}
                                        placeholder={t('appointments:asl_site')}
                                        options={
                                            offices?.map(office => ({
                                                label: office.name ?? '',
                                                value: String(office.id)
                                            })) ?? []
                                        }
                                        value={officeSelected}
                                        onChange={(value: string) => setOfficeSelected(value)}
                                    />

                                    <div
                                        style={{
                                            maxWidth: selectedDates.length > 0 ? '100%' : '220px'
                                        }}
                                    >
                                        <DatePicker
                                            toggle
                                            formatter={date => dayjs(date).format('DD/MM/YYYY')}
                                            numMonths={1}
                                            mode={'range'}
                                            selectedDates={selectedDates}
                                            onDatesChange={handleDatesChange}
                                            triggerProps={{
                                                label: t('appointments:validity_period'),
                                                placeholder: t('appointments:validity_period'),
                                                typeIcon: <CalendarIcon size={20} />
                                            }}
                                        />
                                    </div>
                                </Flexbox>

                                <p>{t('appointments:available_timetables')}</p>

                                <StyledWrapperAvailability>
                                    {fields.map((field, dayIndex) => (
                                        <StyledContainerAvailability key={dayIndex}>
                                            <Switch
                                                label={capitalize(field.day)}
                                                checked={selectedDays.includes(field.day)}
                                                onChange={checked => {
                                                    if (checked) {
                                                        update(dayIndex, {
                                                            ...field,
                                                            hours: [
                                                                {
                                                                    start: NEW_SHIFT_FORM_MODAL.start.defaultValue,
                                                                    end: NEW_SHIFT_FORM_MODAL.end.defaultValue
                                                                }
                                                            ]
                                                        })
                                                    } else {
                                                        update(dayIndex, {...field, hours: []})
                                                    }
                                                }}
                                            />
                                            {selectedDays.includes(field.day) && (
                                                <Flexbox direction="column" gap={6} align={'center'}>
                                                    {field.hours.map((_, hourIndex) => (
                                                        <StyledWrapperAvailableSlots key={hourIndex}>
                                                            <Controller
                                                                name={`shifts.${dayIndex}.hours.${hourIndex}.start`}
                                                                control={control}
                                                                render={({field}) => (
                                                                    <StyledInputTime
                                                                        type="time"
                                                                        {...field}
                                                                        step="3600"
                                                                        min="8:00"
                                                                        max="18:00"
                                                                    />
                                                                )}
                                                            />
                                                            <Controller
                                                                name={`shifts.${dayIndex}.hours.${hourIndex}.end`}
                                                                control={control}
                                                                render={({field}) => (
                                                                    <StyledInputTime
                                                                        type="time"
                                                                        {...field}
                                                                        step="3600"
                                                                        min="8:00"
                                                                        max="18:00"
                                                                    />
                                                                )}
                                                            />
                                                            <Trash03Icon
                                                                cursor="pointer"
                                                                onClick={() => removeHour(dayIndex, hourIndex)}
                                                            />
                                                            {hourIndex === field.hours.length - 1 && (
                                                                <PlusIcon
                                                                    cursor="pointer"
                                                                    onClick={() => addHour(dayIndex)}
                                                                />
                                                            )}
                                                        </StyledWrapperAvailableSlots>
                                                    ))}
                                                </Flexbox>
                                            )}
                                        </StyledContainerAvailability>
                                    ))}
                                </StyledWrapperAvailability>

                                {isEdit && (
                                    <StyledDeleteButton
                                        variant="tertiaryDanger"
                                        type={'button'}
                                        onClick={() => {
                                            shiftDetails?.id && onDeletedShifts(shiftDetails?.id)
                                        }}
                                    >
                                        {isEdit
                                            ? t('appointments:delete_work_shift', {
                                                  WORK_SHIFT_NAME: shiftDetails?.id
                                              })
                                            : t('appointments:delete_work_shift_title')}
                                        <Trash03Icon />
                                    </StyledDeleteButton>
                                )}
                            </Flexbox>
                        </BaseCard>
                    ) : (
                        <EmptyState
                            title={t('appointments:empty_fields_title')}
                            description={t('appointments:empty_fields_description')}
                        />
                    )}

                    <Divider direction={'horizontal'} />

                    {fields?.length > 0 && (
                        <Flexbox justify={'space-between'} fullWidth>
                            <Button variant="tertiary" onClick={onClose} type={'button'}>
                                {t('commons:actions:cancel')}
                            </Button>

                            <Button variant="primary" type={'submit'} disabled={!selectedDates || !officeSelected}>
                                {t(isEdit ? 'commons:actions:save' : 'appointments:create_shift')}
                            </Button>
                        </Flexbox>
                    )}
                </Flexbox>
            </form>
        </>
    )
}
