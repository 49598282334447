import {z} from 'zod'
import {OrderDirection, Partitions, RangeDate, RangeDateLabels} from '@/types.ts'
import {Emotion, UserSmall} from '@/features/auth/types.ts'

export const CheckIn = z.object({
    id: z.number(),
    userId: z.number(),
    fromId: z.number().nullable(),
    createdAt: z.string(),
    updatedAt: z.string(),
    completedAt: z.string().nullable(),
    user: UserSmall,
    emotion: Emotion.nullable(),
    bet: z.number().nullable(),
    form: z.object({
        id: z.number(),
        isDefault: z.boolean(),
        status: z.enum(['completed']),
        createdAt: z.string(),
        updatedAt: z.string(),
        deletedAt: z.string().nullable()
    })
})
export type CheckIn = z.infer<typeof CheckIn>

export const CheckInSearchParams = z.object({
    isCurrentlyInTherapy: z.enum(['true', 'false']).default('false'),
    ...RangeDate.shape,
    emotionId: z.coerce.number().optional(),
    bets: z.string().optional(),
    checkInCompleted: z.string().optional(),
    orderBy: z.enum(['patient', 'mood', 'thoughts', 'money_gambled', 'state']).optional(),
    orderDirection: OrderDirection.optional(),
    search: z.string().optional()
})
export type CheckInSearchParams = z.infer<typeof CheckInSearchParams>

export const CheckInUrlSearchParams = z.object({
    ...CheckInSearchParams.omit({startDate: true, endDate: true}).shape,
    dateRangeLabels: RangeDateLabels
})
export type CheckInUrlSearchParams = z.infer<typeof CheckInUrlSearchParams>

export const CheckInFilters = CheckInSearchParams.omit({
    orderBy: true,
    orderDirection: true,
    isCurrentlyInTherapy: true,
    rangeDate: true,
    search: true
})
export type CheckInFilters = z.infer<typeof CheckInFilters>
export const MoneyGambled = z.object({
    value: z.number(),
    name: z.string()
})
export type MoneyGambled = z.infer<typeof MoneyGambled>

export const CheckInStatsResponse = z.object({
    completed: z.object({
        total: z.number(),
        partitions: Partitions.array()
    }),
    completedIncrements: z.object({
        total: z.number(),
        percentage: z.number().nullable(),
        partitions: Partitions.array()
    }),
    emotions: z.object({
        total: z.number(),
        partitions: Partitions.array()
    }),
    bets: z.object({
        total: z.number(),
        partitions: Partitions.array()
    })
})
export type CheckInStatsResponse = z.infer<typeof CheckInStatsResponse>
