import styled, {css} from 'styled-components'
import {Button} from '@components/ui/button/Button.tsx'

export const StyledSelfAssessmentTableLink = styled(Button)(
    () => css`
        padding: 0;
        text-decoration: underline;
    `
)

export const StyledSelfAssessmentTableId = styled.p(
    ({theme}) => css`
        color: ${theme.palette.neutral[500]};
        font-weight: 400;
    `
)

export const StyledSelfAssessmentOutcome = styled.p<{$hasPositive: boolean}>(
    ({theme: {palette, typography}, $hasPositive}) => css`
        color: ${$hasPositive ? palette.danger[600] : palette.success[600]};
        font-weight: 400;
        ${typography.textSm};
    `
)
