import {SelectProps} from '@/components/commons/select/Select'
import {useTranslation} from 'react-i18next'
import {useGetStates} from '@/features/patient-form/services/useGetStates'
import {LocationSelector} from '@/features/patient-form/components/location-selector/LocationSelector'

interface StateSelectorProps {
    countryId: string
    value: SelectProps['value']
    onChange: SelectProps['onChange']
    errorMessage?: string
    className?: SelectProps['className']
}

export const StateSelector = ({countryId, value, onChange, errorMessage, className}: StateSelectorProps) => {
    const {t} = useTranslation()
    const {remappedData: states, ...statesQuery} = useGetStates(
        {
            countryId: parseInt(countryId)
        },
        {enabled: countryId?.length > 0}
    )

    return (
        <LocationSelector
            className={className}
            options={states}
            onSearch={statesQuery.onSearch}
            fetchNextPage={statesQuery.fetchNextPage}
            isLoading={statesQuery.isLoading || statesQuery.isFetchingNextPage}
            label={`${t('patients:create_patient:form:labels:birth_state')}*`}
            placeholder={t('patients:create_patient:form:placeholder:birth_state')}
            value={value ?? null}
            onChange={onChange}
            errorMessage={errorMessage && t(errorMessage)}
            noOptionsMessage={t('patients:create_patient:form:no_states_options')}
        />
    )
}
