import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {httpGetPatientsIndex} from '@/features/appointments/services/appointments.http.ts'

export const useGetAppointmentsUser = (params: {
    roles: string[]
    limit: number
    officeId?: number | null
    userIds?: number[]
}) => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_APPOINTMENTS_USERS, params],
        queryFn: () => httpGetPatientsIndex(params)
    })
}
