import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    StyledAppointmentsTimeAndLocation,
    StyledBetsGrid,
    StyledLastWeekTitle,
    StyledNextAppointment,
    StyledNextAppointmentCardTitle,
    StyledPatientNameLink
} from '@/features/dashboard/components/appointments/style.ts'
import {ActionsMenu} from '@/features/settings/components/actions-menu/ActionsMenu.tsx'
import {actionsListAppointmentDetails} from '@utilities/constants/actionsMenus.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {BaseCard} from '@components/commons/cards/BaseCard.tsx'
import {ClockIcon, MarkerPin01Icon, VideoRecorderIcon} from '@components/ui/icon/Icon.tsx'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {ErrorIcon} from 'react-hot-toast'
import dayjs from '@/dayjs.ts'
import {AppointmentStatusChip} from '@components/commons/appointment-status-chip/AppointmentStatusChip.tsx'
import {generatePath} from 'react-router-dom'
import {routes} from '@utilities/constants/routes.tsx'
import {useGetPatientDetailsEmotion} from '@/features/patient-details/services/useGetPatientDetailsEmotion.ts'
import {ACTION_TYPES_APPOINTMENT_DETAILS, Appointment, AppointmentStatus} from '@/features/appointments/types.ts'
import {MoodIcon} from '@components/commons/mood-icon/MoodIcon.tsx'
import {Emotion} from '@/features/auth/types.ts'
import {useGetPatientDetailsBets} from '@/features/patient-details/services/useGetPatientDetailsBets.ts'
import isBetween from 'dayjs/plugin/isBetween'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {useEditAppointment} from '@/features/appointments/services/useEditAppointment.ts'
import {useDeleteAppointment} from '@/features/appointments/services/useDeleteAppointment.ts'
import {useGetContactsDetails} from '@/features/settings/services/services-contact/useGetContactsDetails.ts'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)

interface DoctorNextAppointmentProps {
    appointment?: Appointment
    isLoading: boolean
    isError: boolean
    setOpenAddOrEditAppointmentModal: Dispatch<SetStateAction<{open: boolean; id: number | null}>>
}
export const DoctorNextAppointment: FC<DoctorNextAppointmentProps> = ({
    appointment,
    isLoading,
    isError,
    setOpenAddOrEditAppointmentModal
}) => {
    const {t} = useTranslation()

    const mood = useGetPatientDetailsEmotion({patientId: appointment?.patientId, emotionRangeDateLabels: 'last_week'})
    const bets = useGetPatientDetailsBets({patientId: appointment?.patientId, betsRangeDateLabels: 'last_week'})
    const {mutate: editAppointment} = useEditAppointment()
    const office = useGetContactsDetails({id: appointment?.officeId || null})
    const {mutate: deleteAppointment} = useDeleteAppointment()
    const checkIsDisabled = () => {
        const now = dayjs()
        const newStart = dayjs(appointment?.start).subtract(15, 'minutes').utc().format('LLL')
        const newEnd = dayjs(appointment?.end).subtract(15, 'minutes').utc().format('LLL')

        if (appointment?.status !== 'booked') {
            return true
        }
        return !now.isBetween(newStart, newEnd)
    }

    const [hasCtaDisable, setCtaDisabled] = useState<boolean>(checkIsDisabled())

    useEffect(() => {
        const timer = setInterval(() => setCtaDisabled(checkIsDisabled()), 900000)
        return () => clearInterval(timer)
    }, [])

    const onActionClick = (type: string, selectedItem?: number) => {
        switch (type) {
            case ACTION_TYPES_APPOINTMENT_DETAILS.completed:
                editAppointment({
                    appointmentID: selectedItem!,
                    status: AppointmentStatus.enum.completed
                })
                break
            case ACTION_TYPES_APPOINTMENT_DETAILS.edit:
                setOpenAddOrEditAppointmentModal({open: true, id: selectedItem!})
                break
            case ACTION_TYPES_APPOINTMENT_DETAILS.delete:
                deleteAppointment(selectedItem!)
                break
            case ACTION_TYPES_APPOINTMENT_DETAILS.missed:
                editAppointment({
                    appointmentID: selectedItem!,
                    status: AppointmentStatus.enum.missed
                })
                break
            default:
                break
        }
    }
    return (
        <BaseCard variant={'base'} paddingX={6} paddingY={6} bodyHasBorder={false}>
            {isLoading ? (
                <>
                    <Skeleton />
                </>
            ) : isError ? (
                <ErrorBox icon={<ErrorIcon />} title={t('errors:default')} />
            ) : (
                appointment && (
                    <StyledNextAppointment>
                        <StyledPatientNameLink
                            size={'xl'}
                            to={generatePath(routes.PATIENT_DETAILS.handle.path, {patientId: appointment.patientId})}
                            variant={'tertiaryColor'}
                            className={'patient-name'}
                        >
                            {appointment?.patient?.lastName} {appointment?.patient?.firstName}
                        </StyledPatientNameLink>

                        <Flexbox align={'center'} justify={'end'} gap={6} className={'status'}>
                            <AppointmentStatusChip
                                label={t(`commons:appointment_state:${appointment.status}`)}
                                variant={appointment.status}
                            />
                            <ActionsMenu
                                actions={actionsListAppointmentDetails(appointment?.status as AppointmentStatus)}
                                onActionClick={onActionClick}
                                selectedItem={appointment?.id}
                            />
                        </Flexbox>

                        <Flexbox gap={6} align={'center'} className={'time'}>
                            <StyledAppointmentsTimeAndLocation>
                                <ClockIcon />
                                <p>
                                    {`${dayjs.utc(appointment.start).format('HH:mm')} - ${dayjs.utc(appointment.end).format('HH:mm')} 
                            (${dayjs.utc(appointment.end).diff(dayjs.utc(appointment.start), 'hour')} ${t('patient_details:appointments_tab:hour')})`}
                                </p>
                            </StyledAppointmentsTimeAndLocation>
                            <StyledAppointmentsTimeAndLocation>
                                {appointment.isRemote ? <VideoRecorderIcon size={20} /> : <MarkerPin01Icon size={20} />}
                                {office.isLoading ? <Skeleton height={24} width={200} /> : <p>{office.data?.name}</p>}
                            </StyledAppointmentsTimeAndLocation>

                            <Button variant={'secondary'} size={'sm'} disabled={hasCtaDisable}>
                                <VideoRecorderIcon />
                                {t('dashboard:meet_video')}
                            </Button>
                        </Flexbox>
                        <StyledLastWeekTitle className={'titleCard'}>{t('dashboard:last_week')}</StyledLastWeekTitle>

                        <BaseCard
                            paddingY={6}
                            paddingX={6}
                            variant={'base'}
                            cardClassName={'last-week-card'}
                            bodyHasBorder={false}
                        >
                            <Flexbox direction={'column'} fullWidth gap={5}>
                                <StyledNextAppointmentCardTitle>
                                    <p>{t('dashboard:mood')}</p>
                                </StyledNextAppointmentCardTitle>

                                {mood.isLoading ? (
                                    <Skeleton />
                                ) : mood.isError ? (
                                    <ErrorBox icon={<ErrorIcon />} title={t('errors:default')} />
                                ) : (
                                    mood.data && (
                                        <Flexbox gap={4} align={'center'} fullWidth>
                                            {mood.data.partitions.map((value, index) => (
                                                <Flexbox key={index} align={'center'} gap={2}>
                                                    <StyledNextAppointmentCardTitle>
                                                        <p>{value.percentage}%</p>
                                                    </StyledNextAppointmentCardTitle>
                                                    <MoodIcon variant={value.label as Emotion['name']} />
                                                </Flexbox>
                                            ))}
                                        </Flexbox>
                                    )
                                )}
                            </Flexbox>
                        </BaseCard>

                        <BaseCard
                            paddingY={6}
                            paddingX={6}
                            variant={'base'}
                            cardClassName={'amount-card'}
                            bodyHasBorder={false}
                        >
                            <Flexbox direction={'column'} fullWidth gap={5}>
                                <StyledNextAppointmentCardTitle>
                                    <p>{t('dashboard:amount_played')}</p>
                                </StyledNextAppointmentCardTitle>

                                {bets.isLoading ? (
                                    <Skeleton />
                                ) : bets.isError ? (
                                    <ErrorBox icon={<ErrorIcon />} title={t('errors:default')} />
                                ) : (
                                    bets.data && (
                                        <StyledBetsGrid>
                                            {bets.data.percentageBets.partitions.map((value, index) => (
                                                <Flexbox key={index} align={'center'} gap={2}>
                                                    <StyledNextAppointmentCardTitle>
                                                        <p>{value.percentage}%</p>
                                                    </StyledNextAppointmentCardTitle>
                                                    <p>{t(`commons:bets:${value.label}`)}</p>
                                                </Flexbox>
                                            ))}
                                        </StyledBetsGrid>
                                    )
                                )}
                            </Flexbox>
                        </BaseCard>
                    </StyledNextAppointment>
                )
            )}
        </BaseCard>
    )
}
