import {QueryClient, UseInfiniteQueryResult} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types.ts'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            networkMode: 'online',
            retry: 1,
            retryOnMount: true,
            retryDelay: 1000,
            staleTime: 600000, //10 min
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true
        }
    }
})

//rq-helpers
export const infiniteQueryFetchNextPage = async (infiniteQuery: UseInfiniteQueryResult) => {
    if (infiniteQuery.hasNextPage && !infiniteQuery.isFetching) {
        await infiniteQuery.fetchNextPage()
    }
}

export const QUERY_KEYS = {
    PATIENTS: 'patients',
    PATIENTS_STATS: 'patients-stats',
    PATIENTS_DETAILS: 'patients-details',
    PATIENT_DETAILS_APPOINTMENTS: 'patient-details-appointments',
    PATIENTS_BETS: 'patients-bets',
    PATIENTS_EMOTIONS: 'patients-emotion',
    PATIENTS_COUNTRIES: 'patients-countries',
    PATIENTS_STATES: 'patients-states',
    TIMELINE: 'timeline',
    EMOTION: 'emotion',

    CHECK_IN: 'check-in',
    CHECK_IN_STATS: 'check-in-stats',
    CHECK_IN_DETAILS: 'check-in-details',

    SELF_EVALUATION: 'self-evaluation',
    SELF_EVALUATION_STATS: 'self-evaluation-stats',
    SELF_EVALUATION_DETAILS: 'self-evaluation-details',

    GET_ARTICLES: 'get-articles',
    GET_EVENTS: 'get-events',
    GET_CONTACTS: 'get-contacts',
    CONTACTS_DETAILS: 'office-details',
    GET_USERS: 'get-users',
    GET_ME: 'get-me',

    SELF_EVALUATIONS_GROUP: 'sel-evaluations-group',
    VERIFIED_USER_GROUP: 'verification-user-group',
    USER_TREND: 'user-trend',
    GENDERS_TREND: 'genders-trend',
    AGES_GROUP: 'ages-group',
    EMOTIONS_GROUP: 'emotions-group',
    BETS_GROUP: 'bets-group',
    FOLLOW_UP: 'follow-up',

    GET_ROLES: 'get-roles',

    APPOINTMENTS_GROUP: 'appointments-group',
    APPOINTMENTS_TREND: 'appointments-trend',

    APPOINTMENTS: 'appointments',
    APPOINTMENT_DETAILS: 'appointment-details',
    GET_APPOINTMENTS_USERS: 'get-appointments-users',

    GET_AVAILABLE_SLOTS: 'available-slots',

    GET_SHIFTS: 'shifts',
    GET_SHIFT_DETAILS: 'shift-details',
    USER: 'user',
    NOTIFICATIONS: 'notifications'
} as const

export const MUTATION_KEYS = {
    LOGIN: 'login',
    FORGOT_PASSWORD: 'forgot-password',
    CHANGE_PASSWORD: 'change-password',

    REMOVE_CONTACT: 'remove-contact',
    ADD_CONTACT: 'add-contact',
    EDIT_CONTACT: 'edit-contact',

    REMOVE_USER: 'remove-user',
    ADD_USER: 'add-user',
    EDIT_USER: 'edit-user',

    ADD_PATIENT: 'add-patient',
    EDIT_PATIENT: 'edit-patient',

    EDIT_PROFILE: 'edit-profile',
    EDIT_PASSWORD: 'edit-password',

    RECOVERY_PASSWORD: 'recovery-password',

    NOTIFICATIONS_REMINDER: 'notifications-reminder',

    ADD_AVAILABLE_SLOTS: 'add-available-slots',
    REMOVE_AVAILABLE_SLOTS: 'remove-available-slots',

    ADD_APPOINTMENT_NOTE: 'add-appointment-note',
    EDIT_APPOINTMENT: 'edit-appointment',
    DELETE_APPOINTMENT: 'delete-appointment',

    CREATE_SHIFT: 'create-shift',
    EDIT_SHIFT: 'edit-shift',
    DELETE_SHIFT: 'delete-shift'
} as const

export type queryProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}
