import styled, {css, DefaultTheme} from 'styled-components'

type StyledPrivateLayoutProps = {
    $isSidebarOpen: boolean
}

export const StyledPrivateLayout = styled.div<StyledPrivateLayoutProps>`
    ${({$isSidebarOpen}) => css`
        height: 100%;
        display: grid;
        grid-template-columns: ${$isSidebarOpen ? 310 : 80}px 1fr;
        overflow: hidden;
        transition: grid-template-columns 0.5s;
    `}
`

export const StyledMain = styled.div(
    ({theme: {spacing, palette}}: {theme: DefaultTheme}) => css`
        overflow-y: auto;
        height: 100%;
        padding: ${spacing * 8}px 0;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 5}px;
        background: ${palette.neutral[50]};
    `
)
