import styled from 'styled-components'

export const StyledAppointmentsListWrapper = styled.div`
    width: 100%;
    height: 100%;
`
export const StyledList = styled.div`
    display: flex;
    gap: 24px;
    flex-direction: column;
`
