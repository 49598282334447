import styled, {css} from 'styled-components'
import {
    DESKTOP_TABLE_COLUMNS_PADDING,
    DESKTOP_TABLE_SCROLLBAR_WIDTH
} from '@/components/commons/table/desktop-table/style'
import {Percentage} from '@/types.ts'
import {HTMLAttributes} from 'react'

interface StyledTHeadProps extends HTMLAttributes<HTMLDivElement> {
    $isHeaderVisible?: boolean
}
export const StyledTHead = styled.div<StyledTHeadProps>(
    ({theme: {palette}, $isHeaderVisible}) => css`
        display: flex;
        height: ${$isHeaderVisible ? '44px' : '0'};
        visibility: ${$isHeaderVisible ? 'visible' : 'hidden'};
        border-bottom: 1px solid ${palette.neutral[200]};
        color: ${palette.neutral[600]};
        padding-right: ${DESKTOP_TABLE_SCROLLBAR_WIDTH};
        background-color: ${palette.neutral[50]};
    `
)

interface StyledTHProps {
    $width: Percentage
    $alignment?: 'left' | 'center' | 'right'
}
export const StyledTH = styled.div<StyledTHProps>(
    ({theme: {spacing}, $width, $alignment}) => css`
        width: ${$width};
        flex: none;
        display: flex;
        justify-content: ${$alignment == 'right' ? 'flex-end' : $alignment == 'center' ? 'center' : 'flex-start'};
        align-items: center;
        gap: ${spacing}px;
        padding: 0 ${DESKTOP_TABLE_COLUMNS_PADDING};

        & > svg {
            cursor: pointer;
        }
    `
)

export const StyledTHLabel = styled.div(
    ({theme: {spacing, typography}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        overflow: hidden;

        & h3 {
            ${typography.textXs};
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    `
)
