import {SelectProvider, useSelectStore} from '@ariakit/react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {SelectItem, SelectPopover} from '@components/ui/select-atoms/SelectAtoms.tsx'
import {ReactNode, useMemo} from 'react'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {Label} from '@components/ui/label/Label.tsx'
import {StyledSelectedValue, StyledSelectTrigger} from '@components/commons/single-select/style.ts'
import {SelectValue} from '@/types.ts'
import {useTranslation} from 'react-i18next'

export interface SingleSelectProps {
    placeholder?: string
    disabled?: boolean
    options: SelectValue[]
    value: string
    onChange: (value: string) => void
    defaultValue?: string
    label?: string
    errorMessage?: string
    helpText?: string
    required?: boolean
    minWidth?: number
    hasIconOption?: ReactNode
    iconOnTrigger?: ReactNode
    maxWidth?: number
    optionsToCheck?: SelectValue[]
}

export const SingleSelect = ({
    options,
    label,
    placeholder,
    value,
    defaultValue,
    onChange,
    errorMessage,
    disabled,
    helpText,
    required,
    minWidth = 220,
    maxWidth,
    hasIconOption,
    iconOnTrigger,
    optionsToCheck,
    ...rest
}: SingleSelectProps) => {
    const {t} = useTranslation()
    const chooseOption = optionsToCheck ? optionsToCheck : options
    const optionSelected = useMemo(() => chooseOption?.find(option => option?.value === value), [value, options])
    const store = useSelectStore({
        value: value,
        setValue: value => onChange(value),
        defaultValue: defaultValue || ''
    })

    return (
        <Flexbox gap={2} direction={'column'} fullWidth={!maxWidth}>
            <SelectProvider store={store}>
                {label && (
                    <Label>
                        {label || ''}
                        {required && '*'}
                    </Label>
                )}

                <StyledSelectTrigger
                    disabled={disabled}
                    $maxWidth={maxWidth}
                    size="sm"
                    variant="secondary"
                    fullWidth
                    minWidth={minWidth}
                    {...rest}
                >
                    <Flexbox align="center" gap={2}>
                        {iconOnTrigger && iconOnTrigger}
                        <StyledSelectedValue $hasValue={!!value} disabled={disabled}>
                            {optionSelected?.label || placeholder}
                        </StyledSelectedValue>
                    </Flexbox>
                </StyledSelectTrigger>

                <SelectPopover gutter={4} sameWidth>
                    {options?.length > 0 ? (
                        options.map(option => (
                            <SelectItem key={option?.value} value={option?.value}>
                                {option?.label}
                                {hasIconOption && option?.value == value && hasIconOption}
                            </SelectItem>
                        ))
                    ) : (
                        <SelectItem value="" disabled>
                            {t('commons:no_results')}
                        </SelectItem>
                    )}
                </SelectPopover>
            </SelectProvider>
            <InputHelpText error={errorMessage} helpText={helpText} />
        </Flexbox>
    )
}

SingleSelect.displayName = 'SingleSelect'
