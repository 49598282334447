import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledCardStatsSkeletonGrid = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: auto 1fr;
        gap: ${spacing * 4}px;
        width: 100%;
    `
)

export const StyledCardStatsHeader = styled.div(
    ({theme: {typography, palette, spacing}}) => css`
        ${typography.textMd};
        font-weight: 600;
        color: ${palette.neutral[900]};
        width: 100%;
        padding-bottom: ${spacing * 5}px;
    `
)

export const StyledCardStatsBody = styled.div(
    ({theme: {palette}}) => css`
        font-weight: 500;
        color: ${palette.neutral[900]};
        width: 100%;
        height: 100%;
    `
)

export const StyledCardStatsFooter = styled.div(
    ({theme: {typography, palette, spacing}}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.neutral[600]};
        width: 100%;
        margin-top: ${spacing * 5}px;
    `
)

export const StyledCardSkeletonContainer = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        margin-bottom: ${spacing * 5}px;
    `
)
