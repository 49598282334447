import {AGE_SELECT, APPOINTMENT_NOTIFICATION, GENRE_SELECT, RANGE_DATE, TITLE_TYPE} from '@utilities/constants/enum.ts'
import i18n from '@/translations/i18n.ts'

export const TITLE_TYPE_OPTIONS = [
    {value: TITLE_TYPE.female_doctor, label: i18n.t('select_options:title_options:female_doctor')},
    {value: TITLE_TYPE.male_doctor, label: i18n.t('select_options:title_options:male_doctor')},
    {value: TITLE_TYPE.mr, label: i18n.t('select_options:title_options:mr')},
    {value: TITLE_TYPE.mrs, label: i18n.t('select_options:title_options:mrs')}
]

export const APPOINTMENT_NOTIFICATION_OPTIONS = [
    {
        value: APPOINTMENT_NOTIFICATION.fifteen_minutes,
        label: i18n.t('select_options:appointment_notifications:fifteen_minutes')
    },
    {
        value: APPOINTMENT_NOTIFICATION.thirty_minutes,
        label: i18n.t('select_options:appointment_notifications:thirty_minutes')
    },
    {value: APPOINTMENT_NOTIFICATION.one_hour, label: i18n.t('select_options:appointment_notifications:one_hour')},
    {value: APPOINTMENT_NOTIFICATION.two_hours, label: i18n.t('select_options:appointment_notifications:two_hours')}
]

export const AGE_SELECT_OPTIONS = [
    {value: AGE_SELECT.until_17, label: i18n.t('patients:card_stats:age:14_17')},
    {value: AGE_SELECT.from_18_to_34, label: i18n.t('patients:card_stats:age:18_34')},
    {value: AGE_SELECT.from_35_to_50, label: i18n.t('patients:card_stats:age:35_50')},
    {value: AGE_SELECT.over_50, label: i18n.t('patients:card_stats:age:over_50')}
]

export const RANGE_DATE_OPTIONS = [
    {value: RANGE_DATE.last_year, label: i18n.t('patients:top_filters:last_year')},
    {value: RANGE_DATE.last_6_months, label: i18n.t('patients:top_filters:last_6_months')},
    {value: RANGE_DATE.last_3_months, label: i18n.t('patients:top_filters:last_3_months')},
    {value: RANGE_DATE.last_month, label: i18n.t('patients:top_filters:last_month')},
    {value: RANGE_DATE.last_week, label: i18n.t('patients:top_filters:last_week)')}
]

export const GENRE_SELECT_OPTIONS = [
    {value: GENRE_SELECT.male, label: i18n.t('patients:card_stats:genre:men')},
    {value: GENRE_SELECT.female, label: i18n.t('patients:card_stats:genre:women)')}
]
