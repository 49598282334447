import {z} from 'zod'

export const APPOINTMENT_FORM_MODEL = {
    patientId: {
        name: 'patientId',
        label: 'appointments:patient',
        placeholder: 'appointments:patient_placeholder',
        defaultValue: '',
        helpText: ''
    },
    officeId: {
        name: 'officeId',
        label: 'appointments:asl_site',
        placeholder: 'appointments:asl_site_placeholder',
        defaultValue: '',
        helpText: ''
    },
    isRemote: {
        name: 'isRemote',
        label: 'appointments:online',
        defaultValue: '',
        helpText: ''
    },
    date: {
        name: 'date',
        label: 'appointments:date',
        placeholder: 'appointments:date_placeholder'
    },
    time: {
        name: 'time',
        label: 'appointments:time',
        placeholder: 'appointments:time_placeholder'
    },
    therapistId: {
        name: 'therapistId',
        label: 'appointments:doctor',
        placeholder: 'appointments:select_doctor'
    }
} as const

export const AppointmentsValidationSchema = z.object({
    date: z.date().nullable(),
    time: z.string().nullish(),
    therapistId: z.string(),
    patientId: z.string(),
    officeId: z.string(),
    isRemote: z.boolean().nullish()
})

export type AppointmentsValidationSchema = z.infer<typeof AppointmentsValidationSchema>

export const HttpCreateAppointmentsPayload = z.object({
    start: z.string(),
    end: z.string(),
    officeId: z.string(),
    therapistId: z.string(),
    patientId: z.string(),
    remindAt: z.string().nullish(),
    isRemote: z.boolean().nullish()
})

export type HttpCreateAppointmentsPayload = z.infer<typeof HttpCreateAppointmentsPayload>
