import {SearchLgIcon} from '@components/ui/icon/Icon.tsx'
import {useTheme} from 'styled-components'
import {StyledEmptySearch} from '@components/commons/empty-search/style.ts'
import {FC, ReactNode} from 'react'
import {useTranslation} from 'react-i18next'
import {DefaultNamespace} from 'i18next'
import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'
import {Button} from '@components/ui/button/Button.tsx'

interface EmptySearchProps {
    onClearSearch?: () => void
    icon?: ReactNode
    title: DefaultNamespace | string
    subtitle?: DefaultNamespace | string
    customContent?: ReactNode
}
export const EmptySearch: FC<EmptySearchProps> = ({onClearSearch, title, subtitle, icon, customContent}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    return (
        <StyledEmptySearch>
            <IconContainer size="lg">{icon ?? <SearchLgIcon fill={theme.palette.neutral['700']} />}</IconContainer>
            <h3>{title}</h3>
            {!!subtitle && <h4>{subtitle}</h4>}
            {customContent}
            {onClearSearch && (
                <Button size="lg" variant="secondary" onClick={onClearSearch}>
                    {t('commons:clear_search')}
                </Button>
            )}
        </StyledEmptySearch>
    )
}

EmptySearch.displayName = 'EmptySearch'
