import styled, {css} from 'styled-components'

export const StyledLabel = styled.label`
    ${({theme: {palette, typography}}) => css`
        display: block;
        color: ${palette.neutral['700']};
        text-align: start;
        font-weight: 500;
        ${typography.textSm}
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
    `}
`
