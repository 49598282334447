import {SelectValue} from '@/types.ts'
import i18next from 'i18next'

export const SELF_ASSESSMENT_FILTERS_PATIENT_OPTIONS: SelectValue[] = [
    {
        label: i18next.t('self_assessment:filters:patient:register'),
        value: 'false'
    },
    {
        label: i18next.t('self_assessment:filters:patient:not_register'),
        value: 'true'
    }
]

export const SELF_ASSESSMENT_FILTERS_OUTCOME_OPTIONS: SelectValue[] = [
    {
        label: i18next.t('self_assessment:filters:outcome:positive'),
        value: 'positive'
    },
    {
        label: i18next.t('self_assessment:filters:outcome:negative'),
        value: 'negative'
    }
]

export const SELF_ASSESSMENT_FILTERS_PROVENANCE_OPTIONS: SelectValue[] = [
    {
        label: i18next.t('self_assessment:filters:provenance:web'),
        value: 'app'
    },
    {
        label: i18next.t('self_assessment:filters:provenance:app'),
        value: 'web'
    }
]
