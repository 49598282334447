import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'
import {z} from 'zod'
import {RangeDateLabels, ResponseType} from '@/types.ts'
import {getDateFromLabel} from '@utilities/utils.ts'
import {
    httpGetSelfEvaluation,
    HttpGetSelfEvaluationResponse
} from '@/features/self-evaluation/services/selfEvaluation.http.ts'
import {SelfEvaluationSearchParams} from '@/features/self-evaluation/types.ts'

const UseGetSelfAssessmentOptions = z.object({
    rangeDateLabels: RangeDateLabels,
    ...SelfEvaluationSearchParams.shape
})
type UseGetSelfAssessmentOptions = z.infer<typeof UseGetSelfAssessmentOptions>

export const useGetSelfEvaluation = (
    promiseArgs: UseGetSelfAssessmentOptions,
    options?: Omit<UseQueryOptions<HttpGetSelfEvaluationResponse>, 'queryFn' | 'queryKey'>
) => {
    const {rangeDateLabels, ...rest} = promiseArgs
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.SELF_EVALUATION, promiseArgs],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetSelfEvaluation({
                    ...rest,
                    ...getDateFromLabel(rangeDateLabels),
                    responseType: ResponseType.Enum.regular
                }),
                responseShape: HttpGetSelfEvaluationResponse,
                onZodError: captureException
            })
    })
}
