import styled, {css} from 'styled-components'
import {Flexbox} from '../flexbox/FlexBox'
import * as RSwitch from '@radix-ui/react-switch'

export const StyledSwitchWrapper = styled(Flexbox)(
    () => css`
        white-space: nowrap;
    `
)

export const StyledSwitchRoot = styled(RSwitch.Root)(
    ({theme: {palette}}) => css`
        width: 44px;
        height: 24px;
        background-color: ${palette.neutral[100]};
        border-radius: 48px;
        position: relative;
        transition: background-color 100ms;
        will-change: background-color;
        cursor: pointer;
        &[data-state='checked'] {
            background-color: ${palette.primary[600]};
        }
    `
)

export const StyledSwitchThumb = styled(RSwitch.Thumb)(
    ({theme: {shadows}}) => css`
        display: block;
        width: 20px;
        height: 20px;
        background-color: white;
        border-radius: 100%;
        transition: transform 100ms;
        transform: translateX(2px);
        will-change: transform;
        box-shadow: ${shadows.xs};
        &[data-state='checked'] {
            transform: translateX(22px);
        }
    `
)
