import React from 'react'
import {Container} from '@components/ui/container/Container.tsx'

export const EmailSent: React.FC = () => {
    return (
        <Container>
            <h1>Email sent</h1>
        </Container>
    )
}

EmailSent.displayName = 'EmailSent'
