import {PatientTabs} from '@/features/patient-details/types.ts'
import {PatientBets} from '@/features/patients/types.ts'
import dayjs from '@/dayjs.ts'
import {User} from '../auth/types'

export const PATIENT_DETAILS_TABS = [
    {
        id: PatientTabs.patient,
        name: 'patient_details:tabs:patient'
    },
    {
        id: PatientTabs.diary,
        name: 'patient_details:tabs:diary'
    },
    {
        id: PatientTabs.appointments,
        name: 'patient_details:tabs:appointments'
    }
]

export const PATIENT_DETAILS_INFO_TABS = [
    {
        id: 'personalData',
        name: 'patient_details:patient_tabs:info:personal_data'
    },
    {
        id: 'caregiver',
        name: 'patient_details:patient_tabs:info:caregiver'
    }
]

export const remappedGraphicData = (data: PatientBets['data']) => {
    return data.map(value => ({name: dayjs(value.date).format('ll'), amount: value.amount}))
}

export const dailyActivityLabelsDictionary: Record<
    'created' | 'updated',
    Record<'emotion' | 'bet' | 'appointment', string>
> = {
    created: {
        emotion: 'patient_details:diary_tab:timeline:add_mood',
        bet: 'patient_details:diary_tab:timeline:add_bet',
        appointment: 'patient_details:diary_tab:timeline:add_appointment'
    },
    updated: {
        emotion: '',
        bet: '',
        appointment: ''
    }
}

export const adaptPatientToDefaultValues = (patient: User) => {
    return {
        firstName: patient.firstName ?? '',
        lastName: patient.lastName ?? '',
        phoneNumber: patient.phoneNumber ?? '',
        birthCountryId: patient.birthCountry
            ? {
                  label: patient.birthCountry?.name,
                  value: patient.birthCountry?.id?.toString()
              }
            : null,
        birthStateId: patient.birthState
            ? {
                  label: patient.birthState?.name,
                  value: patient.birthState?.id?.toString()
              }
            : null,
        birthCityId: patient.birthCity
            ? {
                  label: patient.birthCity?.name,
                  value: patient.birthCity?.id?.toString()
              }
            : null,
        birthDate: patient.birthDate ? new Date(patient.birthDate) : new Date(),
        gender: patient.gender ?? '',
        taxCode: patient.taxCode ?? '',
        email: patient.email
    }
}

export const CAREGIVER_RELATIONSHIPS = [
    {
        label: 'patient_details:caregiver_relationships:father',
        value: 'father',
        selectValue: 'father',
        gender: 'male'
    },
    {
        label: 'patient_details:caregiver_relationships:mother',
        value: 'mother',
        selectValue: 'mother',
        gender: 'female'
    },
    {
        label: 'patient_details:caregiver_relationships:sibling_male',
        value: 'sibling',
        selectValue: 'sibling_male',
        gender: 'male'
    },
    {
        label: 'patient_details:caregiver_relationships:sibling_female',
        value: 'sibling',
        selectValue: 'sibling_female',
        gender: 'female'
    },
    {
        label: 'patient_details:caregiver_relationships:uncle',
        value: 'uncle',
        selectValue: 'uncle',
        gender: 'male'
    },
    {
        label: 'patient_details:caregiver_relationships:aunt',
        value: 'aunt',
        selectValue: 'aunt',
        gender: 'female'
    },
    {
        label: 'patient_details:caregiver_relationships:cousin_male',
        value: 'cousin',
        selectValue: 'cousin_male',
        gender: 'male'
    },
    {
        label: 'patient_details:caregiver_relationships:cousin_female',
        value: 'cousin',
        selectValue: 'cousin_female',
        gender: 'female'
    },
    {
        label: 'patient_details:caregiver_relationships:friend_male',
        value: 'friend',
        selectValue: 'friend_male',
        gender: 'male'
    },
    {
        label: 'patient_details:caregiver_relationships:friend_female',
        value: 'friend',
        selectValue: 'friend_female',
        gender: 'female'
    }
] satisfies Record<'value' | 'label' | 'gender' | 'selectValue', string | User['gender']>[]

export const RELATIONS_TO_GENDER = {
    'patient_details:caregiver_relationships:father': 'male',
    'patient_details:caregiver_relationships:mother': 'female',
    'patient_details:caregiver_relationships:sibling_male': 'male',
    'patient_details:caregiver_relationships:sibling_female': 'female',
    'patient_details:caregiver_relationships:uncle': 'male',
    'patient_details:caregiver_relationships:aunt': 'female',
    'patient_details:caregiver_relationships:cousin_male': 'male',
    'patient_details:caregiver_relationships:cousin_female': 'female',
    'patient_details:caregiver_relationships:friend_female': 'female'
} as const satisfies Record<(typeof CAREGIVER_RELATIONSHIPS)[number]['label'], 'male' | 'female'>
