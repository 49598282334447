import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {useInputSearch} from '@/hooks/useInputSearch'
import {useEffect} from 'react'
import {useGetSelfEvaluation} from '@/features/self-evaluation/services/useGetSelfEvaluation.ts'
import {SelfEvaluationSearchParams, SelfEvaluationUrlSearchParams} from '@/features/self-evaluation/types.ts'

export const useSelfEvaluationTable = () => {
    const {searchParams, setSearchParams} = useParsedSearchParams([], SelfEvaluationUrlSearchParams)
    const {searchValue, searchError, onSearch, onResetSearch} = useInputSearch(searchParams.search)

    const query = useGetSelfEvaluation(searchParams)

    useEffect(() => {
        setSearchParams({search: searchValue})
    }, [searchValue])

    const sort = (sorter: {
        orderBy: SelfEvaluationSearchParams['order_by']
        orderDirection: SelfEvaluationSearchParams['order_direction']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({order_by: sorter.orderBy, order_direction: sorter.orderDirection})
        } else {
            setSearchParams({order_by: undefined, order_direction: undefined})
        }
    }

    return {
        ...query,
        searchValue,
        searchError,
        onSearch,
        onResetSearch,
        filterValue: Object.keys(searchParams)
            .filter(param => param !== 'rangeDateLabels')
            .some(value => value !== null && value !== undefined),
        sorter: {orderBy: searchParams.order_by, orderDirection: searchParams.order_direction, onSort: sort}
    }
}
