import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'
import {httpGetContactDetails} from '@/features/settings/services/settings.http.ts'
import {OfficeSchema} from '@/features/settings/SettingsFormModal.ts'

export const useGetContactsDetails = (
    promiseArgs: {id: number | null},
    options?: Omit<UseQueryOptions<OfficeSchema>, 'queryFn' | 'queryKey'>
) =>
    useQuery({
        ...options,
        enabled: !!promiseArgs.id,
        queryKey: [QUERY_KEYS.CONTACTS_DETAILS, promiseArgs.id],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetContactDetails(promiseArgs.id),
                responseShape: OfficeSchema,
                onZodError: captureException
            })
    })
