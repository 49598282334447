import {z} from 'zod'
import {ResponseType} from '@/types.ts'
import {SelfEvaluation, SelfEvaluationSearchParams} from '@/features/self-evaluation/types.ts'
import axios from '@/axiosInstance'

/**
 * Get SelfAssessment
 */

export const HttpGetSelfAssessmentOptions = z.object({
    ...SelfEvaluationSearchParams.shape,
    responseType: ResponseType
})
export type HttpGetSelfAssessmentOptions = z.infer<typeof HttpGetSelfAssessmentOptions>

export const HttpGetSelfEvaluationResponse = z.array(SelfEvaluation)
export type HttpGetSelfEvaluationResponse = z.infer<typeof HttpGetSelfEvaluationResponse>

export const httpGetSelfEvaluation = (params: HttpGetSelfAssessmentOptions) => {
    return axios.get('/self-evaluations', {params})
}

/**
 * Get SelfAssessment statistics
 */
export const HttpGetSelfAssessmentStatsOptions = SelfEvaluationSearchParams.pick({startDate: true, endDate: true})
export type HttpGetSelfAssessmentStatsOptions = z.infer<typeof HttpGetSelfAssessmentStatsOptions>

export const httpGetSelfEvaluationStats = (params: HttpGetSelfAssessmentStatsOptions) => {
    return axios.get('/self-evaluations/analytics', {params})
}
