import {ChangeEvent, FC} from 'react'
import dayjs from '@/dayjs.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {ChevronLeftIcon, ChevronRightIcon, PlusIcon, SearchLgIcon} from '@components/ui/icon/Icon.tsx'
import {useTranslation} from 'react-i18next'
import {Button} from '@components/ui/button/Button.tsx'
import {StyledCalendarNavigation} from '@/features/appointments/components/doctors-calendar/calendar-navigation/style.ts'
import {AddAppointmentsModal} from '@/features/appointments/components/modals/AddAppointmentsModal.tsx'
import {useGetContacts} from '@/features/settings/services/services-contact/useGetContacts.ts'
import {useGetPatients} from '@/features/patients/services/useGetPatients.ts'
import {User} from '@/features/auth/types.ts'
import {Appointment, AppointmentFilters} from '@/features/appointments/types.ts'
import {debounce} from '@utilities/helpers.ts'

type CalendarNavigationProps = {
    setCurrentDate: (date: dayjs.Dayjs) => void
    currentDate: dayjs.Dayjs
    currentWeek: dayjs.Dayjs[]
    therapists: User[]
    setAddAppointmentModalOpen: (open: boolean) => void
    addAppointmentModalOpen: boolean
    officeId: number | null
    appointments?: Appointment[]
    setSearchParams: (params: Record<string, string>) => void
    searchParams: AppointmentFilters
}
export const CalendarNavigation: FC<CalendarNavigationProps> = ({
    therapists,
    setCurrentDate,
    currentDate,
    currentWeek,
    setAddAppointmentModalOpen,
    addAppointmentModalOpen,
    officeId,
    appointments,
    setSearchParams,
    searchParams
}) => {
    const {t} = useTranslation()
    const {data: offices} = useGetContacts()
    const {remappedData: patients} = useGetPatients()

    // Function to go to the previous week
    const goToPreviousWeek = () => {
        setCurrentDate(currentDate.subtract(1, 'week'))
    }

    // Function to go to the next week
    const goToNextWeek = () => {
        setCurrentDate(currentDate.add(1, 'week'))
    }

    // Function to go to the current week
    const goToToday = () => {
        setCurrentDate(dayjs())
    }

    return (
        <>
            <Flexbox justify={'space-between'}>
                <InputText
                    type="text"
                    width="auto"
                    placeholder={t('patients:filters:search:placeholder')}
                    typeIcon={<SearchLgIcon size={20} />}
                    onChange={debounce((event: ChangeEvent<HTMLInputElement>) => {
                        setSearchParams({
                            ...searchParams,
                            search: event.target.value
                        })
                    }, 500)}
                />

                <Flexbox gap={6}>
                    <StyledCalendarNavigation align="center" gap={4}>
                        <Flexbox align={'center'} className={'container-navigation'}>
                            <Button className={'button-navigation-left'} variant="secondary" onClick={goToPreviousWeek}>
                                <ChevronLeftIcon />
                            </Button>
                            <Button className={'button-navigation-right'} variant="secondary" onClick={goToNextWeek}>
                                <ChevronRightIcon />
                            </Button>

                            <Flexbox className={'current-week'} justify="center" align="center">
                                {currentWeek[0].format('DD')} - {currentWeek[6].format('DD MMMM YYYY')}
                            </Flexbox>
                        </Flexbox>

                        <Button variant="secondary" onClick={goToToday}>
                            {t('appointments:today')}
                        </Button>
                    </StyledCalendarNavigation>

                    <Button variant="primary" onClick={() => setAddAppointmentModalOpen(true)}>
                        <PlusIcon />
                        {t('appointments:appointment')}
                    </Button>
                </Flexbox>
            </Flexbox>
            {addAppointmentModalOpen && (
                <AddAppointmentsModal
                    offices={offices?.data ? offices.data : []}
                    patients={patients}
                    therapists={therapists}
                    onClose={() => setAddAppointmentModalOpen(false)}
                    officeId={officeId}
                    appointments={appointments}
                />
            )}
        </>
    )
}
