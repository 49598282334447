import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'
import {z} from 'zod'
import {RangeDateLabels} from '@/types.ts'
import {getDateFromLabel} from '@utilities/utils.ts'
import {SelfEvaluationStats} from '@/features/self-evaluation/types.ts'
import {httpGetSelfEvaluationStats} from '@/features/self-evaluation/services/selfEvaluation.http.ts'

const UseGetSelfAssessmentOptions = z.object({
    rangeDateLabels: RangeDateLabels
})
type UseGetSelfAssessmentOptions = z.infer<typeof UseGetSelfAssessmentOptions>

export const useGetSelfEvaluationStats = (
    promiseArgs: UseGetSelfAssessmentOptions,
    options?: Omit<UseQueryOptions<SelfEvaluationStats>, 'queryFn' | 'queryKey'>
) => {
    const {rangeDateLabels, ...rest} = promiseArgs
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.SELF_EVALUATION_STATS, promiseArgs],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetSelfEvaluationStats({
                    ...rest,
                    ...getDateFromLabel(rangeDateLabels)
                }),
                responseShape: SelfEvaluationStats,
                onZodError: captureException
            })
    })
}
