import axios from '@/axiosInstance.ts'
import {Appointment, AppointmentSearchParams, HttpEditAppointmentPayload, Slot} from '@/features/appointments/types.ts'
import {PaginationParams, PaginationResponse, ResponseType} from '@/types.ts'
import {z} from 'zod'
import {HttpCreateAppointmentsPayload} from '@/features/appointments/components/modals/AddAppointmentsSchema.tsx'
import {UseGetAppointmentsOptions} from '@/features/appointments/services/useGetAppointments.ts'
import {UseGetAvailableSlotsProps} from '@/features/appointments/services/useGetAvailableSlots.ts'

export const HttpAppointmentsOptions = z.object({
    ...AppointmentSearchParams.shape,
    ...PaginationParams.shape,
    responseType: ResponseType
})
export type HttpAppointmentsOptions = z.infer<typeof HttpAppointmentsOptions>

export const HttpAppointmentsResponse = z.object({
    meta: PaginationResponse,
    data: Appointment.array()
})
export type HttpAppointmentsResponse = z.infer<typeof HttpAppointmentsResponse>
export const httpGetPatientsIndex = (params: {roles: string[]; officeId?: number | null}) => {
    return axios.get('/backoffice/users', {
        params: {
            ...params,
            roles: params.roles,
            responseType: ResponseType.enum.regular
        }
    })
}

export const httpGetAppointment = (payload: UseGetAppointmentsOptions) => {
    return axios.get('/appointments', {
        params: {
            ...payload
        }
    })
}

export const httpGetAppointmentDetails = (appointmentID: number | null) => {
    return axios.get(`/appointments/${appointmentID}`, {
        params: {
            responseType: ResponseType.enum.regular
        }
    })
}

export const httpCreateAppointment = (params: HttpCreateAppointmentsPayload) => {
    return axios.post('/appointments', params)
}

export const httpEditAppointment = (params: HttpEditAppointmentPayload) => {
    return axios.patch(`/appointments/${params.appointmentID}`, params)
}

export const httpDeleteAppointment = (appointmentID: number) => {
    return axios.delete(`/appointments/${appointmentID}`)
}

export const httpAddAvailableSlots = (payload: Slot) => {
    return axios.post('/appointment-slots', payload)
}

export const httpDeleteAvailableSlots = (slotID: number) => {
    return axios.delete(`/appointment-slots/${slotID}`, {
        params: {
            responseType: ResponseType.enum.regular
        }
    })
}

export const httpGetAvailableSlots = ({fromDate, toDate}: UseGetAvailableSlotsProps) => {
    return axios.get('/appointment-slots', {
        params: {
            fromDate,
            toDate,
            limit: 10000
        }
    })
}

export const httpAddAppointmentNote = ({
    appointmentID,
    noteID,
    note
}: {
    appointmentID: number
    noteID: number
    note: string
}) => {
    return axios.patch(`/appointments/${appointmentID}/customizations/${noteID}`, {note})
}

export const httpDeleteAppointmentNote = ({appointmentID, noteID}: {appointmentID: number; noteID: number}) => {
    return axios.patch(`/appointments/${appointmentID}/customizations/${noteID}`, {
        note: null
    })
}
