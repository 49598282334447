import {z} from 'zod'
import {PatientsSearchParams} from '@/features/patients/types'
import {PaginationParams, PaginationResponse, Partitions, ResponseType} from '@/types'
import {User} from '@/features/auth/types'
import axios from '@/axiosInstance'

/**
 * Get patients
 */

export const HttpGetPatientsOptions = z.object({
    ...PatientsSearchParams.shape,
    ...PaginationParams.shape,
    responseType: ResponseType
})
export type HttpGetPatientsOptions = z.infer<typeof HttpGetPatientsOptions>

export const HttpGetPatientsResponse = z.object({
    data: z.array(User),
    meta: PaginationResponse
})
export type HttpGetPatientsResponse = z.infer<typeof HttpGetPatientsResponse>

export const httpGetPatients = (params: HttpGetPatientsOptions) => {
    return axios.get('/backoffice/users/patients-index', {params})
}

/**
 * Get patients statistics
 */
export const HttpGetPatientsStatsOptions = PatientsSearchParams.pick({
    currentlyInTherapy: true,
    startDate: true,
    endDate: true
})
export type HttpGetPatientsStatsOptions = z.infer<typeof HttpGetPatientsStatsOptions>

export const HttpGetPatientsStatsResponse = z.object({
    genders: z.object({
        partitions: Partitions.array()
    }),
    ages: z.object({
        partitions: Partitions.array()
    }),
    newestUsers: z.number(),
    usersIncrement: z.number().nullable()
})
export type HttpGetPatientsStatsResponse = z.infer<typeof HttpGetPatientsStatsResponse>

export const httpGetPatientsStats = (params: HttpGetPatientsStatsOptions) => {
    return axios.get('/analytics/patients', {params})
}
