import styled, {css} from 'styled-components'

export const StyledContainerPatientSection = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        display: grid;
        grid-template-columns: 280px 1fr;
        gap: ${spacing * 12}px;
        & h2 {
            ${typography.textMd};
            font-weight: 500;
            color: ${palette.neutral[900]};
        }
    `}
`
export const StyledInfoTitle = styled.h2`
    ${({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 2}px 0;
        ${typography.textMd};
        font-weight: 500;
        color: ${palette.neutral[900]};
        }
    `}
`
