import styled, {css} from 'styled-components'

export const StyledPageHeaderContainer = styled.div`
    ${({theme}) => css`
        width: 100%;
        display: grid;
        align-items: start;
        grid-template-columns: 1fr auto;
        margin-bottom: ${theme.spacing * 8}px;
    `}
`

export const StyledPageHeaderText = styled.div`
    ${({theme: {palette, typography}}) => css`
        & h1 {
            color: ${palette.neutral['900']};
            ${typography.displaySm};
            font-weight: 500;
        }
        & p {
            color: ${palette.neutral['600']};
            ${typography.textMd}
        }
        & span {
            color: ${palette.neutral['900']};
            ${typography.textLg}
            font-weight: 600;
        }
    `}
`
