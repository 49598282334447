import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledSidebarContainer = styled(Flexbox)`
    ${({theme: {palette, spacing}}) => css`
        background: ${palette.neutral.white};
        width: 100%;
        border-right: 1px solid ${palette.neutral[200]};
        padding: ${spacing * 4}px;
        height: 100%;

        transition: all 0.3s ease;
    `}
`

export const StyledLogoContainer = styled(Flexbox)<StyledSidebarItemsProps>`
    ${({theme: {spacing}, $isSidebarOpen}) => css`
        padding: ${!$isSidebarOpen ? `${spacing * 8}px 0 0 0` : `${spacing * 4}px ${spacing * 2}px`};

        & img {
            width: ${!$isSidebarOpen ? '32px' : 'auto'};
        }

        .chevron {
            transform: rotate(0deg);

            transition: transform 0.5s;
        }

        .chevron-rotate {
            transform: rotate(180deg);

            transition: transform 0.5s;
        }
    `}
`

type StyledSidebarItemsProps = {
    $isSidebarOpen: boolean
}
export const StyledSidebarItems = styled(Flexbox)<StyledSidebarItemsProps>`
    ${({theme: {spacing}, $isSidebarOpen}) => css`
        ${$isSidebarOpen
            ? css`
                  padding: ${spacing * 18}px 0 ${spacing * 10}px 0;
                  width: 100%;
              `
            : css`
                  align-items: center;
                  justify-content: center;
              `}
    `}
`
type StyledUserInformationContainerProps = {
    $isVisible: boolean
    $isActive: boolean
}
export const StyledUserInformationContainer = styled(Flexbox)<StyledUserInformationContainerProps>`
    ${({theme, $isVisible, $isActive}) => css`
        cursor: pointer;
        width: 100%;
        border: 1px solid ${theme.palette.neutral[300]};
        border-radius: ${theme.spacing * 3}px;
        padding: ${theme.spacing * 2}px ${theme.spacing * 6}px;
        overflow: hidden;
        transition: max-height 0.3s ease;
        max-height: ${$isVisible ? '100px' : '0'};
        background-color: ${$isActive ? theme.palette.primary[100] : theme.palette.neutral.white};

        & p,
        & span {
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        & p {
            color: ${theme.palette.neutral[700]};
            font-weight: 600;
            ${theme.typography.textSm};
        }

        & span {
            color: ${theme.palette.neutral[600]};
            ${theme.typography.textXs};
        }
    `}
`

export const StyledWrapperLastSidebarItems = styled(Flexbox)`
    ${({theme}) => css`
        margin-top: ${theme.spacing * 4}px;
        padding: ${theme.spacing * 3}px ${theme.spacing * 2}px;
        transition:
            margin-top 0.3s ease,
            padding 0.3s ease;
    `}
`

export const StyledLogoutContainer = styled(Flexbox)`
    ${({theme}) => css`
        width: 100%;
        padding: 0 ${theme.spacing * 2}px;
        cursor: pointer;

        & p {
            color: ${theme.palette.neutral[700]};
            ${theme.typography.textSm};
        }
    `}
`

export const StyledContainerBottomItems = styled.div`
    ${() => css`
        margin-top: auto;
        width: 100%;
        cursor: pointer;
    `}
`

//minimized sidebar

export const StyledContainerWrapper = styled(Flexbox)`
    ${({theme}) => css`
        padding-top: ${theme.spacing * 2}px;
        transition: padding-top 0.3s ease;
    `}
`
