import {useInfiniteQuery} from '@tanstack/react-query'
import {QUERY_KEYS, infiniteQueryFetchNextPage} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance'
import {captureException} from '@sentry/react'
import {httpGetNotifications, HttpGetNotificationsResponse} from '@/features/dashboard/services/dashboard.http.ts'

export const useGetNotifications = () => {
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.NOTIFICATIONS],
        queryFn: ({pageParam = 1}) =>
            parseAxiosPromise({
                axiosPromise: httpGetNotifications({page: pageParam}),
                responseShape: HttpGetNotificationsResponse,
                onZodError: captureException
            }),
        initialPageParam: 1,
        getNextPageParam: ({meta}) => (meta.current_page < meta.last_page ? meta.current_page + 1 : null)
    })

    const remappedData = query.data?.pages[0]?.data?.length ? query.data.pages.flatMap(page => page.data) : []

    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData
    }
}
