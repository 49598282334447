import {Appointment, AvailableSlotsForDoctor, Slot, WeekSlots} from '@/features/appointments/types.ts'
import {convertToLocalTime} from '@/features/appointments/components/doctors-calendar/DoctorsCalendar.tsx'
function initializeSlots() {
    const days = ['lun', 'mar', 'mer', 'gio', 'ven', 'sab', 'dom']
    const slots: WeekSlots = {}

    days.forEach(day => {
        slots[day] = []
        for (let hour = 8; hour <= 18; hour++) {
            slots[day].push({start: hour, end: hour + 1, isAvailable: false, isBooked: false})
        }
    })

    return slots
}

export const createAvailableSlotsForDoctors = (
    appointments: Appointment[],
    availableSlotsAbleToBook: Slot[],
    doctorsIds: number[] | undefined
) => {
    const availableSlotsForDoctor: AvailableSlotsForDoctor = {}

    // Process availableSlotsAbleToBook
    availableSlotsAbleToBook
        ? availableSlotsAbleToBook.forEach(slotInfo => {
              const userId = slotInfo.userId as keyof typeof availableSlotsForDoctor
              const startDateLocal = convertToLocalTime(slotInfo.start)
              const endDateLocal = convertToLocalTime(slotInfo.end)
              const startDate = startDateLocal.utc().hour()
              const endDate = endDateLocal.utc().hour()
              const day = startDateLocal.format('ddd').toLowerCase()

              if (!availableSlotsForDoctor[userId]) {
                  availableSlotsForDoctor[userId] = {slot: initializeSlots()}
              }

              for (let hour = startDate; hour < endDate; hour++) {
                  availableSlotsForDoctor[userId].slot[day] = availableSlotsForDoctor[userId].slot[day]?.map(slot => {
                      if (slot.start === hour) {
                          return {...slot, slotInfo, isAvailable: !!slotInfo}
                      }
                      return slot
                  })
              }
          })
        : null

    // Process appointments
    appointments
        ? appointments.forEach(appointment => {
              const therapistId = appointment.therapistId as keyof typeof availableSlotsForDoctor
              const startDateLocal = convertToLocalTime(appointment.start)
              const endDateLocal = convertToLocalTime(appointment.end)
              const startDate = startDateLocal.utc().hour()
              const endDate = endDateLocal.utc().hour()
              const day = startDateLocal.format('ddd').toLowerCase()

              if (startDate < 8 || endDate > 18) {
                  return
              }

              if (!availableSlotsForDoctor[therapistId]) {
                  availableSlotsForDoctor[therapistId] = {slot: initializeSlots()}
              }

              for (let hour = startDate; hour < endDate; hour++) {
                  availableSlotsForDoctor[therapistId].slot[day] = availableSlotsForDoctor[therapistId].slot[day]?.map(
                      slot => {
                          if (slot.start === hour) {
                              return {...slot, appointment, isBooked: !!appointment}
                          }
                          return slot
                      }
                  )
              }
          })
        : null

    doctorsIds?.forEach(doctorId => {
        if (!availableSlotsForDoctor[doctorId]) {
            availableSlotsForDoctor[doctorId] = {slot: initializeSlots()}
        }
    })
    return availableSlotsForDoctor
}
