import axios from '@/axiosInstance'
import {EditPassword} from '@/features/profile/types.ts'

export const httpEditUserMe = (data: object) => {
    return axios.patch(`/users/profile`, data)
}

export const httpEditPassword = (payload: EditPassword) => {
    return axios.post(`auth/change-password`, payload)
}

export const httpEditNotificationsReminder = (payload: {ids: number[]}) => {
    return axios.post(`/users/profile/settings/notifications`, payload)
}
