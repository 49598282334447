import {InputText} from '@/components/commons/input-text/InputText'
import {useTranslation} from 'react-i18next'
import {FilterLinesIcon, SearchLgIcon} from '@/components/ui/icon/Icon'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {useMemo, useState} from 'react'
import {ObjectValues} from '@/utilities/helpers'
import {CheckInFilters} from '@/features/check-in/types.ts'
import {
    CHECK_IN_FILTERS_MODEL,
    CheckInFiltersValidationSchema
} from '@/features/check-in/components/check-in-table-filter/CheckInFiltersModel.ts'
import {useCheckInTable} from '@/features/check-in/services/useCheckInTable.ts'
import {Popover} from '@components/ui/popover/Popover.tsx'
import {
    StyledCheckInTableFiltersTrigger,
    StyledFiltersContent
} from '@/features/check-in/components/check-in-table-filter/style.ts'
import {SelectProvider} from '@ariakit/react'
import {Label} from '@components/ui/label/Label.tsx'
import {SelectTrigger} from '@components/ui/select-atoms/SelectTrigger.tsx'
import {InputStatusIcon} from '@components/ui/input-status-icon/InputStatusIcon.tsx'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {SelectItem, SelectPopover} from '@components/ui/select-atoms/SelectAtoms.tsx'
import {MOONEY_GAMBLED_SELECT_OPTIONS, STATE_SELECT_OPTIONS} from '@/features/check-in/utils.ts'
import {MoodIcon} from '@components/commons/mood-icon/MoodIcon.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {SingleSelect} from '@components/commons/single-select/SingleSelect.tsx'
import {useGetEmotion} from '@/features/patient-details/services/useGetEmotion.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'

export const CheckInTableFilters = () => {
    const {t} = useTranslation()
    const {searchValue, onSearch} = useCheckInTable()
    const {searchParams, setSearchParams} = useParsedSearchParams([], CheckInFilters)
    const [popoverVisibility, setPopoverVisibility] = useState(false)
    const emotions = useGetEmotion()

    const {
        handleSubmit,
        control,
        reset,
        formState: {errors, touchedFields}
    } = useForm<CheckInFiltersValidationSchema>({
        mode: 'onSubmit',
        resolver: zodResolver(CheckInFiltersValidationSchema),
        defaultValues: {
            ...searchParams,
            emotion: searchParams.emotionId?.toString()
        }
    })
    const onSubmit: SubmitHandler<CheckInFiltersValidationSchema> = filters => {
        setSearchParams({
            emotionId: filters.emotion ? parseInt(filters.emotion) : undefined,
            bets: filters.bets || undefined,
            checkInCompleted: filters.checkInCompleted || undefined
        })
        setPopoverVisibility(false)
    }
    const onClear = () => {
        reset({emotion: '', bets: '', checkInCompleted: ''})
        setSearchParams({
            emotionId: undefined,
            bets: undefined,
            checkInCompleted: undefined
        })
    }
    const appliedFiltersCount = useMemo(
        () => ObjectValues(searchParams).filter(param => !!param).length,
        [searchParams]
    )

    const getSelectedMood = (value: number) => {
        const selectedMood = emotions.data?.data.find(item => item.id === value)
        if (selectedMood) {
            return <MoodIcon variant={selectedMood.name} label={t(`check_in:filters:mood:${selectedMood.name}`)} />
        }
    }
    return (
        <Flexbox align="center" justify="space-between" fullWidth>
            <InputText
                type="text"
                width="320px"
                placeholder={t('check_in:filters:search:placeholder')}
                typeIcon={<SearchLgIcon size={20} />}
                onChange={e => onSearch(e.currentTarget.value)}
                defaultValue={searchValue}
            />
            <Popover
                rootProps={{
                    open: popoverVisibility,
                    onOpenChange: value => setPopoverVisibility(value)
                }}
                triggerProps={{asChild: true}}
                contentProps={{align: 'end'}}
                trigger={
                    <StyledCheckInTableFiltersTrigger variant="secondaryColor" $hasFilters={!!appliedFiltersCount}>
                        <FilterLinesIcon />
                        <span>{t('commons:filters')}</span>
                        {!!appliedFiltersCount && <b>({appliedFiltersCount})</b>}
                    </StyledCheckInTableFiltersTrigger>
                }
            >
                <StyledFiltersContent render="form" direction="column" gap={4} onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        render={({field: {onChange, value}}) => (
                            <SelectProvider
                                defaultValue={CHECK_IN_FILTERS_MODEL.emotion.defaultValue}
                                value={value ?? undefined}
                                setValue={value => onChange(value)}
                            >
                                <Flexbox gap={1.5} direction="column" fullWidth>
                                    <Label>{t(CHECK_IN_FILTERS_MODEL.emotion.label)}</Label>
                                    <Flexbox direction="column" fullWidth>
                                        <SelectTrigger
                                            size="lg"
                                            variant="secondary"
                                            placeholder={t(CHECK_IN_FILTERS_MODEL.emotion.placeholder)}
                                            fullWidth
                                        >
                                            {value && getSelectedMood(parseInt(value))}
                                        </SelectTrigger>
                                        <InputStatusIcon
                                            touched={touchedFields.emotion}
                                            hasError={!!errors.emotion?.message}
                                        />
                                    </Flexbox>
                                    <InputHelpText error={errors.emotion?.message} />
                                </Flexbox>
                                <SelectPopover gutter={4} sameWidth>
                                    {emotions.isLoading ? (
                                        <Skeleton />
                                    ) : (
                                        emotions.data?.data.map(value => (
                                            <SelectItem key={value?.id} value={value?.id.toString()}>
                                                <MoodIcon
                                                    variant={value?.name}
                                                    label={t(`check_in:filters:mood:${value.name}`)}
                                                />
                                            </SelectItem>
                                        ))
                                    )}
                                </SelectPopover>
                            </SelectProvider>
                        )}
                        control={control}
                        name={CHECK_IN_FILTERS_MODEL.emotion.name}
                    />
                    <Controller
                        render={({field: {onChange, value}}) => (
                            <SingleSelect
                                options={MOONEY_GAMBLED_SELECT_OPTIONS}
                                value={value || ''}
                                label={t(CHECK_IN_FILTERS_MODEL.bets.label)}
                                onChange={onChange}
                                placeholder={t(CHECK_IN_FILTERS_MODEL.bets.placeholder)}
                                errorMessage={errors.bets?.message}
                            />
                        )}
                        control={control}
                        name={CHECK_IN_FILTERS_MODEL.bets.name}
                    />
                    <Controller
                        render={({field: {onChange, value}}) => (
                            <SingleSelect
                                options={STATE_SELECT_OPTIONS}
                                value={value || ''}
                                label={t(CHECK_IN_FILTERS_MODEL.checkInCompleted.label)}
                                onChange={onChange}
                                placeholder={t(CHECK_IN_FILTERS_MODEL.checkInCompleted.placeholder)}
                                errorMessage={errors.checkInCompleted?.message}
                            />
                        )}
                        control={control}
                        name={CHECK_IN_FILTERS_MODEL.checkInCompleted.name}
                    />
                    <Flexbox fullWidth justify="end" gap={3}>
                        <Button type="button" variant="secondary" onClick={onClear}>
                            {t('commons:cancel')}
                        </Button>
                        <Button type="submit">{t('commons:apply')}</Button>
                    </Flexbox>
                </StyledFiltersContent>
            </Popover>
        </Flexbox>
    )
}
