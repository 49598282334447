import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledAuthWrapper = styled(Flexbox)`
    height: 100%;
`

export const StyledAuthTitle = styled.h3(
    ({theme: {typography, palette}}) => css`
        ${typography.displaySm};
        font-weight: 500;
        color: ${palette.neutral[900]};
    `
)

export const StyledAuthSubtitle = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};
        font-weight: 400;
        color: ${palette.neutral[600]};
        text-align: center;
    `
)

export const StyledAuthContent = styled(Flexbox)`
    max-width: 360px;
    width: 100%;
`

export const StyledAuthForm = styled.form`
    width: 100%;
`
