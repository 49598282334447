import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {BaseCard} from '@components/commons/cards/BaseCard.tsx'
import {
    StyledContainerEventsAndArticles,
    StyledSectionCardsTitle,
    StyledSelfEvaluationCardWrapper
} from '@/features/dashboard/views/style.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {CalendarIcon, PlusIcon, WordPressIcon} from '@components/ui/icon/Icon.tsx'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {routes} from '@utilities/constants/routes.tsx'
import {SelfEvaluationCardsStats} from '@/features/self-evaluation/components/self-evaluation-cards-stats/SelfEvaluationCardsStats.tsx'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {DateRangeSelect} from '@components/commons/date-range-select/DateRangeSelect.tsx'
import {OperatorTodayAppointment} from '@/features/dashboard/components/appointments/OperatorTodayAppointment.tsx'
import {SelfEvaluationUrlSearchParams} from '@/features/self-evaluation/types.ts'
import {ArticleItem} from '@components/commons/article-item/ArticleItem.tsx'
import {useGetArticles} from '@/features/articles/services/useGetArticles.ts'
import {EmptyState} from '@components/commons/empty-state/EmptyState.tsx'
import {envVars} from '@/envVars.ts'
import {useGetEvents} from '@/features/events/services/useGetEvents.ts'
import {AddAppointmentsModal} from '@/features/appointments/components/modals/AddAppointmentsModal.tsx'
import {useState} from 'react'
import {useGetContacts} from '@/features/settings/services/services-contact/useGetContacts.ts'
import {useGetPatients} from '@/features/patients/services/useGetPatients.ts'
import {useGetUsers} from '@/features/settings/services/services-users/useGetUsers.ts'
import {ResponseType} from '@/types.ts'
import {useGetAppointments} from '@/features/appointments/services/useGetAppointments.ts'
import {useGetAppointmentDetails} from '@/features/appointments/services/useGetAppointmentDetails.ts'
import dayjs from '@/dayjs.ts'

const now = dayjs()
export const DashboardSecretary = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {searchParams, setSearchParams} = useParsedSearchParams([], SelfEvaluationUrlSearchParams)
    const [openAddOrEditAppointmentModal, setOpenAddOrEditAppointmentModal] = useState<{
        open: boolean
        id: number | null
    }>({
        open: false,
        id: null
    })
    const {data: articles, isLoading: isLoadingArticles} = useGetArticles()
    const {data: events, isLoading: isLoadingEvents} = useGetEvents()

    const {data: offices} = useGetContacts()
    const {remappedData: patients} = useGetPatients({limit: 10000})
    const {remappedData: therapist} = useGetUsers({
        ...searchParams,
        responseType: ResponseType.enum.backofficeRegular,
        limit: 100,
        roles: ['therapist']
    })

    const {remappedData: appointments} = useGetAppointments({
        limit: 1000,
        fromDate: now.subtract(1, 'year').toISOString(),
        toDate: now.add(1, 'year').toISOString()
    })

    const {data: appointment, isLoading} = useGetAppointmentDetails(openAddOrEditAppointmentModal.id)

    return (
        <>
            {/*today appointment*/}
            <OperatorTodayAppointment setOpenAddOrEditAppointmentModal={setOpenAddOrEditAppointmentModal} />
            {openAddOrEditAppointmentModal.open && !isLoading && (
                <AddAppointmentsModal
                    offices={offices?.data ? offices.data : []}
                    patients={patients}
                    therapists={therapist}
                    onClose={() => setOpenAddOrEditAppointmentModal({open: false, id: null})}
                    officeId={null}
                    appointments={appointments}
                    appointment={appointment}
                />
            )}

            <BaseCard
                variant={'base'}
                headerSlot={
                    <StyledSelfEvaluationCardWrapper align={'center'} justify={'space-between'} fullWidth>
                        <p>{t('self_assessment:title')}</p>
                        <DateRangeSelect
                            value={searchParams.rangeDateLabels}
                            icon={<CalendarIcon />}
                            onChange={value => setSearchParams({rangeDateLabels: value})}
                        />
                    </StyledSelfEvaluationCardWrapper>
                }
            >
                <StyledSelfEvaluationCardWrapper>
                    <SelfEvaluationCardsStats />
                </StyledSelfEvaluationCardWrapper>
            </BaseCard>

            {/*events and articles*/}
            <Flexbox fullWidth gap={6}>
                <BaseCard
                    variant={'sectioned'}
                    headerSlot={
                        <>
                            <StyledSectionCardsTitle>{t('dashboard:last_articles')}</StyledSectionCardsTitle>
                            {articles?.length !== 0 && !isLoadingArticles && (
                                <Button onClick={() => window.open(envVars.VITE_CREATE_ARTICLES_ENDPOINT, '_blank')}>
                                    <PlusIcon />
                                    {t('dashboard:new_article')}
                                </Button>
                            )}
                        </>
                    }
                    footerSlot={
                        <Button variant={'tertiaryColor'} onClick={() => navigate(routes.ARTICLES.path)}>
                            {t('dashboard:see_all')}
                        </Button>
                    }
                >
                    <StyledContainerEventsAndArticles>
                        {!articles?.length && !isLoadingArticles ? (
                            <EmptyState
                                title={t('articles:title')}
                                description={t('articles:subtitle')}
                                onClickCta={() => window.open(envVars.VITE_CREATE_ARTICLES_ENDPOINT, '_blank')}
                                ctaLabel={
                                    <>
                                        <PlusIcon />
                                        <p>{t('articles:cta')}</p>
                                    </>
                                }
                                icon={<WordPressIcon />}
                            />
                        ) : (
                            articles?.map(article => <ArticleItem key={article.id} size={'sm'} article={article} />)
                        )}
                    </StyledContainerEventsAndArticles>
                </BaseCard>

                <BaseCard
                    variant={'sectioned'}
                    headerSlot={
                        <>
                            <StyledSectionCardsTitle>{t('dashboard:last_events')}</StyledSectionCardsTitle>
                            {articles?.length !== 0 && !isLoadingEvents && (
                                <Button onClick={() => window.open(envVars.VITE_CREATE_ARTICLES_ENDPOINT, '_blank')}>
                                    <PlusIcon />
                                    {t('dashboard:new_event')}
                                </Button>
                            )}
                        </>
                    }
                    footerSlot={
                        <Button variant={'tertiaryColor'} onClick={() => navigate(routes.EVENTS.path)}>
                            {t('dashboard:see_all')}
                        </Button>
                    }
                >
                    <StyledContainerEventsAndArticles>
                        {!articles?.length && !isLoadingEvents ? (
                            <EmptyState
                                title={t('events:title')}
                                description={t('events:subtitle')}
                                onClickCta={() => window.open(envVars.VITE_CREATE_ARTICLES_ENDPOINT, '_blank')}
                                ctaLabel={
                                    <>
                                        <PlusIcon />
                                        <p>{t('events:cta')}</p>
                                    </>
                                }
                                icon={<WordPressIcon />}
                            />
                        ) : (
                            events?.map(article => <ArticleItem key={article.id} size={'sm'} article={article} />)
                        )}
                    </StyledContainerEventsAndArticles>
                </BaseCard>
            </Flexbox>
        </>
    )
}
