import {isAxiosError} from 'axios'
import i18next from 'i18next'
import toast from 'react-hot-toast'
import dayjs, {ConfigType} from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import i18n from '@/translations/i18n'

dayjs.extend(LocalizedFormat)

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

type ReactSelect = {
    label: string
    value: string | number
}
export const retrieveSelectSingleValue = (options: ReactSelect[], value: string) => {
    return options.find(option => option.value.toString() == value)
}

export const retrieveSelectMultiValues = (options: ReactSelect[], values: [string]) => {
    return options.filter(option => values.find(value => value.toString() == option.value.toString()))
}

export const getInitials = (name: string) => {
    return name.match(/\b\w/g) || []
}

export const megabytesToBytes = (megabytes: number) => megabytes * 1_000_000

/**
 * Function that throws an error, it's useful to prevent impossible states in the application.
 * Example: const id = params.id ?? raise('No id provided'), in this case id is a string instead of string | undefined
 * @param error
 */
export const raise = (error: string): never => {
    throw new Error(error)
}

export const debounce = <T extends (...args: Parameters<T>) => ReturnType<T>>(
    callback: T,
    delay: number
): ((...args: Parameters<T>) => void) => {
    let timeout: ReturnType<typeof setTimeout>

    return (...args: Parameters<T>) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            callback(...args)
        }, delay)
    }
}

export function round(value: number, precision?: number) {
    const multiplier = Math.pow(10, precision || 0)
    return Math.round(value * multiplier) / multiplier
}

export const errorHandler = (error: unknown) => {
    if (isAxiosError(error)) {
        const message =
            error.response?.data.message && error.status != 500
                ? error.response.data.message
                : i18next.t('errors:default')
        return toast.error(message)
    }

    toast.error(i18next.t('errors:default'))
    return error
}

export const ObjectKeys = Object.keys as <T extends object>(object: T) => Array<keyof T>
export const ObjectValues = Object.values as <T extends object>(object: T) => Array<T[keyof T]>
export const ObjectEntries = Object.entries as <T extends object>(
    object: T
) => Array<{[K in keyof T]: [K, T[K]]}[keyof T]>

/*date time helpers*/
export const formatLocaleDate = (date: NonNullable<ConfigType>, formatType?: string) =>
    dayjs(date)
        .locale(i18n.language)
        .format(formatType || 'll')

export const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text
}

export const formatCurrency = (amount = 0, currency?: string) =>
    new Intl.NumberFormat('it', {
        style: 'currency',
        currency: currency ?? 'EUR'
    })
        .format(amount)
        .replace('€', '')
        .trim()

export const calculatePercentage = (total: number, count: number): number => {
    if (total === 0) {
        return 0
    }
    return (count / total) * 100
}
