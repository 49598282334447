import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts'
import {useOutletContext} from 'react-router-dom'
import {PatientDetailsBetsUrlParams, PatientDetailsURLParams} from '@/features/patient-details/types.ts'
import {useGetPatientDetailsBets} from '@/features/patient-details/services/useGetPatientDetailsBets.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {remappedGraphicData} from '@/features/patient-details/utils.ts'
import {useTheme} from 'styled-components'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {BaseCard} from '@components/commons/cards/BaseCard.tsx'

export const PatientMoneyGambledGraphicChart = () => {
    const {urlParams} = useOutletContext<{urlParams: PatientDetailsURLParams}>()
    const {searchParams} = useParsedSearchParams([], PatientDetailsBetsUrlParams)
    const patientMoneyGambled = useGetPatientDetailsBets({
        patientId: urlParams.patientId,
        betsRangeDateLabels: searchParams.betsRangeDateLabels
    })
    const {palette} = useTheme()

    return (
        <BaseCard paddingX={6} paddingY={6} bodyHasBorder={false}>
            {patientMoneyGambled.isLoading ? (
                <Skeleton height={200} />
            ) : (
                patientMoneyGambled.data && (
                    <ResponsiveContainer width={'100%'} height={200}>
                        <LineChart data={remappedGraphicData(patientMoneyGambled.data.data) || []}>
                            <CartesianGrid vertical={false} />
                            <XAxis strokeOpacity={0} dy={8} dataKey={'name'} />
                            <YAxis strokeOpacity={0} dx={-4} domain={[0, 1000]} />
                            <Line
                                dot={false}
                                type={'monotone'}
                                dataKey="amount"
                                name={'Euro'}
                                stroke={palette.primary[700]}
                                strokeWidth={4}
                            />
                            <Tooltip />
                        </LineChart>
                    </ResponsiveContainer>
                )
            )}
        </BaseCard>
    )
}
