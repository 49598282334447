import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {httpGetRoles} from '@/features/settings/services/settings.http.ts'
import {captureException} from '@sentry/react'
import {HttpGetRolesResponse} from '@/features/settings/types.ts'

export const useGetUsersRoles = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_ROLES],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetRoles(),
                responseShape: HttpGetRolesResponse,
                onZodError: captureException
            })
    })
}
