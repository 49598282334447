import {forwardRef} from 'react'
import {ButtonProps} from '@/components/ui/button/Button'
import {StyledSelectTrigger, StyledSelectTriggerPlaceholder} from '@/components/ui/select-atoms/style'
import {Role, Select} from '@ariakit/react'
import {ChevronDownIcon} from '../icon/Icon'
import i18n from '@/translations/i18n'
import {DefaultNamespace} from 'i18next'

type SelectTriggerProps = {
    minWidth?: number
    isPointer?: boolean
    placeholder?: string | DefaultNamespace
} & ButtonProps

export const SelectTrigger = forwardRef<HTMLSelectElement, SelectTriggerProps>(
    ({minWidth, isPointer, placeholder = i18n.t('commons:select_placeholder'), ...props}, ref) => {
        return (
            <Select
                render={
                    <Role.select
                        ref={ref}
                        render={
                            <StyledSelectTrigger
                                shape="rectangle"
                                $minWidth={minWidth}
                                $isPointer={isPointer}
                                type="button"
                                {...props}
                            >
                                {props.children ?? (
                                    <StyledSelectTriggerPlaceholder>{placeholder}</StyledSelectTriggerPlaceholder>
                                )}
                                <ChevronDownIcon className="select-indicator" />
                            </StyledSelectTrigger>
                        }
                    />
                }
            ></Select>
        )
    }
)

SelectTrigger.displayName = 'SelectTrigger'
