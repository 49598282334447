import {CheckIn, CheckInStatsResponse} from '@/features/check-in/types.ts'
import {CheckInDetails} from '@/features/check-in-details/types.ts'

export const articles = [
    {
        id: 1,
        title: 'Article 1',
        image: 'https://plus.unsplash.com/premium_photo-1670020262456-774caa9d216c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris.',
        date: '2024-04-08',
        type: 'articles'
    },
    {
        id: 2,
        title: 'Article 2',
        image: 'https://plus.unsplash.com/premium_photo-1670020262456-774caa9d216c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris.',
        date: '2021-01-01',
        type: 'articles'
    },
    {
        id: 3,
        title: 'Article 3',
        image: 'https://plus.unsplash.com/premium_photo-1670020262456-774caa9d216c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris.',
        date: '2021-01-01',
        type: 'articles'
    }
]

export const events = [
    {
        id: 1,
        title: 'Events 1',
        image: 'https://plus.unsplash.com/premium_photo-1670020262456-774caa9d216c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris.',
        date: '2024-04-08',
        type: 'events'
    },
    {
        id: 2,
        title: 'Events 2',
        image: 'https://plus.unsplash.com/premium_photo-1670020262456-774caa9d216c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris.',
        date: '2021-01-01',
        type: 'events'
    },
    {
        id: 3,
        title: 'Events 3',
        image: 'https://plus.unsplash.com/premium_photo-1670020262456-774caa9d216c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris.',
        date: '2021-01-01',
        type: 'events'
    },
    {
        id: 1,
        title: 'Events 1',
        image: 'https://plus.unsplash.com/premium_photo-1670020262456-774caa9d216c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris.',
        date: '2024-04-08',
        type: 'events'
    },
    {
        id: 2,
        title: 'Events 2',
        image: 'https://plus.unsplash.com/premium_photo-1670020262456-774caa9d216c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris.',
        date: '2021-01-01',
        type: 'events'
    },
    {
        id: 3,
        title: 'Events 3',
        image: 'https://plus.unsplash.com/premium_photo-1670020262456-774caa9d216c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris.',
        date: '2021-01-01',
        type: 'events'
    },
    {
        id: 3,
        title: 'Events 3',
        image: 'https://plus.unsplash.com/premium_photo-1670020262456-774caa9d216c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris.',
        date: '2021-01-01',
        type: 'events'
    },
    {
        id: 1,
        title: 'Events 1',
        image: 'https://plus.unsplash.com/premium_photo-1670020262456-774caa9d216c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris.',
        date: '2024-04-08',
        type: 'events'
    },
    {
        id: 2,
        title: 'Events 2',
        image: 'https://plus.unsplash.com/premium_photo-1670020262456-774caa9d216c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris.',
        date: '2021-01-01',
        type: 'events'
    },
    {
        id: 3,
        title: 'Events 3',
        image: 'https://plus.unsplash.com/premium_photo-1670020262456-774caa9d216c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris.',
        date: '2021-01-01',
        type: 'events'
    },
    {
        id: 3,
        title: 'Events 3',
        image: 'https://plus.unsplash.com/premium_photo-1670020262456-774caa9d216c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris.',
        date: '2021-01-01',
        type: 'events'
    },
    {
        id: 1,
        title: 'Events 1',
        image: 'https://plus.unsplash.com/premium_photo-1670020262456-774caa9d216c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris.',
        date: '2024-04-08',
        type: 'events'
    },
    {
        id: 2,
        title: 'Events 2',
        image: 'https://plus.unsplash.com/premium_photo-1670020262456-774caa9d216c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris.',
        date: '2021-01-01',
        type: 'events'
    },
    {
        id: 3,
        title: 'Events 3',
        image: 'https://plus.unsplash.com/premium_photo-1670020262456-774caa9d216c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris.',
        date: '2021-01-01',
        type: 'events'
    },
    {
        id: 3,
        title: 'Events 3',
        image: 'https://plus.unsplash.com/premium_photo-1670020262456-774caa9d216c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris.',
        date: '2021-01-01',
        type: 'events'
    }
]

export const availableSlotsForDoctor = {
    1: {
        slot: {
            mon: [
                {start: 8, end: 9, hasSlot: false},
                {start: 9, end: 10, hasSlot: false},
                {start: 10, end: 11, hasSlot: true},
                {start: 11, end: 12, hasSlot: true},
                {start: 12, end: 13, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 13, end: 14, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ],
            tue: [
                {start: 8, end: 9, hasSlot: false},
                {start: 9, end: 10, hasSlot: false},
                {start: 10, end: 11, hasSlot: false},
                {start: 11, end: 12, hasSlot: false},
                {start: 12, end: 13, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 13, end: 14, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ],
            wed: [
                {start: 8, end: 9, hasSlot: true},
                {start: 9, end: 10, hasSlot: true},
                {start: 10, end: 11, hasSlot: true},
                {start: 11, end: 12, hasSlot: true},
                {start: 12, end: 13, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 13, end: 14, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ],
            thu: [
                {start: 8, end: 9, hasSlot: false},
                {start: 9, end: 10, hasSlot: false},
                {start: 10, end: 11, hasSlot: false},
                {start: 11, end: 12, hasSlot: false},
                {start: 12, end: 13, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 13, end: 14, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ],
            fri: [
                {start: 8, end: 9, hasSlot: true},
                {start: 9, end: 10, hasSlot: true, appointment: {name: 'Mario Rossi', location: 'in-person'}},
                {start: 10, end: 11, hasSlot: true},
                {start: 11, end: 12, hasSlot: true},
                {start: 12, end: 13, hasSlot: true},
                {start: 13, end: 14, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ],
            sat: [
                {start: 8, end: 9, hasSlot: false},
                {start: 9, end: 10, hasSlot: false},
                {start: 10, end: 11, hasSlot: false},
                {start: 11, end: 12, hasSlot: false},
                {start: 12, end: 13, hasSlot: false},
                {start: 13, end: 14, hasSlot: false},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ],
            sun: [
                {start: 8, end: 9, hasSlot: false},
                {start: 9, end: 10, hasSlot: false},
                {start: 10, end: 11, hasSlot: false},
                {start: 11, end: 12, hasSlot: false},
                {start: 12, end: 13, hasSlot: false},
                {start: 13, end: 14, hasSlot: false},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ]
        }
    },
    2: {
        slot: {
            mon: [
                {start: 8, end: 9, hasSlot: false},
                {start: 9, end: 10, hasSlot: false},
                {start: 10, end: 11, hasSlot: true},
                {start: 11, end: 12, hasSlot: true},
                {start: 12, end: 13, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 13, end: 14, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ],
            tue: [
                {start: 8, end: 9, hasSlot: false},
                {start: 9, end: 10, hasSlot: false},
                {start: 10, end: 11, hasSlot: false},
                {start: 11, end: 12, hasSlot: false},
                {start: 12, end: 13, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 13, end: 14, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ],
            wed: [
                {start: 8, end: 9, hasSlot: true},
                {start: 9, end: 10, hasSlot: true},
                {start: 10, end: 11, hasSlot: true},
                {start: 11, end: 12, hasSlot: true},
                {start: 12, end: 13, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 13, end: 14, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ],
            thu: [
                {start: 8, end: 9, hasSlot: false},
                {start: 9, end: 10, hasSlot: false},
                {start: 10, end: 11, hasSlot: false},
                {start: 11, end: 12, hasSlot: false},
                {start: 12, end: 13, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 13, end: 14, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ],
            fri: [
                {start: 8, end: 9, hasSlot: true},
                {start: 9, end: 10, hasSlot: true, appointment: {name: 'Mario Rossi', location: 'in-person'}},
                {start: 10, end: 11, hasSlot: true},
                {start: 11, end: 12, hasSlot: true},
                {start: 12, end: 13, hasSlot: true},
                {start: 13, end: 14, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ],
            sat: [
                {start: 8, end: 9, hasSlot: false},
                {start: 9, end: 10, hasSlot: false},
                {start: 10, end: 11, hasSlot: false},
                {start: 11, end: 12, hasSlot: false},
                {start: 12, end: 13, hasSlot: false},
                {start: 13, end: 14, hasSlot: false},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ],
            sun: [
                {start: 8, end: 9, hasSlot: false},
                {start: 9, end: 10, hasSlot: false},
                {start: 10, end: 11, hasSlot: false},
                {start: 11, end: 12, hasSlot: false},
                {start: 12, end: 13, hasSlot: false},
                {start: 13, end: 14, hasSlot: false},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ]
        }
    },
    3: {
        slot: {
            mon: [
                {start: 8, end: 9, hasSlot: false},
                {start: 9, end: 10, hasSlot: false},
                {start: 10, end: 11, hasSlot: true},
                {start: 11, end: 12, hasSlot: true},
                {start: 12, end: 13, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 13, end: 14, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ],
            tue: [
                {start: 8, end: 9, hasSlot: false},
                {start: 9, end: 10, hasSlot: false},
                {start: 10, end: 11, hasSlot: false},
                {start: 11, end: 12, hasSlot: false},
                {start: 12, end: 13, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 13, end: 14, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ],
            wed: [
                {start: 8, end: 9, hasSlot: true},
                {start: 9, end: 10, hasSlot: true},
                {start: 10, end: 11, hasSlot: true},
                {start: 11, end: 12, hasSlot: true},
                {start: 12, end: 13, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 13, end: 14, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ],
            thu: [
                {start: 8, end: 9, hasSlot: false},
                {start: 9, end: 10, hasSlot: false},
                {start: 10, end: 11, hasSlot: false},
                {start: 11, end: 12, hasSlot: false},
                {start: 12, end: 13, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 13, end: 14, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ],
            fri: [
                {start: 8, end: 9, hasSlot: true},
                {start: 9, end: 10, hasSlot: true, appointment: {name: 'Mario Rossi', location: 'in-person'}},
                {start: 10, end: 11, hasSlot: true},
                {start: 11, end: 12, hasSlot: true},
                {start: 12, end: 13, hasSlot: true},
                {start: 13, end: 14, hasSlot: true, appointment: {name: 'nome', location: 'online'}},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ],
            sat: [
                {start: 8, end: 9, hasSlot: false},
                {start: 9, end: 10, hasSlot: false},
                {start: 10, end: 11, hasSlot: false},
                {start: 11, end: 12, hasSlot: false},
                {start: 12, end: 13, hasSlot: false},
                {start: 13, end: 14, hasSlot: false},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ],
            sun: [
                {start: 8, end: 9, hasSlot: false},
                {start: 9, end: 10, hasSlot: false},
                {start: 10, end: 11, hasSlot: false},
                {start: 11, end: 12, hasSlot: false},
                {start: 12, end: 13, hasSlot: false},
                {start: 13, end: 14, hasSlot: false},
                {start: 14, end: 15, hasSlot: false},
                {start: 15, end: 16, hasSlot: false},
                {start: 16, end: 17, hasSlot: false},
                {start: 17, end: 18, hasSlot: false}
            ]
        }
    }
}
export const checkInStatsMocks: CheckInStatsResponse = {
    completed: {
        total: 16,
        partitions: [
            {
                label: 'completed',
                total: 0,
                percentage: 0
            },
            {
                label: 'incomplete',
                total: 16,
                percentage: 100
            }
        ]
    },
    completedIncrements: {
        total: 16,
        percentage: null,
        partitions: [
            {
                label: 'completed',
                total: 0,
                percentage: null
            },
            {
                label: 'incomplete',
                total: 16,
                percentage: null
            }
        ]
    },
    emotions: {
        total: 1,
        partitions: [
            {
                label: 'really_bad',
                total: 1,
                percentage: 100
            },
            {
                label: 'bad',
                total: 0,
                percentage: 0
            },
            {
                label: 'fine',
                total: 0,
                percentage: 0
            },
            {
                label: 'good',
                total: 0,
                percentage: 0
            },
            {
                label: 'really_good',
                total: 0,
                percentage: 0
            }
        ]
    },
    bets: {
        total: 16,
        partitions: [
            {
                label: 'very_low',
                total: 0,
                percentage: 0
            },
            {
                label: 'low',
                total: 0,
                percentage: 0
            },
            {
                label: 'medium',
                total: 16,
                percentage: 100
            },
            {
                label: 'high',
                total: 0,
                percentage: 0
            },
            {
                label: 'very_high',
                total: 0,
                percentage: 0
            }
        ]
    }
}

export const checkIns: CheckIn[] = [
    {
        id: 9,
        userId: 62,
        fromId: null,
        createdAt: '2023-07-12T13:23:43.000+00:00',
        updatedAt: '2024-07-12T13:23:43.000+00:00',
        completedAt: null,
        user: {
            id: 62,
            firstName: 'Pierino',
            lastName: 'Baldassini',
            birthDate: '2024-07-04T00:00:00.000+00:00',
            gender: null,
            taxCode: null,
            phoneNumber: null,
            email: 'patient@humans.tech',
            caregiverId: null,
            isActive: true,
            selfEvaluationId: 74,
            createdAt: '2024-07-04T14:48:43.000+00:00'
        },
        form: {
            id: 1,
            isDefault: false,
            status: 'completed',
            createdAt: '2024-07-12T13:23:11.000+00:00',
            updatedAt: '2024-07-12T13:23:11.000+00:00',
            deletedAt: null
        },
        emotion: null,
        bet: null
    },
    {
        id: 10,
        userId: 62,
        fromId: null,
        createdAt: '2023-07-12T13:23:43.000+00:00',
        updatedAt: '2024-07-12T13:23:43.000+00:00',
        completedAt: null,
        user: {
            id: 62,
            firstName: 'Pierino',
            lastName: 'Baldassini',
            birthDate: '2024-07-04T00:00:00.000+00:00',
            gender: null,
            taxCode: null,
            phoneNumber: null,
            email: 'patient@humans.tech',
            caregiverId: null,
            isActive: true,
            selfEvaluationId: 74,
            createdAt: '2024-07-04T14:48:43.000+00:00'
        },
        form: {
            id: 1,
            isDefault: false,
            status: 'completed',
            createdAt: '2024-07-12T13:23:11.000+00:00',
            updatedAt: '2024-07-12T13:23:11.000+00:00',
            deletedAt: null
        },
        emotion: null,
        bet: null
    },
    {
        id: 11,
        userId: 62,
        fromId: null,
        createdAt: '2023-07-12T13:23:43.000+00:00',
        updatedAt: '2024-07-12T13:23:43.000+00:00',
        completedAt: null,
        user: {
            id: 62,
            firstName: 'Pierino',
            lastName: 'Baldassini',
            birthDate: '2024-07-04T00:00:00.000+00:00',
            gender: null,
            taxCode: null,
            phoneNumber: null,
            email: 'patient@humans.tech',
            caregiverId: null,
            isActive: true,
            selfEvaluationId: 74,
            createdAt: '2024-07-04T14:48:43.000+00:00'
        },
        form: {
            id: 1,
            isDefault: false,
            status: 'completed',
            createdAt: '2024-07-12T13:23:11.000+00:00',
            updatedAt: '2024-07-12T13:23:11.000+00:00',
            deletedAt: null
        },
        emotion: null,
        bet: null
    },
    {
        id: 12,
        userId: 62,
        fromId: null,
        createdAt: '2023-07-12T13:23:43.000+00:00',
        updatedAt: '2024-07-12T13:23:43.000+00:00',
        completedAt: null,
        user: {
            id: 62,
            firstName: 'Pierino',
            lastName: 'Baldassini',
            birthDate: '2024-07-04T00:00:00.000+00:00',
            gender: null,
            taxCode: null,
            phoneNumber: null,
            email: 'patient@humans.tech',
            caregiverId: null,
            isActive: true,
            selfEvaluationId: 74,
            createdAt: '2024-07-04T14:48:43.000+00:00'
        },
        form: {
            id: 1,
            isDefault: false,
            status: 'completed',
            createdAt: '2024-07-12T13:23:11.000+00:00',
            updatedAt: '2024-07-12T13:23:11.000+00:00',
            deletedAt: null
        },
        emotion: null,
        bet: null
    },
    {
        id: 13,
        userId: 62,
        fromId: null,
        createdAt: '2023-07-12T13:23:43.000+00:00',
        updatedAt: '2024-07-12T13:23:43.000+00:00',
        completedAt: null,
        user: {
            id: 62,
            firstName: 'Pierino',
            lastName: 'Baldassini',
            birthDate: '2024-07-04T00:00:00.000+00:00',
            gender: null,
            taxCode: null,
            phoneNumber: null,
            email: 'patient@humans.tech',
            caregiverId: null,
            isActive: true,
            selfEvaluationId: 74,
            createdAt: '2024-07-04T14:48:43.000+00:00'
        },
        form: {
            id: 1,
            isDefault: false,
            status: 'completed',
            createdAt: '2024-07-12T13:23:11.000+00:00',
            updatedAt: '2024-07-12T13:23:11.000+00:00',
            deletedAt: null
        },
        emotion: null,
        bet: null
    },
    {
        id: 14,
        userId: 62,
        fromId: null,
        createdAt: '2023-07-12T13:23:43.000+00:00',
        updatedAt: '2024-07-12T13:23:43.000+00:00',
        completedAt: null,
        user: {
            id: 62,
            firstName: 'Pierino',
            lastName: 'Baldassini',
            birthDate: '2024-07-04T00:00:00.000+00:00',
            gender: null,
            taxCode: null,
            phoneNumber: null,
            email: 'patient@humans.tech',
            caregiverId: null,
            isActive: true,
            selfEvaluationId: 74,
            createdAt: '2024-07-04T14:48:43.000+00:00'
        },
        form: {
            id: 1,
            isDefault: false,
            status: 'completed',
            createdAt: '2024-07-12T13:23:11.000+00:00',
            updatedAt: '2024-07-12T13:23:11.000+00:00',
            deletedAt: null
        },
        emotion: null,
        bet: null
    },
    {
        id: 15,
        userId: 62,
        fromId: null,
        createdAt: '2023-07-12T13:23:43.000+00:00',
        updatedAt: '2024-07-12T13:23:43.000+00:00',
        completedAt: null,
        user: {
            id: 62,
            firstName: 'Pierino',
            lastName: 'Baldassini',
            birthDate: '2024-07-04T00:00:00.000+00:00',
            gender: null,
            taxCode: null,
            phoneNumber: null,
            email: 'patient@humans.tech',
            caregiverId: null,
            isActive: true,
            selfEvaluationId: 74,
            createdAt: '2024-07-04T14:48:43.000+00:00'
        },
        form: {
            id: 1,
            isDefault: false,
            status: 'completed',
            createdAt: '2024-07-12T13:23:11.000+00:00',
            updatedAt: '2024-07-12T13:23:11.000+00:00',
            deletedAt: null
        },
        emotion: null,
        bet: null
    },
    {
        id: 16,
        userId: 62,
        fromId: null,
        createdAt: '2023-07-12T13:23:43.000+00:00',
        updatedAt: '2024-07-12T13:23:43.000+00:00',
        completedAt: null,
        user: {
            id: 62,
            firstName: 'Pierino',
            lastName: 'Baldassini',
            birthDate: '2024-07-04T00:00:00.000+00:00',
            gender: null,
            taxCode: null,
            phoneNumber: null,
            email: 'patient@humans.tech',
            caregiverId: null,
            isActive: true,
            selfEvaluationId: 74,
            createdAt: '2024-07-04T14:48:43.000+00:00'
        },
        form: {
            id: 1,
            isDefault: false,
            status: 'completed',
            createdAt: '2024-07-12T13:23:11.000+00:00',
            updatedAt: '2024-07-12T13:23:11.000+00:00',
            deletedAt: null
        },
        emotion: null,
        bet: null
    },
    {
        id: 17,
        userId: 62,
        fromId: null,
        createdAt: '2024-07-12T13:23:43.000+00:00',
        updatedAt: '2024-07-12T13:23:43.000+00:00',
        completedAt: null,
        user: {
            id: 62,
            firstName: 'Pierino',
            lastName: 'Baldassini',
            birthDate: '2024-07-04T00:00:00.000+00:00',
            gender: null,
            taxCode: null,
            phoneNumber: null,
            email: 'patient@humans.tech',
            caregiverId: null,
            isActive: true,
            selfEvaluationId: 74,
            createdAt: '2024-07-04T14:48:43.000+00:00'
        },
        form: {
            id: 1,
            isDefault: false,
            status: 'completed',
            createdAt: '2024-07-12T13:23:11.000+00:00',
            updatedAt: '2024-07-12T13:23:11.000+00:00',
            deletedAt: null
        },
        emotion: null,
        bet: null
    }
]

export const checkInDetails: CheckInDetails = {
    id: 1,
    patient_id: 1,
    patient_firstname: 'Mario',
    patient_lastname: 'Quattrossi',
    patient_age: 54,
    survey: {
        id: 1,
        created_at: '2024-01-13T14:18:00.000000Z',
        note: null,
        question: [
            {
                question: 'Hai giocato d’azzardo oggi?',
                answer: 'Si'
            },
            {
                question: 'Quanti soldi hai speso oggi per il gioco?',
                answer: '€ 100,00'
            },
            {
                question: 'Quanto sei motivato a smettere di giocare?',
                answer: 'Sto facendo del mio meglio, ma ci sono alti e bassi.'
            },
            {
                question: "Hai mentito ad amici o familiari sull’aver giocato d'azzardo oggi?",
                answer: 'No'
            }
        ]
    },
    recorded_moods: [
        {
            id: 1,
            mood: {
                id: 1,
                index: 1,
                name: 'really_good'
            },
            thoughts: false,
            created_at: '2024-01-13T14:18:00.000000Z'
        },
        {
            id: 2,
            mood: {
                id: 1,
                index: 1,
                name: 'bad'
            },
            thoughts: true,
            created_at: '2024-01-13T14:19:00.000000Z'
        }
    ],
    recorded_money_gambled: [
        {
            id: 1,
            created_at: '2024-01-13T14:19:00.000000Z',
            money_gambled: 100
        },
        {
            id: 2,
            created_at: '2024-01-13T14:19:00.000000Z',
            money_gambled: 100
        }
    ]
}
