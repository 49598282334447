import styled, {css} from 'styled-components'
import {SelectTrigger} from '@components/ui/select-atoms/SelectTrigger.tsx'

type StyledSelectedValueProps = {
    $hasValue: boolean
    disabled?: boolean
}

export const StyledSelectedValue = styled.span<StyledSelectedValueProps>`
    ${({theme: {palette, typography, spacing}, $hasValue, disabled}) => css`
        color: ${$hasValue ? palette.neutral[900] : palette.neutral[400]};
        ${typography.textMd};
        font-weight: 400;
        display: flex;
        gap: ${spacing}px;

        ${disabled &&
        css`
            color: ${palette.neutral[500]};
        `}
    `}
`
export const StyledSelectTrigger = styled(SelectTrigger)<{$maxWidth?: number}>`
    ${({$maxWidth, theme}) => css`
        font-weight: 600;
        min-height: 40px;
        width: 100%;
        max-width: ${$maxWidth}px;

        &:disabled {
            cursor: not-allowed;
            color: ${theme.palette.neutral[400]};

            &:hover {
                outline: 1px solid ${theme.palette.neutral[300]};
                box-shadow: none;
            }
        }
    `}
`
