import {Dispatch, SetStateAction} from 'react'
import {BaseCard} from '@components/commons/cards/BaseCard.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {PlusIcon} from '@components/ui/icon/Icon.tsx'
import {useTranslation} from 'react-i18next'
import {
    StyledContainerNextAppointment,
    StyledHeaderAppointments
} from '@/features/dashboard/components/appointments/style.ts'
import {useNavigate} from 'react-router-dom'
import {routes} from '@utilities/constants/routes.tsx'
import dayjs from '@/dayjs.ts'
import {OperatorUpcomingAppointments} from '@/features/dashboard/components/appointments/OperatorUpcomingAppointments.tsx'
import {DoctorSelect} from '@/features/dashboard/components/doctor-select/DoctorSelect.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {SingleValue} from 'react-select'
import {SelectValue} from '@/types.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {AppointmentsUrlParams} from '@/features/appointments/types.ts'

export const OperatorTodayAppointment = ({
    setOpenAddOrEditAppointmentModal
}: {
    setOpenAddOrEditAppointmentModal: Dispatch<SetStateAction<{open: boolean; id: number | null}>>
}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {searchParams, setSearchParams} = useParsedSearchParams([], AppointmentsUrlParams)
    const onChange = (newValue: SingleValue<SelectValue>) => {
        setSearchParams({therapistId: newValue ? parseInt(newValue.value) : undefined})
    }
    return (
        <>
            <BaseCard
                variant={'sectioned'}
                headerSlot={
                    <>
                        <StyledHeaderAppointments>
                            <p>{t('dashboard:today_appointments')}</p>
                            <span>{dayjs().format('LL')}</span>
                        </StyledHeaderAppointments>

                        <Flexbox gap={4} align={'center'}>
                            <DoctorSelect
                                userId={searchParams.therapistId}
                                onChange={newValue => onChange(newValue as SingleValue<SelectValue>)}
                            />

                            {/*TODO: add modal to add appointment*/}
                            <Button onClick={() => setOpenAddOrEditAppointmentModal({open: true, id: null})}>
                                <PlusIcon />
                                <span>{t('dashboard:add_appointment')}</span>
                            </Button>
                        </Flexbox>
                    </>
                }
                footerSlot={
                    <Button variant={'tertiaryColor'} onClick={() => navigate(routes.APPOINTMENTS.path)}>
                        {t('dashboard:see_all')}
                    </Button>
                }
            >
                <StyledContainerNextAppointment direction={'column'} fullWidth gap={6}>
                    <OperatorUpcomingAppointments setOpenAddOrEditAppointmentModal={setOpenAddOrEditAppointmentModal} />
                </StyledContainerNextAppointment>
            </BaseCard>
        </>
    )
}
