import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {DoctorTodayAppointment} from '@/features/dashboard/components/appointments/DoctorTodayAppointment.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {PatientAnalytical} from '@/features/dashboard/components/patients/PatientAnalytical.tsx'
import {useTranslation} from 'react-i18next'
import {BarChartCircle02Icon, CalendarIcon, Users01Icon} from '@components/ui/icon/Icon.tsx'
import {DateRangeSelect} from '@components/commons/date-range-select/DateRangeSelect.tsx'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {SingleSelect} from '@components/commons/single-select/SingleSelect.tsx'
import {AGE_SELECT_OPTIONS, GENDERS_SELECT_OPTIONS} from '@/features/dashboard/constants.ts'
import {DashboardUrlParamsExtended} from '@/features/dashboard/types.ts'
import {AddAppointmentsModal} from '@/features/appointments/components/modals/AddAppointmentsModal.tsx'
import {useState} from 'react'
import {useGetContacts} from '@/features/settings/services/services-contact/useGetContacts.ts'
import {useGetPatients} from '@/features/patients/services/useGetPatients.ts'
import {useGetUsers} from '@/features/settings/services/services-users/useGetUsers.ts'
import {ResponseType} from '@/types.ts'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {useGetAppointments} from '@/features/appointments/services/useGetAppointments.ts'
import dayjs from '@/dayjs.ts'
import {useGetAppointmentDetails} from '@/features/appointments/services/useGetAppointmentDetails.ts'
const now = dayjs()
export const DashboardDoctor = () => {
    const {t} = useTranslation()
    const {searchParams, setSearchParams} = useParsedSearchParams([], DashboardUrlParamsExtended)
    const [openAddOrEditAppointmentModal, setOpenAddOrEditAppointmentModal] = useState<{
        open: boolean
        id: number | null
    }>({
        open: false,
        id: null
    })
    const user = useAuthStore(state => state.user)

    const {data: offices} = useGetContacts()
    const {remappedData: patients} = useGetPatients({limit: 10000})
    const {remappedData: therapist} = useGetUsers({
        ...searchParams,
        responseType: ResponseType.enum.backofficeRegular,
        limit: 100,
        roles: ['therapist']
    })

    const {remappedData: appointments} = useGetAppointments({
        limit: 1000,
        therapistId: user?.id,
        fromDate: now.subtract(1, 'year').toISOString(),
        toDate: now.add(1, 'year').toISOString()
    })

    const {data: appointment, isLoading} = useGetAppointmentDetails(openAddOrEditAppointmentModal.id)

    return (
        <>
            {/*today appointment*/}
            <DoctorTodayAppointment setOpenAddOrEditAppointmentModal={setOpenAddOrEditAppointmentModal} />

            {/*filter*/}
            <Flexbox fullWidth justify={'space-between'}>
                <DateRangeSelect
                    value={searchParams.rangeDateLabels}
                    icon={<CalendarIcon />}
                    onChange={value => setSearchParams({rangeDateLabels: value})}
                />
                <Flexbox gap={3}>
                    <SingleSelect
                        iconOnTrigger={<Users01Icon />}
                        placeholder={t('dashboard:gender')}
                        options={GENDERS_SELECT_OPTIONS}
                        value={searchParams.genders || ''}
                        onChange={value => setSearchParams({genders: value as DashboardUrlParamsExtended['genders']})}
                    />
                    <SingleSelect
                        iconOnTrigger={<BarChartCircle02Icon />}
                        placeholder={t('dashboard:age')}
                        options={AGE_SELECT_OPTIONS}
                        value={searchParams.age || ''}
                        onChange={value => setSearchParams({age: value as DashboardUrlParamsExtended['age']})}
                    />
                    <Button
                        onClick={() => setSearchParams({age: undefined, genders: undefined})}
                        variant={'tertiaryColor'}
                    >
                        {t('dashboard:remove_filters')}
                    </Button>
                </Flexbox>
            </Flexbox>
            {openAddOrEditAppointmentModal.open && !isLoading && (
                <AddAppointmentsModal
                    offices={offices?.data ? offices.data : []}
                    patients={patients}
                    therapists={therapist}
                    onClose={() => {
                        setOpenAddOrEditAppointmentModal({open: false, id: null})
                    }}
                    officeId={null}
                    appointments={appointments}
                    appointment={appointment}
                    prefilledData={openAddOrEditAppointmentModal.id === null ? {therapistId: user?.id} : null}
                />
            )}

            {/*analytical*/}
            <PatientAnalytical />
        </>
    )
}
