import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Switch} from '@/components/ui/switch/Switch'
import {useTranslation} from 'react-i18next'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {SelectProvider, useSelectStore} from '@ariakit/react'
import {
    StyledSelectRangeItem,
    StyledSelectRangeTrigger
} from '@/features/check-in/components/check-in-filters-controls/style.ts'
import {CalendarIcon, CheckIcon} from '@components/ui/icon/Icon.tsx'
import {SelectPopover} from '@components/ui/select-atoms/SelectAtoms.tsx'
import {ObjectKeys} from '@utilities/helpers.ts'
import {CheckInUrlSearchParams} from '@/features/check-in/types.ts'
import {rangeDateToLabel} from '@utilities/utils.ts'
import {PatientUrlSearchParams} from '@/features/patients/types.ts'

export const CheckInFiltersControls = () => {
    const {t} = useTranslation()
    const {searchParams, setSearchParams} = useParsedSearchParams([], CheckInUrlSearchParams)

    const selectStore = useSelectStore({
        value: searchParams.dateRangeLabels,
        setValue: (value: PatientUrlSearchParams['dateRangeLabels']) => setSearchParams({dateRangeLabels: value}),
        defaultValue: 'range_date'
    })

    return (
        <Flexbox fullWidth align="center" gap={6}>
            <Switch
                label={t('patients:top_filters:active_path')}
                onChange={() =>
                    setSearchParams({
                        isCurrentlyInTherapy: searchParams.isCurrentlyInTherapy == 'true' ? 'false' : 'true'
                    })
                }
                defaultChecked={searchParams.isCurrentlyInTherapy == 'true'}
            />
            <SelectProvider store={selectStore}>
                <StyledSelectRangeTrigger size="sm" variant="secondary" fullWidth minWidth={220}>
                    <Flexbox align="center" gap={2}>
                        <CalendarIcon />
                        {t(rangeDateToLabel[searchParams.dateRangeLabels])}
                    </Flexbox>
                </StyledSelectRangeTrigger>

                <SelectPopover gutter={4} sameWidth>
                    {ObjectKeys(rangeDateToLabel).map(option => (
                        <StyledSelectRangeItem key={option} value={option}>
                            {t(rangeDateToLabel[option])}
                            {option == searchParams.dateRangeLabels && <CheckIcon size={20} />}
                        </StyledSelectRangeItem>
                    ))}
                </SelectPopover>
            </SelectProvider>
        </Flexbox>
    )
}
