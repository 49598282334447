import styled, {css} from 'styled-components'
import {DPDayRange} from '@rehookify/datepicker'

export const StyledCalendarWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: ${spacing}px;
        padding: ${spacing * 6}px;
        width: 320px;
    `
)

export const StyledCalendarHeader = styled.div(
    ({theme: {palette, typography}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        & h3 {
            flex-grow: 1;
            text-align: center;
            ${typography.textLg};
            color: ${palette.neutral['900']};
            font-weight: 800;
        }

        /*Needed to keep centered the month name in multi calendar view*/
        & .invisible {
            visibility: hidden;
        }
    `
)

export const StyledDayGrid = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(7, 40px);
        row-gap: ${spacing}px;
    `
)

export const StyledYearMonthGrid = styled.div(
    ({theme: {spacing}}) => css`
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: ${spacing * 2}px;
    `
)

export const StyledWeekDayContainer = styled.div(
    ({theme: {palette, typography}}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        ${typography.textSm};
        font-weight: 700;
        color: ${palette.neutral.black};
    `
)

export const StyledDayButtonWrapper = styled.div<{
    $rangeStatus: DPDayRange
    $inCurrentMonth: boolean
}>(
    ({$rangeStatus, $inCurrentMonth, theme: {palette}}) => css`
        &:nth-child(7n),
        &.lastMonthDay {
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
        }

        &:nth-child(7n + 1),
        &.firstMonthDay {
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
        }

        ${$inCurrentMonth &&
        $rangeStatus &&
        css`
            background-color: ${palette.neutral['100']};
            color: ${palette.neutral['700']};
            font-weight: 500;
        `}
        ${($rangeStatus == 'range-end' || $rangeStatus == 'will-be-range-end') &&
        css`
            border-radius: 0 50% 50% 0;
        `}
        ${($rangeStatus == 'range-start' || $rangeStatus == 'will-be-range-start') &&
        css`
            border-radius: 50% 0 0 50%;
        `}
    `
)

export const StyledDayButton = styled.button<{$rangeStatus: DPDayRange; $isSelected: boolean}>(
    ({$rangeStatus, $isSelected, theme: {palette, transition}}) => css`
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        ${$isSelected &&
        css`
            background-color: ${palette.primary['600']};
            color: ${palette.neutral.white};
            font-weight: 500;
            transition: ${transition};
            &:hover {
                background-color: ${palette.primary['700']};
            }
        `}
        ${($rangeStatus == 'in-range' || $rangeStatus == 'will-be-in-range') &&
        css`
            background-color: transparent;
        `}
        ${!$isSelected &&
        $rangeStatus == '' &&
        css`
            color: ${palette.neutral['700']};
            &:hover:not(:disabled) {
                background-color: ${palette.neutral['50']};
            }
        `}

        &:disabled {
            color: ${palette.neutral['300']};
            cursor: not-allowed;
        }
    `
)

export const StyledTextButton = styled.button(
    ({theme: {palette, transition}}) => css`
        cursor: pointer;
        transition: ${transition};
        &:hover {
            color: ${palette.primary['600']};
        }
    `
)
