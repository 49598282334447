import styled, {css} from 'styled-components'
type StyledArticleItemWrapperProps = {
    $size?: 'sm' | 'md'
}
export const StyledArticleItemWrapper = styled.div<StyledArticleItemWrapperProps>`
    ${({theme, $size}) => css`
        display: grid;
        grid-template-columns: ${$size === 'md' ? '95px 1fr auto' : '75px auto'};
        gap: ${theme.spacing * 4}px;
        width: 100%;
        padding: ${theme.spacing}px;
        align-items: center;
    `}
`

export const StyledArticleContent = styled.span`
    ${({theme}) => css`
        ${theme.typography.textSm};
        color: ${theme.palette.neutral[600]};
    `}
`
