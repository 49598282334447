import styled, {css} from 'styled-components'
import {BaseCard} from '@components/commons/cards/BaseCard.tsx'

export const StyledArticleTitle = styled.p`
    ${({theme}) => css`
        font-weight: 600;
        ${theme.typography.textMd};
        color: ${theme.palette.neutral[900]};
        cursor: pointer;
    `}
`

export const StyledArticleSubtitle = styled.span`
    ${({theme}) => css`
        ${theme.typography.textSm};
        color: ${theme.palette.neutral[600]};
        max-width: 550px;
        text-align: center;
    `}
`

export const StyledIconWrapper = styled.div`
    ${({theme}) => css`
        padding: ${theme.spacing * 3}px;
        border: 1px solid ${theme.palette.neutral[200]};
        border-radius: ${theme.spacing * 3}px;
        box-shadow: ${theme.shadows.xs};
        display: flex;
        align-items: center;
        margin-bottom: ${theme.spacing * 3}px;
    `}
`

export const StyledContainerArticleButton = styled.div`
    ${({theme}) => css`
        padding: ${theme.spacing * 6}px 0;
    `}
`

interface StyledEmptyStateContainerProps {
    $height?: string | number
}
export const StyledEmptyStateContainer = styled(BaseCard)<StyledEmptyStateContainerProps>`
    ${({$height}) => css`
        height: ${$height ?? 'fit-content'};
    `}
`
