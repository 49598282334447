import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {BaseCardSectionedProps} from '@components/commons/cards/BaseCard.tsx'

type StyledCardProps = {
    $paddingX?: number
    $paddingY?: number
    $bodyHasBorder?: boolean
    $variant?: BaseCardSectionedProps['variant']
}

export const StyledCard = styled(Flexbox)<StyledCardProps>`
    ${({theme, $paddingX = 0, $paddingY = 0}) => css`
        background-color: ${theme.palette.neutral.white};
        border-radius: ${theme.spacing * 3}px;
        padding: ${theme.spacing * $paddingX}px ${theme.spacing * $paddingY}px;
        border: 1px solid ${theme.palette.neutral[200]};
        box-shadow: ${theme.shadows['xs']};

        width: 100%;
        display: grid;
        grid-template-rows: auto 1fr auto;
        grid-template-columns: 1fr;
        justify-content: center;
    `}
`

export const StyledSectionedBodyCard = styled.div<StyledCardProps>`
    ${({theme, $bodyHasBorder}) => css`
        width: 100%;
        border-top: ${$bodyHasBorder ? `1px solid ${theme.palette.neutral[200]}` : 'none'};
        border-bottom: ${$bodyHasBorder ? `1px solid ${theme.palette.neutral[200]}` : 'none'};
    `}
`
export const StyledSectionedHeaderCard = styled(Flexbox)<StyledCardProps>`
    ${({theme, $variant}) => css`
        ${$variant === 'sectioned' &&
        css`
            padding: ${theme.spacing * 5}px ${theme.spacing * 6}px;
        `}

        ${$variant === 'base' &&
        css`
            ${theme.typography.textMd};
            font-weight: 600;
            color: ${theme.palette.neutral[900]};
        `}
    `}
`

export const StyledSectionedFooterCard = styled(Flexbox)<StyledCardProps>`
    ${({theme, $variant}) => css`
        ${$variant === 'sectioned' &&
        css`
            padding: ${theme.spacing * 4}px ${theme.spacing * 6}px;
        `}
    `}
`
