import {
    StyledGraphicChartFilterItem,
    StyledGraphicChartFilterWrapper
} from '@/features/patient-details/components/patient-money-gambled-graphic-chart-filters/style.ts'
import {useTranslation} from 'react-i18next'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {PatientDetailsBetsUrlParams} from '@/features/patient-details/types.ts'

interface MoneyGambledGraphicChartFilters {
    name: PatientDetailsBetsUrlParams['betsRangeDateLabels']
}
const moneyGambledGraphicChartFilters: MoneyGambledGraphicChartFilters[] = [
    {
        name: 'last_week'
    },
    {
        name: 'last_month'
    },
    {
        name: 'last_year'
    }
]

export const PatientMoneyGambledGraphicChartFilters = () => {
    const {t} = useTranslation()
    const {searchParams, setSearchParams} = useParsedSearchParams([], PatientDetailsBetsUrlParams)

    const onClickItem = (name: PatientDetailsBetsUrlParams['betsRangeDateLabels']) => {
        setSearchParams({betsRangeDateLabels: name})
    }
    return (
        <StyledGraphicChartFilterWrapper>
            {moneyGambledGraphicChartFilters.map(filter => (
                <StyledGraphicChartFilterItem
                    $isActive={searchParams.betsRangeDateLabels === filter.name}
                    key={filter.name}
                    onClick={() => onClickItem(filter.name)}
                >
                    <p>{t(`patient_details:patient_tabs:money_gambled:filter:${filter.name}`)}</p>
                </StyledGraphicChartFilterItem>
            ))}
        </StyledGraphicChartFilterWrapper>
    )
}
