import {useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    StyledMoodIconSkeleton,
    StyledPatientDetailsCardBody
} from '@/features/patient-details/components/patient-details-card/style.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'

export const PatientDetailsCardSkeleton = () => {
    const {t} = useTranslation()
    return (
        <>
            <Flexbox direction={'column'} gap={3}>
                <h4>{t('patient_details:patient_tabs:info:mood')}</h4>
                <Flexbox align={'center'} gap={2}>
                    <StyledMoodIconSkeleton height={56} width={56} />
                    <Skeleton width={100} height={20} />
                </Flexbox>
            </Flexbox>
            <StyledPatientDetailsCardBody direction={'column'} gap={6}>
                <Flexbox direction={'column'} gap={3}>
                    <Flexbox gap={3}>
                        <Skeleton width={100} height={20} />
                        <Skeleton width={70} height={20} />
                    </Flexbox>
                    <Divider direction={'horizontal'} />
                </Flexbox>
                <Flexbox direction={'column'} gap={2}>
                    <h4>{t('patient_details:patient_tabs:info:lastName')}</h4>
                    <Skeleton width={200} height={24} />
                </Flexbox>

                <Flexbox direction={'column'} gap={2}>
                    <h4>{t('patient_details:patient_tabs:info:firstName')}</h4>
                    <Skeleton width={200} height={24} />
                </Flexbox>

                <Flexbox direction={'column'} gap={2}>
                    <h4>{t('patient_details:patient_tabs:info:fiscal_code')}</h4>
                    <Skeleton width={200} height={24} />
                </Flexbox>

                <Flexbox direction={'column'} gap={2}>
                    <h4>{t('patient_details:patient_tabs:info:email')}</h4>
                    <Skeleton width={200} height={24} />
                </Flexbox>

                <Flexbox direction={'column'} gap={2}>
                    <h4>{t('patient_details:patient_tabs:info:phone')}</h4>
                    <Skeleton width={200} height={24} />
                </Flexbox>

                <Flexbox direction={'column'} gap={2}>
                    <h4>{t('patient_details:patient_tabs:info:therapy_path')}</h4>
                    <Skeleton width={44} height={24} />
                </Flexbox>

                <div>
                    <Skeleton height={20} width={200} />
                </div>
            </StyledPatientDetailsCardBody>
            <div>
                <Skeleton height={40} width={150} />
            </div>
        </>
    )
}
