import styled, {css} from 'styled-components'

export const StyledDiarySectionContainer = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        height: 100%;
        grid-template-columns: auto 342px;
        gap: ${spacing * 5}px;
    `
)

export const StyledMoodCardTitle = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};
        font-weight: 600;
        color: ${palette.neutral[900]};
    `
)
