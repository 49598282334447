import {ACTION_TYPES} from '@/features/settings/types.ts'
import {
    CalendarCheck01Icon,
    Lock01Icon,
    MessageXSquareIcon,
    PencilLineIcon,
    Trash01Icon
} from '@components/ui/icon/Icon.tsx'
import {ButtonStyleProps} from '@components/ui/button/Button.tsx'
import {ACTION_TYPES_APPOINTMENTS_DASHBOARD} from '@/features/dashboard/types.ts'
import {ACTION_TYPES_APPOINTMENT_DETAILS, AppointmentStatus} from '@/features/appointments/types.ts'

export const actionsListSettingsUser = [
    {
        type: ACTION_TYPES.edit,
        label: 'settings:actions:edit',
        icon: <PencilLineIcon />
    },
    {
        type: ACTION_TYPES.recovery_password,
        label: 'settings:actions:recovery_password',
        icon: <Lock01Icon />
    },
    {
        type: ACTION_TYPES.delete,
        label: 'settings:actions:delete',
        icon: <Trash01Icon />,
        color: 'tertiaryDanger' as ButtonStyleProps['variant']
    }
]

export const actionsListDashboardAppointments = [
    {
        type: ACTION_TYPES_APPOINTMENTS_DASHBOARD.done,
        label: 'dashboard:actions:assign_as_done',
        icon: <CalendarCheck01Icon />
    },
    {
        type: ACTION_TYPES_APPOINTMENTS_DASHBOARD.not_done,
        label: 'dashboard:actions:assign_as_not_done',
        icon: <MessageXSquareIcon />
    },
    {
        type: ACTION_TYPES_APPOINTMENTS_DASHBOARD.edit,
        label: 'dashboard:actions:edit',
        icon: <PencilLineIcon />
    },
    {
        type: ACTION_TYPES_APPOINTMENTS_DASHBOARD.delete,
        label: 'dashboard:actions:delete',
        icon: <Trash01Icon />,
        color: 'tertiaryDanger' as ButtonStyleProps['variant']
    }
]

export const actionsListAppointmentDetails = (status: AppointmentStatus) => [
    {
        type: ACTION_TYPES_APPOINTMENT_DETAILS.completed,
        label: 'appointments:actions_list:marked_completed',
        icon: <CalendarCheck01Icon />,
        disabled: status === AppointmentStatus.enum.completed
    },
    {
        type: ACTION_TYPES_APPOINTMENT_DETAILS.missed,
        label: 'appointments:actions_list:marked_missed',
        icon: <MessageXSquareIcon />,
        disabled: status === AppointmentStatus.enum.missed
    },
    {
        type: ACTION_TYPES_APPOINTMENT_DETAILS.edit,
        label: 'appointments:actions_list:edit',
        icon: <PencilLineIcon />
    },
    {
        type: ACTION_TYPES_APPOINTMENT_DETAILS.delete,
        label: 'appointments:actions_list:delete',
        icon: <Trash01Icon />,
        color: 'tertiaryDanger' as ButtonStyleProps['variant']
    }
]
