import {StyledMain, StyledPrivateLayout} from './styles'
import {useEffect, useState} from 'react'
import {Sidebar} from '@components/commons/sidebar/Sidebar.tsx'
import {Container} from '@components/ui/container/Container.tsx'
import {BreadCrumbs} from '@components/commons/bread-crumbs/BreadCrumbs.tsx'
import {matchPath, Outlet, useLocation} from 'react-router-dom'
import {routes} from '@utilities/constants/routes.tsx'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useTheme} from 'styled-components'
import {socket} from '@/socket.ts'
import toast from 'react-hot-toast'
import {NotificationFromSocket} from '@/features/dashboard/types.ts'

export const PrivateLayout = () => {
    const [isSidebarOpen, setSidebarOpen] = useState<boolean>(true)
    const location = useLocation()
    const isDashboardPath = matchPath(location.pathname, `${routes.DASHBOARD.path}`)
    const user = useAuthStore(state => state.user)
    const {palette} = useTheme()

    useEffect(() => {
        if (user) {
            socket.emit('joinRoom', {
                roomId: `users:${user.id}`
            })

            const handleNotification = (notification: NotificationFromSocket) => {
                toast.success(notification.notificationKeyName, {
                    position: 'top-right'
                })
            }
            socket.on('notification', handleNotification)

            return () => {
                socket.off('joinRoom')
                socket.off('notification', handleNotification)
            }
        }
    }, [user])

    if (!user) {
        return <Spinner color={palette.primary[700]} haveOverlay size={48} />
    }
    return (
        <StyledPrivateLayout $isSidebarOpen={isSidebarOpen}>
            <Sidebar setSidebarOpen={setSidebarOpen} isSidebarOpen={isSidebarOpen} />

            <StyledMain>
                {!isDashboardPath && (
                    <Container fullWidth fullHeight={false}>
                        <BreadCrumbs />
                    </Container>
                )}
                <Outlet />
            </StyledMain>
        </StyledPrivateLayout>
    )
}
