import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {DefaultTheme} from 'styled-components/dist/types'
import {spacing} from '@/theme/theme.ts'

export const makeMoodContainerVariant = ({palette}: DefaultTheme) =>
    ({
        really_bad: css`
            color: ${palette.purple[700]};
        `,
        bad: css`
            color: ${palette.danger[600]};
        `,
        fine: css`
            color: ${palette.warning[600]};
        `,
        good: css`
            color: ${palette.success[600]};
        `,
        really_good: css`
            color: ${palette.primary[600]};
        `
    }) as const satisfies Record<NonNullable<StyledMoodContainerProps['$variant']>, ReturnType<typeof css>>

interface StyledMoodContainerProps {
    $variant: 'really_bad' | 'bad' | 'fine' | 'good' | 'really_good'
}
export const StyledMoodContainer = styled(Flexbox)<StyledMoodContainerProps>(
    ({theme, $variant}) => css`
        ${theme.typography.textSm};
        ${makeMoodContainerVariant(theme)[$variant]};
    `
)

export const makeMoodPrimaryWrapperColor = ({palette}: DefaultTheme) =>
    ({
        really_bad: css`
            background-color: ${palette.purple[300]};
        `,
        bad: css`
            background-color: ${palette.danger[300]};
        `,
        fine: css`
            background-color: ${palette.warning[300]};
        `,
        good: css`
            background-color: ${palette.success[300]};
        `,
        really_good: css`
            background-color: ${palette.primary[300]};
        `
    }) as const satisfies Record<NonNullable<StyledMoodContainerProps['$variant']>, ReturnType<typeof css>>

export const StyledMoodPrimaryWrapper = styled(Flexbox)<StyledMoodContainerProps>(
    ({theme, $variant}) => css`
        border-radius: 50px;
        padding: ${spacing * 2}px;
        ${makeMoodPrimaryWrapperColor(theme)[$variant]};
    `
)
export const makeMoodSecondaryWrapperColor = ({palette}: DefaultTheme) =>
    ({
        really_bad: css`
            background-color: ${palette.purple[100]};
        `,
        bad: css`
            background-color: ${palette.danger[100]};
        `,
        fine: css`
            background-color: ${palette.warning[100]};
        `,
        good: css`
            background-color: ${palette.success[100]};
        `,
        really_good: css`
            background-color: ${palette.primary[100]};
        `
    }) as const satisfies Record<NonNullable<StyledMoodContainerProps['$variant']>, ReturnType<typeof css>>

export const StyledMoodSecondaryWrapper = styled(Flexbox)<StyledMoodContainerProps>(
    ({theme, $variant}) => css`
        border-radius: 50px;
        padding: ${spacing * 2}px;
        ${makeMoodSecondaryWrapperColor(theme)[$variant]};
        & svg {
            height: 32px;
            width: 32px;
        }
    `
)
