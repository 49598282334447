import React from 'react'
import {StyledPageContent} from '@/components/ui/page-content/style'

interface PageContentProps {
    className?: string
    children: React.ReactNode
}

export const PageContent = ({children, className}: PageContentProps) => (
    <StyledPageContent className={className}>{children}</StyledPageContent>
)

PageContent.displayName = 'PageContent'
