import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'
import {httpGetAvailableSlots} from '@/features/appointments/services/appointments.http.ts'
import {HttpGetAvailableSlotsResponseSchema} from '@/features/appointments/types.ts'
import {z} from 'zod'

export const useGetAvailableSlotsProps = z.object({
    fromDate: z.string().optional(),
    toDate: z.string().optional()
})
export type UseGetAvailableSlotsProps = z.infer<typeof useGetAvailableSlotsProps>

export const useGetAvailableSlots = ({fromDate, toDate}: UseGetAvailableSlotsProps) => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_AVAILABLE_SLOTS, fromDate, toDate],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetAvailableSlots({fromDate, toDate}),
                responseShape: HttpGetAvailableSlotsResponseSchema,
                onZodError: captureException
            })
    })
}
