import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

interface StyledEmptyChartContainerProps {
    width?: number
    height?: number
}

export const StyledEmptyChartContainer = styled(Flexbox)<StyledEmptyChartContainerProps>`
    ${({theme: {palette, spacing}, height, width}) => css`
        ${height &&
        css`
            height: ${height}px;
        `}
        ${width &&
        css`
            width: ${width}px;
        `}
        background-color: ${palette.neutral[200]};
        border-radius: 12px;
        padding: ${spacing * 3}px;
        text-align: center;
    `}
`
