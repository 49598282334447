import {FC, useEffect, useMemo} from 'react'
import {
    StyledContainerBottomItems,
    StyledContainerWrapper,
    StyledLogoContainer,
    StyledLogoutContainer,
    StyledSidebarContainer,
    StyledSidebarItems,
    StyledUserInformationContainer,
    StyledWrapperLastSidebarItems
} from '@components/commons/sidebar/style.ts'
import Logo1 from '@assets/images/logo_1.svg'
import Logo from '@assets/images/logo.svg'
import {ChevronRightDoubleIcon, LogoutIcon} from '@components/ui/icon/Icon.tsx'
import {operatorSidebarItems, settingsItem, sidebarItems} from '@utilities/constants/menus.tsx'
import {SidebarItem} from '@components/commons/sidebar-item/SidebarItem.tsx'
import {useTranslation} from 'react-i18next'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {Avatar} from '@components/ui/avatar/Avatar.tsx'
import {matchPath, useLocation, useNavigate} from 'react-router-dom'
import {routes} from '@utilities/constants/routes.tsx'
import {logout} from '@/features/auth/store/actions.ts'
import {useAuthStore} from '@/features/auth/store/store.ts'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {USER_ROLES} from '@/features/dashboard/types.ts'

interface SidebarProps {
    setSidebarOpen: (value: boolean) => void
    isSidebarOpen: boolean
}

export const Sidebar: FC<SidebarProps> = ({setSidebarOpen, isSidebarOpen}) => {
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width <= 1542, [width])
    const {t} = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const user = useAuthStore(state => state.user)

    const isAdmin = user?.role?.name === USER_ROLES.admin

    const handleLogout = () => {
        localStorage.clear()
        window.location.reload()
        logout()
    }

    useEffect(() => {
        setSidebarOpen(true)
        if (isMobile) {
            setSidebarOpen(false)
        }
    }, [width])

    return (
        <StyledSidebarContainer
            direction={'column'}
            align={!isSidebarOpen ? 'center' : 'unset'}
            gap={!isSidebarOpen ? 6 : 0}
        >
            <>
                <StyledLogoContainer
                    align={'center'}
                    justify={'space-between'}
                    direction={isSidebarOpen ? 'row' : 'column'}
                    $isSidebarOpen={isSidebarOpen}
                    gap={isSidebarOpen ? 0 : 6}
                >
                    <img src={isSidebarOpen ? Logo1 : Logo} alt={'logo'} />
                    <ChevronRightDoubleIcon
                        className={isSidebarOpen ? 'chevron-rotate' : 'chevron'}
                        cursor={'pointer'}
                        onClick={() => setSidebarOpen(!isSidebarOpen)}
                    />
                </StyledLogoContainer>

                <StyledSidebarItems direction={'column'} gap={2} $isSidebarOpen={isSidebarOpen}>
                    {isAdmin || user?.role?.name === USER_ROLES.therapist
                        ? sidebarItems.map((item, index) => (
                              <SidebarItem key={index} item={item} isOnlyIcon={!isSidebarOpen} />
                          ))
                        : operatorSidebarItems.map((item, index) => (
                              <SidebarItem key={index} item={item} isOnlyIcon={!isSidebarOpen} />
                          ))}
                </StyledSidebarItems>

                <StyledContainerBottomItems>
                    {/*TODO: add check into route*/}
                    {isAdmin && <SidebarItem item={settingsItem} isOnlyIcon={!isSidebarOpen} />}

                    {isSidebarOpen ? (
                        <StyledWrapperLastSidebarItems direction={'column'} gap={4}>
                            <StyledUserInformationContainer
                                gap={1}
                                direction={'column'}
                                $isVisible={isSidebarOpen}
                                onClick={() => navigate(routes.PROFILE.path)}
                                $isActive={matchPath(location.pathname, routes.PROFILE.path) !== null}
                            >
                                <p>{`${user?.firstName || user?.lastName ? `${user?.firstName} ${user?.lastName}` : '-'}`}</p>
                                <span>{user?.email}</span>
                            </StyledUserInformationContainer>

                            <Divider direction={'horizontal'} />

                            <StyledLogoutContainer gap={1} align={'center'} onClick={handleLogout}>
                                <LogoutIcon />
                                <p>{t('commons:logout')}</p>
                            </StyledLogoutContainer>
                        </StyledWrapperLastSidebarItems>
                    ) : (
                        <StyledContainerWrapper direction={'column'} align={'center'} justify={'center'} gap={7}>
                            <Divider direction={'horizontal'} />

                            <Avatar
                                name={`${user?.firstName || ''} ${user?.lastName || ''}`}
                                onClick={() => navigate(routes.PROFILE.path)}
                                $isActive={matchPath(location.pathname, routes.PROFILE.path) !== null}
                            />

                            <LogoutIcon cursor={'pointer'} onClick={handleLogout} />
                        </StyledContainerWrapper>
                    )}
                </StyledContainerBottomItems>
            </>
        </StyledSidebarContainer>
    )
}
