import {PersonalInfoItem, StyledPersonalInformation, StyledWrapperSurname} from '@pages/profile/style.ts'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {Controller, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {PERSONAL_INFO_FORM_MODEL, PersonalInfoValidationSchema} from '@/features/profile/ProfileFormModal.ts'
import {useEditProfile} from '@/features/profile/services/useEditProfile.ts'
import {InputPhone} from '@components/commons/input-phone/InputPhone.tsx'
import {capitalize} from '@utilities/helpers.ts'
import {TITLE_TYPE_OPTIONS} from '@utilities/constants/list.ts'
import {CheckIcon} from '@components/ui/icon/Icon.tsx'
import {SingleSelect} from '@components/commons/single-select/SingleSelect.tsx'
import {useAuthStore} from '@/features/auth/store/store.ts'

export const PersonalInformationForm = () => {
    const {t} = useTranslation()
    const user = useAuthStore(state => state.user)
    const {mutateAsync: editProfile} = useEditProfile()

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: {errors, touchedFields, isDirty}
    } = useForm<PersonalInfoValidationSchema>({
        mode: 'onSubmit',
        resolver: zodResolver(PersonalInfoValidationSchema),
        defaultValues: {
            ...user,
            role: capitalize(user?.role?.name ?? '')
        }
    })

    const onSubmit = async (data: object) => {
        if (user) {
            await editProfile(data)
            reset(data)
        }
    }

    return (
        <StyledPersonalInformation onSubmit={handleSubmit(onSubmit)}>
            <PersonalInfoItem area="title" className={'title'}>
                {t('profile:subtitle_personal_information')}
            </PersonalInfoItem>
            <PersonalInfoItem area="surname">
                <StyledWrapperSurname>
                    <Controller
                        name={PERSONAL_INFO_FORM_MODEL.title.name}
                        control={control}
                        render={({field}) => {
                            return (
                                <SingleSelect
                                    minWidth={100}
                                    options={TITLE_TYPE_OPTIONS}
                                    label={t(PERSONAL_INFO_FORM_MODEL.title.label)}
                                    placeholder={t(PERSONAL_INFO_FORM_MODEL.title.placeholder)}
                                    value={field.value ?? ''}
                                    onChange={field.onChange}
                                    errorMessage={t(errors.title?.message || '')}
                                    hasIconOption={<CheckIcon size={20} />}
                                />
                            )
                        }}
                    />

                    <InputText
                        type={'text'}
                        label={t(PERSONAL_INFO_FORM_MODEL.lastName.label)}
                        placeholder={t(PERSONAL_INFO_FORM_MODEL.lastName.placeholder)}
                        errorMessage={t(errors.lastName?.message || '')}
                        touched={touchedFields.lastName}
                        {...register(PERSONAL_INFO_FORM_MODEL.lastName.name)}
                    />
                </StyledWrapperSurname>
            </PersonalInfoItem>
            <PersonalInfoItem area="name">
                <InputText
                    type={'text'}
                    label={t(PERSONAL_INFO_FORM_MODEL.firstName.label)}
                    placeholder={t(PERSONAL_INFO_FORM_MODEL.firstName.placeholder)}
                    errorMessage={t(errors.firstName?.message || '')}
                    touched={touchedFields.firstName}
                    {...register(PERSONAL_INFO_FORM_MODEL.firstName.name)}
                />
            </PersonalInfoItem>
            <PersonalInfoItem area="email">
                <InputText
                    type={'email'}
                    label={t(PERSONAL_INFO_FORM_MODEL.email.label)}
                    placeholder={t(PERSONAL_INFO_FORM_MODEL.email.placeholder)}
                    errorMessage={t(errors.email?.message || '')}
                    touched={touchedFields.email}
                    {...register(PERSONAL_INFO_FORM_MODEL.email.name)}
                />
            </PersonalInfoItem>
            <PersonalInfoItem area="phone">
                <Controller
                    name={PERSONAL_INFO_FORM_MODEL.phoneNumber.name}
                    control={control}
                    render={({field}) => {
                        return (
                            <InputPhone
                                label={t(PERSONAL_INFO_FORM_MODEL.phoneNumber.label)}
                                placeholder={t(PERSONAL_INFO_FORM_MODEL.phoneNumber.placeholder)}
                                errorMessage={t(errors.phoneNumber?.message || '')}
                                touched={touchedFields.phoneNumber || false}
                                {...field}
                            />
                        )
                    }}
                />
            </PersonalInfoItem>
            <PersonalInfoItem area="job">
                <InputText
                    type={'text'}
                    label={t(PERSONAL_INFO_FORM_MODEL.occupation.label)}
                    placeholder={t(PERSONAL_INFO_FORM_MODEL.occupation.placeholder)}
                    errorMessage={t(errors.occupation?.message || '')}
                    touched={touchedFields.occupation}
                    {...register(PERSONAL_INFO_FORM_MODEL.occupation.name)}
                />
            </PersonalInfoItem>
            <PersonalInfoItem area="role">
                <InputText
                    disabled
                    type={'text'}
                    label={t(PERSONAL_INFO_FORM_MODEL.role.label)}
                    placeholder={t(PERSONAL_INFO_FORM_MODEL.role.placeholder)}
                    errorMessage={t(errors.role?.message || '')}
                    {...register(PERSONAL_INFO_FORM_MODEL.role.name)}
                />
            </PersonalInfoItem>
            <PersonalInfoItem area="button" className={'button'}>
                <Button variant={'primary'} type={'submit'} disabled={!isDirty}>
                    {t('profile:action:update_profile')}
                </Button>
            </PersonalInfoItem>
        </StyledPersonalInformation>
    )
}
