import {useMutation} from '@tanstack/react-query'
import {httpEditPassword} from '@/features/profile/services/profile.http.ts'
import {EditPassword} from '@/features/profile/types.ts'
import {MUTATION_KEYS, queryProps} from '@/queryClient.ts'
import toast from 'react-hot-toast'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types.ts'
import {useTranslation} from 'react-i18next'
import {errorHandler} from '@utilities/helpers.ts'

export const useEditPassword = (options?: queryProps) => {
    const {t} = useTranslation()
    return useMutation({
        mutationKey: [MUTATION_KEYS.EDIT_PASSWORD],
        mutationFn: (payload: EditPassword) => httpEditPassword(payload),
        onSuccess: () => {
            options?.onSuccess?.()
            toast.success(t('commons:operation_completed'))
        },
        onError: (error: AxiosError<ErrorResponseData>) => {
            options?.onError?.(error)
            errorHandler(error)
        }
    })
}
