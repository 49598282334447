import {InputText} from '@/components/commons/input-text/InputText'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useTranslation} from 'react-i18next'
import {useFormContext} from 'react-hook-form'
import {z} from 'zod'
import {ZodObjectPatientFormSchema} from '@/features/patient-form/PatientFormModel'

export const PatientFormEmailStep = ({formSchema}: {formSchema: ZodObjectPatientFormSchema}) => {
    const {t} = useTranslation()
    const {
        register,
        formState: {errors, dirtyFields}
    } = useFormContext<z.infer<typeof formSchema>>()

    return (
        <>
            <Flexbox direction={'column'} gap={2}>
                <h1>{t('patients:create_patient:subtitle')}</h1>
                <p>{t('patients:create_patient:description')}</p>
            </Flexbox>
            <InputText
                label={t('patients:create_patient:form:labels:email')}
                type={'email'}
                placeholder={t('patients:create_patient:form:placeholder:email')}
                errorMessage={errors.email?.message && t(errors.email?.message)}
                touched={dirtyFields.email}
                {...register('email')}
            />
        </>
    )
}
