import * as pages from '@/pages'

export const routes = {
    LOGIN: {
        path: '/login',
        element: <pages.Login />
    },
    FORGOT_PASSWORD: {
        path: '/forgot-password',
        element: <pages.ForgotPassword />
    },
    CHANGE_PASSWORD: {
        path: '/change-password',
        element: <pages.ChangePassword />
    },
    EMAIL_SENT: {
        path: '/email-sent',
        element: <pages.EmailSent />
    },
    DASHBOARD: {
        path: '/',
        element: <pages.Dashboard />
    },
    APPOINTMENTS: {
        path: '/appointments',
        element: <pages.Appointments />,
        handle: {
            path: '/appointments',
            name: 'routeNames:appointments'
        }
    },
    PATIENTS: {
        path: '/patients',
        element: <pages.Patients />,
        handle: {
            path: '/patients',
            name: 'routeNames:patients'
        }
    },
    PATIENT_DETAILS: {
        path: ':patientId',
        element: <pages.PatientDetails />,
        handle: {
            path: '/patients/:patientId',
            paramName: 'patientId',
            name: ''
        }
    },
    CHECK_IN: {
        path: '/check-in',
        element: <pages.CheckIn />,
        handle: {
            path: '/check-in',
            name: 'routeNames:check_in'
        }
    },
    CHECK_IN_DETAILS: {
        path: ':checkInId',
        element: <pages.CheckInDetails />,
        handle: {
            path: '/check-in/:checkInId',
            paramName: 'checkInId',
            name: ''
        }
    },
    SELF_ASSESSMENT: {
        path: '/self-assessment',
        element: <pages.SelfEvaluation />,
        handle: {
            path: '/self-assessment',
            name: 'routeNames:self_assessment'
        }
    },
    SELF_ASSESSMENT_DETAILS: {
        path: ':selfEvaluationId',
        element: <pages.SelfEvaluationDetails />,
        handle: {
            path: '/self-assessment/:selfEvaluationId',
            paramName: 'selfEvaluationId',
            name: ''
        }
    },
    ARTICLES: {
        path: '/articles',
        element: <pages.Articles />,
        handle: {
            path: '/articles',
            name: 'routeNames:articles'
        }
    },
    EVENTS: {
        path: '/events',
        element: <pages.Events />,
        handle: {
            path: '/events',
            name: 'routeNames:events'
        }
    },
    SETTINGS: {
        path: '/settings',
        element: <pages.Settings />,
        handle: {
            path: '/settings',
            name: 'routeNames:settings'
        }
    },
    PROFILE: {
        path: '/profile',
        element: <pages.Profile />,
        handle: {
            path: '/profile',
            name: 'routeNames:profile'
        }
    }
}
