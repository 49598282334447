import {SelectProps} from '@/components/commons/select/Select'
import {StyledLocationSelector} from './style'
import {FetchNextPageOptions} from '@tanstack/react-query'
import {Location} from '@/features/patients/types'
import {useTranslation} from 'react-i18next'

interface LocationSelectorProps {
    value: SelectProps['value']
    label: string
    options: Location[]
    placeholder?: string
    onChange: SelectProps['onChange']
    searchValue?: string
    isLoading?: boolean
    onSearch: (value: string) => void
    fetchNextPage: (options?: FetchNextPageOptions) => void
    errorMessage?: string
    className?: string
    noOptionsMessage?: string
}

export const LocationSelector = ({
    value,
    options,
    onChange,
    onSearch,
    isLoading,
    label,
    placeholder,
    fetchNextPage,
    errorMessage,
    noOptionsMessage,
    className
}: LocationSelectorProps) => {
    const {t} = useTranslation()

    return (
        <StyledLocationSelector
            className={className}
            options={options.map(option => ({label: option.name, value: option.id.toString()}))}
            onInputChange={value => onSearch(value)}
            onMenuScrollToBottom={fetchNextPage}
            isLoading={isLoading}
            size={'medium'}
            isSearchable={true}
            menuPlacement="top"
            label={label}
            placeholder={placeholder}
            value={value ?? null}
            onChange={onChange}
            errorMessage={errorMessage}
            noOptionsMessage={() => noOptionsMessage}
            loadingMessage={() => t('commons:loading')}
            isClearable
        />
    )
}
