import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    StyledArticleSubtitle,
    StyledArticleTitle,
    StyledContainerArticleButton,
    StyledEmptyStateContainer,
    StyledIconWrapper
} from '@components/commons/empty-state/style.ts'
import {FC, ReactNode} from 'react'
import {Button} from '@components/ui/button/Button.tsx'

interface EmptyStateProps {
    title: string
    description: string
    icon?: ReactNode
    onClickCta?: () => void
    ctaLabel?: string | ReactNode
    iconCta?: ReactNode
    className?: string
    height?: string
}

export const EmptyState: FC<EmptyStateProps> = ({
    className,
    title,
    description,
    onClickCta,
    ctaLabel,
    icon,
    iconCta,
    height
}) => {
    return (
        <StyledEmptyStateContainer
            paddingX={8}
            paddingY={8}
            bodyHasBorder={false}
            cardClassName={className}
            $height={height}
        >
            <Flexbox justify={'center'} align={'center'} direction={'column'}>
                {icon && <StyledIconWrapper>{icon}</StyledIconWrapper>}
                <StyledArticleTitle>{title}</StyledArticleTitle>
                <StyledArticleSubtitle>{description}</StyledArticleSubtitle>

                {onClickCta && (
                    <StyledContainerArticleButton>
                        <Button variant="primary" onClick={onClickCta}>
                            {iconCta}
                            {ctaLabel}
                        </Button>
                    </StyledContainerArticleButton>
                )}
            </Flexbox>
        </StyledEmptyStateContainer>
    )
}
