import {Modal} from '@components/ui/modal-legacy/Modal.tsx'
import {useTranslation} from 'react-i18next'
import {Button} from '@components/ui/button/Button.tsx'
import {FC} from 'react'
import {StyledContainerSlotsAvailabilityBody} from '@/features/appointments/components/modals/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {PlusIcon} from '@components/ui/icon/Icon.tsx'
import {AddNewShiftModal} from '@/features/appointments/components/modals/AddNewShiftModal.tsx'
import {BaseCard} from '@components/commons/cards/BaseCard.tsx'
import {Shift} from '@/features/appointments/types.ts'
import {ContactObj} from '@/features/settings/SettingsFormModal.ts'

type SlotsAvailabilityModalProps = {
    onClose: () => void
    openDeleteModal: (shiftID: number) => void
    openAddNewShift: boolean
    setOpenAddNewShift: (open: boolean) => void
    doctorID: number
    shifts: Shift[]
    offices: ContactObj[]
}
export const SlotsAvailabilityModal: FC<SlotsAvailabilityModalProps> = ({
    doctorID,
    onClose,
    openDeleteModal,
    openAddNewShift,
    setOpenAddNewShift,
    shifts,
    offices
}) => {
    const {t} = useTranslation()

    return (
        <Modal
            width={openAddNewShift ? '800' : '500'}
            closeIconOffset="sm"
            onClose={onClose}
            onOverlayClick={onClose}
            title={t('appointments:slots_availability')}
        >
            <StyledContainerSlotsAvailabilityBody>
                {!openAddNewShift ? (
                    <BaseCard paddingX={4} paddingY={4} bodyHasBorder={false}>
                        <Flexbox direction="column" gap={6}>
                            <p className={'title'}>{t('appointments:work_shift_configuration')}</p>

                            <span className={'description'}>{t('appointments:work_shift_description')}</span>

                            <Button variant="secondaryColor" onClick={() => setOpenAddNewShift(true)}>
                                <PlusIcon />
                                {t('appointments:add_work_shift')}
                            </Button>
                        </Flexbox>
                    </BaseCard>
                ) : (
                    <>
                        {shifts && offices && (
                            <AddNewShiftModal
                                doctorID={doctorID}
                                onDeletedShifts={shiftID => {
                                    openDeleteModal(shiftID)
                                    onClose()
                                }}
                                onClose={() => {
                                    setOpenAddNewShift(false)
                                    onClose()
                                }}
                                shifts={shifts}
                                offices={offices}
                            />
                        )}
                    </>
                )}
            </StyledContainerSlotsAvailabilityBody>
        </Modal>
    )
}
