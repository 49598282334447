import {MUTATION_KEYS, QUERY_KEYS, queryClient} from '@/queryClient'
import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {raise} from '@/utilities/helpers'
import {httpEditPatient, HttpEditPatientParams} from './patientForm.http'

export const useEditPatient = ({
    patientId,
    options
}: {
    patientId?: number
    options?: Omit<
        UseMutationOptions<unknown, AxiosError<{code: string}>, Omit<HttpEditPatientParams, 'urlParams'>>,
        'mutation' | 'mutationKey'
    >
}) =>
    useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.EDIT_PATIENT],
        mutationFn: ({payload}) =>
            patientId
                ? httpEditPatient({
                      urlParams: {patientId},
                      payload
                  })
                : raise('need patient id'),
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PATIENTS]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PATIENTS_DETAILS, patientId]})
            options?.onSuccess?.(data, variables, context)
        },
        onError: (data, variables, context) => {
            options?.onError?.(data, variables, context)
        }
    })
