import {ButtonHTMLAttributes, HTMLAttributes} from 'react'
import {XCloseIcon} from '@/components/ui/icon/Icon'
import {Button} from '@/components/ui/button/Button'
import {StyledModalBody, StyledModalFooter, StyledModalHeader, StyledModalParagraph, StyledModalTitle} from './style'

export const ModalHeader = (props: HTMLAttributes<HTMLDivElement>) => <StyledModalHeader {...props} />
export const ModalBody = (props: HTMLAttributes<HTMLDivElement>) => <StyledModalBody {...props} />
export const ModalFooter = (props: HTMLAttributes<HTMLDivElement>) => <StyledModalFooter {...props} />
export const ModalTitle = (props: HTMLAttributes<HTMLHeadingElement>) => <StyledModalTitle {...props} />
export const ModalParagraph = (props: HTMLAttributes<HTMLParagraphElement>) => <StyledModalParagraph {...props} />
export const ModalXCloseButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
    <Button variant="tertiary" size="sm" shape="square" {...props}>
        <XCloseIcon />
    </Button>
)
