import {z} from 'zod'

export const NEW_SHIFT_FORM_MODAL = {
    start: {
        name: 'start',
        label: '',
        placeholder: '',
        defaultValue: '09:00',
        helpText: ''
    },
    end: {
        name: 'end',
        label: '',
        placeholder: '',
        defaultValue: '18:00',
        helpText: ''
    }
}

export const NewShiftValidationSchema = z.object({
    start: z.string().nullish(),
    end: z.string().nullish()
})

export type NewShiftFormValues = z.infer<typeof NewShiftValidationSchema>
