import {ModalFooter} from '@/components/ui/modal-atoms/ModalAtoms'
import {User} from '@/features/auth/types'
import {zodResolver} from '@hookform/resolvers/zod'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {z} from 'zod'
import {
    StyledCaregiverFormBody,
    StyledCaregiverFormModal,
    StyledCaregiverFormModalHeader,
    StyledCaregiverFormModalTitle
} from './style'
import {Button} from '@/components/ui/button/Button'
import {XCloseIcon} from '@/components/ui/icon/Icon'
import {InputText} from '@/components/commons/input-text/InputText'
import {CAREGIVER_RELATIONSHIPS} from '../../utils'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {InputPhone} from '@/components/commons/input-phone/InputPhone'
import {useEditCaregiver} from '../../services/useEditCaregiver'
import toast from 'react-hot-toast'
import {Select} from '@/components/commons/select/Select'
import {SelectValue} from '@/types'
import {errorHandler} from '@utilities/helpers.ts'

export const EditCaregiverValidationSchema = z.object({
    firstName: z.string().min(1, {message: 'patient_details:caregiver_form_errors:firstName'}),
    lastName: z.string().min(1, {message: 'patient_details:caregiver_form_errors:lastName'}),
    gender: z.string().min(1),
    email: z.string().min(1, {message: 'errors:email_valid'}).email({message: 'errors:email_valid'}),
    phoneNumber: z.string().optional(),
    caregiverRelationshipDegree: z.object(
        {
            label: z.string(),
            value: z.string()
        },
        {
            invalid_type_error: 'patient_details:caregiver_form_errors:relationshipDegree',
            required_error: 'patient_details:caregiver_form_errors:relationshipDegree'
        }
    )
})
export type EditCaregiverValidationSchema = z.infer<typeof EditCaregiverValidationSchema>

interface CaregiverFormModalProps {
    onClose: () => void
    patientId: number
    caregiver: User['caregiver']
}

export const CaregiverFormModal = ({onClose, patientId, caregiver}: CaregiverFormModalProps) => {
    const {t} = useTranslation()
    const form = useForm<EditCaregiverValidationSchema>({
        mode: 'onChange',
        resolver: zodResolver(EditCaregiverValidationSchema),
        defaultValues: {
            lastName: caregiver?.lastName || '',
            firstName: caregiver?.firstName || '',
            email: caregiver?.email,
            phoneNumber: caregiver?.phoneNumber || '',
            caregiverRelationshipDegree: caregiver?.caregiverRelationshipDegree
                ? {
                      label: t(`patient_details:caregiver_relationships:${caregiver.caregiverRelationshipDegree}`, {
                          context: caregiver.gender
                      }),
                      value: caregiver.caregiverRelationshipDegree
                  }
                : undefined,
            gender: caregiver?.gender || ''
        }
    })

    const editCaregiverMutation = useEditCaregiver({
        patientId,
        caregiverId: caregiver?.id,
        options: {
            onSuccess: () => {
                toast.success(t('commons:operation_completed'))
                onClose()
            },
            onError: error => {
                console.error(error)
                errorHandler(error)
            }
        }
    })

    const handleSubmit = form.handleSubmit(formValues => {
        return editCaregiverMutation.mutate({
            payload: {
                lastName: formValues.lastName,
                firstName: formValues.firstName,
                email: formValues.email,
                gender: formValues.gender,
                caregiverRelationshipDegree: formValues?.caregiverRelationshipDegree?.value,
                phoneNumber: formValues.phoneNumber
            }
        })
    })

    return (
        <StyledCaregiverFormModal width={'444px'} onOverlayClick={onClose}>
            <StyledCaregiverFormModalHeader>
                <h4>{t('patient_details:edit_caregiver_title')}</h4>
                <Button variant="tertiary" size="sm" shape="square" onClick={onClose}>
                    <XCloseIcon />
                </Button>
            </StyledCaregiverFormModalHeader>

            <StyledCaregiverFormBody>
                <Flexbox direction="column" gap={6} render="form" {...form}>
                    <StyledCaregiverFormModalTitle>
                        {t('patient_details:caregiver_form_title')}
                    </StyledCaregiverFormModalTitle>
                    <InputText
                        required
                        label={t('patient_details:caregiver_form_labels:lastName')}
                        type={'text'}
                        placeholder={t('patient_details:caregiver_form_placeholders:lastName')}
                        errorMessage={t(form.formState.errors.lastName?.message || '')}
                        touched={form.formState.touchedFields.lastName}
                        {...form.register('lastName')}
                    />
                    <InputText
                        required
                        label={t('patient_details:caregiver_form_labels:firstName')}
                        type={'text'}
                        placeholder={t('patient_details:caregiver_form_placeholders:firstName')}
                        errorMessage={t(form.formState.errors.firstName?.message || '')}
                        touched={form.formState.touchedFields.firstName}
                        {...form.register('firstName')}
                    />
                    <Controller
                        control={form.control}
                        name={'caregiverRelationshipDegree'}
                        render={({field: {onChange, value}}) => (
                            <Select
                                options={CAREGIVER_RELATIONSHIPS.map(relationShip => ({
                                    value: relationShip?.selectValue || '',
                                    label: relationShip?.label ? t(relationShip.label) : ''
                                }))}
                                isClearable
                                label={t('patient_details:caregiver_form_labels:relationshipDegree')}
                                placeholder={t('patient_details:caregiver_form_placeholders:relationshipDegree')}
                                onChange={value => {
                                    const selectValue = value as SelectValue
                                    const targetRelation = CAREGIVER_RELATIONSHIPS.find(
                                        rel => rel.selectValue == selectValue?.value
                                    )
                                    onChange(value)
                                    form.setValue('gender', targetRelation?.gender ?? '')
                                }}
                                defaultValue={value ?? null}
                                errorMessage={t(form.formState.errors.caregiverRelationshipDegree?.message || '')}
                            />
                        )}
                    />
                    <InputText
                        label={t('patient_details:caregiver_form_labels:email')}
                        type={'email'}
                        placeholder={t('patient_details:caregiver_form_placeholders:email')}
                        errorMessage={form.formState.errors.email?.message && t(form.formState.errors.email?.message)}
                        touched={form.formState.dirtyFields.email}
                        {...form.register('email')}
                    />
                    <Controller
                        control={form.control}
                        name={'phoneNumber'}
                        render={({field}) => (
                            <InputPhone
                                label={t('patient_details:caregiver_form_labels:phoneNumber')}
                                placeholder={t('patient_details:caregiver_form_labels:phoneNumber')}
                                errorMessage={t(form.formState.errors.phoneNumber?.message || '')}
                                touched={form.formState.touchedFields.phoneNumber || false}
                                {...field}
                            />
                        )}
                    />
                </Flexbox>
            </StyledCaregiverFormBody>
            <ModalFooter>
                <Flexbox justify="space-between" fullWidth>
                    <Button variant="tertiary" onClick={() => form.reset()}>
                        {t('commons:actions:cancel')}
                    </Button>
                    <Button onClick={handleSubmit}>{t('commons:actions:save')}</Button>
                </Flexbox>
            </ModalFooter>
        </StyledCaregiverFormModal>
    )
}
