import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {StyledContainerUserTable} from '@pages/settings/style.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {PlusIcon} from '@components/ui/icon/Icon.tsx'
import {Table} from '@components/commons/table/Table.tsx'
import {useTranslation} from 'react-i18next'
import {ActionsMenu} from '@/features/settings/components/actions-menu/ActionsMenu.tsx'
import {useState} from 'react'
import {CreateAndEditUserModal} from '@/features/settings/components/create-and-edit-user-modal/CreateAndEditUserModal.tsx'
import {ACTION_TYPES, UsersSearchParams} from '@/features/settings/types.ts'
import {actionsListSettingsUser} from '@utilities/constants/actionsMenus.tsx'
import {DeleteUserModal} from '@/features/settings/components/delete-user-modal/DeleteUserModal.tsx'
import {useGetUsers} from '@/features/settings/services/services-users/useGetUsers.ts'
import {useRemoveUser} from '@/features/settings/services/services-users/useRemoveUser.ts'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {ResponseType} from '@/types.ts'
import dayjs from '@/dayjs.ts'
import {useGetUsersRoles} from '@/features/settings/services/services-users/useGetUsersRoles.ts'
import {capitalize} from '@utilities/helpers.ts'
import {useRecoveryPassword} from '@/features/settings/services/services-users/useRecoveryPassword.ts'
import {useGetContacts} from '@/features/settings/services/services-contact/useGetContacts.ts'

export const SettingsUsers = () => {
    const {t} = useTranslation()
    const [openUserModal, setOpenUserModal] = useState<boolean>(false)
    const [modalType, setModalType] = useState<string>(ACTION_TYPES.create)
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
    const [selectedUser, setSelectedUser] = useState<number | undefined>(undefined)
    const {mutateAsync: deleteUser, isPending} = useRemoveUser()
    const {searchParams, setSearchParams} = useParsedSearchParams([], UsersSearchParams)
    const {data: roles} = useGetUsersRoles()
    const {mutateAsync: recoveryPassword} = useRecoveryPassword()
    const {data: officeAllocations} = useGetContacts()

    const sort = (sorter: {
        orderBy: UsersSearchParams['orderBy']
        orderDirection: UsersSearchParams['orderDirection']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({
                orderBy: sorter.orderBy,
                orderDirection: sorter.orderDirection
            })
        } else {
            setSearchParams({orderBy: undefined, orderDirection: undefined})
        }
    }

    const {
        remappedData: users,
        isLoading,
        isError
    } = useGetUsers({
        ...searchParams,
        responseType: ResponseType.enum.backofficeRegular,
        limit: 20
    })

    const onActionClick = (action: string, selectedItem: number | undefined) => {
        switch (action) {
            case ACTION_TYPES.edit:
            case ACTION_TYPES.create:
                setOpenUserModal(true)
                break
            case ACTION_TYPES.delete:
                setOpenDeleteModal(true)
                break
            default:
                recoveryPassword(selectedItem ?? 0)
                break
        }
        setModalType(action)
        setSelectedUser(selectedItem)
    }

    const onDelete = async () => {
        await deleteUser(selectedUser)
        setOpenDeleteModal(false)
    }

    return (
        <>
            {openUserModal && (
                <CreateAndEditUserModal
                    modalType={modalType}
                    onClose={() => setOpenUserModal(false)}
                    selectedUser={users.find(user => user.id === selectedUser) || null}
                    roles={roles?.data || []}
                    officeAllocations={officeAllocations?.data || []}
                />
            )}

            {openDeleteModal && (
                <DeleteUserModal
                    onClose={() => setOpenDeleteModal(false)}
                    selectedUser={users.find(user => user.id === selectedUser) || null}
                    onDelete={onDelete}
                    isLoading={isPending}
                />
            )}

            <Flexbox direction={'column'} gap={6}>
                <PageHero
                    subtitle={t('settings:tabs:users')}
                    ctaComponent={
                        <Button
                            onClick={() => {
                                onActionClick(ACTION_TYPES.create, undefined)
                            }}
                        >
                            <PlusIcon />
                            {t('settings:users:cta')}
                        </Button>
                    }
                />

                <StyledContainerUserTable>
                    <Table
                        isError={isError}
                        data={users}
                        isLoading={isLoading}
                        sorter={{
                            orderBy: searchParams.orderBy,
                            orderDirection: searchParams.orderDirection,
                            onSort: sort
                        }}
                        columns={[
                            {
                                label: t('settings:table:id'),
                                width: '10%',
                                sortName: 'id',
                                alignment: 'center',
                                cellRender: data => (
                                    <div>
                                        <p>{data?.id}</p>
                                    </div>
                                )
                            },
                            {
                                label: t('settings:table:name'),
                                width: '25%',
                                sortName: 'name',
                                cellRender: data => (
                                    <div>
                                        <p>
                                            {data?.firstName} {data?.lastName}
                                        </p>
                                    </div>
                                )
                            },
                            {
                                label: t('settings:table:email'),
                                width: '25%',
                                sortName: 'email',
                                cellRender: data => (
                                    <div>
                                        <p>{data?.email}</p>
                                    </div>
                                )
                            },
                            {
                                label: t('settings:table:role'),
                                width: '20%',
                                sortName: 'role',
                                cellRender: data => <div>{data.role && <p>{capitalize(data?.role.name)}</p>}</div>
                            },
                            {
                                label: t('settings:table:created_at'),
                                width: '15%',
                                sortName: 'created_at',
                                cellRender: data => (
                                    <div>
                                        <p>{dayjs(data?.createdAt).format('ll')}</p>
                                    </div>
                                )
                            },
                            {
                                label: '',
                                width: '5%',
                                alignment: 'center',
                                cellRender: data => (
                                    <ActionsMenu
                                        actions={actionsListSettingsUser}
                                        onActionClick={onActionClick}
                                        selectedItem={data?.id}
                                    />
                                )
                            }
                        ]}
                    />
                </StyledContainerUserTable>
            </Flexbox>
        </>
    )
}
