import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance'
import {captureException} from '@sentry/react'
import {
    httpGetTimeline,
    HttpGetTimelineOptions,
    HttpGetTimelineResponse
} from '@/features/patient-details/services/patientDetails.http'
import {ResponseType} from '@/types.ts'

export const useGetTimeLine = (
    promiseArgs: HttpGetTimelineOptions,
    options?: Omit<UseQueryOptions<HttpGetTimelineResponse>, 'queryFn' | 'queryKey'>
) =>
    useQuery({
        ...options,
        queryKey: [QUERY_KEYS.TIMELINE, promiseArgs],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetTimeline({
                    ...promiseArgs,
                    responseType: ResponseType.enum.regular
                }),
                responseShape: HttpGetTimelineResponse,
                onZodError: captureException
            })
    })
