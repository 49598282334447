import {PaginationResponse, ResponseTypeParam, PaginationParams, ResponseType} from '@/types.ts'
import {z} from 'zod'
import {
    PatientAppointmentsSearchParams,
    PatientDetailsBetsSearchParams,
    TimeLine
} from '@/features/patient-details/types'
import axios from '@/axiosInstance.ts'
import {Appointment} from '@/features/appointments/types.ts'
import {Emotion} from '@/features/auth/types.ts'

/**
 * Get Patient details
 */

export const HttpGetPatientDetailsOptions = z.object({
    patientId: z.coerce.number(),
    responseType: ResponseType
})

export type HttpGetPatientDetailsOptions = z.infer<typeof HttpGetPatientDetailsOptions>

export const httpGetPatientDetails = (params: HttpGetPatientDetailsOptions) => {
    return axios.get(`/backoffice/users/${params.patientId}`, {params})
}

/**
 * Get Patient details appointments
 */
export const HttpGetPatientAppointmentsOptions = z.object({
    patientId: z.coerce.number().optional(),
    ...PatientAppointmentsSearchParams.shape,
    ...PaginationParams.shape,
    ...ResponseTypeParam.shape
})
export type HttpGetPatientAppointmentsOptions = z.infer<typeof HttpGetPatientAppointmentsOptions>

export const HttpGetPatientAppointmentsResponse = z.object({
    data: z.array(Appointment),
    meta: PaginationResponse
})
export type HttpGetPatientAppointmentsResponse = z.infer<typeof HttpGetPatientAppointmentsResponse>

export const httpGetUserAppointments = (params: HttpGetPatientAppointmentsOptions) => {
    return axios.get(`/backoffice/users/${params.patientId}/appointments`, {params})
}

export const HttpGetPatientDetailsBetsOptions = z.object({
    patientId: z.coerce.number().optional(),
    responseType: ResponseType.optional(),
    ...PatientDetailsBetsSearchParams.shape
})
export type HttpGetPatientDetailsBetsOptions = z.infer<typeof HttpGetPatientDetailsBetsOptions>
export const httpGetPatientDetailsBets = (params: HttpGetPatientDetailsBetsOptions) => {
    return axios.get(`/backoffice/users/${params.patientId}/analytics/bets`, {params})
}

export const HttpGetPatientDetailsEmotionOptions = z.object({
    patientId: z.coerce.number(),
    responseType: ResponseType.optional(),
    ...PatientDetailsBetsSearchParams.shape
})
export type HttpGetPatientDetailsEmotionOptions = z.infer<typeof HttpGetPatientDetailsEmotionOptions>

export const httpGetPatientDetailsEmotion = (params: HttpGetPatientDetailsBetsOptions) => {
    return axios.get(`/backoffice/users/${params.patientId}/analytics/emotions`, {params})
}

export const HttpGetTimelineOptions = z.object({
    patientId: z.coerce.number(),
    responseType: ResponseType.optional(),
    fromDate: z.string(),
    toDate: z.string()
})
export type HttpGetTimelineOptions = z.infer<typeof HttpGetTimelineOptions>

export const HttpGetTimelineResponse = z
    .object({
        date: z.string(),
        timeline: TimeLine.array()
    })
    .array()
export type HttpGetTimelineResponse = z.infer<typeof HttpGetTimelineResponse>

export const httpGetTimeline = (params: HttpGetTimelineOptions) => {
    return axios.get(`/backoffice/users/${params.patientId}/timelines`, {params})
}

export const HttpGetEmotionResponse = z.object({
    data: Emotion.array()
})
export type HttpGetEmotionResponse = z.infer<typeof HttpGetEmotionResponse>
export const httpGetEmotion = () => {
    return axios.get(`/emotions`)
}

/**
 * Update Caregiver
 */
export const HttpEditCaregiverPayload = z.object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    gender: z.string().optional(),
    phoneNumber: z.string().nullish(),
    caregiverRelationshipDegree: z.string().nullish()
})
export type HttpEditCaregiverPayload = z.infer<typeof HttpEditCaregiverPayload>

export const HttpEditCaregiverParams = z.object({
    urlParams: z.object({caregiverId: z.number()}),
    payload: HttpEditCaregiverPayload
})
export type HttpEditCaregiverParams = z.infer<typeof HttpEditCaregiverParams>

export const httpEditCaregiver = ({urlParams, payload}: HttpEditCaregiverParams) =>
    axios.patch(`/users/${urlParams.caregiverId}`, payload)
