import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'

export const StyledCheckInTableLink = styled(Button)`
    text-decoration: underline;
    padding: 0;
`
export const StyledCheckInTableId = styled.p(
    ({theme: {palette}}) => css`
        color: ${palette.neutral[500]};
        font-weight: 400;
    `
)
export const StyledCheckInTableThoughts = styled(Flexbox)<{$hasThoughts: boolean}>(
    ({theme: {palette}, $hasThoughts}) => css`
        color: ${$hasThoughts ? palette.success[600] : palette.danger[600]};
        font-weight: 400;
    `
)
