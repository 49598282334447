import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance'
import {captureException} from '@sentry/react'
import {httpGetEmotion, HttpGetEmotionResponse} from '@/features/patient-details/services/patientDetails.http'

export const useGetEmotion = (options?: Omit<UseQueryOptions<HttpGetEmotionResponse>, 'queryFn' | 'queryKey'>) =>
    useQuery({
        ...options,
        queryKey: [QUERY_KEYS.EMOTION],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetEmotion(),
                responseShape: HttpGetEmotionResponse,
                onZodError: captureException
            })
    })
