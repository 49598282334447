import {ButtonLink} from '@/components/ui/button-link/ButtonLink'
import styled, {css} from 'styled-components'

export const StyledAuthContainer = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        display: grid;
        place-items: stretch;
        gap: ${spacing * 6}px;
        width: 360px;
        & h4 {
            ${typography.displaySm};
            font-weight: 700;
            color: ${palette.neutral['900']};
            text-align: center;
        }
        & p {
            ${typography.textMd};
            text-align: center;
        }
    `}
`

export const StyledForgotPasswordLink = styled(ButtonLink)(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        color: ${palette.primary[600]};
        font-weight: 600;
        cursor: pointer;
        padding: 0;

        &:hover {
            background: transparent;
        }
    `
)
