import {useTranslation} from 'react-i18next'
import {useGetAppointmentsGroup} from '@/features/dashboard/services/useGetAppointmentsGroup.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {DashboardAppointmentsUrlParams} from '@/features/dashboard/types.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {CardStats} from '@components/commons/card-stats/CardStats.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {ErrorIcon} from 'react-hot-toast'
import {PercentageSummary} from '@components/commons/percentage-summary/PercentageSummary.tsx'
import {
    StyledAppointmentsProgressLabel,
    StyledAppointmentsTypologyProgressLabel,
    StyledPrimaryData
} from '@/features/dashboard/components/appointments-group/style.ts'
import {ProgressBar} from '@components/ui/progress-bar/ProgressBar.tsx'
import {MarkerPin01Icon, VideoRecorderIcon} from '@components/ui/icon/Icon.tsx'

export const AppointmentsGroup = () => {
    const {t} = useTranslation()
    const {searchParams} = useParsedSearchParams([], DashboardAppointmentsUrlParams)
    const appointmentStatus = useGetAppointmentsGroup({groupBy: 'status', ...searchParams})
    const appointmentLocation = useGetAppointmentsGroup({groupBy: 'location', ...searchParams})

    const renderProgressBar = (label: string, percentage: number) => {
        switch (label) {
            case 'missed':
                return (
                    <>
                        <StyledAppointmentsProgressLabel>
                            {t(`commons:appointment_state:${label}`)}
                        </StyledAppointmentsProgressLabel>
                        <ProgressBar percentageProgress={percentage} variant={'warning'} />
                    </>
                )
            case 'cancelled':
                return (
                    <>
                        <StyledAppointmentsProgressLabel>
                            {t(`commons:appointment_state:${label}`)}
                        </StyledAppointmentsProgressLabel>
                        <ProgressBar percentageProgress={percentage} variant={'danger'} />
                    </>
                )
            case 'completed':
                return (
                    <>
                        <StyledAppointmentsProgressLabel>
                            {t(`commons:appointment_state:${label}`)}
                        </StyledAppointmentsProgressLabel>
                        <ProgressBar percentageProgress={percentage} variant={'success'} />
                    </>
                )
        }
    }

    return (
        <Flexbox fullWidth gap={6} align={'stretch'}>
            {appointmentStatus.isLoading ? (
                <>
                    <CardStats asSkeleton />
                    <CardStats asSkeleton />
                    <CardStats asSkeleton />
                </>
            ) : appointmentStatus.isError ? (
                <ErrorBox icon={<ErrorIcon />} title={t('errors:default')} />
            ) : (
                appointmentStatus.data && (
                    <>
                        <CardStats
                            headerSlot={t('dashboard:appointments:booked')}
                            bodySlot={
                                <Flexbox direction={'column'} gap={4}>
                                    <StyledPrimaryData>{appointmentStatus.data.analytics.total}</StyledPrimaryData>
                                    <PercentageSummary
                                        amount={appointmentStatus.data.analytics.total}
                                        percentage={appointmentStatus.data.increments.percentage}
                                        label={t(`commons:compare_labels:${searchParams.rangeDateLabels}`)}
                                    />
                                </Flexbox>
                            }
                        />
                        <CardStats
                            headerSlot={t('dashboard:appointments:status')}
                            bodySlot={
                                <Flexbox direction={'column'} gap={4}>
                                    {appointmentStatus.data.analytics.partitions.map((value, index) => (
                                        <Flexbox fullWidth gap={2} align={'center'} key={index}>
                                            {renderProgressBar(value.label, value.percentage || 0)}
                                        </Flexbox>
                                    ))}
                                </Flexbox>
                            }
                        />
                        <CardStats
                            headerSlot={t('dashboard:appointments:typology')}
                            bodySlot={
                                <Flexbox direction={'column'} gap={5}>
                                    {appointmentLocation.data?.analytics.partitions.map((value, index) => (
                                        <Flexbox key={index} fullWidth gap={2}>
                                            <Flexbox gap={2}>
                                                {value.label === 'remote' ? (
                                                    <VideoRecorderIcon size={20} />
                                                ) : (
                                                    <MarkerPin01Icon size={20} />
                                                )}
                                                <StyledAppointmentsTypologyProgressLabel>
                                                    {t(`dashboard:appointments:${value.label}`)}
                                                </StyledAppointmentsTypologyProgressLabel>
                                            </Flexbox>
                                            <ProgressBar
                                                percentageProgress={value.percentage || 0}
                                                variant={'primary'}
                                            />
                                        </Flexbox>
                                    ))}
                                </Flexbox>
                            }
                        />
                    </>
                )
            )}
        </Flexbox>
    )
}
