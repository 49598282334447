import {StyledAppointmentStatus} from '@components/commons/appointment-status-chip/style.ts'
import {FC} from 'react'

export interface AppointmentStatusChipProps {
    label: string
    variant: 'missed' | 'completed' | 'booked' | 'deleted' | 'cancelled'
}
export const AppointmentStatusChip: FC<AppointmentStatusChipProps> = ({label, variant}) => {
    return <StyledAppointmentStatus variant={'outlined'} $appointmentStatusVariant={variant} label={label} />
}
