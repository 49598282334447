import {useOutletContext} from 'react-router-dom'
import {SelfEvaluationDetailsURLParams} from '@/features/self-evaluation-details/types.ts'
import {useTranslation} from 'react-i18next'
import {useGetSelfEvaluationDetails} from '@/features/self-evaluation-details/service/useGetSelfEvaluationDetails.ts'
import {Table} from '@components/commons/table/Table.tsx'
import {
    StyledSelfAssessmentTableFooter,
    StyledSurveyTableWrapper
} from '@/features/self-evaluation-details/components/self-evaluation-details-table/style.ts'

export const SelfEvaluationDetailsTable = () => {
    const {urlParams} = useOutletContext<{urlParams: SelfEvaluationDetailsURLParams}>()
    const {t} = useTranslation()
    const selfAssessmentDetails = useGetSelfEvaluationDetails({selfEvaluationId: urlParams.selfEvaluationId})

    return (
        <StyledSurveyTableWrapper>
            <Table
                data={selfAssessmentDetails.data?.selfEvaluationForm.selfEvaluationFormQuestions || []}
                isLoading={selfAssessmentDetails.isLoading}
                isError={selfAssessmentDetails.isError}
                footerComponent={
                    <StyledSelfAssessmentTableFooter direction={'column'} gap={3}>
                        <h4>{t('self_assessment_details:table:label:note')}</h4>
                        {/*<p>{selfAssessmentDetails.data?.note}</p>*/}
                    </StyledSelfAssessmentTableFooter>
                }
                columns={[
                    {
                        label: t('self_assessment_details:table:label:question'),
                        width: '50%',
                        cellRender: data => <p>{data.keyName}</p>
                    },
                    {
                        label: t('self_assessment_details:table:label:answer'),
                        width: '50%',
                        cellRender: data => <p>{data.keyName}</p>
                    }
                ]}
            />
        </StyledSurveyTableWrapper>
    )
}
