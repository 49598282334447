import {
    ContactFormSchema,
    ContactObj,
    ContactValidationSchema,
    SETTINGS_CONTACTS_FORM_MODEL
} from '@/features/settings/SettingsFormModal.ts'
import {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useRemoveContact} from '@/features/settings/services/services-contact/useRemoveContact.ts'
import {useAddContact} from '@/features/settings/services/services-contact/useAddContact.ts'
import {useEditContact} from '@/features/settings/services/services-contact/useEditContact.ts'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {StyledContactCard, StyledWrapperCards} from '@/features/settings/components/settings-contacts/style.ts'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {InputPhone} from '@components/commons/input-phone/InputPhone.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {PlusIcon, Trash01Icon} from '@components/ui/icon/Icon.tsx'

interface ContactFormProps {
    defaultContacts: ContactObj[]
}

export const ContactForm: FC<ContactFormProps> = ({defaultContacts}) => {
    const {t} = useTranslation()
    const [indexSelected, setIndexSelected] = useState<number>(-1)
    const {mutate: deleteContact} = useRemoveContact()
    const {mutateAsync: mutateAddContact, isPending: isCreatePending} = useAddContact()
    const {mutateAsync: mutateEditContact, isPending: isEditPending} = useEditContact()

    const {
        register,
        handleSubmit,
        control,
        formState: {errors}
    } = useForm<ContactValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(ContactFormSchema),
        defaultValues: {
            contacts: defaultContacts
        }
    })

    const {
        fields: contacts,
        remove,
        append: addContact
    } = useFieldArray({
        control,
        name: 'contacts'
    })

    const onSubmit = async (data: ContactValidationSchema) => {
        const isEdit = data?.contacts?.[indexSelected]?.contact_id
        const contact = data?.contacts?.[indexSelected]
        if (contact) {
            if (isEdit) {
                await mutateEditContact(contact)
            } else {
                await mutateAddContact(contact)
            }
        }
    }

    const removeContact = (index: number, contact_id: number | null | undefined) => {
        remove(index)
        deleteContact(contact_id)
    }

    return (
        <>
            {isCreatePending || (isEditPending && <Spinner size={40} color={'#000'} haveOverlay />)}
            <StyledWrapperCards direction={'column'} gap={6} render={'form'} onSubmit={handleSubmit(onSubmit)}>
                <PageHero subtitle={t('settings:tabs:contact')} />

                {contacts?.length > 0 &&
                    contacts.map((field, index) => (
                        <StyledContactCard key={field.id}>
                            <InputText
                                type="text"
                                label={t(SETTINGS_CONTACTS_FORM_MODEL.name.label)}
                                placeholder={t(SETTINGS_CONTACTS_FORM_MODEL.name.placeholder)}
                                errorMessage={t(errors.contacts?.[index]?.name?.message || '')}
                                {...register(`contacts.${index}.name`)}
                                onFocus={() => setIndexSelected(index)}
                            />
                            <InputText
                                type="text"
                                label={t(SETTINGS_CONTACTS_FORM_MODEL.address.label)}
                                placeholder={t(SETTINGS_CONTACTS_FORM_MODEL.address.placeholder)}
                                errorMessage={t(errors?.contacts?.[index]?.address?.message || '')}
                                {...register(`contacts.${index}.address`)}
                                onFocus={() => setIndexSelected(index)}
                            />
                            <Controller
                                name={`contacts.${index}.phone_number`}
                                control={control}
                                render={({field}) => {
                                    return (
                                        <InputPhone
                                            label={t(SETTINGS_CONTACTS_FORM_MODEL.phone_number.label)}
                                            placeholder={t(SETTINGS_CONTACTS_FORM_MODEL.phone_number.placeholder)}
                                            errorMessage={t(errors.contacts?.[index]?.phone_number?.message || '')}
                                            {...register(`contacts.${index}.phone_number`)}
                                            onFocus={() => setIndexSelected(index)}
                                            touched={false}
                                            {...field}
                                        />
                                    )
                                }}
                            />
                            <InputText
                                type="email"
                                label={t(SETTINGS_CONTACTS_FORM_MODEL.email.label)}
                                placeholder={t(SETTINGS_CONTACTS_FORM_MODEL.email.placeholder)}
                                errorMessage={t(errors?.contacts?.[index]?.email?.message || '')}
                                {...register(`contacts.${index}.email`)}
                                onFocus={() => setIndexSelected(index)}
                            />
                            <div className={'button'}>
                                <Button
                                    onClick={() => removeContact(index, field?.contact_id)}
                                    variant={'tertiaryDanger'}
                                    type={'button'}
                                >
                                    <Trash01Icon />
                                    {t('contacts:actions:remove_contact')}
                                </Button>
                                <Button variant={'tertiaryColor'} type={'submit'} disabled={index !== indexSelected}>
                                    {t('commons:actions:confirm')}
                                </Button>
                            </div>
                        </StyledContactCard>
                    ))}
            </StyledWrapperCards>

            <Button variant={'tertiaryColor'} onClick={() => addContact({})}>
                <PlusIcon />
                {t('contacts:actions:add_contact')}
            </Button>
        </>
    )
}
