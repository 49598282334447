import {FC, useState} from 'react'
import {
    CalendarPlus01Icon,
    CheckCircleIcon,
    PencilLineIcon,
    PlusIcon,
    Users01Icon,
    UserSquareIcon,
    XCircleIcon
} from '@components/ui/icon/Icon.tsx'
import dayjs from '@/dayjs.ts'
import {
    StyledAvailabilitySlotTrigger,
    StyledAvailableHours,
    StyledContainerOfAvailableHours,
    StyledDeleteShiftDescription,
    StyledDeleteShiftTitle,
    StyledDoctorInfo,
    StyledEmptyState,
    StyledItemContainer,
    StyledSlot,
    StyledSlotsContainer,
    StyledSlotWrapper
} from '@/features/appointments/components/doctors-calendar/calendar-item/style.ts'
import {EmptyState} from '@components/commons/empty-state/EmptyState.tsx'
import {useTranslation} from 'react-i18next'
import {SlotsAvailabilityModal} from '@/features/appointments/components/modals/SlotsAvailabilityModal.tsx'
import {DeleteModal} from '@components/commons/delete-modal/DeleteModal.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import ContextMenu from '@/features/appointments/components/context-menu/ContextMenu.tsx'
import i18n from '@/translations/i18n.ts'
import {PrefilledAppointment, TimeSlot, WeekSlots} from '@/features/appointments/types.ts'
import {AppointmentChip} from '@/features/appointments/components/appointment-chip/AppointmentChip.tsx'
import {TITLE_TYPE_OPTIONS} from '@utilities/constants/list.ts'
import {User, UserType} from '@/features/auth/types.ts'
import {useAddAvailableSlots} from '@/features/appointments/services/useAddAvailableSlots.ts'
import {useRemoveAvailableSlots} from '@/features/appointments/services/useRemoveAvailableSlot.ts'
import {ViewAppointmentDetails} from '@/features/appointments/components/modals/ViewAppointmentDetails.tsx'
import {AddAppointmentsModal} from '@/features/appointments/components/modals/AddAppointmentsModal.tsx'
import {useGetPatients} from '@/features/patients/services/useGetPatients.ts'
import {useGetAppointmentsUser} from '@/features/appointments/services/useGetAppointmentsUser.ts'
import {useGetContacts} from '@/features/settings/services/services-contact/useGetContacts.ts'
import {capitalize} from '@utilities/helpers.ts'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {useDeleteShift} from '@/features/appointments/services/useDeleteShift.ts'
import {useGetShiftsIndex} from '@/features/appointments/services/useGetShiftsIndex.ts'
import {useGetAppointmentDetails} from '@/features/appointments/services/useGetAppointmentDetails.ts'

type CalendarItemProps = {
    doctor: User
    slots: string[]
    currentWeek: dayjs.Dayjs[]
    availableSlots?: {
        slot: WeekSlots
    }
    isSingleDoctorView: boolean
    officeId: number | null
}

export const CalendarItem: FC<CalendarItemProps> = ({
    doctor,
    slots,
    currentWeek,
    availableSlots,
    isSingleDoctorView,
    officeId
}) => {
    const {t} = useTranslation()
    const {user} = useAuthStore()
    const [openEditModal, setOpenEditModal] = useState<boolean>(false)
    const [openSlotsAvailability, setOpenSlotsAvailability] = useState<boolean>(false)
    const [openAddNewShift, setOpenAddNewShift] = useState<boolean>(false)
    const [deletedShifts, setDeletedShifts] = useState<{
        open: boolean
        shiftID: number | null
    }>({open: false, shiftID: null})

    const [viewAppointmentDetails, setViewAppointmentDetails] = useState<{
        open: boolean
        appointmentId: number | null
    }>({open: false, appointmentId: null})
    const title = TITLE_TYPE_OPTIONS.find(option => option.value === doctor.title)?.label
    const {mutate: addAvailableSlots} = useAddAvailableSlots()
    const {mutate: deleteAvailableSlots} = useRemoveAvailableSlots()
    const {data: offices} = useGetContacts()
    const {remappedData: patients} = useGetPatients()
    const {data: therapists} = useGetAppointmentsUser({
        roles: ['therapist'],
        limit: 10000
    })
    const {data: shifts} = useGetShiftsIndex()
    const isMine = user?.id === doctor.id
    const {mutate: deleteShift} = useDeleteShift()

    const [prefilledData, setPrefilledData] = useState<{data: PrefilledAppointment | null; open: boolean}>({
        data: null,
        open: false
    })
    const {data: appointment, isLoading} = useGetAppointmentDetails(viewAppointmentDetails.appointmentId)
    const handlePrefilledData = (slot: TimeSlot | undefined, dayIsoString: string, doctor: User) => {
        const startDateLocal = dayIsoString.replace(/00:00:00/g, slot?.start + ':00:00')
        const endDateLocal = dayIsoString.replace(/00:00:00/g, slot?.end + ':00:00')
        setPrefilledData({
            open: true,
            data: {
                date: dayjs(startDateLocal).format('YYYY-MM-DD'),
                start: dayjs(startDateLocal).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                end: dayjs(endDateLocal).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                officeId: officeId,
                therapistId: doctor.id
            }
        })
    }

    const handleAddAvailableSlots = (slot: TimeSlot | undefined, dayIsoString: string, doctor: User) => {
        const startDateLocal = dayIsoString.replace(/00:00:00/g, slot?.start + ':00:00')
        const endDateLocal = dayIsoString.replace(/00:00:00/g, slot?.end + ':00:00')
        addAvailableSlots({
            start: dayjs(startDateLocal).format('YYYY-MM-DDTHH:mm:ss.SSS'),
            end: dayjs(endDateLocal).format('YYYY-MM-DDTHH:mm:ss.SSS'),
            officeId: officeId,
            userId: doctor.id
        })
    }

    const availableSlotItems = (slot: TimeSlot | undefined, dayIsoString: string, doctor: User) => [
        {
            icon: <CalendarPlus01Icon />,
            label: i18n.t('appointments:add_appointment'),
            onClick: () => handlePrefilledData(slot, dayIsoString, doctor)
        },
        {
            icon: <XCircleIcon />,
            label: i18n.t('appointments:block_availability'),
            onClick: () => deleteAvailableSlots(slot?.slotInfo?.id ?? 0)
        }
    ]

    const notAvailableSlotItems = (slot: TimeSlot | undefined, dayIsoString: string, doctor: User) => [
        {
            icon: <CalendarPlus01Icon />,
            label: i18n.t('appointments:enable_appointment'),
            onClick: () => {
                handlePrefilledData(slot, dayIsoString, doctor)
                handleAddAvailableSlots(slot, dayIsoString, doctor)
            }
        },
        {
            icon: <CheckCircleIcon />,
            label: i18n.t('appointments:enable_availability'),
            onClick: () => {
                handleAddAvailableSlots(slot, dayIsoString, doctor)
            }
        }
    ]

    const userCanEditAvailability = () => {
        switch (user?.role?.name) {
            case UserType.enum.therapist:
                return user?.id === doctor.id
            case UserType.enum.admin:
            case UserType.enum.operator:
                return true
        }
    }

    return (
        <>
            <StyledItemContainer $isSingleDoctorView={isSingleDoctorView}>
                <StyledDoctorInfo direction={'column'}>
                    <UserSquareIcon />
                    <p>{title}</p>
                    <p>
                        {doctor.firstName} {doctor.lastName}
                    </p>
                    <span>{capitalize(doctor.role?.name ?? '-')}</span>

                    {userCanEditAvailability() && (
                        <Flexbox
                            onClick={() => {
                                setOpenSlotsAvailability(true)
                                setOpenAddNewShift(true)
                            }}
                            gap={2}
                            align={'center'}
                        >
                            <PencilLineIcon size={20} />
                            <StyledAvailabilitySlotTrigger>
                                {t('appointments:edit_availability_slots')}
                            </StyledAvailabilitySlotTrigger>
                        </Flexbox>
                    )}
                </StyledDoctorInfo>

                <StyledContainerOfAvailableHours
                    direction="column"
                    justify={isSingleDoctorView ? 'space-around' : 'space-between'}
                    align="center"
                >
                    {slots.map(time => (
                        <StyledAvailableHours key={time}>{time}</StyledAvailableHours>
                    ))}
                </StyledContainerOfAvailableHours>

                {!availableSlots && isMine ? (
                    <StyledEmptyState>
                        <EmptyState
                            className="empty-state"
                            icon={<Users01Icon />}
                            title={t('appointments:setting_your_availability')}
                            description={t('appointments:no_appointments')}
                            ctaLabel={t('appointments:add_availability')}
                            iconCta={<PlusIcon />}
                            onClickCta={() => setOpenSlotsAvailability(true)}
                            height={'100%'}
                        />
                    </StyledEmptyState>
                ) : (
                    <StyledSlotsContainer>
                        {currentWeek.map((day, dayIndex) => {
                            const dayName = dayjs(day).format('ddd').toLowerCase()
                            const daySlots =
                                availableSlots?.slot &&
                                availableSlots?.slot[dayName as keyof typeof availableSlots.slot]

                            return (
                                <StyledSlotWrapper key={dayIndex}>
                                    {slots.map((slot, slotIndex) => {
                                        const slotData = daySlots ? daySlots[slotIndex] : undefined
                                        const dayIsoString = day.format('YYYY-MM-DD HH:mm:ss')
                                        return (
                                            <ContextMenu
                                                triggerDisabled={slotData?.isBooked}
                                                key={slot}
                                                menuItems={
                                                    slotData?.isAvailable
                                                        ? availableSlotItems(slotData, dayIsoString, doctor)
                                                        : notAvailableSlotItems(slotData, dayIsoString, doctor)
                                                }
                                            >
                                                <StyledSlot
                                                    key={slot}
                                                    align="center"
                                                    justify="center"
                                                    $isFilled={(slotData?.isAvailable || slotData?.isBooked) ?? false}
                                                    $isSingleDoctorView={isSingleDoctorView}
                                                >
                                                    {slotData?.appointment && (
                                                        <AppointmentChip
                                                            isSingleDoctorView={isSingleDoctorView}
                                                            appointment={slotData.appointment}
                                                            onClick={() =>
                                                                setViewAppointmentDetails({
                                                                    open: true,
                                                                    appointmentId: slotData?.appointment?.id ?? null
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </StyledSlot>
                                            </ContextMenu>
                                        )
                                    })}
                                </StyledSlotWrapper>
                            )
                        })}
                    </StyledSlotsContainer>
                )}
            </StyledItemContainer>

            {openSlotsAvailability && (
                <SlotsAvailabilityModal
                    onClose={() => setOpenSlotsAvailability(false)}
                    openDeleteModal={shiftID =>
                        setDeletedShifts({
                            open: true,
                            shiftID: shiftID
                        })
                    }
                    openAddNewShift={openAddNewShift}
                    setOpenAddNewShift={setOpenAddNewShift}
                    doctorID={doctor.id}
                    shifts={shifts?.data ?? []}
                    offices={offices?.data ?? []}
                />
            )}
            {deletedShifts.open && (
                <DeleteModal
                    modalTitle={t('appointments:delete_work_shift_title')}
                    onClose={() => {
                        setDeletedShifts({open: false, shiftID: null})
                        setOpenSlotsAvailability(true)
                        setOpenAddNewShift(true)
                    }}
                    onDelete={() => {
                        deletedShifts.shiftID && deleteShift(deletedShifts.shiftID)
                        setDeletedShifts({open: false, shiftID: null})
                        setOpenSlotsAvailability(false)
                        setOpenAddNewShift(false)
                    }}
                    isLoading={false}
                    deleteLabel={t('appointments:delete_work_shift_title')}
                >
                    <Flexbox direction="column" justify="flex-start" gap={2}>
                        <StyledDeleteShiftTitle>
                            {t('appointments:delete_work_shift_description', {
                                WORK_SHIFT_NAME: deletedShifts.shiftID
                            })}
                        </StyledDeleteShiftTitle>
                        <StyledDeleteShiftDescription>
                            {t('appointments:delete_work_shift_paragraph')}
                        </StyledDeleteShiftDescription>
                    </Flexbox>
                </DeleteModal>
            )}

            {viewAppointmentDetails.open && viewAppointmentDetails.appointmentId !== null && (
                <ViewAppointmentDetails
                    appointmentID={viewAppointmentDetails.appointmentId}
                    onClose={() => setViewAppointmentDetails({open: false, appointmentId: null})}
                    handleOpenEditModal={selectedItem => {
                        setViewAppointmentDetails({open: false, appointmentId: selectedItem})
                        if (viewAppointmentDetails.appointmentId) {
                            setOpenEditModal(true)
                        }
                    }}
                />
            )}

            {(prefilledData.open || openEditModal) && !isLoading && (
                <AddAppointmentsModal
                    offices={offices?.data ? offices.data : []}
                    patients={patients}
                    therapists={therapists?.data?.data as User[]}
                    onClose={() => {
                        setPrefilledData({open: false, data: null})
                        setOpenEditModal(false)
                        setViewAppointmentDetails({open: false, appointmentId: null})
                    }}
                    prefilledData={prefilledData.data}
                    officeId={officeId}
                    appointment={appointment}
                />
            )}
        </>
    )
}
