import * as RadixContextMenu from '@radix-ui/react-context-menu'
import {FC, ReactNode} from 'react'
import {StyledContextMenu, StyledContextMenuItem} from '@/features/appointments/components/context-menu/style.ts'

type MenuItem = {
    icon: ReactNode
    label: string
    onClick: () => void
}

type ContextMenuProps = {
    children: ReactNode
    menuItems: MenuItem[]
    triggerDisabled?: boolean
}
const ContextMenu: FC<ContextMenuProps> = ({children, menuItems, triggerDisabled}) => {
    return (
        <RadixContextMenu.Root>
            <RadixContextMenu.Trigger disabled={triggerDisabled}>{children}</RadixContextMenu.Trigger>

            <RadixContextMenu.Portal>
                <StyledContextMenu>
                    {menuItems.map((item, index) => (
                        <StyledContextMenuItem key={index} onClick={item.onClick}>
                            {item.icon}
                            {item.label}
                        </StyledContextMenuItem>
                    ))}
                </StyledContextMenu>
            </RadixContextMenu.Portal>
        </RadixContextMenu.Root>
    )
}

export default ContextMenu
