import {CardStats} from '@/components/commons/card-stats/CardStats'
import {
    StyledPatientsStatsNewAmount,
    StyledPatientsCardsStats,
    PatientsStatsAgesListItemValue,
    PatientsStatsAgesListItemLabel,
    PatientsStatsWrapper
} from '@/features/patients/components/patients-cards-stats/style'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {InfoCircleIcon} from '@/components/ui/icon/Icon'
import {useTheme} from 'styled-components'
import {useTranslation} from 'react-i18next'
import {HttpGetPatientsStatsUrlSearchParams} from '@/features/patients/types'
import {Tooltip} from '@/components/ui/tooltip/Tooltip'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {useGetPatientsStats} from '@/features/patients/services/useGetPatientsStats'
import {round} from '@utilities/helpers.ts'
import {PercentageSummary} from '@components/commons/percentage-summary/PercentageSummary.tsx'

export const PatientsCardsStats = () => {
    const {t} = useTranslation()
    const {searchParams} = useParsedSearchParams([], HttpGetPatientsStatsUrlSearchParams)
    const patientsStatsQuery = useGetPatientsStats({
        currentlyInTherapy: searchParams.currentlyInTherapy,
        dateRangeLabels: searchParams.dateRangeLabels
    })

    const {palette} = useTheme()

    return (
        <StyledPatientsCardsStats>
            {patientsStatsQuery.isLoading
                ? Array.from({length: 3}).map((_, index) => <CardStats asSkeleton key={index} />)
                : patientsStatsQuery.data && (
                      <>
                          <CardStats
                              headerSlot={
                                  <Flexbox justify="space-between">
                                      <h3>{t('patients:card_stats:new:title')}</h3>
                                      <Tooltip
                                          trigger={<InfoCircleIcon size={20} color={palette.neutral[400]} />}
                                          side="top"
                                          variant="light"
                                          content={<p>{t('patients:card_stats:new:info')}</p>}
                                      />
                                  </Flexbox>
                              }
                              bodySlot={
                                  <Flexbox direction="column" gap={4}>
                                      <StyledPatientsStatsNewAmount>
                                          {patientsStatsQuery.data.newestUsers}
                                      </StyledPatientsStatsNewAmount>
                                      <Flexbox justify="start" gap={2}>
                                          <PercentageSummary
                                              amount={patientsStatsQuery.data.newestUsers}
                                              percentage={patientsStatsQuery.data.usersIncrement}
                                              label={t(`commons:compare_labels:${searchParams.dateRangeLabels}`)}
                                          />
                                      </Flexbox>
                                  </Flexbox>
                              }
                          />

                          <CardStats
                              headerSlot={<h3>{t('patients:card_stats:age:title')}</h3>}
                              bodySlot={
                                  <Flexbox gap={2} direction="column">
                                      {patientsStatsQuery.data.ages.partitions.map((result, index) => (
                                          <PatientsStatsWrapper key={index}>
                                              <PatientsStatsAgesListItemValue>
                                                  {`${result.percentage && round(result.percentage)}%`}
                                              </PatientsStatsAgesListItemValue>
                                              <PatientsStatsAgesListItemLabel>
                                                  {t(`patients:card_stats:age:${result.label}`)}
                                              </PatientsStatsAgesListItemLabel>
                                          </PatientsStatsWrapper>
                                      ))}
                                  </Flexbox>
                              }
                          />

                          <CardStats
                              headerSlot={<h3>{t('patients:card_stats:genre:title')}</h3>}
                              bodySlot={
                                  <Flexbox gap={2} direction="column">
                                      {patientsStatsQuery.data.genders.partitions.map((result, index) => (
                                          <PatientsStatsWrapper key={index}>
                                              <PatientsStatsAgesListItemValue>
                                                  {`${result.percentage && round(result.percentage)}%`}
                                              </PatientsStatsAgesListItemValue>
                                              <PatientsStatsAgesListItemLabel>
                                                  {t(`patients:card_stats:genre:${result.label}`)}
                                              </PatientsStatsAgesListItemLabel>
                                          </PatientsStatsWrapper>
                                      ))}
                                  </Flexbox>
                              }
                          />
                      </>
                  )}
        </StyledPatientsCardsStats>
    )
}
