import styled, {css} from 'styled-components'
import {SelectItem, SelectPopover} from '@components/ui/select-atoms/SelectAtoms.tsx'
import {SelectTrigger} from '@components/ui/select-atoms/SelectTrigger.tsx'

export const StyledSelectTrigger = styled(SelectTrigger)`
    ${({theme: {palette, typography, spacing}}) => css`
        width: 100%;
        min-height: 40px;
        background-color: ${palette.neutral.white};
        border: 1px solid ${palette.neutral['300']};
        padding: ${spacing * 2}px ${spacing * 3}px;

        align-items: center;
        justify-content: flex-start;
        gap: ${spacing * 2}px;
        flex-wrap: wrap;

        & span {
            color: ${palette.neutral['400']};
            ${typography.textMd};
            font-weight: 400;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    `}
`

export const StyledSelectPopover = styled(SelectPopover)`
    ${({theme: {palette}}) => css`
        width: 100%;
        background-color: ${palette.neutral.white};
        z-index: 1;
    `}
`

export const StyledSelectItem = styled(SelectItem)`
    ${({theme: {spacing}}) => css`
        justify-content: flex-start;
        gap: ${spacing * 2}px;
    `}
`

export const StyledWrapperMultiSelect = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
        width: 100%;
    `}
`
