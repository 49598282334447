import {Dispatch, SetStateAction} from 'react'
import {BaseCard} from '@components/commons/cards/BaseCard.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {PlusIcon} from '@components/ui/icon/Icon.tsx'
import {DoctorNextAppointment} from '@/features/dashboard/components/appointments/DoctorNextAppointment.tsx'
import {DoctorUpcomingAppointments} from '@/features/dashboard/components/appointments/DoctorUpcomingAppointments.tsx'
import {useTranslation} from 'react-i18next'
import {
    StyledContainerNextAppointment,
    StyledHeaderAppointments
} from '@/features/dashboard/components/appointments/style.ts'
import {useNavigate} from 'react-router-dom'
import {routes} from '@utilities/constants/routes.tsx'
import dayjs from '@/dayjs.ts'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {useGetAppointments} from '@/features/appointments/services/useGetAppointments.ts'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
const now = dayjs()
export const DoctorTodayAppointment = ({
    setOpenAddOrEditAppointmentModal
}: {
    setOpenAddOrEditAppointmentModal: Dispatch<SetStateAction<{open: boolean; id: number | null}>>
}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const user = useAuthStore(state => state.user)
    const {
        remappedData: appointments,
        isLoading,
        isError
    } = useGetAppointments({
        limit: 1000,
        therapistId: user?.id,
        fromDate: now.format(),
        toDate: now.endOf('day').format()
    })

    if (appointments.length > 0) {
        appointments.sort((a, b) => {
            return dayjs.utc(a.end).diff(now) - dayjs.utc(b.end).diff(now)
        })
    }

    return (
        <>
            <BaseCard
                variant={'sectioned'}
                headerSlot={
                    <>
                        <StyledHeaderAppointments>
                            <p>{t('dashboard:today_appointments')}</p>
                            <span>{dayjs().format('LL')}</span>
                        </StyledHeaderAppointments>

                        <Button onClick={() => setOpenAddOrEditAppointmentModal({open: true, id: null})}>
                            <PlusIcon />
                            <span>{t('dashboard:add_appointment')}</span>
                        </Button>
                    </>
                }
                footerSlot={
                    <Button variant={'tertiaryColor'} onClick={() => navigate(routes.APPOINTMENTS.path)}>
                        {t('dashboard:see_all')}
                    </Button>
                }
            >
                <StyledContainerNextAppointment direction={'column'} fullWidth gap={6}>
                    {appointments[0] && (
                        <DoctorNextAppointment
                            setOpenAddOrEditAppointmentModal={setOpenAddOrEditAppointmentModal}
                            appointment={appointments[0]}
                            isLoading={isLoading}
                            isError={isError}
                        />
                    )}
                    <DoctorUpcomingAppointments
                        setOpenAddOrEditAppointmentModal={setOpenAddOrEditAppointmentModal}
                        appointments={appointments.slice(1)}
                    />
                </StyledContainerNextAppointment>
            </BaseCard>
        </>
    )
}
