import {components, DropdownIndicatorProps, GroupBase, MultiValueRemoveProps} from 'react-select'
import {ChevronDownIcon, XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {SelectValue} from '@/types.ts'

const MultiValueRemove = (props: MultiValueRemoveProps<SelectValue, boolean, GroupBase<SelectValue>>) => {
    return (
        <components.MultiValueRemove {...props}>
            <XCloseIcon data-color size={12} />
        </components.MultiValueRemove>
    )
}

const DropdownIndicator = (props: DropdownIndicatorProps<SelectValue, boolean, GroupBase<SelectValue>>) => {
    return (
        <components.DropdownIndicator {...props}>
            <ChevronDownIcon size={16} />
        </components.DropdownIndicator>
    )
}

export const customComponents = {
    MultiValueRemove,
    DropdownIndicator
}
