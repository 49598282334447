import React from 'react'
import {Container} from '@components/ui/container/Container.tsx'
import {useTranslation} from 'react-i18next'
import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {FORGOT_PASSWORD_FORM_MODEL, ForgotPasswordValidationSchema} from './ForgotPasswordModel'
import {Button} from '@components/ui/button/Button.tsx'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'
import {routes} from '@utilities/constants/routes.tsx'
import {useNavigate} from 'react-router-dom'
import {ArrowLeftIcon, Key01Icon} from '@/components/ui/icon/Icon'
import {useForgotPassword} from '@/features/auth/services/useForgotPassword'
import {AuthContent, AuthForm, AuthSubTitle, AuthTitle, AuthWrapper} from '@/components/ui/auth-atoms/AuthAtoms'
import toast from 'react-hot-toast'
import {ToastBody} from '@/components/ui/toast-body/ToastBody'

export const ForgotPassword: React.FC = () => {
    const {t} = useTranslation()
    const {
        register,
        handleSubmit,
        formState: {errors, isSubmitting}
    } = useForm<ForgotPasswordValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(ForgotPasswordValidationSchema)
    })
    const navigate = useNavigate()
    const {mutate: mutateForgotPassword, isPending: isPendingForgotPassword} = useForgotPassword({
        onSuccess: () => navigate(routes.EMAIL_SENT.path),
        onError: () => {
            toast.error(<ToastBody title={t('errors:default')} subtitle={t('commons:try_again')} />)
        }
    })

    const onSubmit: SubmitHandler<ForgotPasswordValidationSchema> = data => {
        mutateForgotPassword(data)
    }
    return (
        <Container>
            <AuthWrapper direction="column" align="center" justify="center">
                <AuthContent direction="column" align="center" gap={6}>
                    <IconContainer size={'lg'}>
                        <Key01Icon />
                    </IconContainer>

                    <Flexbox direction={'column'} align="center" gap={3}>
                        <AuthTitle>{t('forgot_password:title')}</AuthTitle>
                        <AuthSubTitle>{t('forgot_password:subtitle')}</AuthSubTitle>
                    </Flexbox>

                    <AuthForm onSubmit={handleSubmit(onSubmit)}>
                        <Flexbox direction={'column'} gap={6} align={'stretch'}>
                            <InputText
                                label={t(FORGOT_PASSWORD_FORM_MODEL.email.label)}
                                type={'email'}
                                placeholder={t(FORGOT_PASSWORD_FORM_MODEL.email.placeholder)}
                                errorMessage={t(errors.email?.message || '')}
                                {...register(t(FORGOT_PASSWORD_FORM_MODEL.email.name))}
                            />
                            <Button
                                type={'submit'}
                                fullWidth
                                variant={'primary'}
                                disabled={isSubmitting || isPendingForgotPassword}
                            >
                                {t('forgot_password:primary_cta')}
                            </Button>
                        </Flexbox>
                    </AuthForm>
                    <ButtonLink to={routes.LOGIN.path} variant={'tertiary'}>
                        <ArrowLeftIcon />
                        {t('forgot_password:secondary_cta')}
                    </ButtonLink>
                </AuthContent>
            </AuthWrapper>
        </Container>
    )
}

ForgotPassword.displayName = 'ForgotPassword'
