import {useEffect, useMemo, useState} from 'react'
import dayjs from '@/dayjs.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {CalendarNavigation} from '@/features/appointments/components/doctors-calendar/calendar-navigation/CalendarNavigation.tsx'
import {CalendarHeader} from '@/features/appointments/components/doctors-calendar/calendar-header/CalendarHeader.tsx'
import {CalendarItem} from '@/features/appointments/components/doctors-calendar/calendar-item/CalendarItem.tsx'
import {StyledScrollableContainer} from '@/features/appointments/components/doctors-calendar/calendar-item/style.ts'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {useGetAppointmentsUser} from '@/features/appointments/services/useGetAppointmentsUser.ts'
import {User, UserType} from '@/features/auth/types.ts'
import {useGetAppointments} from '@/features/appointments/services/useGetAppointments.ts'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {useGetAvailableSlots} from '@/features/appointments/services/useGetAvailableSlots.ts'
import {createAvailableSlotsForDoctors} from '@/features/appointments/components/doctors-calendar/calendar-navigation/remap.tsx'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {AppointmentFilters} from '@/features/appointments/types.ts'
import {EmptyState} from '@components/commons/empty-state/EmptyState.tsx'
import {useTranslation} from 'react-i18next'

dayjs.extend(utc)
dayjs.locale('it')
dayjs.extend(timezone)

const timeZone = dayjs.tz.guess()

export const convertToLocalTime = (utcTime: string) => {
    return dayjs(utcTime).tz(timeZone)
}

// Main Calendar Component
const DoctorsCalendar = ({officeId}: {officeId: number | null}) => {
    const {t} = useTranslation()
    const [addAppointmentModalOpen, setAddAppointmentModalOpen] = useState(false)
    // State to manage the current date
    const [currentDate, setCurrentDate] = useState(dayjs())
    const user = useAuthStore(state => state.user)
    const slots = Array.from({length: 11}, (_, i) => `${i + 8}:00`)
    const {searchParams, setSearchParams} = useParsedSearchParams([], AppointmentFilters) // Function to calculate the current week

    const currentWeek = useMemo(() => {
        const startOfWeek = currentDate.startOf('week').add(0, 'day')
        return Array.from({length: 7}, (_, i) => startOfWeek.add(i, 'day'))
    }, [currentDate])

    useEffect(() => {
        localStorage.setItem('currentWeek', JSON.stringify(currentWeek))
    }, [currentWeek])

    const {data: availableSlots} = useGetAvailableSlots({
        fromDate: currentWeek[0].format('YYYY-MM-DD'),
        toDate: currentWeek[6].format('YYYY-MM-DD')
    })

    const {remappedData: appointments} = useGetAppointments({
        fromDate: currentWeek[0].format('YYYY-MM-DD'),
        toDate: currentWeek[6].format('YYYY-MM-DD'),
        limit: 10000,
        search: searchParams.search,
        appointmentType: searchParams.appointmentMode == 'all' ? null : searchParams.appointmentMode,
        therapistId: Number(searchParams.doctorId && searchParams.doctorId)
    })

    const appointmentWithoutDeletedItem = appointments?.filter(appointment => !appointment.status.includes('cancelled'))

    const {data: therapists} = useGetAppointmentsUser({
        roles: ['therapist'],
        limit: 10000,
        officeId,
        userIds: searchParams.doctorId ? [Number(searchParams.doctorId)] : undefined
    })

    const availableSlotsAbleToBook = availableSlots?.data
    const doctorsToIterate = therapists?.data?.data as User[]
    const doctors = useMemo(() => {
        if (user?.role?.name == UserType.enum.therapist && doctorsToIterate) {
            const userIndex = doctorsToIterate.findIndex(doctor => doctor.id === user.id)
            return userIndex !== -1
                ? [doctorsToIterate[userIndex], ...doctorsToIterate.filter(doctor => doctor.id !== user.id)]
                : doctorsToIterate
        } else {
            return doctorsToIterate
        }
    }, [doctorsToIterate])

    const isSingleDoctorView = doctors?.length === user?.id

    const availableSlotsForDoctor = useMemo(() => {
        return createAvailableSlotsForDoctors(
            appointmentWithoutDeletedItem,
            availableSlotsAbleToBook ?? [],
            doctors?.map(doctor => doctor.id)
        )
    }, [appointmentWithoutDeletedItem, availableSlotsAbleToBook])

    return (
        <>
            <CalendarNavigation
                therapists={therapists?.data?.data}
                setCurrentDate={setCurrentDate}
                currentDate={currentDate}
                currentWeek={currentWeek}
                setAddAppointmentModalOpen={setAddAppointmentModalOpen}
                addAppointmentModalOpen={addAppointmentModalOpen}
                officeId={officeId}
                appointments={appointments}
                setSearchParams={setSearchParams}
                searchParams={searchParams}
            />

            <Flexbox direction="column" gap={4} fullWidth>
                <CalendarHeader
                    currentWeek={currentWeek}
                    doctors={doctors}
                    setSearchParams={setSearchParams}
                    searchParams={searchParams}
                />

                <StyledScrollableContainer direction="column" gap={4} fullWidth>
                    {doctors?.length > 0 ? (
                        doctors?.map(doctor => (
                            <CalendarItem
                                key={doctor.id}
                                doctor={doctor}
                                slots={slots}
                                currentWeek={currentWeek}
                                availableSlots={
                                    availableSlotsForDoctor[doctor.id as keyof typeof availableSlotsForDoctor]
                                }
                                isSingleDoctorView={isSingleDoctorView}
                                officeId={officeId}
                            />
                        ))
                    ) : (
                        <EmptyState title={t('commons:no_results')} description={''} />
                    )}
                </StyledScrollableContainer>
            </Flexbox>
        </>
    )
}

export default DoctorsCalendar
