import {StyledAppointmentChip} from '@/features/appointments/components/appointment-chip/style.ts'
import {FC} from 'react'
import {Appointment} from '@/features/appointments/types.ts'
import {MarkerPin01Icon, VideoRecorderIcon} from '@components/ui/icon/Icon.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

type AppointmentChipProps = {
    appointment: Appointment
    onClick: () => void
    isSingleDoctorView: boolean
}
export const AppointmentChip: FC<AppointmentChipProps> = ({appointment, onClick, isSingleDoctorView}) => {
    return (
        <StyledAppointmentChip
            gap={1}
            align="center"
            justify="space-between"
            $isSingleDoctorView={isSingleDoctorView}
            onClick={onClick}
        >
            <span className={'patient-name'}>
                {appointment.patient?.firstName} {appointment.patient?.lastName}
            </span>
            <Flexbox>{appointment.isRemote ? <VideoRecorderIcon /> : <MarkerPin01Icon />}</Flexbox>
        </StyledAppointmentChip>
    )
}
