import styled, {css} from 'styled-components'

export const StyledSelfEvaluationsGrid = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        display: grid;
        grid-template-columns: 82px auto;
        ${typography.textSm};
        align-items: center;
        gap: ${spacing * 2}px;
        color: ${palette.neutral[600]};
        & p {
            font-weight: 600;
        }
    `}
`
