import {SelectItem, SelectPopover, SelectLabel} from '@ariakit/react'
import styled, {DefaultTheme, css} from 'styled-components'
import {Button} from '@/components/ui/button/Button'
import {StyledButtonProps} from '../button/style'

export const StyledSelectPopover = styled(SelectPopover)(
    ({theme: {palette, shadows, spacing}}) => css`
        background-color: ${palette.neutral.white};
        border: 1px solid ${palette.neutral[200]};
        border-radius: 8px;
        box-shadow: ${shadows.lg};
        padding: ${spacing}px ${spacing * 1.5}px;
        z-index: 1;
    `
)

export const StyledSelectItem = styled(SelectItem)(
    ({theme: {palette, spacing, typography}}) => css`
        ${typography.textMd};
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: ${spacing * 2.5}px ${spacing * 2}px;
        cursor: pointer;
        border-radius: 6px;
        font-weight: 400;
        margin: 1px 0; /* used to space each list item */
        color: ${palette.neutral[900]};
        min-width: 100%;

        & span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        & svg:not(.custom-icon) {
            fill: ${palette.primary[600]};
        }

        &[aria-selected='true'] {
            background-color: ${palette.neutral[100]};
        }

        &:not([aria-selected='true']):hover {
            background-color: ${palette.neutral[50]};
        }
    `
)

type StyledSelectTrigger = {
    theme: DefaultTheme
    $minWidth?: number
    $isPointer?: boolean
} & StyledButtonProps

export const StyledSelectTrigger = styled(Button)<StyledSelectTrigger>(
    ({theme: {palette}, $minWidth: minWidth, $isPointer: isPointer = true}) => css`
        font-weight: 400;
        justify-content: space-between;
        min-width: ${minWidth ?? 0}px;
        cursor: ${isPointer ? 'pointer' : 'auto'};
        width: 100%;

        &:hover {
            outline: 1px solid ${palette.primary[300]};
            box-shadow: 0 0 0 4px ${palette.primary[100]};
        }

        &:focus {
            outline: 1px solid ${palette.primary[300]};
            box-shadow: 0 0 0 4px ${palette.primary[100]};
        }

        &:not(:disabled):hover {
            background: transparent;
        }

        & > svg.select-indicator {
            fill: ${palette.neutral[500]};
            transition: ease-in-out 200ms;
            margin-left: auto;
        }

        &[aria-expanded='true'] {
            outline: 1px solid ${palette.primary[300]};
            box-shadow: 0 0 0 4px ${palette.primary[100]};

            & > svg.select-indicator {
                transform: rotate(180deg);
            }
        }
    `
)

export const StyledSelectTriggerPlaceholder = styled.span(
    ({theme}) => css`
        color: ${theme.palette.neutral[400]};
    `
)

export const StyledSelectLabel = styled(SelectLabel)`
    ${({theme: {palette, typography}}) => css`
        display: block;
        color: ${palette.neutral['700']};
        text-align: start;
        ${typography.textSm}
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    `}
`
