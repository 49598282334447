import {StyledArticleContent, StyledArticleItemWrapper} from '@components/commons/article-item/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {StyledArticleTitle} from '@components/commons/empty-state/style.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'

export const skeletonItems = Array.from({length: 5}, (_, i) => i)

export const SkeletonArticleItem = () => {
    return (
        <>
            <StyledArticleItemWrapper $size={'md'}>
                <Skeleton height={95} width={95} />

                <Flexbox gap={2} direction={'column'} justify={'flex-end'}>
                    <Flexbox gap={8} align={'center'}>
                        <Skeleton height={20} width={100} />
                        <StyledArticleContent>
                            <Skeleton height={20} width={100} />
                        </StyledArticleContent>
                    </Flexbox>

                    <StyledArticleTitle>
                        <Skeleton height={20} width={200} />
                    </StyledArticleTitle>

                    <StyledArticleContent>
                        <Skeleton height={20} width={300} />
                    </StyledArticleContent>
                </Flexbox>

                <Flexbox align={'flex-start'}>
                    <Skeleton height={40} width={100} />
                </Flexbox>
            </StyledArticleItemWrapper>
        </>
    )
}
