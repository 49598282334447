import {forwardRef} from 'react'
import 'react-phone-input-2/lib/style.css'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {PhoneInputProps} from 'react-phone-input-2'
import {StyledInput} from '@components/commons/input-phone/style.ts'
import {StyledLabel} from '@components/ui/label/style.ts'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {InputStatusIcon} from '@components/ui/input-status-icon/InputStatusIcon.tsx'
import {InputWrapper} from '@components/ui/input-wrapper/InputWrapper.tsx'

interface InputPhoneProps extends PhoneInputProps {
    label?: string
    required?: boolean
    name?: string
    defaultCountry?: string
    errorMessage?: string
    helpText?: string
    touched: boolean
}

export const InputPhone = forwardRef<HTMLInputElement, InputPhoneProps>(
    (
        {
            placeholder,
            disabled,
            onlyCountries,
            countryCodeEditable = true,
            preferredCountries = [],
            enableSearch = true,
            searchPlaceholder = 'Search',
            searchNotFound = 'No result found',
            value,
            label,
            required,
            name,
            defaultCountry = 'it',
            errorMessage,
            helpText,
            touched,
            inputProps,
            ...field
        },
        ref
    ) => {
        return (
            <Flexbox direction={'column'} gap={1.5} fullWidth>
                {label && (
                    <StyledLabel htmlFor={name}>
                        {label}
                        {required && '*'}
                    </StyledLabel>
                )}

                <InputWrapper hasError={!!errorMessage} touched={touched}>
                    <StyledInput
                        value={value}
                        onlyCountries={onlyCountries}
                        countryCodeEditable={countryCodeEditable}
                        disabled={disabled}
                        disableDropdown={disabled}
                        placeholder={placeholder}
                        country={defaultCountry}
                        enableSearch={enableSearch}
                        preferredCountries={preferredCountries}
                        searchPlaceholder={searchPlaceholder}
                        searchNotFound={searchNotFound}
                        hasError={!!errorMessage}
                        inputProps={{
                            name,
                            ref,
                            ...inputProps
                        }}
                        {...field}
                    />
                    <InputStatusIcon touched={touched} hasError={!!errorMessage} />
                </InputWrapper>

                <InputHelpText error={errorMessage} helpText={helpText} />
            </Flexbox>
        )
    }
)
