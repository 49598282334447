import {MUTATION_KEYS} from '@/queryClient.ts'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {HttpChangePasswordPayload, httpChangePassword} from '@/features/auth/services/auth.http.ts'
import {ErrorResponseData} from '@/types'

type useLoginProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useChangePassword = (options?: useLoginProps) =>
    useMutation({
        mutationKey: [MUTATION_KEYS.CHANGE_PASSWORD],
        mutationFn: (params: HttpChangePasswordPayload) => httpChangePassword(params),
        onError: (error: AxiosError<ErrorResponseData>) => options?.onError?.(error),
        onSuccess: () => options?.onSuccess?.()
    })
