import styled, {css} from 'styled-components'
import * as RadixContextMenu from '@radix-ui/react-context-menu'

export const StyledContextMenu = styled(RadixContextMenu.Content)`
    ${({theme: {palette, spacing}}) => css`
        background: ${palette.neutral.white};
        border-radius: ${spacing * 2}px;
        border: 1px solid ${palette.neutral[200]};
    `}
`

export const StyledContextMenuItem = styled(RadixContextMenu.Item)`
    ${({theme: {spacing, palette}}) => css`
        padding: ${spacing * 4}px ${spacing * 4.5}px;
        color: ${palette.neutral[700]};
        cursor: pointer;

        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
    `}
`
