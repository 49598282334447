import {z} from 'zod'
import axios from '@/axiosInstance'
import {ResponseType} from '@/types.ts'

/**
 * Get selfAssessmentDetails
 */

export const HttpGetSelfEvaluationDetailsOptions = z.object({
    selfEvaluationId: z.coerce.number(),
    responseType: ResponseType.optional()
})
export type HttpGetSelfEvaluationDetailsOptions = z.infer<typeof HttpGetSelfEvaluationDetailsOptions>

export const httpGetSelfEvaluationDetails = (params: HttpGetSelfEvaluationDetailsOptions) => {
    return axios.get(`/self-evaluations/${params.selfEvaluationId}`, {params})
}
