import dayjs from '@/dayjs.ts'
import {capitalize, ObjectValues} from '@utilities/helpers.ts'
import {FC, useEffect, useMemo, useState} from 'react'
import {
    StyledFiltersContent,
    StyledHeaderContainer,
    StyledNumberOfTheWeek,
    StyledWeekDays,
    StyledWeekDaysWrapper
} from '@/features/appointments/components/doctors-calendar/calendar-header/style.ts'
import {StyledPatientsTableFiltersTrigger} from '@/features/patients/components/patients-table-filters/style.ts'
import {FilterLinesIcon, MarkerPin01Icon, UserSquareIcon, VideoRecorderIcon} from '@components/ui/icon/Icon.tsx'
import {useTranslation} from 'react-i18next'
import {Popover} from '@components/ui/popover/Popover.tsx'
import {Radio} from '@components/commons/radio/Radio.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {SingleSelect} from '@components/commons/single-select/SingleSelect.tsx'
import {User} from '@/features/auth/types.ts'
import {AppointmentFilters, appointmentModeOptions} from '@/features/appointments/types.ts'
import {routes} from '@utilities/constants/routes.tsx'
import {useNavigate} from 'react-router-dom'

type CalendarHeaderProps = {
    currentWeek: dayjs.Dayjs[]
    doctors: User[]
    setSearchParams: (params: Record<string, string>) => void
    searchParams: AppointmentFilters
}

export const CalendarHeader: FC<CalendarHeaderProps> = ({currentWeek, doctors, searchParams, setSearchParams}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [filterSelected, setFilterSelected] = useState<AppointmentFilters>({})
    const [popoverVisibility, setPopoverVisibility] = useState(false)

    const handleSetSearchParams = () => {
        setSearchParams(filterSelected)
        setPopoverVisibility(false)
    }
    const appliedFiltersCount = useMemo(
        () => ObjectValues(searchParams).filter(param => !!param).length,
        [searchParams]
    )

    useEffect(() => {
        setSearchParams({
            appointmentMode: 'all'
        })
        setFilterSelected({
            appointmentMode: 'all'
        })
    }, [])

    return (
        <StyledHeaderContainer>
            <Popover
                rootProps={{
                    open: popoverVisibility,
                    onOpenChange: value => setPopoverVisibility(value)
                }}
                triggerProps={{asChild: true}}
                contentProps={{align: 'end'}}
                trigger={
                    <StyledPatientsTableFiltersTrigger variant="secondaryColor" $hasFilters={!!appliedFiltersCount}>
                        <FilterLinesIcon />
                        <span>{t('commons:filters')}</span>
                        {appliedFiltersCount >= 1 && <b>({appliedFiltersCount})</b>}
                    </StyledPatientsTableFiltersTrigger>
                }
            >
                <StyledFiltersContent direction="column" gap={6} fullWidth>
                    <SingleSelect
                        iconOnTrigger={<UserSquareIcon size={20} />}
                        placeholder={t('appointments:select_doctor')}
                        label={t('appointments:doctor')}
                        options={doctors?.map(doctor => ({
                            label: `${doctor.firstName} ${doctor.lastName}`,
                            value: String(doctor.id)
                        }))}
                        value={filterSelected.doctorId || ''}
                        onChange={value =>
                            setFilterSelected({
                                ...filterSelected,
                                doctorId: value
                            })
                        }
                    />

                    <Flexbox direction="column" gap={4}>
                        <p>{t('appointments:appointment_mode')}</p>

                        <Radio
                            label={t('appointments:all')}
                            checked={filterSelected.appointmentMode === appointmentModeOptions.enum.all}
                            onChange={() =>
                                setFilterSelected({...filterSelected, appointmentMode: appointmentModeOptions.enum.all})
                            }
                        />
                        <Radio
                            label={
                                <Flexbox gap={2} align={'center'}>
                                    <p>{t('appointments:headquarter')}</p>
                                    <MarkerPin01Icon size={20} />
                                </Flexbox>
                            }
                            checked={filterSelected.appointmentMode === appointmentModeOptions.enum.office}
                            onChange={() =>
                                setFilterSelected({
                                    ...filterSelected,
                                    appointmentMode: appointmentModeOptions.enum.office
                                })
                            }
                        />
                        <Radio
                            label={
                                <Flexbox gap={2} align={'center'}>
                                    <p>{t('appointments:video_meet')}</p>
                                    <VideoRecorderIcon size={20} />
                                </Flexbox>
                            }
                            checked={filterSelected.appointmentMode === appointmentModeOptions.enum.remote}
                            onChange={() =>
                                setFilterSelected({
                                    ...filterSelected,
                                    appointmentMode: appointmentModeOptions.enum.remote
                                })
                            }
                        />
                    </Flexbox>

                    <Flexbox gap={3} fullWidth justify={'flex-end'}>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setFilterSelected({})
                                setSearchParams({})
                                navigate(routes.APPOINTMENTS.path)
                                setPopoverVisibility(false)
                            }}
                        >
                            {t('commons:cancel')}
                        </Button>

                        <Button variant="primary" onClick={handleSetSearchParams}>
                            {t('commons:apply')}
                        </Button>
                    </Flexbox>
                </StyledFiltersContent>
            </Popover>

            <StyledWeekDaysWrapper>
                {currentWeek.map(day => (
                    <StyledWeekDays gap={1} key={day.toString()}>
                        <span> {capitalize(day.format('ddd'))} </span>
                        <StyledNumberOfTheWeek align="center" justify="center" $isToday={day.isSame(dayjs(), 'day')}>
                            <p>{day.date()}</p>
                        </StyledNumberOfTheWeek>
                    </StyledWeekDays>
                ))}
            </StyledWeekDaysWrapper>
        </StyledHeaderContainer>
    )
}
