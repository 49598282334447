import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance'
import {captureException} from '@sentry/react'
import {httpGetPatientDetailsBets} from '@/features/patient-details/services/patientDetails.http.ts'
import {PatientBets} from '@/features/patients/types.ts'
import {z} from 'zod'
import {RangeDateLabels, ResponseType} from '@/types.ts'
import {getDateFromLabel} from '@utilities/utils.ts'

const UseGetPatientDetailsBets = z.object({
    patientId: z.coerce.number().optional(),
    betsRangeDateLabels: RangeDateLabels
})
type UseGetPatientDetailsBets = z.infer<typeof UseGetPatientDetailsBets>
export const useGetPatientDetailsBets = (
    promiseArgs: UseGetPatientDetailsBets,
    options?: Omit<UseQueryOptions<PatientBets>, 'queryFn' | 'queryKey'>
) => {
    const {betsRangeDateLabels, ...rest} = promiseArgs

    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.PATIENTS_BETS, promiseArgs],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetPatientDetailsBets({
                    ...getDateFromLabel(betsRangeDateLabels),
                    ...rest,
                    responseType: ResponseType.Enum.backofficeRegular
                }),
                responseShape: PatientBets,
                onZodError: captureException
            }),
        enabled: !!promiseArgs.patientId
    })
}
