import {createBrowserRouter, createRoutesFromElements, Route} from 'react-router-dom'
import {PrivateRoute} from '@route-guards/private-route/PrivateRoute.tsx'
import {PublicRoute} from '@route-guards/public-route/PublicRoute.tsx'
import {PublicLayout} from '@layouts/public-layout/PublicLayout.tsx'
import {PrivateLayout} from '@layouts/private-layout/PrivateLayout.tsx'
import {routes} from '@utilities/constants/routes.tsx'
import {PatientDetailsRouteGuard} from '@route-guards/patient-details-route-guard/PatientDetailsRouteGuard.tsx'
import {CheckInDetailsRouteGuard} from '@route-guards/check-in-details-route-guard/CheckInDetailsRouteGuard.tsx'
import {SelfEvaluationRouteGuard} from '@route-guards/self-evaluation-route-guard/SelfEvaluationRouteGuard.tsx'
import {RoleRouteGuard} from '@route-guards/role-route-guard/RoleRouteGuard.tsx'
import {SettingsRouteGuard} from '@route-guards/settings-route-guard/SettingsRouteGuard.tsx'

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/">
            <Route element={<PrivateRoute />}>
                <Route element={<PrivateLayout />}>
                    <Route {...routes.DASHBOARD} />
                    <Route {...routes.APPOINTMENTS} />

                    <Route element={<RoleRouteGuard />} path={routes.PATIENTS.path} handle={routes.PATIENTS.handle}>
                        <Route index path={routes.PATIENTS.path} element={routes.PATIENTS.element} />
                        <Route element={<PatientDetailsRouteGuard />}>
                            <Route {...routes.PATIENT_DETAILS} />
                        </Route>
                    </Route>

                    <Route
                        element={<RoleRouteGuard />}
                        path={routes.SELF_ASSESSMENT.path}
                        handle={routes.SELF_ASSESSMENT.handle}
                    >
                        <Route index path={routes.SELF_ASSESSMENT.path} element={routes.SELF_ASSESSMENT.element} />
                        <Route element={<SelfEvaluationRouteGuard />}>
                            <Route {...routes.SELF_ASSESSMENT_DETAILS} />
                        </Route>
                    </Route>

                    <Route element={<RoleRouteGuard />} path={routes.CHECK_IN.path} handle={routes.CHECK_IN.handle}>
                        <Route index path={routes.CHECK_IN.path} element={routes.CHECK_IN.element} />
                        <Route element={<CheckInDetailsRouteGuard />}>
                            <Route {...routes.CHECK_IN_DETAILS} />
                        </Route>
                    </Route>

                    <Route {...routes.ARTICLES} />
                    <Route {...routes.EVENTS} />
                    <Route element={<SettingsRouteGuard />} path={routes.SETTINGS.path} handle={routes.SETTINGS.handle}>
                        <Route {...routes.SETTINGS} />
                    </Route>
                    <Route {...routes.PROFILE} />
                </Route>
            </Route>
            <Route element={<PublicRoute />}>
                <Route element={<PublicLayout />}>
                    <Route {...routes.LOGIN} />
                    <Route {...routes.FORGOT_PASSWORD} />
                    <Route {...routes.CHANGE_PASSWORD} />
                </Route>
            </Route>
        </Route>
    )
)
