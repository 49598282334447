import {Modal} from '@components/ui/modal-legacy/Modal.tsx'
import {StyledDeleteUserModal, StyledContent} from '@components/commons/delete-modal/style.ts'
import {FC, ReactNode} from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useTranslation} from 'react-i18next'

interface DeleteUserModalProps {
    isLoading: boolean
    onClose: () => void
    onDelete: () => void
    modalTitle?: string
    children: ReactNode
    deleteLabel?: string
}

export const DeleteModal: FC<DeleteUserModalProps> = ({
    deleteLabel,
    children,
    modalTitle,
    isLoading,
    onClose,
    onDelete
}) => {
    const {t} = useTranslation()

    return (
        <Modal
            width={'544px'}
            closeIconOffset="sm"
            onClose={onClose}
            onOverlayClick={onClose}
            title={t(modalTitle ?? '')}
            submitButton={
                <Button variant="primaryDanger" onClick={onDelete} disabled={isLoading}>
                    {deleteLabel ? deleteLabel : t('commons:actions:confirm')}
                    {isLoading && <Spinner size={16} />}
                </Button>
            }
        >
            <StyledDeleteUserModal>
                <StyledContent>{children}</StyledContent>
            </StyledDeleteUserModal>
        </Modal>
    )
}

DeleteModal.displayName = 'DeleteModal'
