import {MUTATION_KEYS} from '@/queryClient.ts'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {captureException} from '@sentry/react'
import {HttpLoginPayload, HttpLoginResponse, httpLogin} from '@/features/auth/services/auth.http.ts'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {ErrorResponseData} from '@/types'

type UseLoginProps = {
    onSuccess?: (data: HttpLoginResponse) => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useLogin = (options?: UseLoginProps) =>
    useMutation({
        mutationKey: [MUTATION_KEYS.LOGIN],
        mutationFn: (params: HttpLoginPayload) =>
            parseAxiosPromise({
                axiosPromise: httpLogin(params),
                onZodError: captureException,
                responseShape: HttpLoginResponse
            }),
        onError: (error: AxiosError<ErrorResponseData>) => options?.onError?.(error),
        onSuccess: data => options?.onSuccess?.(data)
    })
