import toast, {Toast, ToastBar as RHTToastBar} from 'react-hot-toast'
import {AlertCircleIcon, CheckIcon, XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {toastBarStyle, ToastContentWrapper} from '@components/commons/toast-bar/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'

interface ToastBarProps {
    t: Toast
}
export const ToastBar = ({t}: ToastBarProps) => {
    return (
        <RHTToastBar toast={t} style={toastBarStyle}>
            {({message}) => (
                <>
                    <Flexbox gap={4} align={'center'}>
                        {t.type === 'error' ? (
                            <IconContainer radiusFull={true} type={'danger'}>
                                <AlertCircleIcon />
                            </IconContainer>
                        ) : (
                            <IconContainer radiusFull={true} type={'success'}>
                                <CheckIcon />
                            </IconContainer>
                        )}
                        <ToastContentWrapper>
                            {typeof message === 'string' ? <h4>{message}</h4> : message}
                        </ToastContentWrapper>
                        <Button variant={'tertiary'} onClick={() => toast.dismiss(t.id)}>
                            <XCloseIcon />
                        </Button>
                    </Flexbox>
                </>
            )}
        </RHTToastBar>
    )
}

ToastBar.displayName = 'Toast'
