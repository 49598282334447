import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'
import {
    httpGetSelfEvaluationsGroup,
    HttpGetSelfEvaluationsGroupResponse
} from '@/features/dashboard/services/dashboard.http.ts'
import {DashboardAdminUrlParams} from '@/features/dashboard/types.ts'
import {getDateFromLabel} from '@utilities/utils.ts'

export const useGetSelfEvaluationsGroup = (
    promiseArgs: DashboardAdminUrlParams,
    options?: Omit<UseQueryOptions<HttpGetSelfEvaluationsGroupResponse>, 'queryFn' | 'queryKey'>
) => {
    const {rangeDateLabels, ...rest} = promiseArgs
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.SELF_EVALUATIONS_GROUP, promiseArgs],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetSelfEvaluationsGroup({
                    ...rest,
                    ...getDateFromLabel(rangeDateLabels)
                }),
                responseShape: HttpGetSelfEvaluationsGroupResponse,
                onZodError: captureException
            })
    })
}
