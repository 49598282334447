import React from 'react'
import DoctorsCalendar from '@/features/appointments/components/doctors-calendar/DoctorsCalendar.tsx'
import {useTranslation} from 'react-i18next'
import {StyledChevronIcon, StyledContainerAppointment, StyledOfficeName} from '@pages/appointments/style.ts'
import {DropdownMenu} from '@components/ui/dropdown-menu/DropdownMenu.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useGetContacts} from '@/features/settings/services/services-contact/useGetContacts.ts'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {UserType} from '@/features/auth/types.ts'

type OfficeSelected = {
    id: number | null
    name: string | null
}
export const Appointments: React.FC = () => {
    const {t} = useTranslation()
    const {data: offices} = useGetContacts()
    const {user} = useAuthStore()
    const userAllocation = user?.offices
    const userRole = user?.role?.name
    const officesAvailableForUserLoggedIn = offices?.data?.filter(office =>
        userAllocation?.map(allocation => allocation.id).includes(office.id)
    )
    const [officeSelected, setOfficeSelected] = React.useState<OfficeSelected | null>(null)
    const availableOfficeToSelect = userRole === UserType.enum.admin ? offices?.data : officesAvailableForUserLoggedIn

    return (
        <StyledContainerAppointment fullWidth>
            <DropdownMenu
                align={'start'}
                trigger={
                    <Flexbox gap={2} align={'center'}>
                        <StyledOfficeName>
                            {t('appointments:title', {
                                HOSPITAL_BASED_IN: officeSelected
                                    ? officeSelected.name
                                    : availableOfficeToSelect?.[0]?.name
                            })}
                        </StyledOfficeName>
                        <StyledChevronIcon />
                    </Flexbox>
                }
                actions={
                    availableOfficeToSelect?.map(office => ({
                        component: (
                            <Flexbox gap={2} align={'center'}>
                                <p>{office?.name}</p>
                            </Flexbox>
                        ),
                        onClickCb: () =>
                            setOfficeSelected({
                                id: office?.id,
                                name: office?.name
                            })
                    })) ?? []
                }
            />

            <DoctorsCalendar
                officeId={(officeSelected ? officeSelected.id : availableOfficeToSelect?.[0]?.id) ?? null}
            />
        </StyledContainerAppointment>
    )
}

Appointments.displayName = 'Appointments'
