import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledPatientsTableFiltersTrigger = styled(Button)<{$hasFilters?: boolean}>(
    ({theme: {palette}, $hasFilters}) => css`
        width: 200px;
        outline-color: ${palette.neutral[300]};

        &:hover {
            outline-color: ${palette.primary[300]};
            background-color: ${palette.primary[50]};
        }

        ${$hasFilters &&
        css`
            background-color: ${palette.primary[100]};
            outline-color: ${palette.primary[300]};

            & > b {
                color: ${palette.neutral[900]};
            }
        `}
    `
)

export const StyledFiltersContent = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        width: 350px;
        padding: ${spacing * 2.5}px;
    `
)
