import {useInfiniteQuery} from '@tanstack/react-query'
import {QUERY_KEYS, infiniteQueryFetchNextPage} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance'
import {captureException} from '@sentry/react'
import {PaginationParams, ResponseType} from '@/types.ts'
import {z} from 'zod'
import {HttpAppointmentsResponse, httpGetAppointment} from '@/features/appointments/services/appointments.http.ts'
import {appointmentModeOptions} from '@/features/appointments/types.ts'

export const UseGetAppointmentsOptions = z.object({
    ...PaginationParams.shape,
    fromDate: z.string().optional(),
    toDate: z.string().optional(),
    rangeDateLabels: z
        .enum(['last_week', 'last_month', 'last_3_months', 'last_6_months', 'last_year'])
        .default('last_week')
        .optional(),
    therapistId: z.number().optional(),
    search: z.string().optional(),
    appointmentType: appointmentModeOptions.nullish()
})
export type UseGetAppointmentsOptions = z.infer<typeof UseGetAppointmentsOptions>

export const useGetAppointments = (promiseArgs: Omit<UseGetAppointmentsOptions, 'page'>, refetchInterval?: number) => {
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.APPOINTMENTS, promiseArgs],
        queryFn: ({pageParam = 1}) =>
            parseAxiosPromise({
                axiosPromise: httpGetAppointment({
                    ...promiseArgs,
                    responseType: ResponseType.enum.regular,
                    page: pageParam
                }),
                responseShape: HttpAppointmentsResponse,
                onZodError: captureException
            }),
        refetchInterval: refetchInterval || false,
        initialPageParam: 1,
        getNextPageParam: ({meta}) => (meta.current_page < meta.last_page ? meta.current_page + 1 : null)
    })

    const remappedData = query.data?.pages[0]?.data?.length ? query.data.pages.flatMap(page => page.data) : []
    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData
    }
}
