import {useGetVerifiedUsersGroup} from '@/features/dashboard/services/useGetVerifiedUsersGroup.ts'
import {DashboardAdminUrlParams} from '@/features/dashboard/types.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'
import {StyledPrimaryData} from '@/features/dashboard/components/verify-user-group/style.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import MiniChart from '@assets/images/chart-mini.svg'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {ErrorIcon} from 'react-hot-toast'
import {PercentageSummary} from '@components/commons/percentage-summary/PercentageSummary.tsx'
import {round} from '@utilities/helpers.ts'

export const VerifiedUserGroup = () => {
    const {t} = useTranslation()
    const {searchParams} = useParsedSearchParams([], DashboardAdminUrlParams)
    const {data, isError, isLoading} = useGetVerifiedUsersGroup(searchParams)

    return (
        <>
            {isLoading ? (
                <Flexbox fullWidth direction={'column'} gap={4}>
                    <Flexbox fullWidth align={'center'} justify={'space-between'}>
                        <Flexbox direction={'column'} gap={2}>
                            <Flexbox direction={'column'} gap={1}>
                                <p>{t('dashboard:total:register')}</p>
                                <Skeleton height={38} width={130} />
                            </Flexbox>
                            <Skeleton height={20} width={200} />
                        </Flexbox>
                        <img src={MiniChart} alt={'icon'} />
                    </Flexbox>
                    <Flexbox fullWidth align={'center'} justify={'space-between'}>
                        <Flexbox direction={'column'} gap={2}>
                            <Flexbox direction={'column'} gap={1}>
                                <p>{t('dashboard:total:currently_in_therapy')}</p>
                                <Skeleton height={38} width={130} />
                            </Flexbox>
                            <Skeleton height={20} width={200} />
                        </Flexbox>
                        <img src={MiniChart} alt={'icon'} />
                    </Flexbox>
                </Flexbox>
            ) : isError ? (
                <ErrorBox icon={<ErrorIcon />} title={t('errors:default')} />
            ) : (
                data && (
                    <Flexbox fullWidth direction={'column'} gap={4}>
                        <Flexbox fullWidth align={'center'} justify={'space-between'}>
                            <Flexbox direction={'column'} gap={2}>
                                <Flexbox direction={'column'} gap={1}>
                                    <p>{t('dashboard:total:register')}</p>
                                    <StyledPrimaryData>{data.analytics.total}</StyledPrimaryData>
                                </Flexbox>
                                <PercentageSummary
                                    amount={data.increments.total}
                                    percentage={data.increments.percentage}
                                    label={t(`commons:compare_labels:${searchParams.rangeDateLabels}`)}
                                />
                            </Flexbox>
                            <img src={MiniChart} alt={'icon'} />
                        </Flexbox>
                        <Flexbox fullWidth align={'center'} justify={'space-between'}>
                            <Flexbox direction={'column'} gap={2}>
                                {data.analytics.partitions.map(
                                    (value, index) =>
                                        value.label === 'currently_in_therapy' && (
                                            <Flexbox direction={'column'} gap={2} key={index}>
                                                <Flexbox direction={'column'} gap={1}>
                                                    <p>{t('dashboard:total:currently_in_therapy')}</p>
                                                    {value.percentage ? (
                                                        <StyledPrimaryData>{`${round(value.percentage)}% (${value.total})`}</StyledPrimaryData>
                                                    ) : (
                                                        <StyledPrimaryData>{value.total}</StyledPrimaryData>
                                                    )}
                                                </Flexbox>
                                                <PercentageSummary
                                                    amount={value.total}
                                                    percentage={value.percentage}
                                                    label={t(`commons:compare_labels:${searchParams.rangeDateLabels}`)}
                                                />
                                            </Flexbox>
                                        )
                                )}
                            </Flexbox>
                            <img src={MiniChart} alt={'icon'} />
                        </Flexbox>
                    </Flexbox>
                )
            )}
        </>
    )
}
