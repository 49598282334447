import styled, {css} from 'styled-components'

export const StyledPatientInfoGrid = styled.div(
    ({theme: {typography, spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: ${spacing * 6}px;
        row-gap: ${spacing * 2}px;
        & p {
            font-weight: 400;
            ${typography.textSm}
        }
        & h4 {
            ${typography.textSm}
            font-weight: 500;
        }
        & a {
            padding: 0;
            text-decoration: underline;
        }
    `
)

export const StyledCheckInDetailsSectionTitle = styled.h3(
    ({theme: {typography, palette}}) => css`
        color: ${palette.neutral[600]};
        font-weight: 600;
        ${typography.textMd}
    `
)
