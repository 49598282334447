import {Navigate, Outlet} from 'react-router-dom'
import {routes} from '@/utilities/constants/routes.tsx'
import {useAuthStore} from '@/features/auth/store/store.ts'

export const PrivateRoute = () => {
    const accessToken = useAuthStore(state => state.accessToken)
    const user = useAuthStore(state => state.user)

    return accessToken ? <Outlet context={{userRole: user?.role}} /> : <Navigate to={routes.LOGIN.path} />
}
