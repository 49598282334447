import {FC} from 'react'
import {StyledThumbnailContainer, StyledThumbnailImage} from '@components/commons/thumbnail/style.ts'

interface ThumbnailProps {
    size?: number
    isRounded?: boolean
    src: string
    alt: string
}
export const Thumbnail: FC<ThumbnailProps> = ({size = 95, isRounded = true, src, alt}) => {
    return (
        <StyledThumbnailContainer size={size} $isRounded={isRounded}>
            <StyledThumbnailImage src={src} alt={alt} />
        </StyledThumbnailContainer>
    )
}
