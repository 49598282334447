import {Table} from '@/components/commons/table/Table'
import {useTranslation} from 'react-i18next'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {usePatientsTable} from '@/features/patients/services/usePatientsTable'
import {
    StyledPatientCaregiver,
    StyledPatientTableId,
    StyledPatientTableLink,
    StyledPatientsTabelNoData
} from '@/features/patients/components/patients-table/style'
import {
    CheckIcon,
    DotsVerticalIcon,
    Key01Icon,
    MarkerPin01Icon,
    Trash01Icon,
    Users01Icon,
    VideoRecorderIcon,
    XCloseIcon
} from '@/components/ui/icon/Icon'
import {formatLocaleDate} from '@/utilities/helpers'
import {DropdownMenu} from '@/components/ui/dropdown-menu/DropdownMenu'
import {EmptySearch} from '@/components/commons/empty-search/EmptySearch'
import {useNavigate} from 'react-router-dom'
import dayjs, {formatDate} from '@/dayjs.ts'
import {Dispatch, SetStateAction} from 'react'
import {User} from '@/features/auth/types.ts'

export const PatientsTable = ({
    setDeleteModalOpen,
    setResetPasswordModalOpen
}: {
    setDeleteModalOpen: Dispatch<SetStateAction<User | null>>
    setResetPasswordModalOpen: Dispatch<SetStateAction<User | null>>
}) => {
    const {t} = useTranslation()
    const patientsTable = usePatientsTable()
    const navigate = useNavigate()

    return (
        <Table
            data={patientsTable.remappedData}
            isLoading={patientsTable.isLoading}
            sorter={patientsTable.sorter}
            searchValue={patientsTable.searchValue}
            emptyStateComponent={
                <EmptySearch
                    title={t('patients:empty_state:title_search')}
                    subtitle={t('patients:empty_state:title_search')}
                    onClearSearch={() => navigate('/patients')}
                    icon={<Users01Icon />}
                />
            }
            columns={[
                {
                    label: t('patients:table:patient'),
                    width: '22%',
                    sortName: 'name',
                    alignment: 'left',
                    cellRender: data => (
                        <Flexbox direction="column">
                            <StyledPatientTableLink to={`${data.id}`}>
                                {data.lastName && `${data.lastName} ${data.firstName}`}
                            </StyledPatientTableLink>
                            <StyledPatientTableId>{`ID ${data.id}`}</StyledPatientTableId>
                        </Flexbox>
                    )
                },
                {
                    label: t('patients:table:age'),
                    width: '8%',
                    sortName: 'age',
                    alignment: 'left',
                    cellRender: data => dayjs().diff(dayjs(data.birthDate), 'year')
                },
                {
                    label: t('patients:table:next_appointment'),
                    width: '25%',
                    alignment: 'left',
                    sortName: 'appointment',
                    cellRender: data => (
                        <Flexbox align="center" gap={2}>
                            {data.appointment ? (
                                <>
                                    {data.appointment?.linkVideoCall ? (
                                        <VideoRecorderIcon size={20} />
                                    ) : (
                                        <MarkerPin01Icon size={20} />
                                    )}

                                    <Flexbox direction="column">
                                        <p>Frosinone</p> {/*TODO insert place after API implements*/}
                                        <p>
                                            {data.appointment?.start ? (
                                                `
                                                    ${formatDate(data.appointment.start, 'd MMM YYYY')} |
                                                    ${formatDate(data.appointment.start, 'HH:mm')} -
                                                    ${formatDate(data.appointment.end, 'HH:mm')}
                                                `
                                            ) : (
                                                <StyledPatientsTabelNoData>
                                                    {t('patients:table:no_appointments')}
                                                </StyledPatientsTabelNoData>
                                            )}
                                        </p>
                                    </Flexbox>
                                </>
                            ) : (
                                <StyledPatientsTabelNoData>
                                    {t('patients:table:no_appointments')}
                                </StyledPatientsTabelNoData>
                            )}
                        </Flexbox>
                    )
                },
                {
                    label: t('patients:table:caregiver'),
                    width: '10%',
                    alignment: 'left',
                    sortName: 'type',
                    cellRender: data => (
                        <StyledPatientCaregiver $hasCaregiver={!!data.caregiverId} align="center" gap={2}>
                            {data.caregiverId != null ? (
                                <>
                                    <CheckIcon />
                                    {t('commons:yes')}
                                </>
                            ) : (
                                <>
                                    <XCloseIcon />
                                    {t('commons:no')}
                                </>
                            )}
                        </StyledPatientCaregiver>
                    )
                },
                {
                    label: t('patients:table:created_at'),
                    width: '15%',
                    sortName: 'createAt',
                    alignment: 'left',
                    cellRender: data => formatLocaleDate(data.createdAt)
                },
                {
                    label: t('patients:table:last_update'),
                    width: '15%',
                    sortName: 'updatedAt',
                    alignment: 'left',
                    cellRender: data => formatLocaleDate(data.updatedAt)
                },
                {
                    label: '',
                    width: '5%',
                    alignment: 'right',
                    cellRender: data => (
                        <DropdownMenu
                            sideOffset={8}
                            trigger={<DotsVerticalIcon />}
                            actions={[
                                {
                                    component: t('patients:table:actions:reset'),
                                    icon: <Key01Icon size={16} />,
                                    onClickCb: () => setResetPasswordModalOpen(data)
                                },
                                {
                                    component: t('patients:table:actions:remove'),
                                    isDanger: true,
                                    icon: <Trash01Icon size={16} />,
                                    onClickCb: () => setDeleteModalOpen(data)
                                }
                            ]}
                        />
                    )
                }
            ]}
        />
    )
}
