import styled, {css} from 'styled-components'
import {
    DESKTOP_TABLE_COLUMNS_PADDING,
    DESKTOP_TABLE_SCROLLBAR_WIDTH
} from '@/components/commons/table/desktop-table/style'
import {Virtuoso} from 'react-virtuoso'
import {Percentage} from '@/types'

export const StyledDesktopTBody = styled(Virtuoso)`
    ${({theme: {palette}}) => css`
        overflow-y: scroll !important;

        &::-webkit-scrollbar {
            width: ${DESKTOP_TABLE_SCROLLBAR_WIDTH};
            background-color: ${palette.neutral[50]};
        }
        &::-webkit-scrollbar-thumb {
            background-color: ${palette.neutral[300]};
            border-radius: 3px;

            &:hover {
                background-color: ${palette.neutral[400]};
            }
        }
    `}
`

export const StyledTRowWrapper = styled.div<{$hasClick?: boolean}>(
    ({theme: {palette}, $hasClick}) => css`
        border-bottom: 1px solid ${palette.neutral[200]};
        transition: ease-in-out 300ms;
        cursor: ${$hasClick && 'pointer'};
        &:hover {
            background: ${palette.neutral['50']};
        }
    `
)

export const StyledTRow = styled.div`
    display: flex;
`

interface StyledTCellProps {
    $width: Percentage
    $alignment?: 'left' | 'center' | 'right'
}
export const StyledTCell = styled.div<StyledTCellProps>(
    ({$width, $alignment, theme: {typography, palette}}) => css`
        width: ${$width};
        min-height: 64px;
        display: flex;
        align-items: center;
        justify-content: ${$alignment == 'right' ? 'flex-end' : $alignment == 'center' ? 'center' : 'flex-start'};
        padding: 0 ${DESKTOP_TABLE_COLUMNS_PADDING};
        ${typography.textSm};
        color: ${palette.neutral[600]};
        white-space: nowrap;

        & > div {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    `
)
