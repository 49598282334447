import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Modal} from '@/components/ui/modal/Modal'
import {ModalHeader} from '@/components/ui/modal-atoms/ModalAtoms'
import {StateSelector} from '@/features/patient-form/components/state-selector/StateSelector'
import {Button} from '@/components/ui/button/Button'

export const StyledPatientFormModal = styled(Modal)`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr) auto;
`

export const StyledPatientFormModalHeader = styled(ModalHeader)(
    ({theme: {typography, palette, spacing}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: ${spacing * 2.5}px ${spacing * 2}px ${spacing * 2.5}px ${spacing * 6}px;

        & > h4 {
            ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral['black']};
        }
    `
)

export const StyledStateSelector = styled(StateSelector)(
    ({theme: {palette}}) => css`
        & .select__control {
            min-height: unset;
            height: 40px;
            border-radius: 12px;
            transition: all 200ms ease-in-out;
            &:hover {
                box-shadow: 0 0 0 4px ${palette.primary[100]};
                border: 1px solid ${palette.primary[300]}!important;
            }
            &:focus {
                box-shadow: 0 0 0 4px ${palette.primary[100]};
                border-color: ${palette.primary[300]};
            }
        }
    `
)

export const StyledPatientFormFormBody = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        & h1 {
            ${typography.textXl};
            font-weight: 500;
            color: ${palette.neutral[900]};
        }
        & p {
            ${typography.textMd};
            font-weight: 400;
            color: ${palette.neutral[900]};
        }
    `
)

export const StyledCalculatePatientFiscalCodeButton = styled(Button)`
    padding: 0;
    margin-left: auto;
`
