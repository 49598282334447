import {Modal} from '@components/ui/modal-legacy/Modal.tsx'
import {useTranslation} from 'react-i18next'
import {Controller, useForm, useWatch} from 'react-hook-form'
import {SingleSelect} from '@components/commons/single-select/SingleSelect.tsx'
import {
    CalendarIcon,
    CheckIcon,
    ClockIcon,
    MarkerPin01Icon,
    User01Icon,
    UserSquareIcon,
    VideoRecorderIcon
} from '@components/ui/icon/Icon.tsx'
import {
    APPOINTMENT_FORM_MODEL,
    AppointmentsValidationSchema
} from '@/features/appointments/components/modals/AddAppointmentsSchema.tsx'
import {zodResolver} from '@hookform/resolvers/zod'
import {Button} from '@components/ui/button/Button.tsx'
import {
    StyledContainerAppointmentForm,
    StyledSwitchIsRemoteHelpText
} from '@/features/appointments/components/modals/style.ts'
import {DatePicker} from '@components/commons/date-picker/DatePicker.tsx'
import dayjs from '@/dayjs.ts'
import {FC, useEffect, useMemo, useState} from 'react'
import {OfficeSchema} from '@/features/settings/SettingsFormModal.ts'
import appointmentSlots from '@/features/appointments/services/appointmentSlots.tsx'
import {useCreateAppointment} from '@/features/appointments/services/useCreateAppointment.ts'
import {User} from '@/features/auth/types.ts'
import {Switch} from '@components/ui/switch/Switch.tsx'
import {useEditAppointment} from '@/features/appointments/services/useEditAppointment.ts'
import {Appointment, PrefilledAppointment} from '@/features/appointments/types.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useGetAvailableSlots} from '@/features/appointments/services/useGetAvailableSlots.ts'
import {useAuthStore} from '@/features/auth/store/store.ts'

type AddAppointmentsModalProps = {
    offices: OfficeSchema[]
    onClose: () => void
    patients: User[]
    therapists: User[]
    prefilledData?: PrefilledAppointment | null
    officeId: number | null
    appointments?: Appointment[]
    appointment?: Appointment
}

export type DatePickerDateView = {
    month: number | string | null
    year: number | string | null
}
export const AddAppointmentsModal: FC<AddAppointmentsModalProps> = ({
    patients,
    offices,
    therapists,
    onClose,
    prefilledData,
    officeId,
    appointments,
    appointment
}) => {
    const user = useAuthStore(state => state.user)
    const {t} = useTranslation()
    const {mutate: createAppointment, isPending: isPendingCreate} = useCreateAppointment({onSuccess: onClose})
    const {mutate: editAppointment, isPending: isPendingEdit} = useEditAppointment({onSuccess: onClose})

    const [datePickerDateView, setDatePickerDateView] = useState<DatePickerDateView>({
        month: null,
        year: null
    })

    const {data: availableSlots} = useGetAvailableSlots({
        fromDate: datePickerDateView?.month
            ? dayjs(`${datePickerDateView?.year}-${datePickerDateView?.month}-01`).format('YYYY-MM-DD')
            : dayjs().format('YYYY-MM-DD'),
        toDate: datePickerDateView?.month
            ? dayjs(`${datePickerDateView?.year}-${datePickerDateView?.month}-01`).endOf('month').format('YYYY-MM-DD')
            : dayjs().endOf('month').format('YYYY-MM-DD')
    })

    const isEdit = Boolean(appointment?.id)
    const isPrefilled = prefilledData !== null && prefilledData !== undefined

    const startTime = dayjs.utc(isPrefilled ? prefilledData?.start : appointment?.start).format('HH')
    const endTime = dayjs.utc(isPrefilled ? prefilledData?.end : appointment?.end).format('HH')

    const prefilledTime = useMemo(() => {
        return appointmentSlots?.find(slot => +slot.start === Number(startTime) && +slot.end === Number(endTime))?.id
    }, [startTime, endTime, isPrefilled, isEdit, appointment, prefilledData])

    const defaultValues = {
        date: appointment?.start ? new Date(appointment.start) : undefined,
        time: String(prefilledTime),
        therapistId: String(appointment?.therapistId) ?? '',
        patientId: String(appointment?.patientId) ?? '',
        officeId: String(appointment?.officeId) ?? '',
        isRemote: appointment?.isRemote ?? false
    }

    const defaultValuesPrefilled = {
        date: prefilledData?.start ? new Date(prefilledData.start) : undefined,
        time: String(prefilledTime),
        therapistId: String(prefilledData?.therapistId) ?? '',
        patientId: String(prefilledData?.patientId) ?? '',
        officeId: String(prefilledData?.officeId) ?? '',
        isRemote: false
    }

    const {
        control,
        handleSubmit,
        setValue,
        formState: {errors, isSubmitting}
    } = useForm<AppointmentsValidationSchema>({
        mode: 'onSubmit',
        resolver: zodResolver(AppointmentsValidationSchema),
        defaultValues: isEdit
            ? defaultValues
            : isPrefilled
              ? defaultValuesPrefilled
              : {
                    date: undefined,
                    time: '',
                    therapistId: '',
                    patientId: undefined,
                    officeId: String(officeId),
                    isRemote: false
                }
    })

    const dateSelected = useWatch({control, name: APPOINTMENT_FORM_MODEL.date.name})
    const patientId = useWatch({control, name: APPOINTMENT_FORM_MODEL.patientId.name})
    const therapistSelected = useWatch({control, name: APPOINTMENT_FORM_MODEL.therapistId.name})

    useEffect(() => {
        if (officeId) {
            setValue(APPOINTMENT_FORM_MODEL.officeId.name, String(officeId))
        }
    }, [officeId])

    const patientSelected = patients?.find(patient => patient.id === Number(patientId))
    const hasOnlineAppointment = patientSelected?.onlineAppointmentEnabled

    useEffect(() => {
        setValue(APPOINTMENT_FORM_MODEL.isRemote.name, hasOnlineAppointment)
    }, [patientId])

    const onSubmit = (data: AppointmentsValidationSchema) => {
        const findTimeName = appointmentSlots.find(slot => slot.id === Number(data.time))
        const start = Number(findTimeName?.start ?? 0)
        const end = Number(findTimeName?.end ?? 0)
        const date = new Date(data.date ?? '')

        const startDate = new Date(date)
        startDate.setHours(start, 0, 0, 0)

        const endDate = new Date(date)
        endDate.setHours(end, 0, 0, 0)

        const startIsoString = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString()
        const endIsoString = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString()

        if (isEdit) {
            editAppointment({
                ...data,
                appointmentID: appointment?.id ?? 0,
                start: startIsoString,
                end: endIsoString
            })
        } else {
            createAppointment({
                ...data,
                start: startIsoString,
                end: endIsoString,
                remindAt: null
            })
        }
    }

    //methods to get the available slots dates for the selected doctor into calendar
    // Calculate unique dates based on available slots for the selected therapist
    const uniqueDates = useMemo(() => {
        if (!therapistSelected || !availableSlots?.data) return []

        // Filter slots for the selected therapist and collect all start and end dates
        const allDates = availableSlots.data
            .filter(slot => slot.userId === Number(therapistSelected))
            .flatMap(slot => [slot.start, slot.end])
            .map(date => dayjs(date).format('YYYY-MM-DD'))

        // Remove duplicates and return an array of unique dates
        return Array.from(new Set(allDates))
    }, [therapistSelected, availableSlots])

    // Generate all dates for the month and determine which ones are available
    const {generateMonthDates, availableDates} = useMemo(() => {
        const daysInMonth = dayjs(`${datePickerDateView?.year}-${datePickerDateView?.month}`).daysInMonth()
        const allMonthDates = Array.from({length: daysInMonth}, (_, i) =>
            dayjs(`${datePickerDateView?.year}-${datePickerDateView?.month}-${i + 1}`).format('YYYY-MM-DD')
        )

        const availableDates = allMonthDates.filter(date => uniqueDates.includes(date))
        return {
            generateMonthDates: allMonthDates,
            availableDates
        }
    }, [datePickerDateView, uniqueDates])

    // determines the dates that are not available
    const excludeDates = useMemo(() => {
        return generateMonthDates.filter(date => !availableDates.includes(date))
    }, [generateMonthDates, availableDates])

    // convert the exclude dates to date objects
    const excludeDatesAsDateObjects = useMemo(() => {
        return excludeDates.map(date => new Date(date))
    }, [excludeDates])

    //methods to get the available slots dates for the selected doctor

    const availableDatesForSelection = useMemo(() => {
        if (!therapistSelected || !dateSelected || !appointments) return []

        const appointmentsForTherapistSelected = appointments.filter(
            appointment => appointment.therapistId === Number(therapistSelected)
        )
        return appointmentsForTherapistSelected.filter(appointmentFiltered => {
            return (
                dayjs.utc(appointmentFiltered.start).format('YYYY-MM-DD') === dayjs(dateSelected).format('YYYY-MM-DD')
            )
        })
    }, [appointments, patientSelected, dateSelected])

    const availableTimeForSelection = useMemo(() => {
        if (!availableDatesForSelection) return appointmentSlots
        return appointmentSlots.filter(
            slot =>
                !availableDatesForSelection.some(
                    appointment =>
                        dayjs.utc(appointment.start).format('HH') === slot.start &&
                        dayjs.utc(appointment.end).format('HH') === slot.end
                )
        )
    }, [availableDatesForSelection])

    return (
        <Modal
            isLoading={isPendingCreate || isPendingEdit || isSubmitting}
            width={'500'}
            closeIconOffset="sm"
            onClose={onClose}
            onOverlayClick={onClose}
            title={t('appointments:add_appointment')}
            submitButton={
                <Button type="submit" variant="primary" form={'appointment-form'}>
                    {t('commons:actions:save')}
                </Button>
            }
        >
            <StyledContainerAppointmentForm
                direction={'column'}
                render={'form'}
                onSubmit={handleSubmit(onSubmit)}
                id={'appointment-form'}
            >
                <Controller
                    name={APPOINTMENT_FORM_MODEL.patientId.name}
                    control={control}
                    render={({field}) => {
                        const defaultPatient = patients.find(patient => patient.id == prefilledData?.patientId)
                        return (
                            <SingleSelect
                                minWidth={100}
                                disabled={!!prefilledData?.patientId}
                                options={patients?.map(patient => ({
                                    label: `${patient.firstName} ${patient.lastName}`,
                                    value: String(patient.id)
                                }))}
                                label={t(APPOINTMENT_FORM_MODEL.patientId.label)}
                                placeholder={t(APPOINTMENT_FORM_MODEL.patientId.placeholder)}
                                defaultValue={defaultPatient?.id?.toString()}
                                value={field.value ?? ''}
                                onChange={field.onChange}
                                errorMessage={t(errors?.patientId?.message?.toString() || '')}
                                hasIconOption={<CheckIcon size={20} />}
                                iconOnTrigger={<User01Icon size={20} />}
                                helpText={
                                    field.value
                                        ? hasOnlineAppointment
                                            ? t('appointments:patient_has_meet_enabled')
                                            : t('appointments:patient_has_meet_disabled')
                                        : ''
                                }
                            />
                        )
                    }}
                />

                <Controller
                    name={APPOINTMENT_FORM_MODEL.officeId.name}
                    control={control}
                    render={({field}) => {
                        return (
                            <SingleSelect
                                minWidth={100}
                                options={offices?.map(office => ({
                                    label: office.name,
                                    value: String(office.id)
                                }))}
                                label={t(APPOINTMENT_FORM_MODEL.officeId.label)}
                                placeholder={t(APPOINTMENT_FORM_MODEL.officeId.placeholder)}
                                value={field.value ?? ''}
                                onChange={field.onChange}
                                errorMessage={t(errors?.officeId?.message?.toString() || '')}
                                hasIconOption={<CheckIcon size={20} />}
                                iconOnTrigger={<MarkerPin01Icon size={20} />}
                                disabled={!!appointment?.id}
                            />
                        )
                    }}
                />

                <Controller
                    name={APPOINTMENT_FORM_MODEL.isRemote.name}
                    control={control}
                    render={({field}) => {
                        return (
                            <Flexbox gap={2} direction={'column'}>
                                <Switch
                                    className={'styled-switch'}
                                    icon={<VideoRecorderIcon />}
                                    label={t(APPOINTMENT_FORM_MODEL.isRemote.label)}
                                    labelPosition={'left'}
                                    defaultChecked={Boolean(field.value)}
                                    onChange={field.onChange}
                                    disabled={!patientSelected}
                                />
                                {field.value === true && !hasOnlineAppointment && (
                                    <StyledSwitchIsRemoteHelpText>
                                        <p>{t('appointments:patient_has_meet_disabled_help_text')}</p>
                                    </StyledSwitchIsRemoteHelpText>
                                )}
                            </Flexbox>
                        )
                    }}
                />

                <Controller
                    name={APPOINTMENT_FORM_MODEL.therapistId.name}
                    control={control}
                    render={({field}) => {
                        return (
                            <SingleSelect
                                minWidth={100}
                                options={therapists?.map(therapist => ({
                                    label: `${therapist.firstName} ${therapist.lastName}`,
                                    value: String(therapist.id)
                                }))}
                                label={t(APPOINTMENT_FORM_MODEL.therapistId.label)}
                                placeholder={t(APPOINTMENT_FORM_MODEL.therapistId.placeholder)}
                                value={field.value ?? ''}
                                onChange={value => {
                                    field.onChange(value)
                                    setValue(APPOINTMENT_FORM_MODEL.date.name, null)
                                    setValue(APPOINTMENT_FORM_MODEL.time.name, '')
                                }}
                                errorMessage={t(errors?.therapistId?.message?.toString() || '')}
                                hasIconOption={<CheckIcon size={20} />}
                                iconOnTrigger={<UserSquareIcon size={20} />}
                                disabled={
                                    Boolean(prefilledData?.therapistId) || appointment?.therapist?.id === user?.id
                                }
                            />
                        )
                    }}
                />

                <Controller
                    control={control}
                    name={APPOINTMENT_FORM_MODEL.date.name}
                    render={({field: {onChange, value, onBlur}, fieldState: {error}}) => (
                        <DatePicker
                            toggle
                            formatter={date => dayjs(date).format('DD/MM/YYYY')}
                            exclude={{day: [], date: isEdit ? [] : excludeDatesAsDateObjects}}
                            numMonths={1}
                            mode={'single'}
                            selectedDates={value ? [value] : []}
                            onDatesChange={dates => {
                                onChange(dates[0])
                                setValue(APPOINTMENT_FORM_MODEL.time.name, '')
                            }}
                            handleDateViewChange={setDatePickerDateView}
                            onBlur={onBlur}
                            triggerProps={{
                                required: true,
                                label: t(APPOINTMENT_FORM_MODEL.date.label),
                                errorMessage: t(error?.message || ''),
                                placeholder: t(APPOINTMENT_FORM_MODEL.date.label),
                                typeIcon: <CalendarIcon size={20} />
                            }}
                            disabled={!!prefilledData?.start}
                        />
                    )}
                />
                <Controller
                    name={APPOINTMENT_FORM_MODEL.time.name}
                    control={control}
                    render={({field}) => {
                        return (
                            <SingleSelect
                                minWidth={100}
                                options={availableTimeForSelection.map(slot => ({
                                    label: slot.name,
                                    value: String(slot.id)
                                }))}
                                label={t(APPOINTMENT_FORM_MODEL.time.label)}
                                placeholder={t(APPOINTMENT_FORM_MODEL.time.placeholder)}
                                value={field.value ?? ''}
                                onChange={field.onChange}
                                errorMessage={t(errors?.time?.message?.toString() || '')}
                                hasIconOption={<CheckIcon size={20} />}
                                iconOnTrigger={<ClockIcon size={20} />}
                                disabled={!dateSelected || Boolean(prefilledData?.start)}
                                optionsToCheck={appointmentSlots.map(slot => ({
                                    label: slot.name,
                                    value: String(slot.id)
                                }))}
                            />
                        )
                    }}
                />
            </StyledContainerAppointmentForm>
        </Modal>
    )
}
