import {RouterProvider} from 'react-router-dom'
import {StyleSheetManager, ThemeProvider} from 'styled-components'
import {getAppTheme, GlobalStyles} from '@/theme/theme.ts'
import {router} from './router.tsx'
import {Toaster} from 'react-hot-toast'
import {ToastBar} from './components/commons/toast-bar/ToastBar.tsx'
import {useEffect} from 'react'
import {userMe} from '@/features/auth/store/actions.ts'
import {useAuthStore} from '@/features/auth/store/store.ts'

export default function App() {
    const themeName = 'light' //get it from the app reducer

    const accessToken = useAuthStore(state => state.accessToken)
    useEffect(() => {
        if (accessToken) {
            userMe()
        }
    }, [userMe, accessToken])

    return (
        <ThemeProvider theme={getAppTheme({name: themeName})}>
            <StyleSheetManager>
                <>
                    <Toaster position="bottom-center">{toast => <ToastBar t={toast} />}</Toaster>
                    <GlobalStyles />
                    <RouterProvider router={router} />
                </>
            </StyleSheetManager>
        </ThemeProvider>
    )
}
