import {z} from 'zod'

export const SELF_ASSESSMENT_FILTERS_MODEL = {
    anonymous: {
        name: 'anonymous',
        label: 'self_assessment:filters:patient:label',
        placeholder: 'self_assessment:filters:patient:placeholder',
        defaultValue: '',
        helpText: ''
    },
    result: {
        name: 'result',
        label: 'self_assessment:filters:outcome:label',
        placeholder: 'self_assessment:filters:outcome:placeholder',
        defaultValue: '',
        helpText: ''
    },
    intakeSource: {
        name: 'intakeSource',
        label: 'self_assessment:filters:provenance:label',
        placeholder: 'self_assessment:filters:provenance:placeholder',
        defaultValue: '',
        helpText: ''
    }
} as const

export const SelfAssessmentFiltersValidationSchema = z.object({
    anonymous: z.string().optional(),
    result: z.string().optional(),
    intakeSource: z.string().optional()
})
export type SelfAssessmentFiltersValidationSchema = z.infer<typeof SelfAssessmentFiltersValidationSchema>
