import {forwardRef, useState} from 'react'
import {SelectProvider, useSelectStore} from '@ariakit/react'
import {
    StyledSelectItem,
    StyledSelectPopover,
    StyledSelectTrigger,
    StyledWrapperMultiSelect
} from '@components/commons/multi-select/style.ts'
import {Label} from '@components/ui/label/Label.tsx'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {Chip} from '@components/commons/chip/Chip.tsx'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {SelectValue} from '@/types.ts'

type MultiSelectValue = NonNullable<SelectValue>

type MultipleSelectProps = {
    options: MultiSelectValue[]
    label: string
    placeholder: string
    value: MultiSelectValue[]
    onChange: (value: MultiSelectValue[]) => void
    defaultValue?: MultiSelectValue[]
    required?: boolean
    errorMessage?: string
    helpText?: string
}
export const MultiSelect = forwardRef<HTMLDivElement, MultipleSelectProps>(
    ({options, label, placeholder, value, onChange, defaultValue, required, errorMessage, helpText}, ref) => {
        const [selectedValues, setSelectedValues] = useState<MultiSelectValue[]>(value || [])

        const store = useSelectStore({
            value: selectedValues.map(selectedValue => selectedValue.value),
            setValue: (newValues: string[]) => {
                const newSelectedValues = options.filter(option => newValues.includes(option.value))
                setSelectedValues(newSelectedValues)
            },
            defaultValue: defaultValue?.map(defaultValue => defaultValue.value)
        })

        const handleClickToSelect = (itemValue: string) => {
            const isSelected = selectedValues.some(selectedItem => selectedItem.value === itemValue)

            const newSelectedValues = isSelected
                ? selectedValues.filter(selectedItem => selectedItem.value !== itemValue)
                : [...selectedValues, options.find(option => option.value === itemValue)]

            const selectedItems = newSelectedValues as MultiSelectValue[]
            setSelectedValues(selectedItems)
            onChange(selectedItems)
        }

        return (
            <StyledWrapperMultiSelect ref={ref}>
                <SelectProvider store={store}>
                    <Label>
                        {label || ''}
                        {required && '*'}
                    </Label>

                    <StyledSelectTrigger>
                        {selectedValues?.length > 0 ? (
                            selectedValues.map(selectedValue => (
                                <Chip
                                    fullWidth
                                    label={selectedValue.label}
                                    key={selectedValue.value}
                                    onDelete={() => handleClickToSelect(selectedValue.value)}
                                    color={'neutral'}
                                    variant={'outlined'}
                                />
                            ))
                        ) : (
                            <span>{placeholder || ''}</span>
                        )}
                    </StyledSelectTrigger>

                    <StyledSelectPopover>
                        {options?.length > 0 &&
                            options.map(item => (
                                <StyledSelectItem key={item.value} onClick={() => handleClickToSelect(item.value)}>
                                    <Checkbox
                                        id={item.value}
                                        checked={selectedValues.some(
                                            selectedValue => selectedValue.value === item.value
                                        )}
                                    />
                                    {item.label || ''}
                                </StyledSelectItem>
                            ))}
                    </StyledSelectPopover>
                    <InputHelpText error={errorMessage} helpText={helpText} />
                </SelectProvider>
            </StyledWrapperMultiSelect>
        )
    }
)
