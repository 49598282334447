import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    StyledSelfAssessmentDetailsOutcome,
    StyledSelfAssessmentInfoContainer,
    StyledSelfAssessmentPatientLink
} from '@/features/self-evaluation-details/components/self-evaluation-details-table-header/style.ts'
import {generatePath, useOutletContext} from 'react-router-dom'
import {routes} from '@utilities/constants/routes.tsx'
import {Globe03Icon, Phone02Icon} from '@components/ui/icon/Icon.tsx'
import {formatLocaleDate} from '@utilities/helpers.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {useGetSelfEvaluationDetails} from '@/features/self-evaluation-details/service/useGetSelfEvaluationDetails.ts'
import {useTranslation} from 'react-i18next'
import {SelfEvaluationDetailsURLParams} from '@/features/self-evaluation-details/types.ts'
import {Provenance} from '@/features/self-evaluation/types.ts'

export const SelfEvaluationTableHeader = () => {
    const {urlParams} = useOutletContext<{urlParams: SelfEvaluationDetailsURLParams}>()
    const {t} = useTranslation()
    const selfAssessmentDetails = useGetSelfEvaluationDetails({selfEvaluationId: urlParams.selfEvaluationId})
    return (
        <Flexbox gap={6}>
            {!selfAssessmentDetails.isLoading && selfAssessmentDetails.data ? (
                <>
                    <StyledSelfAssessmentInfoContainer direction={'column'} gap={2}>
                        <h4>{t('self_assessment_details:patient')}</h4>
                        {selfAssessmentDetails.data.user ? (
                            <StyledSelfAssessmentPatientLink
                                to={generatePath(routes.PATIENT_DETAILS.path, {
                                    patientId: selfAssessmentDetails.data.user?.id
                                })}
                            >
                                {`${selfAssessmentDetails.data.user?.lastName} ${selfAssessmentDetails.data.user?.firstName}`}
                            </StyledSelfAssessmentPatientLink>
                        ) : (
                            <p>{t('self_assessment:filters:patient:not_register')}</p>
                        )}
                    </StyledSelfAssessmentInfoContainer>
                    <StyledSelfAssessmentInfoContainer direction={'column'} gap={2}>
                        <h4>{t('self_assessment_details:outcome')}</h4>
                        <StyledSelfAssessmentDetailsOutcome
                            $hasPositive={
                                selfAssessmentDetails.data.totalScore <
                                selfAssessmentDetails.data.selfEvaluationForm.minValidScore
                            }
                        >
                            {selfAssessmentDetails.data.totalScore <
                            selfAssessmentDetails.data.selfEvaluationForm.minValidScore
                                ? t('self_assessment:table:positive')
                                : t('self_assessment:table:negative')}
                        </StyledSelfAssessmentDetailsOutcome>
                    </StyledSelfAssessmentInfoContainer>
                    <StyledSelfAssessmentInfoContainer direction={'column'} gap={2}>
                        <h4>{t('self_assessment_details:provenance')}</h4>
                        {selfAssessmentDetails.data.intakeSource && (
                            <Flexbox gap={3} align={'center'}>
                                {selfAssessmentDetails.data.intakeSource === Provenance.Enum.web ? (
                                    <Globe03Icon />
                                ) : (
                                    <Phone02Icon />
                                )}
                                <p>{t(`self_assessment:table:${selfAssessmentDetails.data.intakeSource}`)}</p>
                            </Flexbox>
                        )}
                    </StyledSelfAssessmentInfoContainer>
                    <StyledSelfAssessmentInfoContainer direction={'column'} gap={2}>
                        <h4>{t('self_assessment_details:compiled_at')}</h4>
                        <p>{formatLocaleDate(selfAssessmentDetails.data.createdAt, 'D MMM YYYY, HH:mm')}</p>
                    </StyledSelfAssessmentInfoContainer>
                </>
            ) : (
                <>
                    {Array.from(Array(4)).map((_, index) => (
                        <Flexbox direction={'column'} gap={2} key={index}>
                            <Skeleton height={20} width={180} />
                            <Skeleton height={20} width={180} />
                        </Flexbox>
                    ))}
                </>
            )}
        </Flexbox>
    )
}
