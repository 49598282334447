import {StyledRichTextContainer} from '@components/ui/rich-text-area/style.ts'
import {RichTextArea} from '@components/ui/rich-text-area/RichTextArea.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {PencilLineIcon, Save01Icon, Trash01Icon} from '@components/ui/icon/Icon.tsx'
import {FC, useEffect, useState} from 'react'
import {useAddAppointmentNote} from '@/features/appointments/services/useAddAppointmentNote.ts'
import {useTranslation} from 'react-i18next'
import {Appointment} from '@/features/appointments/types.ts'
import {useDeleteAppointmentNote} from '@/features/appointments/services/useDeleteAppointmentNote.ts'

type EditorProps = {
    appointment: Appointment
}
export const Editor: FC<EditorProps> = ({appointment}) => {
    const {t} = useTranslation()
    const [note, setNote] = useState<{id: number | null; value: string | null}>({id: null, value: null})
    const {mutate: addAppointmentNote} = useAddAppointmentNote({onSuccess: () => setIsEditEnabled(false)})
    const {mutate: deleteAppointmentNote} = useDeleteAppointmentNote({
        onSuccess: () => {
            setIsEditEnabled(false)
            setNote({id: null, value: ''})
        }
    })

    const [isEditEnabled, setIsEditEnabled] = useState(false)

    useEffect(() => {
        if (appointment?.appointmentCustomizations) {
            appointment.appointmentCustomizations?.forEach(item =>
                item.userId === appointment.therapist?.id
                    ? setNote({id: item.id, value: item.note})
                    : setIsEditEnabled(false)
            )
        }
    }, [appointment])
    return (
        <StyledRichTextContainer>
            {note?.value || isEditEnabled ? (
                <RichTextArea
                    value={note.value}
                    placeholder={t('appointments:notes_placeholder')}
                    onChange={event => setNote(prevState => ({...prevState, value: event as string}))}
                    isEditEnabled={isEditEnabled}
                />
            ) : (
                <p>{t('appointments:empty_notes')}</p>
            )}

            <Flexbox direction={'column'} justify={'space-between'}>
                <Flexbox>
                    <Button variant={'tertiaryColor'} onClick={() => setIsEditEnabled(prevState => !prevState)}>
                        <PencilLineIcon />
                    </Button>

                    <Button
                        variant={'tertiaryColor'}
                        onClick={() =>
                            note.id &&
                            deleteAppointmentNote({
                                appointmentID: appointment.id,
                                noteID: note.id
                            })
                        }
                    >
                        <Trash01Icon />
                    </Button>
                </Flexbox>

                {isEditEnabled && (
                    <Button
                        variant={'tertiaryColor'}
                        onClick={() =>
                            note.id &&
                            addAppointmentNote({
                                appointmentID: appointment.id,
                                noteID: note.id,
                                note: note.value || ''
                            })
                        }
                    >
                        {t('commons:actions:save')}
                        <Save01Icon />
                    </Button>
                )}
            </Flexbox>
        </StyledRichTextContainer>
    )
}
