import {FC, ReactNode} from 'react'
import {
    StyledCard,
    StyledSectionedBodyCard,
    StyledSectionedFooterCard,
    StyledSectionedHeaderCard
} from '@components/commons/cards/style.ts'

export type BaseCardSectionedProps = {
    children?: ReactNode
    headerSlot?: ReactNode
    footerSlot?: ReactNode
    headerClassName?: string
    bodyClassName?: string
    footerClassName?: string
    cardClassName?: string
    paddingX?: number
    paddingY?: number
    bodyHasBorder?: boolean
    gap?: number
    variant?: 'base' | 'sectioned'
}

export const BaseCard: FC<BaseCardSectionedProps> = ({
    children,
    headerSlot,
    footerSlot,
    gap,
    headerClassName,
    bodyClassName,
    footerClassName,
    cardClassName,
    paddingY,
    paddingX,
    bodyHasBorder = true,
    variant = 'base',
    ...props
}) => {
    return (
        <StyledCard gap={gap} $paddingX={paddingX} $paddingY={paddingY} className={cardClassName} {...props}>
            {/*header*/}
            {headerSlot && (
                <StyledSectionedHeaderCard
                    className={headerClassName}
                    fullWidth
                    justify={'space-between'}
                    align={'center'}
                    $variant={variant}
                >
                    {headerSlot}
                </StyledSectionedHeaderCard>
            )}

            {/*body*/}
            <StyledSectionedBodyCard className={bodyClassName} $bodyHasBorder={bodyHasBorder}>
                {children}
            </StyledSectionedBodyCard>

            {/*footer*/}
            {footerSlot && (
                <StyledSectionedFooterCard
                    className={footerClassName}
                    fullWidth
                    justify={'flex-end'}
                    $variant={variant}
                >
                    {footerSlot}
                </StyledSectionedFooterCard>
            )}
        </StyledCard>
    )
}
