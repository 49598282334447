import React from 'react'
import {Container} from '@components/ui/container/Container.tsx'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {useTranslation} from 'react-i18next'
import {PersonalInformationForm} from '@/features/profile/components/personal-information-form/PersonalInformationForm.tsx'
import {PasswordManagementForm} from '@/features/profile/components/password-management-form/PasswordManagementForm.tsx'
import {AppointmentNotificationsForm} from '@/features/profile/components/appointment-notifications-form/AppointmentNotificationsForm.tsx'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {USER_ROLES} from '@/features/dashboard/types.ts'

export const Profile: React.FC = () => {
    const {t} = useTranslation()
    const user = useAuthStore(state => state.user)
    const isAdmin = user?.role?.name === USER_ROLES.admin

    return (
        <Container fullWidth>
            <PageHero title={t('routeNames:profile')} />
            <PersonalInformationForm />
            {!isAdmin && <AppointmentNotificationsForm />}
            <PasswordManagementForm />
        </Container>
    )
}

Profile.displayName = 'Profile'
