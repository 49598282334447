import React from 'react'
import {Container} from '@components/ui/container/Container.tsx'
import {PageContent} from '@components/ui/page-content/PageContent.tsx'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {useTranslation} from 'react-i18next'
import {CheckInFiltersControls} from '@/features/check-in/components/check-in-filters-controls/CheckInFiltersControls.tsx'
import {CheckInStatsGrid} from '@/features/check-in/components/check-in-stats-grid/CheckInStatsGrid.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ActivityIcon, AlertCircleIcon} from '@components/ui/icon/Icon.tsx'
import {EmptyState} from '@components/commons/empty-state/EmptyState.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {useCheckInTable} from '@/features/check-in/services/useCheckInTable.ts'
import {CheckInTable} from '@/features/check-in/components/check-in-table/CheckInTable.tsx'
import {CheckInTableFilters} from '@/features/check-in/components/check-in-table-filter/CheckInTableFilters.tsx'

export const CheckIn: React.FC = () => {
    const {t} = useTranslation()
    const checkinTable = useCheckInTable()
    return (
        <Container fullWidth>
            <PageContent>
                <PageHero title={t('check_in:title')} ctaComponent={<CheckInFiltersControls />} />
                {checkinTable.isError ? (
                    <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
                ) : checkinTable.remappedData.length !== 0 ? (
                    <Flexbox direction={'column'} gap={6} align={'stretch'}>
                        <CheckInStatsGrid />
                        <CheckInTableFilters />
                        <CheckInTable />
                    </Flexbox>
                ) : (
                    <EmptyState
                        icon={<ActivityIcon />}
                        title={t('check_in:empty_state:title')}
                        description={t('check_in:empty_state:description')}
                    />
                )}
            </PageContent>
        </Container>
    )
}

CheckIn.displayName = 'CheckIn'
