import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'
import {
    HttpDashboardAppointmentsGroupOptions,
    httpGetAppointmentsGroup,
    HttpGetAppointmentsGroupResponse
} from '@/features/dashboard/services/dashboard.http.ts'
import {getDateFromLabel} from '@utilities/utils.ts'
import {z} from 'zod'
import {DashboardAppointmentsUrlParams} from '@/features/dashboard/types.ts'

const UseAppointmentsGroupOption = z.object({
    ...DashboardAppointmentsUrlParams.shape,
    ...HttpDashboardAppointmentsGroupOptions.omit({startDate: true, endDate: true}).shape,
    groupBy: z.enum(['location', 'status'])
})
export type UseAppointmentsGroupOption = z.infer<typeof UseAppointmentsGroupOption>

export const useGetAppointmentsGroup = (
    promiseArgs: UseAppointmentsGroupOption,
    options?: Omit<UseQueryOptions<HttpGetAppointmentsGroupResponse>, 'queryFn' | 'queryKey'>
) => {
    const {rangeDateLabels, ...rest} = promiseArgs
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.APPOINTMENTS_GROUP, promiseArgs],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetAppointmentsGroup({
                    ...rest,
                    ...getDateFromLabel(rangeDateLabels)
                }),
                responseShape: HttpGetAppointmentsGroupResponse,
                onZodError: captureException
            })
    })
}
