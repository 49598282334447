import {AvatarFallback, AvatarImage} from '@radix-ui/react-avatar'
import {AvatarRoot} from '@components/ui/avatar/style.ts'
import {getInitials} from '@utilities/helpers.ts'

interface AvatarProps {
    name: string
    imageUrl?: string
    onClick?: () => void
    $isActive?: boolean
}

export const Avatar = ({name, imageUrl, onClick, $isActive}: AvatarProps) => (
    <AvatarRoot onClick={onClick} $isActive={$isActive}>
        <AvatarImage src={imageUrl} alt={name} />
        <AvatarFallback delayMs={600}>{getInitials(name)}</AvatarFallback>
    </AvatarRoot>
)

Avatar.displayName = 'Avatar'
