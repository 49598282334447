import {FC} from 'react'
import {StyledNotificationItem, StyledUnreadDot} from '@/features/dashboard/components/notifications/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Chip} from '@components/commons/chip/Chip.tsx'
import {useGetAppointments} from '@/features/appointments/services/useGetAppointments.ts'
import {NotificationItem as Notification} from '@/features/dashboard/types.ts'
import dayjs from '@/dayjs.ts'
import {useGetContacts} from '@/features/settings/services/services-contact/useGetContacts.ts'
import {useTranslation} from 'react-i18next'
import {MarkerPin01Icon, VideoRecorderIcon} from '@components/ui/icon/Icon.tsx'
import {AppointmentStatus} from '@/features/appointments/types.ts'
import {generatePath} from 'react-router-dom'
import {routes} from '@utilities/constants/routes.tsx'
import {StyledPatientNameLink} from '@/features/dashboard/components/appointments/style.ts'

interface NotificationItemProps {
    notification: Notification
}

export const NotificationItem: FC<NotificationItemProps> = ({notification}) => {
    const {t} = useTranslation()
    const firstDayOfThisYear = new Date(new Date().getFullYear(), 0, 1)
    const lastDayOfThisYear = new Date(new Date().getFullYear(), 11, 31)
    const {remappedData: appointments} = useGetAppointments({
        fromDate: firstDayOfThisYear.toISOString(),
        toDate: lastDayOfThisYear.toISOString(),
        limit: 10000
    })
    const {data: offices} = useGetContacts()

    const appointment = appointments.find(appointment => appointment.id === notification.attributes.appointment.id)
    const appointmentDate = dayjs(appointment?.start).format('DD MMMM YYYY')
    const appointmentTimeStart = dayjs.utc(appointment?.start).format('HH:mm')
    const appointmentTimeEnd = dayjs.utc(appointment?.end).format('HH:mm')
    const office = offices?.data.find(office => office.id === appointment?.officeId)

    return (
        <>
            <StyledNotificationItem>
                <StyledUnreadDot />
                <Flexbox gap={2} direction={'column'}>
                    <Flexbox justify={'space-between'} fullWidth align={'baseline'}>
                        <StyledPatientNameLink
                            size={'xl'}
                            to={generatePath(routes.PATIENT_DETAILS.handle.path, {
                                patientId: appointment?.patientId ?? 0
                            })}
                            variant={'tertiaryColor'}
                            className={'patient-name'}
                        >
                            {appointment?.patient?.firstName} {appointment?.patient?.lastName}
                        </StyledPatientNameLink>
                        <Chip
                            label={t(`commons:appointment_state:${appointment?.status}`)}
                            color={
                                appointment?.status === AppointmentStatus.enum.completed
                                    ? 'success'
                                    : appointment?.status === AppointmentStatus.enum.missed ||
                                        appointment?.status === AppointmentStatus.enum.cancelled
                                      ? 'danger'
                                      : 'primary'
                            }
                            variant={'outlined'}
                        />
                    </Flexbox>

                    <Flexbox className={'appointment-info'}>
                        <p>
                            {appointmentDate} - {appointmentTimeStart}/{appointmentTimeEnd} -{' '}
                        </p>
                        <Flexbox align={'center'} justify={'center'} gap={1}>
                            <p>{office?.name}</p>
                            {appointment?.isRemote ? <VideoRecorderIcon size={20} /> : <MarkerPin01Icon size={20} />}
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
            </StyledNotificationItem>
        </>
    )
}
