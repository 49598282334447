import {z} from 'zod'
import {Location} from '@/features/patients/types.ts'
import {ContactObj} from '@/features/settings/SettingsFormModal.ts'

export const UserType = z.enum(['patient', 'caregiver', 'therapist', 'admin', 'operator'])
export type UserType = z.infer<typeof UserType>

//TODO: centralize user role name

export const UserRole = z.object({
    id: z.number(),
    name: z.enum(['patient', 'caregiver', 'therapist', 'admin', 'operator'])
})
export type UserRole = z.infer<typeof UserRole>

export const Emotion = z.object({
    id: z.number(),
    name: z.enum(['really_bad', 'bad', 'fine', 'good', 'really_good']),
    index: z.number()
})
export type Emotion = z.infer<typeof Emotion>

export const Notification = z.object({
    id: z.number(),
    name: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
    deletedAt: z.string().nullable()
})

export type Notification = z.infer<typeof Notification>

export const UserAppointment = z.object({
    id: z.number(),
    status: z.enum(['missed', 'completed', 'booked', 'cancelled']),
    start: z.string(),
    end: z.string(),
    officeId: z.number().nullable(),
    therapistId: z.number().nullable(),
    patientId: z.number().nullable(),
    linkVideoCall: z.string().url().nullable(),
    isRemote: z.boolean()
})

export type UserAppointment = z.infer<typeof UserAppointment>

export const User = z.object({
    id: z.number(),
    title: z.string().nullish(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    gender: z.enum(['male', 'female']).nullish(),
    birthCountry: Location.nullish(),
    birthState: Location.nullish(),
    birthCity: Location.nullish(),
    caregiverId: z.number().nullish(),
    occupation: z.string().nullish(),
    phoneNumber: z.string().nullish(),
    phoneNumberVerifiedAt: z.string().nullish(),
    taxCode: z.string().nullish(),
    email: z.string().email(),
    emailVerifiedAt: z.string().nullish(),
    birthDate: z.string().nullish(),
    isActive: z.boolean(),
    roleId: z.number().nullish(),
    createdAt: z.string(),
    updatedAt: z.string(),
    role: UserRole.nullish(),
    emotion: Emotion.nullish(),
    isCurrentlyInTherapy: z.boolean().nullish(),
    totalBets: z.number().nullish(),
    selfEvaluationId: z.number().nullish(),
    notificationTypes: z.array(Notification).nullish(),
    remindOffset: z.number().nullish(),
    onlineAppointmentEnabled: z.boolean().nullish(),
    appointment: UserAppointment.nullish(),
    offices: z.array(ContactObj).nullish(),
    caregiver: z
        .object({
            id: z.number(),
            firstName: z.string(),
            lastName: z.string(),
            email: z.string(),
            phoneNumber: z.string().nullable(),
            gender: z.string().nullish(),
            caregiverRelationshipDegree: z.string().nullable()
        })
        .nullish()
})
export type User = z.infer<typeof User>

export const UserSmall = User.pick({
    id: true,
    firstName: true,
    lastName: true,
    birthDate: true,
    gender: true,
    taxCode: true,
    phoneNumber: true,
    email: true,
    type: true,
    caregiverId: true,
    isActive: true,
    selfEvaluationId: true,
    createdAt: true,
    notificationTypes: true,
    remindOffset: true
})
export type UserSmall = z.infer<typeof UserSmall>

export const UserBackOfficeSmall = User.pick({
    id: true,
    title: true,
    firstName: true,
    lastName: true,
    birthDate: true,
    gender: true,
    type: true,
    caregiverId: true,
    occupation: true,
    phoneNumber: true,
    email: true,
    isActive: true,
    roleId: true,
    caregiver: true
})
export type UserBackOfficeSmall = z.infer<typeof UserBackOfficeSmall>

const AuthState = z.object({
    user: User.nullable(),
    accessToken: z.string(),
    refreshToken: z.string()
})
export type AuthState = z.infer<typeof AuthState>
