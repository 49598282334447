import {SelectProps} from '@/components/commons/select/Select'
import {useTranslation} from 'react-i18next'
import {useGetCountries} from '@/features/patient-form/services/useGetCountries'
import {LocationSelector} from '@/features/patient-form/components/location-selector/LocationSelector'

interface CountrySelectorProps {
    value: SelectProps['value']
    onChange: SelectProps['onChange']
    errorMessage?: string
    className?: string
}

export const CountrySelector = ({value, onChange, errorMessage, className}: CountrySelectorProps) => {
    const {t} = useTranslation()
    const {remappedData: countries, ...countriesQuery} = useGetCountries()

    return (
        <LocationSelector
            className={className}
            options={countries}
            onSearch={countriesQuery.onSearch}
            fetchNextPage={countriesQuery.fetchNextPage}
            isLoading={countriesQuery.isLoading || countriesQuery.isFetchingNextPage}
            label={`${t('patients:create_patient:form:labels:birth_country')}*`}
            placeholder={t('patients:create_patient:form:placeholder:birth_country')}
            value={value ?? null}
            onChange={onChange}
            errorMessage={errorMessage && t(errorMessage)}
            noOptionsMessage={t('patients:create_patient:form:no_countries_options')}
        />
    )
}
