import {SelectItemProps, SelectLabelProps, SelectPopoverProps} from '@ariakit/react'
import {StyledSelectItem, StyledSelectLabel, StyledSelectPopover} from '@/components/ui/select-atoms/style'

export const SelectPopover = (props: SelectPopoverProps) => <StyledSelectPopover gutter={4} sameWidth {...props} />
SelectPopover.displayName = 'SelectPopover'

export const SelectItem = (props: SelectItemProps) => <StyledSelectItem {...props} />
SelectItem.displayName = 'SelectItem'

export const SelectLabel = (props: SelectLabelProps) => <StyledSelectLabel {...props} />
SelectLabel.displayName = 'SelectLabel'
