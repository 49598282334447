import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledCalendarNavigation = styled(Flexbox)`
    ${({theme: {palette, typography}}) => css`
        & .container-navigation {
            background-color: ${palette.neutral.white};
            border: 1px solid ${palette.neutral[200]};
            border-radius: 12px;
        }

        & .button-navigation-left {
            border-radius: 12px 0 0 12px;
        }

        & .button-navigation-right {
            border-radius: 0;
        }

        & .button-navigation-left,
        & .button-navigation-right {
            outline: none;
            border-right: 1px solid ${palette.neutral[200]};
            box-shadow: none;
        }

        & .current-week {
            cursor: default;
            font-weight: 600;
            ${typography.textSm}
            color: ${palette.neutral[900]};
            width: 210px;
            text-transform: capitalize;
        }
    `}
`
