import {Partitions} from '@/types.ts'
import {ChartData} from '@/features/dashboard/types.ts'
import {round} from '@utilities/helpers.ts'
import {
    HttpGetAppointmentsTrendResponse,
    HttpGetUserTrendResponse
} from '@/features/dashboard/services/dashboard.http.ts'
import dayjs from '@/dayjs.ts'
import i18next from 'i18next'

export const adaptDataForChart = (analytics: Partitions[], color: string[], increments?: Partitions[]): ChartData[] =>
    analytics.map((value, index) => ({
        name: i18next.t(`dashboard:${value.label}`),
        total: value.total,
        percentage: value.percentage && round(value.percentage),
        fill: color[index],
        incrementsPercentage: increments ? increments[index].percentage : null
    }))

interface DataForUserTrendChart {
    name: string
    registeredUsers: number
    currentlyInTherapyUsers: number
}

export const adaptDataForUserTrendChart = (data: HttpGetUserTrendResponse): DataForUserTrendChart[] =>
    data.map(value => ({
        name: dayjs(value.label).format('ll'),
        registeredUsers: value.registeredUsers,
        currentlyInTherapyUsers: value.currentlyInTherapyUsers
    }))

interface DataForAppointmentTrendChart {
    name: string
    totalBooked: number
    totalCompleted: number
}

export const adaptDataForAppointmentTrendChart = (
    data: HttpGetAppointmentsTrendResponse
): DataForAppointmentTrendChart[] =>
    data.map(value => ({
        name: dayjs(value.label).format('ll'),
        ...value
    }))
