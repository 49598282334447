import styled, {css, DefaultTheme} from 'styled-components'
import {Chip} from '@components/commons/chip/Chip.tsx'

export type StyledAppointmentStateProps = {
    $appointmentStatusVariant: 'missed' | 'completed' | 'booked' | 'deleted' | 'cancelled'
}

export const makeAppointmentStateVariantStyle = (theme: DefaultTheme) =>
    ({
        missed: css`
            border-color: ${theme.palette.danger[600]};
            color: ${theme.palette.danger[700]};
        `,
        completed: css`
            border-color: ${theme.palette.success[600]};
            color: ${theme.palette.success[600]};
        `,
        booked: css`
            border-color: ${theme.palette.primary[700]};
            color: ${theme.palette.primary[700]};
        `,
        deleted: css`
            border-color: ${theme.palette.warning[600]};
            color: ${theme.palette.warning[600]};
        `,
        /*TODO: check if there is both cancelled and deleted*/
        cancelled: css`
            border-color: ${theme.palette.danger[600]};
            color: ${theme.palette.danger[700]};
        `
    }) as const satisfies Record<
        NonNullable<StyledAppointmentStateProps['$appointmentStatusVariant']>,
        ReturnType<typeof css>
    >

export const StyledAppointmentStatus = styled(Chip)<StyledAppointmentStateProps>(
    ({theme, $appointmentStatusVariant}) => css`
        ${makeAppointmentStateVariantStyle(theme)[$appointmentStatusVariant]};
    `
)
