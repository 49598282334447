import {ButtonHTMLAttributes, forwardRef} from 'react'
import {StyledButton} from '@components/ui/button/style.ts'

export type ButtonStyleProps = {
    fullWidth?: boolean
    shape?: 'rectangle' | 'square'
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    shouldWrap?: boolean
    variant?:
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'primaryDanger'
        | 'secondaryDanger'
        | 'tertiaryDanger'
        | 'secondaryColor'
        | 'tertiaryColor'
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & ButtonStyleProps

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({fullWidth, shape, size, variant, shouldWrap, ...props}, ref) => (
        <StyledButton
            ref={ref}
            $fullWidth={fullWidth}
            $shape={shape}
            $size={size}
            $variant={variant}
            $shouldWrap={shouldWrap}
            {...props}
        />
    )
)
Button.displayName = 'Button'
