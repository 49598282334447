import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledHeaderContainer = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: grid;
        grid-template-columns: 280px 1fr;
        width: 100%;
        padding: ${spacing * 2.5}px;
        background: ${palette.neutral.white};
        border: 1px solid ${palette.neutral[200]};
        border-radius: ${spacing * 3}px ${spacing * 3}px 0 0;
    `}
`

export const StyledWeekDaysWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        justify-items: center;
        flex-grow: 1;
        gap: ${spacing * 2.5}px;
    `}
`

export const StyledWeekDays = styled(Flexbox)`
    ${({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 2}px ${spacing * 2.5}px;
        cursor: default;
        border-radius: ${spacing * 10}px;

        ${typography.textSm}

        & span {
            color: ${palette.neutral[500]};
        }
    `}
`

type StyledNumberOfTheWeekProps = {
    $isToday: boolean
}

export const StyledNumberOfTheWeek = styled(Flexbox)<StyledNumberOfTheWeekProps>`
    ${({theme, $isToday}) => css`
        background-color: ${$isToday ? theme.palette.primary[600] : 'transparent'};
        color: ${$isToday ? theme.palette.neutral.white : theme.palette.neutral[900]};
        border-radius: 50%;
        width: 25px;
        height: 20px;
    `}
`

export const StyledFiltersContent = styled(Flexbox)`
    ${({theme}) => css`
        padding: ${theme.spacing * 2.5}px;
        min-width: 350px;

        & .select {
            width: 100%;
        }
    `}
`
