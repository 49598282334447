import styled, {css} from 'styled-components'

export const StyledPrimaryData = styled.h1`
    ${({theme}) => css`
        ${theme.typography.displaySm};
        color: ${theme.palette.neutral[900]};
    `}
`
export const StyledAppointmentsProgressLabel = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        color: ${palette.neutral[700]};
        min-width: 70px;
    `
)

export const StyledAppointmentsTypologyProgressLabel = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        color: ${palette.neutral[700]};
        font-weight: 500;
        width: 80px;
    `
)
