import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'
import {httpGetEmotionsGroup, HttpGetEmotionsGroupResponse} from '@/features/dashboard/services/dashboard.http.ts'
import {DashboardUrlParamsExtended} from '@/features/dashboard/types.ts'
import {getDateFromLabel} from '@utilities/utils.ts'

export const useGetEmotionsGroup = (
    promiseArgs: DashboardUrlParamsExtended,
    options?: Omit<UseQueryOptions<HttpGetEmotionsGroupResponse>, 'queryFn' | 'queryKey'>
) => {
    const {rangeDateLabels, ...rest} = promiseArgs
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.EMOTIONS_GROUP, promiseArgs],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetEmotionsGroup({
                    ...rest,
                    ...getDateFromLabel(rangeDateLabels)
                }),
                responseShape: HttpGetEmotionsGroupResponse,
                onZodError: captureException
            })
    })
}
