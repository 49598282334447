import axios from '@/axiosInstance.ts'
import {HttpCreateShiftPayload, Shift} from '@/features/appointments/types.ts'

export const httpGetShiftsIndex = () => {
    return axios.get('/shifts')
}

export const httpGetShiftDetails = (shiftID: number | null) => {
    return axios.get(`/shifts/${shiftID}`)
}

export const httpCreateShift = (params: HttpCreateShiftPayload) => {
    return axios.post(`/shifts`, params)
}

export const httpEditShift = (params: Shift) => {
    return axios.patch(`/shifts/${params.id}`, params)
}

export const httpDeleteShift = (shiftID: number) => {
    return axios.delete(`/shifts/${shiftID}`)
}
