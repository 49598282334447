import {useInputSearch} from '@/hooks/useInputSearch'
import {useInfiniteQuery, UseInfiniteQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'
import {
    HttpGetLocationsResponse,
    httpGetStates,
    HttpGetStatesParams
} from '@/features/patient-form/services/patientForm.http'

export const useGetStates = (
    promiseArgs: Omit<HttpGetStatesParams, 'page' | 'limit' | 'responseType'>,
    options?: Pick<UseInfiniteQueryOptions<HttpGetLocationsResponse>, 'enabled'>
) => {
    const {searchValue, searchError, onSearch, onResetSearch} = useInputSearch()
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.PATIENTS_STATES, {...promiseArgs, search: searchValue}],
        queryFn: ({pageParam = 1}) =>
            parseAxiosPromise({
                axiosPromise: httpGetStates({
                    ...promiseArgs,
                    search: searchValue,
                    page: pageParam,
                    responseType: 'regular',
                    limit: 30
                }),
                responseShape: HttpGetLocationsResponse,
                onZodError: captureException
            }),
        ...options,
        initialPageParam: 1,
        getNextPageParam: ({meta}) => (meta.current_page < meta.last_page ? meta.current_page + 1 : null)
    })

    const remappedData = query.data?.pages?.[0]?.data?.length ? query.data.pages.flatMap(page => page.data) : []
    return {
        ...query,
        remappedData,
        searchValue,
        searchError,
        onSearch,
        onResetSearch
    }
}
