import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledSurveyTableFooterWrapper = styled(Flexbox)(
    ({theme: {spacing, typography}}) => css`
        padding: ${spacing * 4}px;
        & h4 {
            ${typography.textSm};
            font-weight: 500;
        }
        & p {
            ${typography.textSm};
            font-weight: 400;
        }
    `
)

export const StyledSurveyTableWrapper = styled.div`
    height: 400px;
    width: 100%;
`
