import {ChartData} from '@/features/dashboard/types.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {StyledItemDot, StyledLegendItemContainer} from '@/features/dashboard/components/chart-legend/style.ts'
import {Chip} from '@components/commons/chip/Chip.tsx'
import {ArrowDownIcon, ArrowUpIcon} from '@components/ui/icon/Icon.tsx'

export const ChartLegend = ({data, showTotal}: {data: ChartData[]; showTotal?: boolean}) => {
    return (
        <Flexbox direction={'column'} gap={1}>
            {data.map((item, index) => (
                <Flexbox fullWidth gap={6} key={index}>
                    <StyledLegendItemContainer gap={2}>
                        <StyledItemDot $color={item.fill} />
                        <Flexbox direction={'column'} gap={1}>
                            <span>{item.name}</span>
                            <Flexbox gap={1}>
                                <p>{item.percentage ? `${item.percentage}%` : item.total}</p>
                                {showTotal && <span>{`(${item.total})`}</span>}
                            </Flexbox>
                        </Flexbox>
                    </StyledLegendItemContainer>
                    {item.incrementsPercentage && (
                        <Chip
                            label={
                                <Flexbox gap={0.5} align={'center'}>
                                    {item.incrementsPercentage > 0 ? (
                                        <ArrowUpIcon size={12} />
                                    ) : (
                                        <ArrowDownIcon size={12} />
                                    )}
                                    {`${item.incrementsPercentage}%`}
                                </Flexbox>
                            }
                            variant={'filled'}
                            color={item.incrementsPercentage > 0 ? 'danger' : 'success'}
                        />
                    )}
                </Flexbox>
            ))}
        </Flexbox>
    )
}
