import {ReactNode} from 'react'
import {StyledHeadingSection} from '@/components/commons/heading-section/style'

export const HeadingSection = ({title, endSlot}: {title: string; endSlot: ReactNode}) => {
    return (
        <StyledHeadingSection justify="space-between" align="center" fullWidth>
            <h3>{title}</h3>
            {endSlot}
        </StyledHeadingSection>
    )
}
