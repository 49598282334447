import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'
import {DefaultTheme} from 'styled-components/dist/types'

export const StyledSelfAssessmentCardsStats = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: ${spacing * 6}px;
        width: 100%;
    `
)

export const StyledSelfAssessmentStatsCollectedAmount = styled.div(
    ({theme: {typography}}) => css`
        ${typography.displaySm}
    `
)

export const makeStatsPercentStyle = ({palette}: DefaultTheme) =>
    ({
        over: css`
            color: ${palette.success[600]};
        `,
        under: css`
            color: ${palette.warning[600]};
        `,
        equal: css`
            color: ${palette.neutral[400]};
        `
    }) as const satisfies Record<NonNullable<'over' | 'under' | 'equal'>, ReturnType<typeof css>>

export const StyledSelfAssessmentStatsPercentResult = styled(Flexbox)<{$variant: 'over' | 'under' | 'equal'}>(
    ({theme, $variant}) => css`
        ${theme.typography.textSm};
        ${makeStatsPercentStyle(theme)[$variant]};
    `
)

export const StyledSelfAssessmentCompareLabel = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        color: ${palette.neutral['600']};
    `
)
export const StyledProvenienceContainer = styled(Flexbox)(
    ({theme: {typography}}) => css`
        & h3 {
            ${typography.displaySm};
        }
        & p {
            ${typography.textSm};
        }
    `
)
