import {InputText} from '@/components/commons/input-text/InputText'
import {usePatientsTable} from '../../services/usePatientsTable'
import {useTranslation} from 'react-i18next'
import {FilterLinesIcon, SearchLgIcon} from '@/components/ui/icon/Icon'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Popover} from '@/components/ui/popover/Popover'
import {SelectProvider} from '@ariakit/react'
import {SelectItem, SelectPopover} from '@/components/ui/select-atoms/SelectAtoms'
import {SelectTrigger} from '@/components/ui/select-atoms/SelectTrigger'
import {
    StyledFiltersContent,
    StyledPatientsTableFiltersTrigger
} from '@/features/patients/components/patients-table-filters/style'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {PATIENTS_FILTERS_MODEL, PatientFiltersValidationSchema} from './PatientsFIltersModel'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {InputStatusIcon} from '@/components/ui/input-status-icon/InputStatusIcon'
import {Button} from '@/components/ui/button/Button'
import {PatientsFilters} from '@/features/patients/types'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {
    AGE_SELECT_OPTIONS,
    GENRE_SELECT_OPTIONS,
    MODE_SELECT_OPTIONS,
    PLACE_SELECT_OPTIONS
} from '@/features/patients/utils'
import {Label} from '@/components/ui/label/Label'
import {useMemo, useState} from 'react'
import {ObjectKeys} from '@/utilities/helpers'

export const PatientsTableFilters = () => {
    const {t} = useTranslation()
    const {searchValue, onSearch} = usePatientsTable()
    const {searchParams, setSearchParams} = useParsedSearchParams([], PatientsFilters)
    const [popoverVisibility, setPopoverVisibility] = useState(false)

    const {
        handleSubmit,
        control,
        reset,
        formState: {errors, touchedFields}
    } = useForm<PatientFiltersValidationSchema>({
        mode: 'onSubmit',
        resolver: zodResolver(PatientFiltersValidationSchema),
        defaultValues: {
            ...searchParams
        }
    })

    const onSubmit: SubmitHandler<PatientFiltersValidationSchema> = filters => {
        setSearchParams({
            age: filters.age || undefined,
            gender: filters.gender || undefined,
            appointmentModality: filters.appointmentModality || undefined,
            place: filters.place || undefined
        })

        setPopoverVisibility(false)
    }

    const onClear = () => {
        reset({age: '', gender: '', appointmentModality: '', place: ''})
        setSearchParams({
            age: undefined,
            gender: undefined,
            appointmentModality: undefined,
            place: undefined
        })
    }

    const appliedFiltersCount = useMemo(
        () => ObjectKeys(searchParams).filter(param => !!param && param !== 'name').length,
        [searchParams]
    )

    return (
        <Flexbox align="center" justify="space-between" fullWidth>
            <InputText
                type="text"
                width="auto"
                placeholder={t('patients:filters:search:placeholder')}
                typeIcon={<SearchLgIcon size={20} />}
                onChange={e => onSearch(e.currentTarget.value)}
                defaultValue={searchValue}
            />

            <Popover
                rootProps={{
                    open: popoverVisibility,
                    onOpenChange: value => setPopoverVisibility(value)
                }}
                triggerProps={{asChild: true}}
                contentProps={{align: 'end'}}
                trigger={
                    <StyledPatientsTableFiltersTrigger variant="secondaryColor" $hasFilters={!!appliedFiltersCount}>
                        <FilterLinesIcon />
                        <span>{t('commons:filters')}</span>
                        {!!appliedFiltersCount && <b>({appliedFiltersCount})</b>}
                    </StyledPatientsTableFiltersTrigger>
                }
            >
                <StyledFiltersContent render="form" direction="column" gap={4} onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        render={({field: {onChange, value}}) => (
                            <SelectProvider
                                defaultValue={PATIENTS_FILTERS_MODEL.age.defaultValue}
                                value={value ?? undefined}
                                setValue={value => onChange(value)}
                            >
                                <Flexbox gap={1.5} direction="column" fullWidth>
                                    <Label>{t(PATIENTS_FILTERS_MODEL.age.label)}</Label>
                                    <Flexbox direction="column" fullWidth>
                                        <SelectTrigger
                                            size="lg"
                                            variant="secondary"
                                            placeholder={t(PATIENTS_FILTERS_MODEL.age.placeholder)}
                                            fullWidth
                                        >
                                            {value ? t(`patients:filters:age:${value}`) : null}
                                        </SelectTrigger>
                                        <InputStatusIcon touched={touchedFields.age} hasError={!!errors.age?.message} />
                                    </Flexbox>
                                    <InputHelpText error={errors.age?.message} />
                                </Flexbox>
                                <SelectPopover gutter={4} sameWidth>
                                    {AGE_SELECT_OPTIONS.map(value => (
                                        <SelectItem key={value} value={value}>
                                            {t(`patients:filters:age:${value}`)}
                                        </SelectItem>
                                    ))}
                                </SelectPopover>
                            </SelectProvider>
                        )}
                        control={control}
                        name={PATIENTS_FILTERS_MODEL.age.name}
                    />

                    <Controller
                        render={({field: {onChange, value}}) => (
                            <SelectProvider
                                defaultValue={PATIENTS_FILTERS_MODEL.gender.defaultValue}
                                value={value ?? undefined}
                                setValue={value => onChange(value)}
                            >
                                <Flexbox gap={1.5} direction="column" fullWidth>
                                    <Label>{t(PATIENTS_FILTERS_MODEL.gender.label)}</Label>
                                    <Flexbox direction="column" fullWidth>
                                        <SelectTrigger
                                            size="lg"
                                            variant="secondary"
                                            placeholder={t(PATIENTS_FILTERS_MODEL.gender.placeholder)}
                                            fullWidth
                                        >
                                            {value ? t(`patients:filters:genre:${value}`) : null}
                                        </SelectTrigger>
                                        <InputStatusIcon
                                            touched={touchedFields.gender}
                                            hasError={!!errors.gender?.message}
                                        />
                                    </Flexbox>
                                    <InputHelpText error={errors.gender?.message} />
                                </Flexbox>
                                <SelectPopover gutter={4} sameWidth>
                                    {GENRE_SELECT_OPTIONS.map(value => (
                                        <SelectItem key={value} value={value}>
                                            {t(`patients:filters:genre:${value}`)}
                                        </SelectItem>
                                    ))}
                                </SelectPopover>
                            </SelectProvider>
                        )}
                        control={control}
                        name={PATIENTS_FILTERS_MODEL.gender.name}
                    />

                    <Controller
                        render={({field: {onChange, value}}) => (
                            <SelectProvider
                                defaultValue={PATIENTS_FILTERS_MODEL.place.defaultValue}
                                value={value ?? undefined}
                                setValue={value => onChange(value)}
                            >
                                <Flexbox gap={1.5} direction="column" fullWidth>
                                    <Label>{t(PATIENTS_FILTERS_MODEL.place.label)}</Label>
                                    <Flexbox direction="column" fullWidth>
                                        <SelectTrigger
                                            size="lg"
                                            variant="secondary"
                                            placeholder={t(PATIENTS_FILTERS_MODEL.place.placeholder)}
                                            fullWidth
                                        >
                                            {value ? t(`patients:filters:place:${value}`) : null}
                                        </SelectTrigger>
                                        <InputStatusIcon
                                            touched={touchedFields.place}
                                            hasError={!!errors.place?.message}
                                        />
                                    </Flexbox>
                                    <InputHelpText error={errors.place?.message} />
                                </Flexbox>
                                <SelectPopover gutter={4} sameWidth>
                                    {PLACE_SELECT_OPTIONS.map(value => (
                                        <SelectItem key={value} value={value}>
                                            {t(`patients:filters:place:${value}`)}
                                        </SelectItem>
                                    ))}
                                </SelectPopover>
                            </SelectProvider>
                        )}
                        control={control}
                        name={PATIENTS_FILTERS_MODEL.place.name}
                    />

                    <Controller
                        render={({field: {onChange, value}}) => (
                            <SelectProvider
                                defaultValue={PATIENTS_FILTERS_MODEL.appointmentModality.defaultValue}
                                value={value ?? undefined}
                                setValue={value => onChange(value)}
                            >
                                <Flexbox gap={1.5} direction="column" fullWidth>
                                    <Label>{t(PATIENTS_FILTERS_MODEL.appointmentModality.label)}</Label>
                                    <Flexbox direction="column" fullWidth>
                                        <SelectTrigger
                                            size="lg"
                                            variant="secondary"
                                            placeholder={t(PATIENTS_FILTERS_MODEL.appointmentModality.placeholder)}
                                            fullWidth
                                        >
                                            {value ? t(`patients:filters:mode:${value}`) : null}
                                        </SelectTrigger>
                                        <InputStatusIcon
                                            touched={touchedFields.appointmentModality}
                                            hasError={!!errors.appointmentModality?.message}
                                        />
                                    </Flexbox>
                                    <InputHelpText error={errors.appointmentModality?.message} />
                                </Flexbox>
                                <SelectPopover gutter={4} sameWidth>
                                    {MODE_SELECT_OPTIONS.map(value => (
                                        <SelectItem key={value} value={value}>
                                            {t(`patients:filters:mode:${value}`)}
                                        </SelectItem>
                                    ))}
                                </SelectPopover>
                            </SelectProvider>
                        )}
                        control={control}
                        name={PATIENTS_FILTERS_MODEL.appointmentModality.name}
                    />

                    <Flexbox fullWidth justify="end" gap={3}>
                        <Button type="button" variant="secondary" onClick={onClear}>
                            {t('commons:cancel')}
                        </Button>
                        <Button type="submit">{t('commons:apply')}</Button>
                    </Flexbox>
                </StyledFiltersContent>
            </Popover>
        </Flexbox>
    )
}
