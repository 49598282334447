import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'

export const StyledPatientNameLink = styled(ButtonLink)`
    padding: 0;
    justify-content: left;
`

export const StyledPatientName = styled.p(
    ({theme: {palette, typography}}) => css`
        color: ${palette.primary[600]};
        font-weight: 600;
        ${typography.textLg}
    `
)

export const StyledAppointmentsTimeAndLocation = styled.div`
    ${({theme: {palette, typography, spacing}}) => css`
        color: ${palette.neutral[900]};
        font-weight: 500;
        ${typography.textMd};

        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;

        & svg {
            fill: ${palette.neutral[500]};
        }
    `}
`

export const StyledNextAppointmentCardTitle = styled.div(
    ({theme}) => css`
        ${theme.typography.textMd};
        font-weight: 600;
        color: ${theme.palette.neutral[900]};
    `
)
export const StyledNextAppointment = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, auto);
        width: 100%;
        column-gap: ${spacing * 6}px;
        row-gap: ${spacing * 2}px;
        align-items: center;

        grid-template-areas:
            'name status'
            'time .'
            'titleCard .'
            'card amount';

        .patient-name {
            grid-area: name;
        }

        .status {
            grid-area: status;
        }

        .time {
            grid-area: time;
        }

        .titleCard {
            grid-area: titleCard;
        }

        .last-week-card {
            grid-area: card;
            height: 100%;
        }

        .amount-card {
            grid-area: amount;
            align-self: end;
        }
    `}
`

export const StyledLastWeekTitle = styled.span`
    ${({theme: {typography, spacing}}) => css`
        ${typography.textSm};
        font-weight: 600;
        margin-bottom: ${spacing * 2}px;
    `}
`

export const StyledHeaderAppointments = styled.div`
    ${({theme: {typography, palette}}) => css`
        color: ${palette.neutral[900]};

        & p {
            ${typography.textLg};
            font-weight: 600;
        }

        & span {
            ${typography.textMd};
            font-weight: 500;
        }
    `}
`

export const StyledContainerNextAppointment = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 6}px ${spacing * 8}px;
    `}
`

export const StyledUpcomingAppointmentsTableWrapper = styled(Flexbox)`
    height: 235px;
    width: 100%;
`
export const StyledOperatorUpcomingAppointmentsTableWrapper = styled(Flexbox)`
    height: 400px;
    width: 100%;
`
export const StyledUpcomingAppointmentsTableDataCell = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 5}px 0;
    `}
`
export const StyledPassCode = styled.span`
    ${({theme: {typography}}) => css`
        ${typography.textXs};
    `}
`

export const StyledBetsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 8px;
    width: 100%;
`
