import styled, {css} from 'styled-components'

type StyledTabItemProps = {
    $isActive: boolean
}

type StyledTabSectionWrapperProps = {
    $maxWidth?: number
}
export const StyledTabSectionWrapper = styled.div<StyledTabSectionWrapperProps>`
    ${({theme, $maxWidth}) => css`
        display: flex;
        gap: ${theme.spacing * 3}px;
        padding: ${theme.spacing * 2}px 0;
        border-bottom: 1px solid ${theme.palette.neutral['200']};
        ${$maxWidth &&
        css`
            max-width: ${$maxWidth}px;
            overflow: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        `}
    `}
`
export const StyledTabItem = styled.div<StyledTabItemProps>`
    ${({theme, $isActive}) => css`
        ${theme.typography.textSm};
        white-space: nowrap;
        font-weight: 600;
        color: ${theme.palette.neutral['500']};
        cursor: pointer;

        ${$isActive &&
        css`
            color: ${theme.palette.primary['600']};
            text-decoration: underline;
            text-underline-offset: 12px;
            text-decoration-thickness: 2px;
        `}

        ${theme.mediaQueries.l} {
            ${theme.typography.textLg};
        }
    `}
`
