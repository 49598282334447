import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance'
import {captureException} from '@sentry/react'
import {Appointment} from '@/features/appointments/types.ts'
import {httpGetAppointmentDetails} from '@/features/appointments/services/appointments.http.ts'

export const useGetAppointmentDetails = (appointmentID: number | null) => {
    return useQuery({
        enabled: !!appointmentID,
        queryKey: [QUERY_KEYS.APPOINTMENT_DETAILS, appointmentID],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetAppointmentDetails(appointmentID),
                responseShape: Appointment,
                onZodError: captureException
            })
    })
}
