import {StyledIconContainer} from '@components/ui/icon-container/style.ts'
import {FC, ReactNode} from 'react'

export interface IconContainerProps {
    children: ReactNode
    className?: ''
    size?: 'md' | 'lg'
    type?: 'neutral' | 'danger' | 'success' | 'warning'
    radiusFull?: boolean
}
export const IconContainer: FC<IconContainerProps> = ({
    children,
    className,
    size = 'md',
    type = 'neutral',
    radiusFull = false
}) => {
    return (
        <StyledIconContainer className={className} $radiusFull={radiusFull} $size={size} $type={type}>
            {children}
        </StyledIconContainer>
    )
}

IconContainer.displayName = 'IconContainer'
