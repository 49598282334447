import {forwardRef, InputHTMLAttributes, ReactNode} from 'react'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {StyledRadio, StyledRadioInputWrapper} from '@components/commons/radio/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
    label: ReactNode
    labelPosition?: 'left' | 'right'
    errorMessage?: string
    helpText?: string
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
    ({label, labelPosition = 'right', helpText, ...rest}, ref) => {
        return (
            <Flexbox direction="column" gap={2}>
                <StyledRadioInputWrapper>
                    <StyledRadio labelPosition={labelPosition} type="radio" ref={ref} {...rest} />
                    <p>{label}</p>
                </StyledRadioInputWrapper>
                <InputHelpText helpText={helpText} />
            </Flexbox>
        )
    }
)

Radio.displayName = 'Radio'
