import {useGetCheckInDetails} from '@/features/check-in-details/services/useGetCheckInDetails.ts'
import {FC} from 'react'
import {StyledMoodSectionGrid} from '@/features/check-in-details/components/mood-section/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'
import {formatDate} from '@/dayjs.ts'
import {MoneyGambledSectionSkeleton} from '@/features/check-in-details/components/money-gambled-section/MoneyGambledSectionSkeleton.tsx'
import {
    StyledMoneyGambledSectionGrid,
    StyledMoneyIconWrapper
} from '@/features/check-in-details/components/money-gambled-section/style.ts'
import {formatCurrency} from '@utilities/helpers.ts'

interface MoneyGambledSectionProps {
    checkInId: number
}
export const MoneyGambledSection: FC<MoneyGambledSectionProps> = ({checkInId}) => {
    const {t} = useTranslation()
    const checkInDetailsQuery = useGetCheckInDetails({checkInId: checkInId})

    return (
        <>
            {checkInDetailsQuery.isLoading ? (
                <MoneyGambledSectionSkeleton />
            ) : checkInDetailsQuery.data?.recorded_money_gambled ? (
                checkInDetailsQuery.data?.recorded_money_gambled.map(data => (
                    <StyledMoneyGambledSectionGrid>
                        <StyledMoneyIconWrapper>
                            <p>{formatCurrency(data.money_gambled, 'EUR')}</p>
                        </StyledMoneyIconWrapper>
                        <Flexbox direction={'column'} gap={2}>
                            <Flexbox gap={2}>
                                <h4>{t('check_in_details:compilation_date')}</h4>
                                <p>{formatDate(data.created_at, 'D MMM, YYYY - HH:mm')}</p>
                            </Flexbox>
                        </Flexbox>
                    </StyledMoneyGambledSectionGrid>
                ))
            ) : (
                <StyledMoodSectionGrid>
                    <StyledMoneyIconWrapper>
                        <span>{formatCurrency(0, 'EUR')}</span>
                    </StyledMoneyIconWrapper>
                    <Flexbox direction={'column'} gap={2}>
                        <Flexbox gap={2}>
                            <h4>{t('check_in_details:compilation_date')}</h4>
                            <p>-</p>
                        </Flexbox>
                    </Flexbox>
                </StyledMoodSectionGrid>
            )}
        </>
    )
}
