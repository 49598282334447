import {FC} from 'react'
import {
    StyledMoodContainer,
    StyledMoodPrimaryWrapper,
    StyledMoodSecondaryWrapper
} from '@components/commons/mood-icon/style.ts'
import {FaceFrownIcon, FaceHappyIcon, FaceNeutralIcon, FaceSadIcon, FaceSmileIcon} from '@components/ui/icon/Icon.tsx'

interface MoodIconProps {
    variant: 'really_bad' | 'bad' | 'fine' | 'good' | 'really_good'
    label?: string
    hasBackground?: boolean
}
export const moodRecord = {
    really_bad: {
        icon: <FaceSadIcon className={'custom-icon'} size={32} />
    },
    bad: {
        icon: <FaceFrownIcon className={'custom-icon'} size={32} />
    },
    fine: {
        icon: <FaceNeutralIcon className={'custom-icon'} size={32} />
    },
    good: {
        icon: <FaceHappyIcon className={'custom-icon'} size={32} />
    },
    really_good: {
        icon: <FaceSmileIcon className={'custom-icon'} size={32} />
    }
}

export const MoodIcon: FC<MoodIconProps> = ({label, variant, hasBackground = false}) => (
    <StyledMoodContainer align={'center'} $variant={variant} gap={2}>
        {hasBackground ? (
            <StyledMoodPrimaryWrapper $variant={variant}>
                <StyledMoodSecondaryWrapper $variant={variant}>{moodRecord[variant].icon}</StyledMoodSecondaryWrapper>
            </StyledMoodPrimaryWrapper>
        ) : (
            moodRecord[variant].icon
        )}
        <p>{label}</p>
    </StyledMoodContainer>
)
