import React, {useEffect} from 'react'
import {Container} from '@components/ui/container/Container.tsx'
import {useTranslation} from 'react-i18next'
import {PlusIcon, WordPressIcon} from '@components/ui/icon/Icon.tsx'
import {StyledContainerItems} from '@pages/articles/style.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {ArticleItem} from '@components/commons/article-item/ArticleItem.tsx'
import {EmptyState} from '@components/commons/empty-state/EmptyState.tsx'
import toast from 'react-hot-toast'
import {useGetArticles} from '@/features/articles/services/useGetArticles.ts'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {SkeletonArticleItem, skeletonItems} from '@components/commons/article-item/SkeletonArticleItems.tsx'
import {envVars} from '@/envVars.ts'

export const Articles: React.FC = () => {
    const {t} = useTranslation()
    const {data: articles, isLoading, isError} = useGetArticles()

    useEffect(() => {
        isError && toast.error(t('errors:default'))
    }, [isError])

    return (
        <>
            <Container fullWidth>
                <PageHero
                    title={t('routeNames:articles')}
                    ctaComponent={
                        articles &&
                        articles?.length > 0 && (
                            <Button onClick={() => window.open(envVars.VITE_CREATE_ARTICLES_ENDPOINT, '_blank')}>
                                <PlusIcon />
                                <p>{t('articles:cta')}</p>
                            </Button>
                        )
                    }
                />
                {isLoading && (
                    <StyledContainerItems direction={'column'} gap={4} fullWidth>
                        {skeletonItems?.map(item => <SkeletonArticleItem key={item} />)}
                    </StyledContainerItems>
                )}
                {!articles?.length && !isLoading ? (
                    <EmptyState
                        title={t('articles:title')}
                        description={t('articles:subtitle')}
                        onClickCta={() => window.open(envVars.VITE_CREATE_ARTICLES_ENDPOINT, '_blank')}
                        ctaLabel={
                            <>
                                <PlusIcon />
                                <p>{t('articles:cta')}</p>
                            </>
                        }
                        icon={<WordPressIcon />}
                    />
                ) : (
                    <StyledContainerItems direction={'column'} gap={4}>
                        {articles?.map(article => <ArticleItem key={article.id} article={article} />)}
                    </StyledContainerItems>
                )}
            </Container>
        </>
    )
}

Articles.displayName = 'Articles'
