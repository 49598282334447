import {SelectValue} from '@/types.ts'
import i18next from 'i18next'

export const MOOD_SELECT_OPTIONS: SelectValue[] = [
    {
        label: i18next.t('commons:mood:really_bad'),
        value: 'really_bad'
    },
    {
        label: i18next.t('commons:mood:bad'),
        value: 'bad'
    },
    {
        label: i18next.t('commons:mood:fine'),
        value: 'fine'
    },
    {
        label: i18next.t('commons:mood:good'),
        value: 'good'
    },
    {
        label: i18next.t('commons:mood:really_good'),
        value: 'really_good'
    }
]

export const MOONEY_GAMBLED_SELECT_OPTIONS: SelectValue[] = [
    {
        label: i18next.t('commons:bets:very_low'),
        value: 'very_low'
    },
    {
        label: i18next.t('commons:bets:low'),
        value: 'low'
    },
    {
        label: i18next.t('commons:bets:medium'),
        value: 'medium'
    },
    {
        label: i18next.t('commons:bets:high'),
        value: 'high'
    },
    {
        label: i18next.t('commons:bets:very_high'),
        value: 'very_high'
    }
]
export const STATE_SELECT_OPTIONS: SelectValue[] = [
    {
        label: i18next.t('check_in:filters:state:compiled'),
        value: 'true'
    },
    {
        label: i18next.t('check_in:filters:state:not_compiled'),
        value: 'false'
    }
]
