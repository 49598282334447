import {z} from 'zod'

export const CHECK_IN_FILTERS_MODEL = {
    emotion: {
        name: 'emotion',
        label: 'check_in:filters:mood:label',
        placeholder: 'check_in:filters:mood:placeholder',
        defaultValue: '',
        helpText: ''
    },
    bets: {
        name: 'bets',
        label: 'check_in:filters:money_gambled:label',
        placeholder: 'check_in:filters:money_gambled:placeholder',
        defaultValue: '',
        helpText: ''
    },
    checkInCompleted: {
        name: 'checkInCompleted',
        label: 'check_in:filters:state:label',
        placeholder: 'check_in:filters:state:placeholder',
        defaultValue: '',
        helpText: ''
    }
} as const

export const CheckInFiltersValidationSchema = z.object({
    emotion: z.string().optional(),
    bets: z.string().optional(),
    checkInCompleted: z.string().optional()
})
export type CheckInFiltersValidationSchema = z.infer<typeof CheckInFiltersValidationSchema>
