import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {spacing} from '@/theme/theme.ts'

export const StyledDiaryWrapper = styled(Flexbox)(
    () => css`
        padding: 0 ${spacing * 6}px ${spacing * 4}px ${spacing * 6}px;
    `
)

export const StyledSelectedDay = styled.h3(
    ({theme: {palette, spacing, typography}}) => css`
        ${typography.textLg};
        font-weight: 600;
        color: ${palette.neutral[900]};
        padding: 0 ${spacing * 6}px;
    `
)

export const StyledSectionTitle = styled.p(
    ({theme: {palette, typography}}) => css`
        ${typography.textMd};
        color: ${palette.neutral[900]};
        font-weight: 500;
    `
)

export const StyledCheckInQuestion = styled.p(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        color: ${palette.neutral[600]};
        font-weight: 400;
    `
)

export const StyledCheckInAnswer = styled.p(
    ({theme: {palette, typography}}) => css`
        ${typography.textMd};
        color: ${palette.neutral[700]};
        font-weight: 500;
    `
)
