import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledContainerItems = styled(Flexbox)`
    ${() => css`
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    `}
`
