import styled, {css} from 'styled-components'

export const StyledPageContent = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);
        width: 100%;
        max-width: 100%;
        height: 100%;
        margin: 0 auto;
        ${mediaQueries.l} {
            padding: 0 ${spacing * 4}px;
        }
    `}
`
