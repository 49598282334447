import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledAppointmentCardBody = styled(Flexbox)(
    ({theme}) => css`
        padding: ${theme.spacing * 6}px;
        & p {
            ${theme.typography.textMd};
            font-weight: 500;
            color: ${theme.palette.neutral['black']};
        }
    `
)
export const StyledAppointmentCardNote = styled.div(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 6}px;
        background-color: ${palette.neutral[100]};
        border-radius: 0 0 ${spacing * 3}px ${spacing * 3}px;
        border: 1px solid ${palette.neutral[200]};
    `
)

export const StyledDeleteDropdownItem = styled(Flexbox)(
    ({theme: {palette}}) => css`
        color: ${palette.danger[700]};
    `
)
