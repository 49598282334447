import {FC} from 'react'
import {StyledContainerButtonToChangePassword, StyledPasswordManagementFormWrapper} from '@pages/profile/style.ts'
import {useTranslation} from 'react-i18next'
import {StyledSettingsSubtitle} from '@pages/settings/style.ts'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useForm} from 'react-hook-form'
import {
    PASSWORD_MANAGEMENT_FORM_MODEL,
    PasswordManagementValidationSchema
} from '@/features/profile/ProfileFormModal.ts'
import {zodResolver} from '@hookform/resolvers/zod'
import {useEditPassword} from '@/features/profile/services/useEditPassword.ts'
import {EditPassword} from '@/features/profile/types.ts'

export const PasswordManagementForm: FC = () => {
    const {t} = useTranslation()
    const {mutateAsync: editPassword} = useEditPassword({
        onSuccess: () => {
            reset()
        }
    })

    const {
        handleSubmit,
        register,
        reset,
        formState: {errors, touchedFields, isDirty, isValid}
    } = useForm<PasswordManagementValidationSchema>({
        mode: 'all',
        resolver: zodResolver(PasswordManagementValidationSchema),
        defaultValues: {
            currentPassword: '',
            password: '',
            passwordConfirmation: ''
        }
    })

    const onSubmit = async (data: EditPassword) => {
        await editPassword(data)
    }

    return (
        <StyledPasswordManagementFormWrapper onSubmit={handleSubmit(onSubmit)}>
            <StyledSettingsSubtitle>{t('profile:subtitle_password_management')}</StyledSettingsSubtitle>

            <InputText
                type={'password'}
                visibilityToggle
                label={t(PASSWORD_MANAGEMENT_FORM_MODEL.currentPassword.label)}
                placeholder={t(PASSWORD_MANAGEMENT_FORM_MODEL.currentPassword.placeholder)}
                errorMessage={t(errors.currentPassword?.message || '')}
                touched={touchedFields.currentPassword}
                {...register(PASSWORD_MANAGEMENT_FORM_MODEL.currentPassword.name)}
            />

            <InputText
                type={'password'}
                visibilityToggle
                label={t(PASSWORD_MANAGEMENT_FORM_MODEL.password.label)}
                placeholder={t(PASSWORD_MANAGEMENT_FORM_MODEL.password.placeholder)}
                errorMessage={t(errors.password?.message || '')}
                touched={touchedFields.password}
                {...register(PASSWORD_MANAGEMENT_FORM_MODEL.password.name)}
            />

            <InputText
                type={'password'}
                visibilityToggle
                label={t(PASSWORD_MANAGEMENT_FORM_MODEL.passwordConfirmation.label)}
                placeholder={t(PASSWORD_MANAGEMENT_FORM_MODEL.passwordConfirmation.placeholder)}
                errorMessage={t(errors.passwordConfirmation?.message || '')}
                touched={touchedFields.passwordConfirmation}
                {...register(PASSWORD_MANAGEMENT_FORM_MODEL.passwordConfirmation.name)}
            />

            <StyledContainerButtonToChangePassword>
                <Button variant={'primary'} type={'submit'} disabled={!isDirty || !isValid}>
                    {t('profile:action:change_password')}
                </Button>
            </StyledContainerButtonToChangePassword>
        </StyledPasswordManagementFormWrapper>
    )
}
