import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {DashboardUrlParamsExtended} from '@/features/dashboard/types.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {ErrorIcon} from 'react-hot-toast'
import {useTranslation} from 'react-i18next'
import {Cell, Pie, PieChart, Tooltip} from 'recharts'
import {adaptDataForChart} from '@/features/dashboard/utils.ts'
import {EmptyChart} from '@/features/dashboard/components/empty-chart/EmptyChart.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTheme} from 'styled-components'
import {useGetEmotionsGroup} from '@/features/dashboard/services/useGetEmotionsGroup.ts'
import {MoodIcon} from '@components/commons/mood-icon/MoodIcon.tsx'
import {Emotion} from '@/features/auth/types.ts'
import {round} from '@utilities/helpers.ts'
import {StyledEmotionLegendContainer} from '@/features/dashboard/components/genders-trend-chart/style.ts'

export const EmotionsGroupChart = () => {
    const {t} = useTranslation()
    const {searchParams} = useParsedSearchParams([], DashboardUrlParamsExtended)
    const {isLoading, isError, data} = useGetEmotionsGroup(searchParams)
    const {palette} = useTheme()
    const colors = [
        palette.purple[600],
        palette.warning[600],
        palette.danger[600],
        palette.success[600],
        palette.primary[600]
    ]

    return (
        <div>
            {isLoading ? (
                <Flexbox gap={4}>
                    <Flexbox fullWidth direction={'column'} gap={6}>
                        {Array.from(Array(5)).map((_, index) => (
                            <StyledEmotionLegendContainer key={index}>
                                <Skeleton height={32} />
                                <Skeleton height={32} width={120} />
                            </StyledEmotionLegendContainer>
                        ))}
                    </Flexbox>
                    <Skeleton height={200} width={200} />
                </Flexbox>
            ) : isError ? (
                <ErrorBox icon={<ErrorIcon />} title={t('errors:default')} />
            ) : (
                data && (
                    <Flexbox gap={4} align={'center'}>
                        <Flexbox direction="column" gap={4}>
                            {data.partitions.map((value, index) => (
                                <StyledEmotionLegendContainer key={index}>
                                    <p>{value.percentage ? `${round(value.percentage)}%` : value.total}</p>
                                    <MoodIcon
                                        variant={value.label as Emotion['name']}
                                        label={t(`commons:mood:${value.label}`)}
                                    />
                                </StyledEmotionLegendContainer>
                            ))}
                        </Flexbox>
                        {data.total !== 0 ? (
                            <PieChart width={200} height={200}>
                                <Pie
                                    data={adaptDataForChart(data.partitions, colors)}
                                    innerRadius={'50%'}
                                    outerRadius={'100%'}
                                    dataKey="total"
                                >
                                    {adaptDataForChart(data.partitions, colors).map((value, index) => (
                                        <Cell key={`cell-${index}`} fill={value.fill} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        ) : (
                            <EmptyChart height={200} width={200} title={t('commons:no_metrics')} />
                        )}
                    </Flexbox>
                )
            )}
        </div>
    )
}
