import {z} from 'zod'

export const PATIENTS_FILTERS_MODEL = {
    age: {
        name: 'age',
        label: 'patients:filters:age:label',
        placeholder: 'patients:filters:age:placeholder',
        defaultValue: '',
        helpText: ''
    },
    gender: {
        name: 'gender',
        label: 'patients:filters:genre:label',
        placeholder: 'patients:filters:genre:placeholder',
        defaultValue: '',
        helpText: ''
    },
    place: {
        name: 'place',
        label: 'patients:filters:place:label',
        placeholder: 'patients:filters:place:placeholder',
        defaultValue: '',
        helpText: ''
    },
    appointmentModality: {
        name: 'appointmentModality',
        label: 'patients:filters:mode:label',
        placeholder: 'patients:filters:mode:placeholder',
        defaultValue: '',
        helpText: ''
    }
} as const

export const PatientFiltersValidationSchema = z.object({
    age: z.string().optional(),
    gender: z.string().optional(),
    place: z.string().optional(),
    appointmentModality: z.string().optional()
})
export type PatientFiltersValidationSchema = z.infer<typeof PatientFiltersValidationSchema>
