import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledSelfAssessmentPatientLink = styled(Link)(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        color: ${palette.primary[600]};
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;
    `
)

export const StyledSelfAssessmentInfoContainer = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        width: 180px;
        & h4 {
            ${typography.textSm};
            color: ${palette.neutral[600]};
            font-weight: 500;
        }
    `
)

export const StyledSelfAssessmentDetailsOutcome = styled.p<{$hasPositive: boolean}>(
    ({theme: {palette, typography}, $hasPositive}) => css`
        color: ${$hasPositive ? palette.danger[600] : palette.success[600]};
        font-weight: 400;
        ${typography.textSm};
    `
)
