import {z} from 'zod'
import {OrderDirection, PaginationParams, PaginationResponse, ResponseType} from '@/types.ts'
import {User} from '@/features/auth/types.ts'

export const SettingsSearchParams = z.object({
    type: z.enum(['users', 'contact']).optional().default('users')
})

export type SettingsSearchParams = z.infer<typeof SettingsSearchParams>

export const SettingsTabs = {
    users: 'users',
    contact: 'contact'
} as const

export const SETTINGS_TABS = [
    {
        id: SettingsTabs.users,
        name: 'settings:tabs:users'
    },
    {
        id: SettingsTabs.contact,
        name: 'settings:tabs:contact'
    }
]

export const ACTION_TYPES = {
    edit: 'edit',
    recovery_password: 'recovery_password',
    delete: 'delete',
    create: 'create'
} as const

export const UsersSearchParams = z.object({
    orderBy: z.enum(['id', 'name', 'email', 'role', 'created_at']).optional(),
    orderDirection: OrderDirection.optional(),
    roles: z.enum(['patient', 'caregiver', 'therapist', 'admin', 'operator']).array().optional()
})

export type UsersSearchParams = z.infer<typeof UsersSearchParams>
export const HttpGetUsersOptions = z.object({
    ...UsersSearchParams.shape,
    ...PaginationParams.shape,
    responseType: ResponseType.optional()
})

export type HttpGetUsersOptions = z.infer<typeof HttpGetUsersOptions>
export const HttpGetUsersResponse = z.object({
    data: z.array(User),
    meta: PaginationResponse
})

export const Role = z.object({
    id: z.number(),
    name: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
    deletedAt: z.string().nullable()
})

export type Role = z.infer<typeof Role>

export const HttpGetRolesResponse = z.object({
    data: z.array(Role),
    meta: PaginationResponse
})

export type HttpGetRolesResponse = z.infer<typeof HttpGetRolesResponse>

export const HttpShowUsersOptions = z.object({
    userId: z.coerce.number().optional()
})
export type HttpShowUsersOptions = z.infer<typeof HttpShowUsersOptions>
