import {z} from 'zod'
import {UserBackOfficeSmall, UserSmall} from '@/features/auth/types.ts'
import {PaginationResponse, RangeDateLabels} from '@/types.ts'

export const AppointmentStatus = z.enum(['missed', 'completed', 'booked', 'cancelled'])
export type AppointmentStatus = z.infer<typeof AppointmentStatus>
export const Appointment = z.object({
    id: z.number(),
    status: AppointmentStatus,
    start: z.string(),
    end: z.string(),
    officeId: z.number().nullable(),
    therapistId: z.number().nullable(),
    patientId: z.number(),
    linkVideoCall: z.string().url().nullable(),
    createdAt: z.string(),
    updatedAt: z.string(),
    therapist: UserBackOfficeSmall.nullish(),
    patient: UserSmall.nullish(),
    isRemote: z.boolean(),
    office: z
        .object({
            id: z.number(),
            name: z.string(),
            address: z.string()
        })
        .nullish(),
    appointmentCustomizations: z
        .array(
            z.object({
                note: z.string().nullable(),
                id: z.number(),
                userId: z.number()
            })
        )
        .nullish()
})

export type Appointment = z.infer<typeof Appointment>

export const AppointmentSearchParams = z.object({
    therapistId: z.coerce.number().optional(),
    fromDate: z.string(),
    toDate: z.string()
})

export type AppointmentSearchParams = z.infer<typeof AppointmentSearchParams>

export const AppointmentsUrlParams = z.object({
    ...AppointmentSearchParams.omit({fromDate: true, toDate: true}).shape,
    rangeDateLabels: RangeDateLabels
})

export type AppointmentsUrlParams = z.infer<typeof AppointmentsUrlParams>

export const Slot = z.object({
    start: z.string(),
    end: z.string(),
    officeId: z.number().nullish(),
    userId: z.number(),
    id: z.number().nullish()
})

export type Slot = z.infer<typeof Slot>

export interface TimeSlot {
    start: number
    end: number
    appointment?: Appointment
    slotInfo?: Slot
    isBooked?: boolean
    isAvailable?: boolean
}

export interface WeekSlots {
    [day: string]: TimeSlot[]
}

export interface AvailableSlotsForDoctor {
    [therapistId: number]: {
        slot: WeekSlots
    }
}

export const HttpGetAvailableSlotsResponseSchema = z.object({
    data: z.array(Slot),
    meta: PaginationResponse
})
export type HttpGetAvailableSlotsResponseSchema = z.infer<typeof HttpGetAvailableSlotsResponseSchema>

export const ACTION_TYPES_APPOINTMENT_DETAILS = {
    edit: 'edit',
    delete: 'delete',
    completed: 'completed',
    missed: 'missed'
} as const

export const HttpEditAppointmentPayload = z.object({
    status: AppointmentStatus.nullish(),
    appointmentID: z.number(),
    start: z.string().optional(),
    end: z.string().optional(),
    officeId: z.string().optional(),
    therapistId: z.string().optional(),
    patientId: z.string().optional(),
    remindAt: z.string().nullish(),
    isRemote: z.boolean().nullish()
})

export type HttpEditAppointmentPayload = z.infer<typeof HttpEditAppointmentPayload>

export const PrefilledAppointment = z.object({
    date: z.string().optional(),
    start: z.string().optional(),
    end: z.string().optional(),
    officeId: z.number().nullish(),
    therapistId: z.number().optional(),
    patientId: z.number().optional()
})

export type PrefilledAppointment = z.infer<typeof PrefilledAppointment>

//Shifts

const timeSchema = z.object({
    hour: z.number().int().min(0).max(23),
    minute: z.number().int().min(0).max(59)
})

const shiftTimeSchema = z.object({
    fromTime: timeSchema,
    toTime: timeSchema
})

const daysScheduleSchema = z.object({
    sunday: z.array(shiftTimeSchema).nullish(),
    monday: z.array(shiftTimeSchema).nullish(),
    tuesday: z.array(shiftTimeSchema).nullish(),
    wednesday: z.array(shiftTimeSchema).nullish(),
    thursday: z.array(shiftTimeSchema).nullish(),
    friday: z.array(shiftTimeSchema).nullish(),
    saturday: z.array(shiftTimeSchema).nullish()
})

export type DaysSchedule = z.infer<typeof daysScheduleSchema>

export const Shift = z.object({
    id: z.number(),
    userId: z.number(),
    officeId: z.number(),
    fromDate: z.string(),
    toDate: z.string(),
    daysSchedule: daysScheduleSchema || z.object({})
})

export type Shift = z.infer<typeof Shift>

export const HttpShiftsResponse = z.object({
    data: z.array(Shift),
    meta: PaginationResponse
})

export type HttpShiftsResponse = z.infer<typeof HttpShiftsResponse>

export const HttpCreateShiftPayload = z.object({
    userId: z.number(),
    officeId: z.number(),
    fromDate: z.string(),
    toDate: z.string(),
    daysSchedule: daysScheduleSchema
})

export type HttpCreateShiftPayload = z.infer<typeof HttpCreateShiftPayload>

export const appointmentModeOptions = z.enum(['remote', 'office', 'all'])

export const AppointmentFilters = z.object({
    doctorId: z.string().optional(),
    appointmentMode: appointmentModeOptions.optional(),
    search: z.string().optional()
})

export type AppointmentFilters = z.infer<typeof AppointmentFilters>
