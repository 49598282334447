import {useGetCheckInDetails} from '@/features/check-in-details/services/useGetCheckInDetails.ts'
import {MoodIcon} from '@components/commons/mood-icon/MoodIcon.tsx'
import {
    StyledMoodIconWrapper,
    StyledMoodSectionGrid
} from '@/features/check-in-details/components/mood-section/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'
import {formatDate} from '@/dayjs.ts'
import {HelpCircleIcon} from '@components/ui/icon/Icon.tsx'
import {MoodSectionSkeleton} from '@/features/check-in-details/components/mood-section/MoodSectionSkeleton.tsx'

export const MoodSection = ({checkInId}: {checkInId: number}) => {
    const {t} = useTranslation()
    const checkInDetailsQuery = useGetCheckInDetails({checkInId})

    return (
        <>
            {checkInDetailsQuery.isLoading ? (
                <MoodSectionSkeleton />
            ) : checkInDetailsQuery.data?.recorded_moods ? (
                checkInDetailsQuery.data.recorded_moods.map(data => (
                    <StyledMoodSectionGrid>
                        <StyledMoodIconWrapper>
                            <MoodIcon label={t(`check_in:filters:mood:${data.mood.name}`)} variant={data.mood.name} />
                        </StyledMoodIconWrapper>
                        <Flexbox direction={'column'} gap={2}>
                            <Flexbox gap={2}>
                                <h4>{t('check_in_details:compilation_date')}</h4>
                                <p>{formatDate(data.created_at, 'D MMM, YYYY - HH:mm')}</p>
                            </Flexbox>
                            <Flexbox gap={2}>
                                <h4>{t('check_in_details:thoughts')}</h4>
                                <p>{data.thoughts ? t('commons:yes') : t('commons:no')}</p>
                            </Flexbox>
                        </Flexbox>
                    </StyledMoodSectionGrid>
                ))
            ) : (
                <StyledMoodSectionGrid>
                    <StyledMoodIconWrapper>
                        <Flexbox align={'center'} gap={2}>
                            <HelpCircleIcon />
                            <span>{t('check_in_details:not_compiled')}</span>
                        </Flexbox>
                    </StyledMoodIconWrapper>
                    <Flexbox direction={'column'} gap={2}>
                        <Flexbox gap={2}>
                            <h4>{t('check_in_details:compilation_date')}</h4>
                            <p>-</p>
                        </Flexbox>
                        <Flexbox gap={2}>
                            <h4>{t('check_in_details:thoughts')}</h4>
                            <p>-</p>
                        </Flexbox>
                    </Flexbox>
                </StyledMoodSectionGrid>
            )}
        </>
    )
}
