import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledDashboardTitle = styled.h1`
    ${({theme: {typography, palette}}) => css`
        ${typography.textXl};
        font-weight: 600;
        color: ${palette.neutral[900]};
    `}
`
export const StyledContainerDashboardPage = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        padding-bottom: ${spacing * 10}px;
    `}
`
