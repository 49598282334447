import styled, {css} from 'styled-components'

export const StyledContainerSettingsBody = styled.div`
    ${({theme}) => css`
        padding-top: ${theme.spacing * 8}px;
        padding-bottom: ${theme.spacing * 6}px;
    `}
`

export const StyledSettingsSubtitle = styled.p`
    ${({theme}) => css`
        ${theme.typography.textLg};
        font-weight: 600;
        color: ${theme.palette.neutral[900]};
        grid-column: 1 / -1;
    `}
`

export const StyledContainerUserTable = styled.div`
    ${() => css`
        height: calc(100vh - 500px);
        width: 100%;
    `}
`

export const StyledWrapperRemindersTime = styled.div`
    ${({theme}) => css`
        display: grid;
        grid-template-columns: 130px auto;
        align-items: center;
        gap: ${theme.spacing * 5}px;
        ${theme.typography.textSm};

        span.title {
            color: ${theme.palette.neutral[900]};
        }

        span.description {
            color: ${theme.palette.neutral[500]};
        }
    `}
`
