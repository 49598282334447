import {Dispatch, FC, SetStateAction, useState} from 'react'
import {BaseCard} from '@components/commons/cards/BaseCard.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    CalendarCheck01Icon,
    CalendarDateIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    ClockIcon,
    DotsVerticalIcon,
    FilePlus02Icon,
    MarkerPin01Icon,
    MessageXSquareIcon,
    PencilLineIcon,
    Trash01Icon,
    VideoRecorderIcon
} from '@components/ui/icon/Icon.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {
    StyledAppointmentCardBody,
    StyledAppointmentCardNote,
    StyledDeleteDropdownItem
} from '@/features/patient-details/components/appointment-item/style.ts'
import {DropdownMenu} from '@components/ui/dropdown-menu/DropdownMenu.tsx'
import dayjs, {formatDate} from '@/dayjs.ts'
import {useTranslation} from 'react-i18next'
import {AppointmentStatusChip} from '@components/commons/appointment-status-chip/AppointmentStatusChip.tsx'
import {Appointment, AppointmentStatus} from '@/features/appointments/types.ts'
import {Editor} from '@/features/appointments/components/editor/Editor.tsx'
import {useGetContactsDetails} from '@/features/settings/services/services-contact/useGetContactsDetails.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {useEditAppointment} from '@/features/appointments/services/useEditAppointment.ts'
import {useDeleteAppointment} from '@/features/appointments/services/useDeleteAppointment.ts'

interface AppointmentItemProps {
    appointment: Appointment
    setOpenAddOrEditAppointmentModal: Dispatch<SetStateAction<{open: boolean; id: number | null}>>
}
export const AppointmentItem: FC<AppointmentItemProps> = ({appointment, setOpenAddOrEditAppointmentModal}) => {
    const {t} = useTranslation()
    const [isVisibleInfo, setIsVisibleInfo] = useState<boolean>(false)
    const office = useGetContactsDetails({id: appointment.officeId})
    const {mutate: editAppointment} = useEditAppointment()
    const {mutate: deleteAppointment} = useDeleteAppointment()

    return (
        <BaseCard bodyHasBorder={false}>
            <StyledAppointmentCardBody direction={'column'} gap={4}>
                <Flexbox justify={'space-between'} fullWidth>
                    <AppointmentStatusChip
                        label={t(`commons:appointment_state:${appointment.status}`)}
                        variant={appointment.status}
                    />
                    <DropdownMenu
                        trigger={<DotsVerticalIcon />}
                        actions={[
                            {
                                component: (
                                    <Flexbox gap={2} align={'center'}>
                                        <CalendarCheck01Icon size={16} />
                                        <p>{t('patient_details:appointments_tab:dropdown:mark_done')}</p>
                                    </Flexbox>
                                ),
                                onClickCb: () =>
                                    editAppointment({
                                        appointmentID: appointment.id!,
                                        status: AppointmentStatus.enum.completed
                                    })
                            },
                            {
                                component: (
                                    <Flexbox gap={2} align={'center'}>
                                        <MessageXSquareIcon size={16} />
                                        <p>{t('patient_details:appointments_tab:dropdown:mark_missed')}</p>
                                    </Flexbox>
                                ),
                                onClickCb: () =>
                                    editAppointment({
                                        appointmentID: appointment.id!,
                                        status: AppointmentStatus.enum.missed
                                    })
                            },
                            {
                                component: (
                                    <Flexbox gap={2} align={'center'}>
                                        <PencilLineIcon size={16} />
                                        <p>{t('patient_details:appointments_tab:dropdown:edit')}</p>
                                    </Flexbox>
                                ),
                                onClickCb: () => setOpenAddOrEditAppointmentModal({open: true, id: appointment.id})
                            },
                            {
                                component: (
                                    <StyledDeleteDropdownItem gap={2} align={'center'}>
                                        <Trash01Icon size={16} />
                                        <p>{t('patient_details:appointments_tab:dropdown:delete')}</p>
                                    </StyledDeleteDropdownItem>
                                ),
                                onClickCb: () => deleteAppointment(appointment.id)
                            }
                        ]}
                    />
                </Flexbox>
                <Flexbox gap={6}>
                    <Flexbox gap={2} align={'center'}>
                        <CalendarDateIcon />
                        <p>{formatDate(appointment.start, 'd MMM YYYY')}</p>
                    </Flexbox>
                    <Flexbox gap={2} align={'center'}>
                        <ClockIcon />
                        <p>
                            {`${formatDate(appointment.start, 'HH:mm')} - ${formatDate(appointment.end, 'HH:mm')} 
                            (${dayjs(appointment.end).diff(dayjs(appointment.start), 'hour')} ${t('patient_details:appointments_tab:hour')})`}
                        </p>
                    </Flexbox>
                </Flexbox>
                <Flexbox justify={'space-between'} fullWidth>
                    <Flexbox gap={6} align={'center'}>
                        <Flexbox gap={2} align={'center'}>
                            {appointment.isRemote ? <VideoRecorderIcon size={20} /> : <MarkerPin01Icon size={20} />}
                            {office.isLoading ? <Skeleton height={24} width={200} /> : <p>{office.data?.name}</p>}
                        </Flexbox>
                        {appointment.isRemote && (
                            <Button variant={'secondary'}>
                                <VideoRecorderIcon />
                                {t('patient_details:appointments_tab:play_meet')}
                            </Button>
                        )}
                    </Flexbox>
                    {appointment.appointmentCustomizations?.find(item => item.userId === appointment.therapist?.id)
                        ?.note ? (
                        <Button variant={'tertiaryColor'} onClick={() => setIsVisibleInfo(!isVisibleInfo)}>
                            {isVisibleInfo ? <ChevronUpIcon /> : <ChevronDownIcon />}
                            {t('patient_details:appointments_tab:view_note')}
                        </Button>
                    ) : (
                        <Button variant={'secondaryColor'} onClick={() => setIsVisibleInfo(prev => !prev)}>
                            <FilePlus02Icon />
                            {t('patient_details:appointments_tab:add_note')}
                        </Button>
                    )}
                </Flexbox>
            </StyledAppointmentCardBody>
            {isVisibleInfo && (
                <StyledAppointmentCardNote>
                    <Editor appointment={appointment} />
                </StyledAppointmentCardNote>
            )}
        </BaseCard>
    )
}
