import {InitialConfigType, LexicalComposer} from '@lexical/react/LexicalComposer'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin'
import {HeadingNode} from '@lexical/rich-text'
import {ListPlugin} from '@lexical/react/LexicalListPlugin'
import {ListNode, ListItemNode} from '@lexical/list'
import {
    StyledContentEditable,
    StyledHeaderRichTextArea,
    StyledPlaceholder,
    StyledRichTextArea,
    StyledRichTextareaWrapper
} from '@components/ui/rich-text-area/style.ts'
import {ToolbarPlugin} from '@components/ui/rich-text-area/ToolbarPlugin.tsx'
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin'
import {EditorState, createEditor} from 'lexical'

interface RichTextProps {
    placeholder: string
    onChange: (event: string) => void
    value: string | null
    isEditEnabled: boolean
}

export const RichTextArea = ({placeholder, value, onChange, isEditEnabled}: RichTextProps) => {
    const initialConfig: InitialConfigType = {
        namespace: 'RichTextArea',
        onError: err => console.error(err),
        editorState: value
            ? createEditor({nodes: [HeadingNode, ListNode, ListItemNode]}).parseEditorState(value)
            : undefined,
        editable: true,
        nodes: [HeadingNode, ListNode, ListItemNode]
    }

    const onChangeState = (editorState: EditorState) => {
        const editorStateJSON = JSON.stringify(editorState.toJSON())
        onChange(editorStateJSON)
    }

    return (
        <StyledRichTextArea $isEditEnabled={isEditEnabled}>
            <LexicalComposer initialConfig={initialConfig}>
                {isEditEnabled && <StyledHeaderRichTextArea>{<ToolbarPlugin />}</StyledHeaderRichTextArea>}
                <StyledRichTextareaWrapper $isEditEnabled={isEditEnabled}>
                    <RichTextPlugin
                        contentEditable={<StyledContentEditable />}
                        placeholder={<StyledPlaceholder>{placeholder}</StyledPlaceholder>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <OnChangePlugin onChange={onChangeState} />
                    <ListPlugin />
                </StyledRichTextareaWrapper>
            </LexicalComposer>
        </StyledRichTextArea>
    )
}
