import styled, {css} from 'styled-components'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'

type StyledItemWrapperProps = {
    $isActive: boolean
}
export const StyledItemWrapper = styled(ButtonLink)<StyledItemWrapperProps>`
    ${({theme: {spacing, palette}, $isActive}) => css`
        width: 100%;
        padding: ${spacing * 2}px ${spacing * 3}px;
        justify-content: left;
        align-items: center;
        border-radius: ${spacing * 2}px;

        ${$isActive &&
        css`
            background-color: ${palette.primary[100]};
        `}
    `}
`

type StyledIconWrapperProps = {
    $isOnlyIcon?: boolean
}
export const StyledIconWrapper = styled.div<StyledIconWrapperProps>`
    ${({$isOnlyIcon}) => css`
        display: flex;

        ${$isOnlyIcon &&
        css`
            & svg {
                width: 20px;
                height: 20px;
            }
        `}
    `}
`

type StyledItemNameProps = {
    $isVisible: boolean
}

export const StyledItemName = styled.p<StyledItemNameProps>`
    ${({theme: {typography, palette}, $isVisible}) => css`
        ${typography.textMd}
        font-weight: 600;
        color: ${palette.neutral[700]};
        display: ${$isVisible ? 'block' : 'none'};
        overflow: hidden;
        white-space: nowrap;
        transition: width 0.5s ease;
    `}
`
