import styled, {css} from 'styled-components'

export const StyledEmotionLegendContainer = styled.div`
    ${({theme: {typography, spacing, palette}}) => css`
        ${typography.textXl};
        font-weight: 600;
        display: grid;
        grid-template-columns: 48px auto;
        gap: ${spacing * 2}px;
        color: ${palette.neutral[900]};
    `}
`
