import {useCheckInTable} from '@/features/check-in/services/useCheckInTable.ts'
import {Table} from '@components/commons/table/Table.tsx'
import {EmptySearch} from '@components/commons/empty-search/EmptySearch.tsx'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'
import {routes} from '@utilities/constants/routes.tsx'
import {ActivityIcon, CheckIcon, HelpCircleIcon, XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    StyledCheckInTableId,
    StyledCheckInTableLink,
    StyledCheckInTableThoughts
} from '@/features/check-in/components/check-in-table/style.ts'
import {MoodIcon} from '@components/commons/mood-icon/MoodIcon.tsx'
import {formatCurrency} from '@utilities/helpers.ts'
import {formatDate} from '@/dayjs.ts'
import React from 'react'

export const CheckInTable = () => {
    const checkInTable = useCheckInTable()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const onClickPatient = (event: React.MouseEvent<HTMLElement>, patientId: number) => {
        event.stopPropagation()
        return navigate(generatePath(routes.PATIENT_DETAILS.handle.path, {patientId: patientId}))
    }
    return (
        <Table
            data={checkInTable.remappedData}
            isLoading={checkInTable.isLoading}
            sorter={checkInTable.sorter}
            searchValue={checkInTable.searchValue}
            onClickRow={item => navigate(generatePath(routes.CHECK_IN_DETAILS.path, {checkInId: item.id}))}
            emptyStateComponent={
                <EmptySearch
                    title={t('check_in:table:empty_search:title')}
                    subtitle={t('check_in:table:empty_search:subtitle')}
                    onClearSearch={() => navigate(routes.CHECK_IN.path)}
                    icon={<ActivityIcon />}
                />
            }
            columns={[
                {
                    label: t('check_in:table:label:patient'),
                    width: '25%',
                    sortName: 'patient',
                    alignment: 'left',
                    cellRender: data => (
                        <Flexbox direction={'column'}>
                            <StyledCheckInTableLink
                                variant={'tertiaryColor'}
                                onClick={event => onClickPatient(event, data.user.id)}
                            >
                                {`${data.user.firstName} ${data.user.lastName}`}
                            </StyledCheckInTableLink>
                            <StyledCheckInTableId>{`ID ${data.id}`}</StyledCheckInTableId>
                        </Flexbox>
                    )
                },
                {
                    label: t('check_in:table:label:mood'),
                    width: '15%',
                    sortName: 'mood',
                    cellRender: data => (
                        <>
                            {data.emotion ? (
                                <MoodIcon variant={data.emotion.name} label={t(`commons:mood:${data.emotion.name}`)} />
                            ) : (
                                <Flexbox align={'center'} gap={2}>
                                    <HelpCircleIcon />
                                    {t('check_in:table:not_filled')}
                                </Flexbox>
                            )}
                        </>
                    )
                },
                {
                    label: t('check_in:table:label:thoughts'),
                    width: '15%',
                    cellRender: data => (
                        <>
                            {data.emotion ? (
                                data.emotion.name ? (
                                    <StyledCheckInTableThoughts
                                        align={'center'}
                                        gap={2}
                                        $hasThoughts={!data.emotion.name}
                                    >
                                        <CheckIcon />
                                        {t('commons:yes')}
                                    </StyledCheckInTableThoughts>
                                ) : (
                                    <StyledCheckInTableThoughts
                                        align={'center'}
                                        gap={2}
                                        $hasThoughts={!data.emotion.name}
                                    >
                                        <XCloseIcon />
                                        {t('commons:no')}
                                    </StyledCheckInTableThoughts>
                                )
                            ) : (
                                <StyledCheckInTableThoughts $hasThoughts={false}>-</StyledCheckInTableThoughts>
                            )}
                        </>
                    )
                },
                {
                    label: t('check_in:table:label:money_gambled'),
                    width: '19%',
                    sortName: 'money_gambled',
                    cellRender: data => (
                        <StyledCheckInTableThoughts $hasThoughts={false}>
                            {data.bet ? `-${formatCurrency(data.bet, 'EUR')}` : '-'}
                        </StyledCheckInTableThoughts>
                    )
                },
                {
                    label: t('check_in:table:label:state'),
                    width: '11%',
                    cellRender: data => (
                        <>
                            {data.completedAt ? (
                                <p>{t('check_in:table:filled')}</p>
                            ) : (
                                <p>{t('check_in:table:not_filled')}</p>
                            )}
                        </>
                    )
                },
                {
                    label: t('check_in:table:label:compiled_at'),
                    width: '15%',
                    sortName: 'state',
                    cellRender: data => (
                        <>{data.completedAt ? <p>{formatDate(data.completedAt, 'D MMM YYYY, HH:mm')}</p> : <p>-</p>}</>
                    )
                }
            ]}
        ></Table>
    )
}
