import styled, {css} from 'styled-components'
import {CSSProperties} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledOverlay = styled.div(
    ({theme: {palette, zIndex}}) => css`
        position: fixed;
        inset: 0;
        background: ${palette.neutral['600']};
        opacity: 0.6;
        z-index: ${zIndex.modalOverlay};
    `
)

export const StyledModal = styled.div<{width?: CSSProperties['width']}>(
    ({width, theme: {palette, zIndex, shadows, spacing}}) => css`
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: ${zIndex.modal};
        border-radius: 12px;
        background: ${palette.neutral.white};
        width: ${width}px;
        max-height: calc(100% - ${spacing * 6}px);
        max-width: calc(100% - ${spacing * 6}px);
        box-shadow: ${shadows.lg};
    `
)

export const StyledModalHeader = styled(Flexbox)<{dir: 'ltr' | 'rtl'; $closeIconOffset?: 'sm' | 'md' | 'lg'}>(
    ({dir, $closeIconOffset, theme: {spacing, palette, typography}}) => css`
        ${dir == 'ltr'
            ? `right: ${
                  $closeIconOffset == 'sm' ? spacing * 3 : $closeIconOffset == 'lg' ? spacing * 6 : spacing * 4
              }px;`
            : `left: ${
                  $closeIconOffset == 'sm' ? spacing * 3 : $closeIconOffset == 'lg' ? spacing * 6 : spacing * 4
              }px;`}

        padding: ${spacing * 4.5}px ${spacing * 6}px;
        border-bottom: 1px solid ${palette.neutral[300]};

        & > p {
            ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral[900]};
        }

        & > svg {
            cursor: pointer;
        }
    `
)

export const StyledModalFooter = styled(Flexbox)`
    ${({theme: {palette, spacing}}) => css`
        border-top: 1px solid ${palette.neutral[300]};
        width: 100%;
        padding: ${spacing * 4}px ${spacing * 6}px;
    `}
`

export const StyledModalBody = styled.div`
    ${() => css`
        max-height: calc(100vh - 230px);
        overflow-y: scroll;
    `}
`

export const StyledSpinnerContainer = styled.div`
    ${() => css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    `}
`
