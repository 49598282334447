import {CardStats} from '@/components/commons/card-stats/CardStats'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {InfoCircleIcon} from '@/components/ui/icon/Icon'
import {useTheme} from 'styled-components'
import {useTranslation} from 'react-i18next'
import {Tooltip} from '@/components/ui/tooltip/Tooltip'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {
    CheckInStatsMoneyListItemLabel,
    CheckInStatsMoneyListItemValue,
    StyledBetsGrid,
    StyledCheckInStatsGrid,
    StyledFilledOutPrimaryPercentage,
    StyledMoodGrid,
    StyledMoodPercentage
} from '@/features/check-in/components/check-in-stats-grid/style.ts'
import {MoodIcon} from '@components/commons/mood-icon/MoodIcon.tsx'
import {useGetCheckInStats} from '@/features/check-in/services/useGetCheckInStats.ts'
import {CheckInUrlSearchParams} from '@/features/check-in/types.ts'
import {PercentageSummary} from '@components/commons/percentage-summary/PercentageSummary.tsx'
import {Emotion} from '@/features/auth/types.ts'

export const CheckInStatsGrid = () => {
    const {t} = useTranslation()
    const {palette} = useTheme()

    const {searchParams} = useParsedSearchParams([], CheckInUrlSearchParams)
    const checkInStatsQuery = useGetCheckInStats({
        isCurrentlyInTherapy: searchParams.isCurrentlyInTherapy,
        dateRangeLabels: searchParams.dateRangeLabels
    })

    return (
        <StyledCheckInStatsGrid>
            {checkInStatsQuery.isLoading
                ? Array.from({length: 3}).map((_, index) => <CardStats asSkeleton key={index} />)
                : checkInStatsQuery.data && (
                      <>
                          <CardStats
                              headerSlot={
                                  <Flexbox justify="space-between">
                                      <h3>{t('check_in:card_stats:filled_out:title')}</h3>
                                      <Tooltip
                                          trigger={<InfoCircleIcon size={20} color={palette.neutral[400]} />}
                                          side="top"
                                          variant="light"
                                          content={<p>{t('check_in:card_stats:filled_out:info')}</p>}
                                      />
                                  </Flexbox>
                              }
                              bodySlot={
                                  <Flexbox direction="column" gap={4}>
                                      <StyledFilledOutPrimaryPercentage>
                                          {checkInStatsQuery.data?.completed.partitions[0].percentage}%
                                      </StyledFilledOutPrimaryPercentage>
                                      <Flexbox justify="start" gap={2}>
                                          <PercentageSummary
                                              amount={checkInStatsQuery.data.completed.total}
                                              percentage={checkInStatsQuery.data.completedIncrements.percentage}
                                              label={t(`commons:compare_labels:${searchParams.dateRangeLabels}`)}
                                          />
                                      </Flexbox>
                                  </Flexbox>
                              }
                          />

                          <CardStats
                              headerSlot={<h3>{t('check_in:card_stats:mood:title')}</h3>}
                              bodySlot={
                                  <StyledMoodGrid>
                                      {checkInStatsQuery.data?.emotions.partitions.map((result, index) => (
                                          <Flexbox gap={2} align="center" key={index}>
                                              <MoodIcon variant={result.label as Emotion['name']} />
                                              <StyledMoodPercentage>
                                                  {result.percentage ? `${result.percentage}%` : result.total}
                                              </StyledMoodPercentage>
                                          </Flexbox>
                                      ))}
                                  </StyledMoodGrid>
                              }
                          />

                          <CardStats
                              headerSlot={<h3>{t('check_in:card_stats:money_gambled:title')}</h3>}
                              bodySlot={
                                  <Flexbox gap={2} direction="column">
                                      {checkInStatsQuery.data?.bets.partitions.map((result, index) => (
                                          <StyledBetsGrid key={index}>
                                              <CheckInStatsMoneyListItemValue>
                                                  {result.percentage ? `${result.percentage}%` : result.total}
                                              </CheckInStatsMoneyListItemValue>
                                              <CheckInStatsMoneyListItemLabel>
                                                  {t(`commons:bets:${result.label}`)}
                                              </CheckInStatsMoneyListItemLabel>
                                          </StyledBetsGrid>
                                      ))}
                                  </Flexbox>
                              }
                          />
                      </>
                  )}
        </StyledCheckInStatsGrid>
    )
}
