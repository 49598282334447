import {SelectProps} from '@components/commons/select/Select.tsx'
import {useGetUsers} from '@/features/settings/services/services-users/useGetUsers.ts'
import {ResponseType} from '@/types.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {UsersSearchParams} from '@/features/settings/types.ts'
import {useTranslation} from 'react-i18next'
import {useGetUserDetails} from '@/features/settings/services/services-users/useGetUserDetails.ts'
import {FC} from 'react'
import {StyledDoctorSelect} from '@/features/dashboard/components/doctor-select/style.ts'

interface DoctorSelectProps {
    onChange: SelectProps['onChange']
    userId?: number
}

export const DoctorSelect: FC<DoctorSelectProps> = ({onChange, userId}) => {
    const {t} = useTranslation()
    const {searchParams} = useParsedSearchParams([], UsersSearchParams)
    const userQuery = useGetUserDetails({
        userId: userId
    })
    const {
        remappedData: users,
        isLoading,
        fetchNextPage
    } = useGetUsers({
        ...searchParams,
        responseType: ResponseType.enum.backofficeRegular,
        limit: 30,
        roles: ['therapist']
    })

    return (
        <StyledDoctorSelect
            options={users.map(option => ({
                label: `${option.lastName} ${option.firstName}`,
                value: option.id.toString()
            }))}
            onMenuScrollToBottom={fetchNextPage}
            isLoading={isLoading || userQuery.isLoading}
            size={'medium'}
            isSearchable={true}
            placeholder={t('dashboard:all_therapist')}
            value={
                userQuery.data && {
                    value: userQuery.data.id.toString(),
                    label: `${userQuery.data.lastName} ${userQuery.data.firstName}`
                }
            }
            onChange={onChange}
            noOptionsMessage={() => t('dashboard:empty_doctor_select')}
            loadingMessage={() => t('commons:loading')}
            isClearable
        />
    )
}
