import {StyledSpinner, StyledSpinnerOverlay} from '@components/ui/spinner/style.ts'

interface SpinnerProps {
    color?: string
    size: number
    haveOverlay?: boolean
}

export const Spinner = ({color = '#fff', size = 40, haveOverlay = false}: SpinnerProps) => {
    return (
        <>
            {haveOverlay ? (
                <StyledSpinnerOverlay>
                    <StyledSpinner color={color} size={size} />
                </StyledSpinnerOverlay>
            ) : (
                <StyledSpinner color={color} size={size} />
            )}
        </>
    )
}

Spinner.displayName = 'Spinner'
