import React from 'react'
import {Container} from '@components/ui/container/Container.tsx'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {useTranslation} from 'react-i18next'
import {useGetCheckInDetails} from '@/features/check-in-details/services/useGetCheckInDetails.ts'
import {StyledCheckInDetailsSectionTitle, StyledPatientInfoGrid} from '@pages/check-in-details/style.ts'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AlertCircleIcon, ClockIcon} from '@components/ui/icon/Icon.tsx'
import {CheckInDetailsURLParams} from '@/features/check-in-details/types.ts'
import {SurveyTable} from '@/features/check-in-details/components/survey-table/SurveyTable.tsx'
import {MoodSection} from '@/features/check-in-details/components/mood-section/MoodSection.tsx'
import {formatDate} from '@/dayjs.ts'
import {MoneyGambledSection} from '@/features/check-in-details/components/money-gambled-section/MoneyGambledSection.tsx'
import {useOutletContext} from 'react-router-dom'

export const CheckInDetails: React.FC = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: CheckInDetailsURLParams}>()
    const checkInDetailsQuery = useGetCheckInDetails({checkInId: urlParams.checkInId})
    return (
        <Container fullWidth>
            <PageHero title={`#${urlParams.checkInId}`} />
            {checkInDetailsQuery.isError ? (
                <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
            ) : (
                <Flexbox direction={'column'} gap={6}>
                    <StyledPatientInfoGrid>
                        <h4>{t('check_in_details:patient')}</h4>
                        <h4>{t('check_in_details:age')}</h4>
                        {checkInDetailsQuery.data && (
                            <ButtonLink
                                variant={'tertiaryColor'}
                                to={`/patients/${checkInDetailsQuery.data.patient_id}`}
                            >
                                {`${checkInDetailsQuery.data.patient_lastname} ${checkInDetailsQuery.data.patient_firstname}`}
                            </ButtonLink>
                        )}
                        <p>{checkInDetailsQuery.data?.patient_age}</p>
                    </StyledPatientInfoGrid>
                    <Divider direction={'horizontal'} />
                    <Flexbox gap={2} align={'center'}>
                        <StyledCheckInDetailsSectionTitle>
                            {t('check_in_details:check_in')}
                        </StyledCheckInDetailsSectionTitle>
                        {checkInDetailsQuery.data?.survey && (
                            <>
                                {checkInDetailsQuery.data.survey ? (
                                    <p>
                                        {formatDate(checkInDetailsQuery.data?.survey.created_at, 'D MMM, YYYY - HH:mm')}
                                    </p>
                                ) : (
                                    <Flexbox gap={6} align={'center'}>
                                        <ClockIcon />
                                        <span>{t('check_in_details:not_compiled')}</span>
                                    </Flexbox>
                                )}
                            </>
                        )}
                    </Flexbox>
                    <SurveyTable checkInId={urlParams.checkInId} />
                    <Divider direction={'horizontal'} />
                    <StyledCheckInDetailsSectionTitle>{t('check_in_details:mood')}</StyledCheckInDetailsSectionTitle>
                    <MoodSection checkInId={urlParams.checkInId} />
                    <Divider direction={'horizontal'} />
                    <StyledCheckInDetailsSectionTitle>
                        {t('check_in_details:money_gambled')}
                    </StyledCheckInDetailsSectionTitle>
                    <MoneyGambledSection checkInId={urlParams.checkInId} />
                </Flexbox>
            )}
        </Container>
    )
}

CheckInDetails.displayName = 'CheckInDetails'
