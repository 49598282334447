import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {PatientsSearchParams, PatientUrlSearchParams} from '../types'
import {useInputSearch} from '@/hooks/useInputSearch'
import {useGetPatients} from './useGetPatients'
import {useEffect} from 'react'

export const usePatientsTable = () => {
    const {searchParams, setSearchParams} = useParsedSearchParams([], PatientUrlSearchParams)
    const {searchValue, searchError, onSearch, onResetSearch} = useInputSearch(searchParams.name)

    const patientsQuery = useGetPatients({
        ...searchParams,
        limit: 20
    })

    useEffect(() => {
        setSearchParams({name: searchValue})
    }, [searchValue])

    const sort = (sorter: {
        orderBy: PatientsSearchParams['orderBy']
        orderDirection: PatientsSearchParams['orderDirection']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({orderBy: sorter.orderBy, orderDirection: sorter.orderDirection})
        } else {
            setSearchParams({orderBy: undefined, orderDirection: undefined})
        }
    }

    return {
        ...patientsQuery,
        searchValue,
        searchError,
        onSearch,
        onResetSearch,
        filterValue: Object.keys(searchParams)
            .filter(param => param !== 'dateRangeLabels' && param !== 'currentlyInTherapy')
            .some(value => value !== null && value !== undefined),
        sorter: {orderBy: searchParams.orderBy, orderDirection: searchParams.orderDirection, onSort: sort}
    }
}
